import React, { useEffect } from "react";
import SmsSettingService from "./SmsSettingService";
import { NavLink, useParams } from "react-router-dom";

const EditSmsSetting = () => {
  const {
    submitloader,
    handleSubmit,
    handleChange,
    input,
    errors,
    getDetailById,
  } = SmsSettingService();
  const param = useParams();

  useEffect(() => {
    if (param.id !== undefined) {
      getDetailById();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">SMS Setting </li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form className="form-container w-100">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Api Code :
                      <input
                        type="text"
                        className="form-control"
                        id="apiCode"
                        name="apiCode"
                        value={input?.apiCode}
                        placeholder="Enter the api code"
                        onChange={handleChange}
                      />
                      {errors?.apiCode && (
                        <div className="help-block text-danger">
                          {errors?.apiCode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      API Type:
                      <select
                        className="form-control"
                        id="apiType"
                        name="apiType"
                        value={input?.apiType}
                        onChange={handleChange}
                      >
                        <option value=""> Select</option>
                        <option value="0"> 0</option>
                        <option value="1"> 1</option>
                        <option value="2"> 2</option>
                        <option value="3"> 3</option>
                      </select>
                      {errors?.apiType && (
                        <div className="help-block text-danger">
                          {errors?.apiType}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      API Method:
                      <select
                        className="form-control"
                        id="apiMethod"
                        name="apiMethod"
                        value={input?.apiMethod}
                        onChange={handleChange}
                      >
                        <option value=""> Select</option>
                        <option value="GET"> GET</option>
                        <option value="POST"> POST</option>
                        <option value="PATCH"> PATCH</option>
                        <option value="DELETE"> DELETE</option>
                      </select>
                      {errors?.apiMethod && (
                        <div className="help-block text-danger">
                          {errors?.apiMethod}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Name :
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={input?.name}
                        placeholder="Enter the  name"
                        onChange={handleChange}
                      />
                      {errors?.name && (
                        <div className="help-block text-danger">
                          {errors?.name}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=" col-md-12">
                    <div className="form-group">
                      URL:
                      <textarea
                        className="form-control"
                        id="url"
                        name="url"
                        value={input?.url}
                        placeholder="Enter the URL"
                        rows={4}
                        onChange={handleChange}
                      ></textarea>
                      {errors?.url && (
                        <div className="help-block text-danger">
                          {errors?.url}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isWhatsapp"
                            type="checkbox"
                            checked={input?.isWhatsapp}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>WhatsApp</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isMultipleAllowed"
                            type="checkbox"
                            checked={input?.isMultipleAllowed}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Multiple Allowed</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isActive"
                            type="checkbox"
                            checked={input?.isActive}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Active</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            <>
              <button
                type="submit"
                className="btn btn-green"
                disabled={submitloader}
                onClick={handleSubmit}
              >
                {submitloader ? "Submitting" : "Submit"}
              </button>
            </>

            <NavLink to="/SmsSettings" className="btn btn-danger">
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};

export default EditSmsSetting;
