import React from "react";
import { NavLink } from "react-router-dom";
import EmailSettingServices from "./EmailSettingServices";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const EditEmailSetting = () => {
  const {
    handleSubmit,
    submitloader,
    errors,
    input,
    handleChange,
    getEmailById,
  } = EmailSettingServices();
  const params = useParams();

  useEffect(() => {
    if (params.id !== undefined) {
      getEmailById();
    }
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Email Setting </li>

                        <li className="breadcrumb-item active">Form</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container w-100">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <form className="form-container w-100">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Email From :
                        <input
                          type="email"
                          className="form-control"
                          id="emailFrom"
                          name="emailFrom"
                          value={input?.emailFrom}
                          placeholder="Enter the mail address"
                          onChange={handleChange}
                          required
                        />
                        {errors.emailFrom && (
                          <div className="help-block text-danger">
                            {errors.emailFrom}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Password :
                        <input
                          type="text"
                          className="form-control"
                          id="password"
                          placeholder="Enter the password"
                          name="password"
                          value={input?.password}
                          onChange={handleChange}
                        />
                        {errors.password && (
                          <div className="help-block text-danger">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        User Name :
                        <input
                          type="text"
                          className="form-control"
                          id="userId"
                          name="userId"
                          value={input?.userId}
                          placeholder="Enter the user name"
                          onChange={handleChange}
                        />
                        {errors.userId && (
                          <div className="help-block text-danger">
                            {errors.userId}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Host Name :
                        <input
                          type="text"
                          className="form-control"
                          id="hostName"
                          name="hostName"
                          value={input?.hostName}
                          placeholder="Enter the host name"
                          onChange={handleChange}
                        />
                        {errors.hostName && (
                          <div className="help-block text-danger">
                            {errors.hostName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Port :
                        <input
                          type="number"
                          className="form-control"
                          id="port"
                          name="port"
                          value={input?.port}
                          placeholder="Enter the port number"
                          onChange={handleChange}
                        />
                        {errors.port && (
                          <div className="help-block text-danger">
                            {errors.port}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        &nbsp;
                        <label className="mb-0 d-flex align-items-center">
                          <div className="radio-left">
                            <input
                              name="isActive"
                              type="checkbox"
                              checked={input?.isActive}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="radio-right">
                            <span>Active</span>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        &nbsp;
                        <label className="mb-0 d-flex align-items-center">
                          <div className="radio-left">
                            <input
                              name="isSSL"
                              type="checkbox"
                              checked={input?.isSSL}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="radio-right">
                            <span>SSL</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  disabled={submitloader}
                  onClick={handleSubmit}
                >
                  {submitloader ? "Submitting" : "Submit"}
                </button>
              </>

              <NavLink to="/EmailSettings" className="btn btn-danger">
                Close
              </NavLink>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default EditEmailSetting;
