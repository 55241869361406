import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import CouponListService from "./CouponListService";

const AddCoupon = () => {
  const {
    handleSubmit,
    handleChange,
    submitloader,
    input,
    errors,
    getCouponById,
    id,
  } = CouponListService();

  useEffect(() => {
    if (id) {
      getCouponById();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Coupon </li>

                      <li className="breadcrumb-item active">
                        {id ? "Edit" : "Add"}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container w-100">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Price :
                      <input
                        type="text"
                        className="form-control"
                        name="price"
                        placeholder="Please enter a price"
                        value={input?.price}
                        onChange={handleChange}
                      />
                      {errors.price && (
                        <div className="help-block text-danger">
                          {errors.price}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Code :
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please enter your code"
                        name="code"
                        value={input?.code}
                        onChange={handleChange}
                      />
                      {errors.code && (
                        <div className="help-block text-danger">
                          {errors.code}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Max Benefit :
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please enter max benefit"
                        name="maxbenefit"
                        value={input?.maxbenefit}
                        onChange={handleChange}
                      />
                      {errors.maxbenefit && (
                        <div className="help-block text-danger">
                          {errors.maxbenefit}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Min Purchase :
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please enter min purchase"
                        name="minPurchase"
                        value={input?.minPurchase}
                        onChange={handleChange}
                      />
                      {errors.minPurchase && (
                        <div className="help-block text-danger">
                          {errors.minPurchase}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Redeem Count :
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please enter redeem count"
                        name="redeemCount"
                        value={input?.redeemCount}
                        onChange={handleChange}
                      />
                      {errors.redeemCount && (
                        <div className="help-block text-danger">
                          {errors.redeemCount}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Frequency :
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please enter frequency"
                        name="frequency"
                        value={input?.frequency}
                        onChange={handleChange}
                      />
                      {errors.frequency && (
                        <div className="help-block text-danger">
                          {errors.frequency}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Short Description :
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please enter short description"
                        name="shortdescription"
                        value={input?.shortdescription}
                        onChange={handleChange}
                      />
                      {errors.shortdescription && (
                        <div className="help-block text-danger">
                          {errors.shortdescription}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Description :
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please enter description"
                        name="description"
                        value={input?.description}
                        onChange={handleChange}
                      />
                      {errors.description && (
                        <div className="help-block text-danger">
                          {errors.description}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isFixed"
                            type="checkbox"
                            checked={input?.isFixed}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Fixed</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isAssociated"
                            type="checkbox"
                            checked={input?.isAssociated}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Associated</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            {!submitloader ? (
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-green">Submitting...</button>
              </>
            )}
            <NavLink to="/CouponList" className="btn btn-danger">
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};
export default AddCoupon;
