import React from "react";
import HeaderTop from "../../Component/Homes/HeaderTop";
import FooterLast from "../../Component/Homes/FooterLast";

const Agripreneur = () => {
    return (
      <>
    
        <HeaderTop />
        {/* <HeaderBottom /> */}
        <>
        <main>


<section className="breadcrumb-area breadcrumb-bg">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumb-content">
                    <h2 className="title">Agripreneur</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Agripreneur</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="error-area pt-100 pb-100">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-6 col-md-10">
                <div className="error-content text-center">
                    <div className="error_txt">404</div>
                    <h5>oops! The page you requested was not found!</h5>
                    <p>The page you are looking for was moved, removed, renamed or might never existed.</p>
                    <div className="search_form">
                        <form method="post">
                            <input name="text" id="text" type="text" placeholder="Search Here ...." className="form-control" />
                            <button type="submit" className="icon_search"><i className="flaticon-loupe"></i></button>
                        </form>
                    </div>
                    <a href="/home" className="btn rounded-btn">Back To Home</a>
                </div>
            </div>
        </div>
    </div>
</section>

</main>
        </>
      <FooterLast />
    </>
  );
};
export default Agripreneur;