import React, { useState } from "react";
import { postRequest, getRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { DATACONSTANT } from "../../Services/DataConstant";

const UserService = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState();
  const navigate = useNavigate();
  const [userAdd, setUserAdd] = useState();
  const [area, setarea] = useState();
  const [assign, setAssign] = useState();
  const [submitloader, setSubmitLoader] = useState(false);
  const params = useParams();
  const location = useLocation();
  const [assignArea, setAssignArea] = useState([]);
  const [errors, setErrors] = useState({});
  const { isArea, areaData } = location?.state || {};
  const [toDate, setToDate] = useState(new Date(Date.now()));
  const [fromDate, setfromDate] = useState(new Date(Date.now()));
  const [pincode, setPincode] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModalFundTranfer, setShowModalFundTransfer] = useState(false);
  const [idFundTransfer, setIDfundTransfer] = useState(null);
  const [inputFundtrans, setInputFundTrans] = useState({ trType: "C" });
  const [isOpen, setIsOpen] = useState("");
  const [addressForProfile, setAdressforProfile] = useState([]);
  const [orderForProfile, setOrderforProfile] = useState([]);
  const orderType = DATACONSTANT.ORDER_TYPE;
  const [userProfileById, setUserProfileById] = useState({});

  const toggleDropdown = async (i) => {
    setIsOpen(isOpen == i ? "" : i);
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    setPincode(value);
  };

  const getByPinCodes = async (pincode) => {
    const res = await postRequest(`api/user/GetAreaByPincode`, {
      isMapped: false,
      start: 0,
      top: 100,
      pincode: pincode,
      mobileNo: "",
    });
    if (res) {
      setTableData(res.areas);
    }
  };

  const pincolumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "70px",
    },

    {
      name: " AREA",
      selector: (row) => row.area,
      width: "140px",
    },
    {
      name: " AREAID",
      selector: (row) => row.areaId,
      width: "100px",
    },
    {
      name: " CITY",
      selector: (row) => row.city,
      width: "140px",
    },
    {
      name: "PINCODE",
      selector: (row) => row.pincode,
      width: "100px",
    },
  ];

  const pindata = tableData?.map((d, i) => ({
    serial: i + 1,
    action: (
      <>
        <input
          type="checkbox"
          checked={selectedRows.includes(i)}
          onChange={() => toggleRowSelection(i)}
        />
      </>
    ),
    area: d?.area,
    pincode: d?.pincode,
    city: d?.city,
    areaId: d?.areaId,
  }));

  const toggleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        return prevSelectedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevSelectedRows, index];
      }
    });
  };

  const handleAssign = async (e) => {
    e.preventDefault();
    let selectedItems = selectedRows.map((index) => tableData[index]);
    let additionalData = {
      userID: +params.id,
    };
    let requestData = {
      ...additionalData,
      areaId: selectedItems.map((item) => item?.areaId).toString(),
      pincode: selectedItems[0]?.pincode,
    };
    try {
      const res = await postRequest(
        `api/user/DeliveryPincodeMapping`,
        requestData
      );
      if (res) {
        toast.success(res.responseText);
        setAssignArea([...assignArea, ...selectedItems]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error assigning data:", error);
      setIsLoading(false);
    }
  };

  const handleChangedate = (e) => {
    setToDate(e);
  };

  const handleChangedates = (e) => {
    setfromDate(e);
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "70px",
    },
    {
      name: "ACTION",
      selector: (row) => row.actions,
      width: "100px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      width: "190px",
    },
    {
      name: "USER NAME",
      selector: (row) => row.userName,
      width: "120px",
    },
    {
      name: "NUMBER",
      selector: (row) => row.phoneNumber,
      width: "120px",
    },
    {
      name: "BALANCE",
      selector: (row) => row.balance,
      width: "120px",
    },
    {
      name: "ADDRESS",
      selector: (row) => row.address,
      width: "210px",
    },
    {
      name: "ACTIVE",
      selector: (row) => row.isActive,
      width: "100px",
    },
  ];

  const data = userInfo?.map((d, i) => ({
    serial: i + 1,
    actions: (
      <>
        <div key={i} className="dropdown">
          <button
            className="btn btn-green btn-sm dropdown-toggle"
            type="button"
            onClick={() => {
              toggleDropdown(i + 1);
            }}
          ></button>
          <ul
            className={` ${
              isOpen === i + 1 ? "d-block cus-drop" : "d-none cus-drop"
            }`}
          >
            <li>
              <NavLink to={{ pathname: `/UserProfile/${d?.id}` }}>
                <div className="dropdown-item">Detail</div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: "/Ledger" }}
                state={{ alreadyfilled: d }}
              >
                <div className="dropdown-item">Ledger</div>
              </NavLink>
            </li>
            <li>
              <span
                className="dropdown-item"
                type="button"
                onClick={() => handlepopupfundtransfer(d?.id)}
              >
                Fund Transfer
              </span>
            </li>
          </ul>
        </div>
      </>
    ),
    name: (
      <>
        {d?.roleId == 3 ? (
          <NavLink
            to={{
              pathname: `/AssignArea/${d?.id}`,
            }}
            state={{ isArea: isArea, areaData: areaData }}
            className="btn btn-green btn-sm "
          >
            ASSIGN
          </NavLink>
        ) : (
          ""
        )}

        {d?.name}
        <div>
          <span>[ID:{d?.id}]</span>
        </div>
      </>
    ),
    userName: d?.userName,
    phoneNumber: d?.phoneNumber,
    balance: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.balance}
      </>
    ),
    address: (
      <div className="user-addresscell" title={d?.address}>
        {d?.address}
      </div>
    ),
    isActive: d?.isActive ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
  }));

  const handlepopupfundtransfer = async (Id) => {
    setShowModalFundTransfer(true);
    setIDfundTransfer(Id);
  };

  const handlingCloseModal = () => setShowModalFundTransfer(false);

  const handleFundTransfer = (e) => {
    const { name, value } = e.target;
    setInputFundTrans({ ...inputFundtrans, [name]: value });
  };

  const handleTrTypeChange = (type) => {
    setInputFundTrans({
      ...inputFundtrans,
      trType: type,
    });
  };

  const handleConfirmFundTransfer = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(`api/user/FundTransfer`, {
        userId: idFundTransfer,
        amount: +inputFundtrans.amount,
        trType: inputFundtrans.trType,
        remark: inputFundtrans.remark,
      });
      if (res.statusCode === 1) {
        toast.success(res.responseText);
        userDetail();
        setInputFundTrans({});
        setShowModalFundTransfer(false);
      } else {
        toast.error(res.responseText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //////***** GET USER DETAIL ******//////

  const userDetail = async (roleId, isFilterChange, e) => {
    e?.preventDefault();
    setIsLoading(true);
    try {
      let newRoleId = isFilterChange ? roleId || 2 : isArea ? 3 : 2; // if navigate from area get delivery person
      const moduleBase = "api/user";
      const res = await postRequest(
        // `${moduleBase}/List${roleId ? "?RoleID=" + roleId : ""}`,
        `${moduleBase}/List?`,
        {
          RoleID: +newRoleId,
          ...input,
          toDate: toDate ? moment(toDate).format("DD MMM YYYY") : "",
          fromDate: fromDate ? moment(fromDate).format("DD MMM YYYY") : "",
          mobileNo: input?.mobileNo || "",
        }
      );
      if (res) {
        setUserInfo(res);
      } else {
        toast.error("Something Went Wrong");
      }
      setSubmitLoader(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred:", error);
      setSubmitLoader(false);
    }
  };
  //////***** GET USER DETAIL ******//////

  /////////*USER ADDON *////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInput((prev) => {
      return {
        ...prev,
        [name]: name === "roleId" ? parseInt(value) : value,
      };
    });
    userDetail(value, true);
  };

  const handleDelivery = async (e, postalCode) => {
    const { name, value } = e.target;
    if (postalCode) {
      if (value.length === 6) {
        try {
          const res = await getRequest(
            `api/Home/GetAreaListAsync?postalCode=${value}`
          );
          let _a = [{ id: 0, area: "SELECT " }, ...res];
          setarea(_a);
        } catch (error) {
          console.error("Error occurred:", error);
        }
      }
    }
    setUserAdd((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // const handleKeyDown = (e) => {
  //   if (
  //     !(e.key >= "0" && e.key <= "9") &&
  //     !["ArrowLeft", "ArrowRight", "Backspace", "Delete"].includes(e.key)
  //   ) {
  //     console.log("gjgjhgjkgkhgkhkhkuhgkhiuhkuh", e);
  //     e.preventDefault(); // Prevent the character from being entered
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    try {
      var res = await postRequest(`api/user/CreateDeliveryPerson`, {
        ...userAdd,
        otp: "0",
      });
      if (res) {
        toast.success(res.responseText);
        navigate("/User");
      } else {
        toast.error("Wrong Input");
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong!!!");
    }
  };

  /////////*USER ADDON *////////////////

  ////////* ASSIGN AREA *//////////

  const handleArea = (e) => {
    const { name, value } = e.target;
    setAssign((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmitArea = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    let d = {
      userID: params.id,
    };
    try {
      const res = await postRequest(`api/user/DeliveryPincodeMapping`, {
        ...d,
        ...assign,
      });
      if (res?.statusCode !== 1) {
        toast.error(res.responseText || "Failed");
      } else {
        getAssignArea();
        toast.success(res.responseText || "Success");
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong!!!");
    }
  };

  const getAssignArea = async (e) => {
    try {
      const res = await getRequest(`api/user/GetAreaByPincode`, {
        isMapped: false,
        start: 0,
        top: 100,
        pincode: 226010,
        mobileNo: "",
      });
      setPincode(res.areas);
      // if (isArea) {
      //   setAssign({ pincode: areaData?.pincode });
      // }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  ////////* ASSIGN AREA *//////////

  ////////* GET USER PROFILE BY ID *//////////

  const getUserProfileById = async () => {
    try {
      const res = await postRequest(`api/user/GetById?id=${params.id}`);
      if (res) {
        setUserProfileById(res);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  ////////* GET USER PROFILE BY ID *//////////

  ////////* USER ADDRESS FOR PROFILE *//////////

  const getUserProfileAddress = async () => {
    try {
      const res = await postRequest(
        `api/user/UserAdressList?userId=${params?.id}`
      );
      if (res) {
        setAdressforProfile(res);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  ////////* USER ADDRESS FOR PROFILE *//////////

  ////////* USER ORDERS FOR PROFILE *//////////

  const ordersForUserProfile = async () => {
    try {
      const res = await postRequest(`api/Order/Get`, {
        loginId: 0,
        isRecurring: 0,
        orderId: 0,
        fromDate: "",
        toDate: "",
        userId: params.id,
        orderStatus: 0,
      });
      if (res.statusCode === 1) {
        setOrderforProfile(res.result);
      } else {
        if (res.statusCode === -1) {
          toast.error(res.responseText);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
      setIsLoading(false);
    }
  };

  const orderColumn = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "CUSTOMER",
      selector: (row) => row.customer,
      width: "180px",
    },
    {
      name: "ORDER ID",
      selector: (row) => row.orderId,
      width: "100px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      width: "100px",
    },
    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "120px",
    },
    {
      name: "SCHEDULE ON",
      selector: (row) => row.scheduledOn,
      width: "120px",
    },

    {
      name: "STATUS REMARK",
      selector: (row) => row.statusRemark,
      width: "140px",
    },
    {
      name: "TOTAL ITEM",
      selector: (row) => row.totalItem,
      width: "110px",
    },
    {
      name: "TOTAL COST",
      selector: (row) => row.totalMrp,
      width: "110px",
    },
    {
      name: "PAYABLE AMT.",
      selector: (row) => row.paybleAmount,
      width: "120px",
    },
    {
      name: "PAYMENT MODE",
      selector: (row) => row.paymentMode,
      width: "140px",
    },
    {
      name: "PACKAGE NAME",
      selector: (row) => row.packageName,
      width: "180px",
    },
    {
      name: "REMARK",
      selector: (row) => row.remark,
      width: "160px",
    },
    {
      name: "ISCOMPLETED",
      selector: (row) => row.isCompleted,
      width: "120px",
    },
    {
      name: "ACTION",
      selector: (row) => row.invoiceRec,
      width: "120px",
    },
  ];

  const orderData = orderForProfile?.map((d, i) => ({
    serial: i + 1,
    customer: (
      <>
        {d?.customerName}
        <small className="d-block">[Number: {d?.customerMobile}]</small>
      </>
    ),
    packageName: d?.packageName,
    orderId: <>ID:{d?.id}</>,
    status: (
      <div
        className={
          orderType[d?.status] === "PENDING"
            ? "badge badge-warning text-white"
            : orderType[d?.status] === "CANCELED"
            ? "badge badge-danger"
            : orderType[d?.status] === "RESCHEDULE"
            ? "badge badge-info text-white"
            : orderType[d?.status] === "PLACED"
            ? "badge badge-primary"
            : orderType[d?.status] === "DELIVERED"
            ? "badge badge-success"
            : ""
        }
      >
        {orderType[d?.status]}
      </div>
    ),
    entryOn: d?.entryOn,
    scheduledOn: d?.scheduledOn,
    statusRemark: d?.statusRemark,
    totalItem: d?.totalItem,
    totalMrp: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.totalMrp}
      </>
    ),
    paybleAmount: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.paybleAmount}
      </>
    ),
    paymentMode: d?.paymentMode,
    remark: d?.remark,
    isCompleted: d?.isCompleted ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    invoiceRec: (
      <>
        <NavLink
          to={{
            pathname: "/InvoiceOrder/" + d?.id,
          }}
          state={{ refference: `/UserProfile/${params?.id}` }}
        >
          <button
            type="button"
            className="btn btn-green sm cus-invoice-btn"
            style={{ fontSize: "10px" }}
          >
            INVOICE
          </button>
        </NavLink>
      </>
    ),
  }));

  ////////* USER ORDERS FOR PROFILE *//////////

  /////////*VALIDATIONS ON INPUT *////////////////
  const validateInput = () => {
    const newErrors = {};
    if (!userAdd?.name) {
      newErrors.name = "Please enter the Name.";
    }
    if (!userAdd?.phoneNumber) {
      newErrors.phoneNumber = "Please enter the phoneNumber.";
    }
    if (!userAdd?.email) {
      newErrors.email = "Please enter an email.";
    }

    if (!userAdd?.postalCode) {
      newErrors.postalCode = "Please enter the Pin code.";
    }
    if (!userAdd?.areaId || input?.areaId === "N/A") {
      newErrors.areaId = "Please enter the Area.";
    }
    if (!userAdd?.gender || input?.gender === "N/A") {
      newErrors.gender = "Please enter the Gender.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /////////*VALIDATIONS ON INPUT *////////////////

  /////////*EXPORT EXCEL FOR USER *////////////////

  const FILETYPE = {
    EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const handleDownloadUserExcel = async () => {
    try {
      const res = await postRequest(`api/user/ExportUserListAsync`, {
        roleID: 2,
        mobileNo: "",
        toDate: moment(toDate).format("DD MMM YYYY"),
        fromDate: moment(fromDate).format("DD MMM YYYY"),
      });
      if (res.statusCode === 1) {
        const base64String = res.result;
        const sampleArr = base64ToArrayBuffer(base64String);
        const blob = new Blob([sampleArr], { type: FILETYPE.EXCEL });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "blank";
        link.download = "ExportedFile.xlsx";
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  /////////*EXPORT EXCEL FOR USER *////////////////

  const goback = () => {
    navigate("/User");
  };

  return {
    data,
    isLoading,
    userDetail,
    handleChange,
    userInfo,
    handleDelivery,
    handleSubmit,
    area,
    submitloader,
    assignArea,
    assign,
    handleArea,
    handleSubmitArea,
    getAssignArea,
    columns,
    errors,
    isArea,
    toDate,
    fromDate,
    handleChangedate,
    handleChangedates,
    setSubmitLoader,
    handlePincodeChange,
    tableData,
    pincode,
    getByPinCodes,
    toggleRowSelection,
    selectedRows,
    handleAssign,
    pindata,
    pincolumns,
    showModalFundTranfer,
    handlingCloseModal,
    handleFundTransfer,
    inputFundtrans,
    handleConfirmFundTransfer,
    handleTrTypeChange,
    input,
    setInput,
    goback,
    getUserProfileAddress,
    addressForProfile,
    ordersForUserProfile,
    orderData,
    orderColumn,
    orderForProfile,
    userProfileById,
    getUserProfileById,
    handleDownloadUserExcel,
    // handleKeyDown,
  };
};

export default UserService;
