import { React, useState } from "react";
import { toast } from "react-toastify";
import { validEmail } from "../../Services/Validation";

import { NavLink } from "react-router-dom";

const FooterLast = () => {
  const [data, setData] = useState();
  const [error, setError] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let err = [];
    if (name === "email" && !validEmail.test(value)) {
      err.push("email");
    } else {
      err = [];
    }

    setError(err);
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let err = [];
    try {
      if (!validEmail.test(data?.email)) {
        err.push("email");
      }
      setError(err);
      if (!err) {
        toast.success(
          "Your query has been submitted successfully!! We will get back soon 😀"
        );
      }
    } catch {}
  };
  return (
    <div>
      <footer>
      <div className="footer-area gray-bg pt-80 pb-30">
          <div className="container">
              <div className="row ">
                  <div className="col">
                      <div className="footer-widget mb-50">
                          <div className="footer-logo mb-25">
                              <a href="/"><img src="siteadmin/img/logo/hello-farmers-logo.svg" alt="Hello Farmers" /></a>
                          </div>
                          <div className="footer-contact-list">
                              <ul>
                                  <li>
                                      <div className="icon"><i className="flaticon-place"></i></div>
                                      <p>3/553, Vivek Khand, Gomti Nagar(Lucknow)-226010</p>
                                  </li>
                                  <li>
                                      <div className="icon"><i className="flaticon-telephone-1"></i></div>
                                      <h5 className="number"><a href="tel:+919889009622">+91 9889 009622</a></h5>
                                  </li>
                                  <li>
                                      <div className="icon"><i className="flaticon-mail"></i></div>
                                      <p><a href="mailto:support@hellofarmers.co.in"><span>support@hellofarmers.co.in</span></a></p>
                                  </li>

                              </ul>
                          </div>
                          <div className="footer-social">
                              <ul>
                                  <li><a href="https://www.facebook.com/profile.php?id=61550659974825&mibextid=LQQJ4d" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                  <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                  <li><a href="https://instagram.com/hello_farmers_?igshid=MzRlODBiNWFlZA==" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                  <li><a href="https://www.linkedin.com/company/skvipm-digisolutions-pvt-ltd/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              </ul>
                          </div>
                      </div>
                  </div>

                  <div className="col">
                      <div className="footer-widget mb-50">
                          <div className="fw-title">
                              <h5 className="title">Useful Links</h5>
                          </div>
                          <div className="fw-link">
                              <ul>
                                  <li><NavLink to="/AboutUs">About Us</NavLink></li>
                                  <li><NavLink to="/team">Our Team</NavLink></li>
                                  <li><NavLink to="/gallery">Gallery</NavLink></li>
                                  <li><NavLink to="/Blog">Blog</NavLink></li>
                                  <li><NavLink to="/events">Events</NavLink></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div className="col">
                      <div className="footer-widget mb-50">
                          <div className="fw-title">
                              <h5 className="title">Links</h5>
                          </div>
                          <div className="fw-link">
                              <ul>
                              <li><NavLink to="/Terms">Terms</NavLink></li>
                                  <li><NavLink to="/Privacy">Privacy</NavLink></li>
                                  <li><NavLink to="/Refund">Orders and Returns</NavLink></li>
                                  <li><NavLink to="/Faq">Faq</NavLink></li>
                                  <li><NavLink to="/contactus">Contact Us</NavLink></li>
                              </ul>
                          </div>
                      </div>
                  </div>
                 
                  <div className="col-md-3">
                      <div className="footer-widget footer-box-widget">
                          <div className="f-download-wrap">
                              <div className="fw-title">
                                  <h5 className="title">Download App</h5>
                              </div>
                              <div className="download-btns">
                                  <a href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers" target="_blank"><img src="siteadmin/img/icon/g_play.webp" alt="Hello Farmers" /></a>
                                  <a href="https://apps.apple.com/in/app/hello-farmers/id6465133530" target="_blank"><img src="siteadmin/img/icon/app_store.webp" alt="Hello Farmers"/></a>
                              </div>
                          </div>
                          <div className="f-newsletter">
                              <div className="fw-title">
                                  <h5 className="title">Newsletter</h5>
                              </div>
                              <form action="#">
                                  <input type="email" placeholder="Email Address" />
                                  <button><i className="flaticon-send"></i></button>
                              </form>
                              {/* <p>Do Not Show Your Mail</p> */}
                          </div>
                      </div>
                  </div>

                  <div className="col">
                  <div className="footer-widget mb-50">
                  <h5 className="title text-center">SKVIPM DIGISOLUTIONS PVT LTD</h5>
                  <p className="title text-center">"An auxiliary unit of </p>
                  <h5 className="title text-center">Kisantreat Agritech India Pvt Ltd."</h5>
                          <div className="footer-logo mt-25">
                              <a href="/"><img src="siteadmin/img/logo/the-veg-box.svg" alt="Hello Farmers" /></a>
                          </div>
                          
                          
                      </div>

                  </div>
              </div>
          </div>
      </div>
      <div className="copyright-wrap">
          <div className="container">
              <div className="row align-items-center">
                  <div className="col-md-12">
                      <div className="copyright-text">
                          <p>Copyright &copy; 2023 Hello Farmer's All Rights Reserved</p>
                      </div>
                  </div>
                  {/* <!--<div className="col-md-6">
                      <div className="payment-accepted text-center text-md-right">
                          <img src="siteadmin/img/images/payment_card.png" alt="Hello Farmers" />
                      </div>
                  </div>--> */}
              </div>
          </div>
      </div>
  </footer>
    </div>
  );
};
export default FooterLast;
