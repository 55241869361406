import HeaderTop from "./HeaderTop";
import HeaderBottom from "./Navigation";
import FooterLast from "./FooterLast";
import { useEffect } from "react";

const Events = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <HeaderTop />
      
      <main>

<section className="blog-area pt-70 pb-50">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="section-title section-title-two text-center mb-50">
                    <span className="sub-title">Our Articles</span>
                    <h2 className="title">Our Latest Events</h2>
                    
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-9">
                <div className="blog-post-item mb-30">
                    <div className="blog-post-thumb">
                        <a href="/event-details"><img src="siteadmin/img/blog/blog_post_thumb01.webp" alt="Hello Farmers" /></a>
                    </div>
                    <div className="blog-post-content">
                        <div className="blog-post-date">03 <span>AUG</span></div>
                        <div className="blog-post-meta">
                            <ul>
                                <li><a href="/event-details">Admin</a></li>
                                <li><a href="/event-details">03 Comments</a></li>
                            </ul>
                        </div>
                        <h4 className="title"><a href="/event-details">Best ways to support healthy immune system</a></h4>
                        <div className="blog-post-bottom">
                            <ul>
                                <li className="read-more"><a href="/event-details">Read More <i className="fas fa-angle-double-right"></i></a></li>
                                <li className="share-btn"><a href="/event-details"><i className="fas fa-share-alt"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-9">
                <div className="blog-post-item mb-30">
                    <div className="blog-post-thumb">
                        <a href="/event-details"><img src="siteadmin/img/blog/blog_post_thumb02.webp" alt="Hello Farmers" /></a>
                    </div>
                    <div className="blog-post-content">
                        <div className="blog-post-date">04 <span>AUG</span></div>
                        <div className="blog-post-meta">
                            <ul>
                                <li><a href="/event-details">Admin</a></li>
                                <li><a href="/event-details">04 Comments</a></li>
                            </ul>
                        </div>
                        <h4 className="title"><a href="/event-details">Farm Fresh Vegetables Produced Method</a></h4>
                        <div className="blog-post-bottom">
                            <ul>
                                <li className="read-more"><a href="/event-details">Read More <i className="fas fa-angle-double-right"></i></a></li>
                                <li className="share-btn"><a href="/event-details"><i className="fas fa-share-alt"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-9">
                <div className="blog-post-item mb-30">
                    <div className="blog-post-thumb">
                        <a href="/event-details"><img src="siteadmin/img/blog/blog_post_thumb03.webp" alt="Hello Farmers" /></a>
                    </div>
                    <div className="blog-post-content">
                        <div className="blog-post-date">05 <span>AUG</span></div>
                        <div className="blog-post-meta">
                            <ul>
                                <li><a href="/event-details">Admin</a></li>
                                <li><a href="/event-details">03 Comments</a></li>
                            </ul>
                        </div>
                        <h4 className="title"><a href="/event-details">Best ways to support healthy immune system</a></h4>
                        <div className="blog-post-bottom">
                            <ul>
                                <li className="read-more"><a href="/event-details">Read More <i className="fas fa-angle-double-right"></i></a></li>
                                <li className="share-btn"><a href="/event-details"><i className="fas fa-share-alt"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


</main>

      {/* <div
        className="breadcrumb-area text-center shadow dark bg-fixed text-light"
        style={{
          backgroundImage: "url(assets/img/banner/21.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Blog</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li>
                    <a href="index.html">
                      <i className="fas fa-home"></i>Home
                    </a>
                  </li>
                  <li className="active">Blog</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-area single full-blog full-blog default-padding">
        <div className="container">
          <div className="blog-items">
            <div className="row">
              <div className="blog-content col-lg-10 offset-lg-1 col-md-12">
                <div className="blog-style-two item">
                  <div className="thumb">
                    <a href="blog-single-with-sidebar.html">
                      <img src="assets/img/blog/11.jpg" alt="Thumb" />
                    </a>
                    <div className="date">
                      <strong>18</strong> <span>January, 2023</span>
                    </div>
                  </div>
                  <div className="info">
                    <h3>Community Supported Agriculture:</h3>
                    <div className="meta">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fas fa-user-circle"></i> Admin
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fas fa-comments"></i> 26 Comments
                          </a>
                        </li>
                      </ul>
                    </div>
                    <p>
                      Our specially designed HelloFarmers Community Farming as a
                      Service (CFaaS) business model is developed by thinking
                      about the consumer as a community and providing direct
                      farm access to every individual consumer where they can
                      choose what to grow for their food requirements and farmer
                      can receive a fair price in return. HelloFarmers is a
                      brilliant next level example of Community Supported
                      Agriculture.
                    </p>
                    <p>
                      <b> Community Farming</b> is a relatively recent and
                      innovative concept that is intended to create a
                      relationship between farmers and consumers. Explore the
                      new ways of growing farms. Let’s grow your own food with a
                      new advanced style. With this small footprint, you choose
                      rapid growth of locally grown, fresh, pesticide-free food
                    </p>
                    <blockquote>
                      Community farming also provides opportunities for social
                      and cultural exchange. When individuals come together to
                      farm, they have the opportunity to learn from each other
                      and build relationships. This is helpful to strengthen the
                      community and promote a sense of belonging. For example,
                      community members come together to share meals,
                      participate in festivals and celebrations, and even create
                      art and crafts.
                    </blockquote>
                    <p>
                      <b>
                        One of the key principles of community farming is
                        sustainability :{" "}
                      </b>
                      Community farming practices are often designed to promote
                      sustainable agriculture, which means that the farm is
                      managed in a way that is environmentally friendly,
                      socially just, and economically viable. This includes
                      practices such as crop rotation, composting, and
                      conservation of natural resources. Additionally, community
                      farming promotes the use of organic and non-toxic methods
                      of farming, which is beneficial to both the environment
                      and human health
                    </p>
                  </div>
                </div>

                <div className="blog-comments">
                  <div className="comments-area">
                    <div className="comments-form">
                      <div className="title">
                        <h3>Leave a comments</h3>
                      </div>
                      <form action="#" className="contact-comments">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                name="name"
                                className="form-control"
                                placeholder="Name *"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                name="email"
                                className="form-control"
                                placeholder="Email *"
                                type="email"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group comments">
                              <textarea
                                className="form-control"
                                placeholder="Comment"
                              ></textarea>
                            </div>
                            <div className="form-group full-width submit">
                              <button
                                className="btn btn-animation dark border"
                                type="submit"
                              >
                                Post Comment
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FooterLast />
    </>
  );
};

export default Events;
