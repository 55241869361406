import { React, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import ProductService from "./ProductService";
import Style from "./Product.module.css";
import { Modal, Button } from "react-bootstrap";

const Product = () => {
  const { state } = useLocation();
  const { CID } = state || {};
  const {
    getCategoryList,
    data,
    handleCateogryList,
    productProfile,
    list,
    loading,
    columns,
    handleCultivationSubmit,
    showModal,
    handleClose,
    handleDelete,
    checkCultivated,
    categoryIdSave,
    setCategoryTdSave,
    handleSearch,
    searchTerm,
    setSearchTerm,
  } = ProductService();

  useEffect(() => {
    productProfile(CID);
    getCategoryList();
    if (CID) setCategoryTdSave(CID);
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Product</li>

                        <li className="breadcrumb-item active">Product List</li>
                      </ul>
                    </div>

                    {/* <div className="col-md-6 col-sm-12 justify-content-end d-flex align-items-center">
                      <NavLink to="/EditProduct" className="btn btn-sm mr-3">
                        <i className="fa fa-plus-square"></i>&nbsp;ADD
                      </NavLink>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-2">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <div className="form-container">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="col-md-4 col-sm-12">
                    {checkCultivated?.length > 0 ? (
                      <button
                        className="btn btn-sm mr-3"
                        onClick={handleCultivationSubmit}
                      >
                        TOGGLE CULTIVATE DATE
                      </button>
                    ) : (
                      ""
                    )}
                    {/* <NavLink
                      to="/EditProduct"
                      className="btn btn-sm"

                    >
                      <i className="fa fa-plus-square"></i>&nbsp;ADD
                    </NavLink> */}
                  </div>
                  <div className="col-md-8 col-sm-12 d-flex justify-content-end">
                    <p className="dropdown d-inline-block mb-0 pr-3">
                      <button
                        className="btn-secondarydropdown-toggle"
                        id="dropdownMenu1"
                        data-toggle="dropdown"
                        style={{ border: "none", background: "transparent" }}
                      >
                        <select
                          className="form-control"
                          onChange={handleCateogryList}
                          value={categoryIdSave}
                        >
                          {list?.map((item, i) => (
                            <option key={i} value={item?.categoryId}>
                              {item?.categoryName}
                            </option>
                          ))}
                        </select>
                      </button>
                    </p>
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control w-50"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={handleSearch}
                      className="btn btn-sm mx-2"
                    >
                      Search
                    </button>
                    <button className="btn btn-sm ">
                      <NavLink to="/EditProduct" className="text-white">
                        <i className="fa fa-plus-square"></i>&nbsp;ADD
                      </NavLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pak-height card">
          <div className="card-body p-2">
            <div className="table-responsive ">
              {loading ? (
                <div className="spinner-container">
                  <Spinner type="TailSpin" color="#00BFFF" />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  className="rdt_TableHeadRow"
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 243px)"
                />
              )}
            </div>
          </div>
        </div>
        <Modal show={showModal} size="sm" onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="mt-3">
              <i
                className="bi bi-exclamation-circle-fill"
                style={{ color: "#f1c40f" }}
              ></i>
              &nbsp;Confirmation
            </Modal.Title>
            <span
              aria-hidden="true"
              onClick={handleClose}
              className="modal-cross"
            >
              &times;
            </span>
          </Modal.Header>
          <Modal.Body className="pt-0">
            Aye you sure to delete product?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              No
            </Button>
            <Button variant="success" onClick={handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
};
export default Product;
