import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";
import FooterLast from "../../Component/Homes/FooterLast";
import { useEffect } from "react";

const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <HeaderTop />
      <main>

        
        <section className="breadcrumb-area breadcrumb-bg">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            <h2 className="title">Refund Policy</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Refund Policy</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        
        <section className="terms-and-conditions-area pt-85 pb-85">
            <div className="container">
                <div className="row justify-content-center">
                    
                    <div className="col-xl-10">
                        <div className="terms-and-conditions-wrap">
                            <h5>Refund and Return Policy</h5>
                            <p>Farmers are responsible for their own refund and return policy for products sold through the App. If a user has an issue with a product they have purchased through the App, they should contact the farmer directly.</p>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        
        

    </main>


      <FooterLast />
    </>
  );
};

export default Refund;
