import React, { Component } from 'react';


class TopMsg extends Component {
    constructor(props) {
      super(props);
  
      // Initialize the state to keep track of whether the message is visible or not
      this.state = {
        showMessage: true,
      };
    }
  
    // Event handler for the click event
    handleRemoveClick = () => {
      // Hide the message by updating the state
      this.setState({ showMessage: false });
    };
  
    render() {
      const { showMessage } = this.state;
  
      // Render the message only if showMessage is true
      return (
        showMessage && (
          <div className="header-message-wrap">
          <div className="container custom-container">
              <div className="row">
                  <div className="col-12">
                      <div className="top-notify-message">
                          <p>place your complaints (if any) within 24hrs of receiving your delivery</p>
                          <span className="message-remove" onClick={this.handleRemoveClick}>X</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
          
        )
      );
    }
  }
  
  export default TopMsg;
  