import { React, useState } from "react";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

const CropService = () => {
  const [crops, setCrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState();
  const [submitloader, setSubmitLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [getId, setGetId] = useState();

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,

      width: "90px",
    },
    {
      name: "EDIT",
      selector: (row) => row.edit,
      width: "100px",
    },
    {
      name: "CROP NAME",
      selector: (row) => row.cropName,
      width: "400px",
    },
  ];

  const data = crops?.map((d, i) => ({
    serial: i + 1,
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditCrop/" + d.id,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span onClick={() => handlepopup(d?.id)} style={{ cursor: "pointer" }}>
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),

    cropName: (
      <>
        {d?.cropName}
        <small className="d-block">[ID:{d?.id}]</small>
      </>
    ),
  }));

  ///////********* GET THE CROP LIST *******//////////

  const cropListing = async () => {
    try {
      const res = await postRequest(`api/CropType/GetAsync`);
      if (res.result) {
        setCrops(res.result);
        setLoading(false);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  ///////********* GET THE CROP LIST *******//////////

  ///////********* EDIT AND ADD CROP LIST *******/////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    let _value = value;
    if (_value == "on") {
      _value = !e.target.checked;
    }
    setInput((prev) => {
      return {
        ...prev,
        [name]: _value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      let res = {};

      let { id, cropName } = { ...input };
      if (id == undefined) {
        id = 0;
      }
      res = await postRequest(
        `api/CropType/AddAsync?ID=${id}&CropName=${cropName}`,
        null
      );
      if (!res) {
        toast.error(res.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/CropList", { replace: true });
        }
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.log("Error", error);
      toast.error("Something went wrong!!!");
    }
  };
  ///////********* EDIT AND ADD CROP LIST *******/////////

  ///////********* VALIDATING THE CROP LIST *******/////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.cropName) {
      newErrors.cropName = "Please enter a crop name.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  ///////********* VALIDATING THE CROP LIST *******/////////

  ///////*********GET BY ID CROP LIST *******/////////
  const getCrop = async () => {
    try {
      const res = await postRequest(
        `api/CropType/GetByIdAsync?id=${params.id}`
      );

      if (res.result) {
        setInput(res.result);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  ///////********* GET BY ID CROP LIST *******/////////

  ///////********* DELETE THE CROP LIST *******/////////

  const handlepopup = async (Id) => {
    setShowModal(true);
    setGetId(Id);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/CropType/DeleteAsync?ID=${getId}`);
      if (res) {
        cropListing();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  ///////********* DELETE THE CROP LIST *******/////////

  return {
    cropListing,
    data,
    loading,
    handleChange,
    handleSubmit,
    getCrop,
    submitloader,
    params,
    input,
    columns,
    errors,
    showModal,
    handleClose,
    handleDelete,
  };
};

export default CropService;
