import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import UserService from "./UserService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button, Table } from "react-bootstrap";
import numberToWords from "number-to-words";

const User = () => {
  const {
    data,
    isLoading,
    userDetail,
    handleChange,
    userInfo,
    columns,
    isArea,
    toDate,
    fromDate,
    handleChangedate,
    handleChangedates,
    submitloader,
    setSubmitLoader,
    showModalFundTranfer,
    handlingCloseModal,
    handleFundTransfer,
    inputFundtrans,
    handleConfirmFundTransfer,
    handleTrTypeChange,
    input,
    setInput,
    handleDownloadUserExcel
  } = UserService();
  const [formattedAmount, setFormattedAmount] = useState("");

  useEffect(() => {
    if (inputFundtrans.amount) {
      const numericAmount = inputFundtrans?.amount;
      const amountWords = numberToWords.toWords(numericAmount);
      setFormattedAmount(amountWords);
    } else {
      setFormattedAmount("");
    }
  }, [inputFundtrans.amount]);

  useEffect(() => {
    userDetail();
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Users</li>

                        <li className="breadcrumb-item active"> List</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form className="form-container">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <div className="form-container">
                  <div className="row">
                    <div className="col-lg-2 col-md-6">
                      From Date:
                      <DatePicker
                        id="fromDate"
                        name="fromDate"
                        selected={fromDate}
                        maxDate={new Date()}
                        onChange={handleChangedates}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-2 col-md-6">
                      To Date:
                      <DatePicker
                        id="toDate"
                        name="toDate"
                        selected={toDate}
                        minDate={new Date(fromDate)}
                        maxDate={new Date()}
                        onChange={handleChangedate}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-lg-2 col-md-6">
                      Select
                      <select
                        className="form-control"
                        id="roleId"
                        name="roleId"
                        defaultValue={isArea ? "3" : ""}
                        onChange={handleChange}
                      >
                        {/* <option value="">SELECT</option> */}
                        {/* <option value="1">Admin</option> */}
                        <option value="2">User</option>
                        {/* <option value="3">DeliveryPerson</option> */}
                        <option value="4">Kisaan</option>
                        <option value="5">FPO</option>
                      </select>
                    </div>

                    <div className="col-lg-3 col-md-6">
                      Search:
                      <input
                        type="text"
                        name="mobileNo"
                        className="form-control"
                        placeholder="Enter Mobile No."
                        value={input?.mobileNo}
                        onChange={(e) =>
                          setInput((prev) => {
                            return {
                              ...prev,
                              ["mobileNo"]: e.target.value,
                            };
                          })
                        }
                      ></input>
                    </div>

                    <div className="col-lg-3 col-md-6 d-flex align-items-end">
                      {!submitloader ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-green mr-2"
                            id="btnSubmit"
                            onClick={(e) => {
                              userDetail(false, false, e);
                              setSubmitLoader(true);
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="btn btn-green">
                            Submitting...
                          </button>
                        </>
                      )}
                      <div>
                        <>
                          <button
                            type="button"
                            className="btn btn-green btn-md"
                            onClick={handleDownloadUserExcel}
                            style={{ padding: "11px" }}
                          >
                            <i className="bi bi-file-earmark-excel"></i>
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pak-height card">
            <div className="card-body p-2">
              <div className="table-responsive">
                {isLoading ? (
                  <div className="spinner-container">
                    <Spinner type="TailSpin" color="#00BFFF" />
                  </div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    className="rdt_TableHeadRow123"
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 260px)"
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        <Modal
          show={showModalFundTranfer}
          onHide={handlingCloseModal}
          className="cus-top-modal"
        >
          <Modal.Header>
            <Modal.Title className="mt-3 change-status">
              Fund Transfer
            </Modal.Title>
            <span
              aria-hidden="true"
              onClick={handlingCloseModal}
              className="modal-cross"
              style={{ fontSize: "24px", color: "red", cursor: "pointer" }}
            >
              &times;
            </span>
          </Modal.Header>
          <form onSubmit={handleConfirmFundTransfer}>
            <Modal.Body className="pt-0 modal-body-textarea pb-2">
              <div className="row">
                <div className="col-md-12 d-flex">
                  <div className="btn-group btn-toggle my-2" id="btnGroup">
                    <button
                      className={`btn btn-sm ${
                        inputFundtrans.trType === "C"
                          ? "btn-outline-success active"
                          : "btn-outline-success"
                      }`}
                      onClick={() => handleTrTypeChange("C")}
                    >
                      Credit
                    </button>
                    <button
                      className={`btn btn-sm  ${
                        inputFundtrans.trType === "D"
                          ? "btn-outline-danger active"
                          : "btn-outline-danger"
                      }`}
                      onClick={() => handleTrTypeChange("D")}
                    >
                      Debit
                    </button>
                  </div>
                </div>
                <div className="col-md-3 py-2">
                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      marginTop: "5px",
                    }}
                  >
                    Amount :
                  </h6>
                </div>
                <div className="col-md-9 py-2">
                  <input
                    type="number"
                    className="form-control cus-input"
                    placeholder="Enter Amount"
                    name="amount"
                    value={inputFundtrans?.amount || ""}
                    onChange={handleFundTransfer}
                    required={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      marginTop: "1rem",
                    }}
                  >
                    Remark :
                  </h6>
                </div>
                <div className="col-md-9">
                  <textarea
                    className="cus-textarea mt-1 w-100"
                    rows="3"
                    cols="55"
                    required={true}
                    placeholder="Enter your remark"
                    name="remark"
                    value={inputFundtrans?.remark || ""}
                    onChange={handleFundTransfer}
                  />
                </div>
              </div>
              <div className="row px-3">
                <h6
                  style={{
                    textAlign: "left",
                    fontWeight: "normal",
                    marginTop: "1rem",
                  }}
                >
                  <span style={{ color: "red", fontSize: "15px" }}>
                    {formattedAmount}
                  </span>
                </h6>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handlingCloseModal}>
                Cancel
              </Button>
              <Button variant="success" type="submit">
                Transfer
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </main>
    </>
  );
};
export default User;
