import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
const Navigation = ({ scrollToSection, scrollToSections }) => {
  return (
    <ul className="navigation">
      <li>
        <NavLink to="/">Home</NavLink>
        {/* <a href="/home">Home</a> */}
      </li>
      {/* <li className="menu-item-has-children"> */}
      <li>
        <NavLink to="/AboutUs">About Us</NavLink>
        {/* <a href="/Aboutus">About Us</a> */}
        {/* <ul className="submenu">
          <li>
            <a href="/Aboutus">Overview</a>
          </li>
          <li>
            <a href="/AboutUs#vision">Our Vision</a>
          </li>
          <li>
            <a href="/AboutUs#mission">Our Mission</a>
          </li>
          <li>
            <a href="/AboutUs#usp">USP</a>
          </li>
        </ul> */}
      </li>
      <li>
        {/* <li className="menu-item-has-children"> */}
        <NavLink to="/Solution">Solutions</NavLink>
        {/* <a href="/Solution">Solutions</a> */}
        {/* <ul className="submenu">
          <li>
            <a href="/Solution"> Family Subscriptions</a>
          </li>
          <li>
            <a href="/Solution">Community Farming</a>
          </li>
          <li>
            <a href="/Solution">Agri Tourism</a>
          </li>
          <li>
            <a href="/Solution">Skill Training </a>
          </li>
          <li>
            <a href="/Solution">Export Hub</a>
          </li>
        </ul> */}
      </li>
      <li>
        {/* <li className="menu-item-has-children"> */}
        <NavLink to="/Oppurtunity">Opportunities</NavLink>
        {/* <a href="/Oppurtunity">Opportunities</a> */}
        {/* <ul className="submenu">
          <li>
            <a href="/Oppurtunity"> Community Farming Partner</a>
          </li>
          <li>
            <a href="/Oppurtunity">Urban Franchise Partner</a>
          </li>
          <li>
            <a href="/Oppurtunity">Farm to School Program</a>
          </li>
          <li>
            <a href="/Oppurtunity">Corporate Farming</a>
          </li>
        </ul> */}
      </li>
      {/* <li><a href="/Agripreneur">Agripreneur</a></li> */}
      <li>
        <NavLink to="/events">Events</NavLink>
        {/* <a href="/events">Events</a> */}
      </li>
      <li>
        <NavLink to="/Blog">Blog</NavLink>
        {/* <a href="/Blog">Blog</a> */}
      </li>
      <li>
        <NavLink to="/gallery">Gallery</NavLink>
        {/* <a href="/gallery">Gallery</a> */}
      </li>
      <li>
        <NavLink to="/faq">FAQ</NavLink>
        {/* <a href="/faq">FAQ</a> */}
      </li>
      <li>
        <NavLink to="/contactus">Contacts</NavLink>
        {/* <a href="/contactus">contacts</a> */}
      </li>
    </ul>
  );
};
export default Navigation;
