import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import PackageService from "./PackageService";

const EditPackage = () => {
  const {
    handleChange,
    handleEditor,
    getpackage,
    handleSubmit,
    input,
    editor,
    submitloader,
    errors,
    editor1,
    handleEditor1,
    handleImage,
    baseUrl,
  } = PackageService();

  const params = useParams();

  useEffect(() => {
    if (params.id !== undefined) {
      getpackage();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Package</li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container w-100">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Name :
                      <input
                        type="text"
                        className="form-control"
                        id="packageName"
                        name="packageName"
                        placeholder="Enter the package name"
                        value={input?.packageName}
                        onChange={handleChange}
                      />
                      {errors.packageName && (
                        <div className="help-block text-danger">
                          {errors.packageName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Cost :
                      <input
                        type="text"
                        className="form-control"
                        id="packageCost"
                        name="packageCost"
                        placeholder="Enter the cost in rs."
                        value={input?.packageCost || ""}
                        onChange={handleChange}
                      />
                      {errors.packageCost && (
                        <div className="help-block text-danger">
                          {errors.packageCost}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Recurring In :
                      <input
                        type="text"
                        className="form-control"
                        id="recurringIn"
                        name="recurringIn"
                        placeholder="Enter the number of days"
                        value={input?.recurringIn || ""}
                        onChange={handleChange}
                      />
                      {errors.recurringIn && (
                        <div className="help-block text-danger">
                          {errors.recurringIn}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Discount :
                      <input
                        type="text"
                        className="form-control"
                        id="discount"
                        name="discount"
                        placeholder="Enter the percentage discount"
                        value={input?.discount || ""}
                        onChange={handleChange}
                      />
                      {errors.discount && (
                        <div className="help-block text-danger">
                          {errors.discount}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      ExpiredIn :
                      <input
                        type="text"
                        className="form-control"
                        id="expiredIn"
                        name="expiredIn"
                        placeholder="Enter the  number of days to expired"
                        value={input?.expiredIn || ""}
                        onChange={handleChange}
                      />
                      {errors.expiredIn && (
                        <div className="help-block text-danger">
                          {errors.expiredIn}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Minimum Purchase :
                      <input
                        type="text"
                        className="form-control"
                        id="minimumPurchase"
                        name="minimumPurchase"
                        placeholder="Enter the minimum purchase number"
                        value={input?.minimumPurchase || ""}
                        onChange={handleChange}
                      />
                      {errors.minimumPurchase && (
                        <div className="help-block text-danger">
                          {errors.minimumPurchase}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-2">
                      Image :
                      <input
                        type="file"
                        className="form-control"
                        id="imagePath"
                        name="imagePath"
                        onChange={handleImage}
                      />
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-group">
                      &nbsp;
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isActive"
                            type="checkbox"
                            checked={input?.isActive}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Active</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <img
                      className="d-block"
                      style={{
                        minHeight: "80px",
                        maxWidth: "80px",
                        float: "right",
                      }}
                      src={
                        input?.imagePath?.includes("http")
                          ? input?.imagePath
                          : baseUrl + input?.imagePath
                      }
                      onError={(e) =>
                        (e.target.onerror = null)(
                          (e.target.src = "/assets/img/noimage.jpg")
                        )
                      }
                      class="card-img-top"
                    />
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      Description :
                      <JoditEditor
                        ref={editor}
                        tabIndex={1}
                        id="description"
                        name="description"
                        value={input?.description}
                        onChange={(e) => handleEditor(e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      Offer Description :
                      <JoditEditor
                        ref={editor1}
                        tabIndex={1}
                        id="offerDescription"
                        name="offerDescription"
                        value={input?.offerDescription}
                        onChange={(e) => handleEditor1(e)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            {!submitloader ? (
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-green">Submitting...</button>
              </>
            )}
            <NavLink to="/Package" className="btn btn-danger">
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};

export default EditPackage;
