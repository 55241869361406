import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "../Services/Cookies";
import { DATACONSTANT } from "../Services/DataConstant";
import NaviBar from "./Administrative/NaviBar";
import Sidebar from "./Administrative/Sidebar";

const ProtectedRoute = ({ toggleSidebar, showSidebar, children }) => {
  let location = useLocation();
  window.addEventListener("beforeunload", (e) => {
    e.preventDefault();
  });
  let isAuthenticated = !!getCookie(DATACONSTANT.COOKIE_NAME);
  if (!isAuthenticated) {
    return <Navigate to="/Login" state={{ from: location }} replace />;
  }

  return (
    <>
      <NaviBar toggleSidebar={toggleSidebar} />
      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
      {children}
    </>
  );
};

export default ProtectedRoute;
