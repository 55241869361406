import React from "react";
import SmsSettingService from "./SmsSettingService";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner, Button, Modal } from "react-bootstrap";
import { useEffect } from "react";

const SmsSettings = () => {
  const {
    loading,
    columns,
    data,
    getSmsDetail,
    showModal,
    handleClose,
    handleDelete,
  } = SmsSettingService();

  useEffect(() => {
    getSmsDetail();
  }, []);

  return (
    <div>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Setting</li>

                        <li className="breadcrumb-item active">SMS Setting</li>
                      </ul>
                    </div>

                    <div className="col-md-6 col-sm-12 justify-content-end d-flex align-items-center">
                      <NavLink to="/EditSmsSetting" className="btn btn-sm mr-3">
                        <i className="fa fa-plus-square"></i>&nbsp;ADD
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" card">
          <div className="card-body p-2">
            <div className="table-responsive">
              {loading ? (
                <div className="spinner-container">
                  <Spinner type="TailSpin" color="#00BFFF" />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  className="rdt_TableHeadRow"
                  scrollY="60px"
                />
              )}
            </div>
          </div>
        </div>
        <Modal show={showModal} size="sm" onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="mt-3">
              <i
                className="bi bi-exclamation-circle-fill"
                style={{ color: "#f1c40f" }}
              ></i>
              &nbsp;Confirmation
            </Modal.Title>
            <span
              aria-hidden="true"
              onClick={handleClose}
              className="modal-cross"
            >
              &times;
            </span>
          </Modal.Header>
          <Modal.Body className="pt-0">Aye you sure to delete sms?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              No
            </Button>
            <Button variant="success" onClick={handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
};

export default SmsSettings;
