import React, { useState } from "react";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import moment from "moment";
import { DATACONSTANT } from "../../Services/DataConstant";
import { NavLink } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import format from "date-fns/format";

const GetOrderService = () => {
  const [input, setInput] = useState({ orderStatus: 0 });
  // const [toDate, setToDate] = useState(new Date(Date.now()));
  // // 86400000
  // const [fromDate, setfromDate] = useState(new Date(Date.now()));
  const [toDateNew, setToDateNew] = useState(new Date(Date.now()));
  const [fromDateNew, setfromDateNew] = useState(new Date(Date.now()));
  const [toDate, setToDate] = useState(new Date(Date.now()));
  const [fromDate, setfromDate] = useState(new Date(Date.now()));
  const [submitloader, setSubmitLoader] = useState(false);
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const orderType = DATACONSTANT.ORDER_TYPE;
  const orderStatus = DATACONSTANT.ORDER_STATUS;
  const ledgertrType = DATACONSTANT.LEDGER_TRTYPE;
  const payModeType = DATACONSTANT.PAYMENTMODE_TYPE;
  const [invoice, setInvoice] = useState({});
  const param = useParams();
  const navigate = useNavigate();
  const [pgorder, setPGOrder] = useState([]);
  const [ledgerList, setLedgerList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [logRequest, setLogRequest] = useState();
  const [showModalRemark, setShowModalRemark] = useState(false);
  const [showModalCheckStatus, setShowModalCheckStatus] = useState(false);
  const [remark, setRemark] = useState("");
  const [selectedTid, setSelectedTid] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusCheckRequest, setstatusCheckRequest] = useState();
  const [orderScheduleOn, setOrderScheduleOn] = useState([]);
  const [showModalForMark, setShowModalFOrMark] = useState(false);
  const [idForMark, setIDfoeMark] = useState(null);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const location = useLocation();
  const refference = location.state?.refference;

  const handleChangedate = (e) => {
    setToDate(e);
  };

  const handleChangedates = (e) => {
    setfromDate(e);
  };

  /////******** NEW ORDER********///////

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },

    {
      name: "CUSTOMER",
      selector: (row) => row.customer,
      width: "180px",
    },
    {
      name: "ORDER ID",
      selector: (row) => row.orderId,
      width: "100px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      width: "100px",
    },

    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "120px",
    },
    {
      name: "SCHEDULE ON",
      selector: (row) => row.scheduledOn,
      width: "120px",
    },

    {
      name: "STATUS REMARK",
      selector: (row) => row.statusRemark,
      width: "140px",
    },
    {
      name: "TOTAL ITEM",
      selector: (row) => row.totalItem,
      width: "110px",
    },
    {
      name: "TOTAL COST",
      selector: (row) => row.totalMrp,
      width: "110px",
    },
    {
      name: "PAYABLE AMT.",
      selector: (row) => row.paybleAmount,
      width: "120px",
    },
    {
      name: "PAYMENT MODE",
      selector: (row) => row.paymentMode,
      width: "140px",
    },
    {
      name: "PACKAGE NAME",
      selector: (row) => row.packageName,
      width: "180px",
    },
    {
      name: "REMARK",
      selector: (row) => row.remark,
      width: "160px",
    },
    {
      name: "ISCOMPLETED",
      selector: (row) => row.isCompleted,
      width: "120px",
    },
    {
      name: "ACTION",
      selector: (row) => row.invoiceRec,
      width: "120px",
    },
  ];

  const handleChangedateNew = (e) => {
    setToDateNew(e);
  };

  const handleChangedatesNew = (e) => {
    setfromDateNew(e);
  };

  const data = order?.map((d, i) => ({
    serial: i + 1,
    packageName: d?.packageName,
    orderId: <>ID:{d?.id}</>,
    remark: d?.remark,
    status: (
      <div
        className={
          orderType[d?.status] === "PENDING"
            ? "badge badge-warning text-white"
            : orderType[d?.status] === "CANCELED"
            ? "badge badge-danger"
            : orderType[d?.status] === "RESCHEDULE"
            ? "badge badge-info text-white"
            : orderType[d?.status] === "PLACED"
            ? "badge badge-primary"
            : orderType[d?.status] === "DELIVERED"
            ? "badge badge-success"
            : ""
        }
      >
        {orderType[d?.status]}
      </div>
    ),
    customer: (
      <>
        {d?.customerName}
        <small className="d-block">[Number: {d?.customerMobile}]</small>
      </>
    ),
    entryOn: d?.entryOn,
    scheduledOn: d?.scheduledOn,
    isCompleted: d?.isCompleted ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    statusRemark: d?.statusRemark,
    totalItem: d?.totalItem,
    totalMrp: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.totalMrp}
      </>
    ),
    paybleAmount: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.paybleAmount}
      </>
    ),
    paymentMode: payModeType[d?.paymentMode],
    invoiceRec: (
      <>
        <NavLink
          to={{
            pathname: "/InvoiceOrder/" + d?.id,
          }}
          state={{ refference: "/GetOrder" }}
        >
          <button type="button" className="btn btn-green btn-sm">
            INVOICE
          </button>
        </NavLink>
      </>
    ),
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: name === "orderStatus" ? parseInt(value) : value,
      };
    });
  };

  const handleSubmit = async (status, e) => {
    e?.preventDefault();
    setSubmitLoader(true);
    setLoading(true);
    try {
      const res = await postRequest(`api/Order/GetOrderForAdmin`, {
        ...input,
        orderStatus: status || input.orderStatus,
        isRecurring: 0,
        toDate: moment(toDateNew).format("DD MMM YYYY"),
        fromDate: moment(fromDateNew).format("DD MMM YYYY"),
      });

      if (!res) {
        toast.error(res.result.error);
      } else {
        setOrder(res.result);
      }
      setLoading(false);
      setSubmitLoader(false);
    } catch (error) {
      setLoading(false);
      setSubmitLoader(false);
      toast.error("Something went wrong!!!");
    }
  };

  /////******** NEW ORDER********///////

  /////******** RECURRING ORDER********///////

  const recurringcolumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "CUSTOMER",
      selector: (row) => row.customer,
      width: "190px",
    },
    {
      name: "ORDER ID",
      selector: (row) => row.orderId,
      width: "100px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      width: "100px",
    },

    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "120px",
    },
    {
      name: "SCHEDULE ON",
      selector: (row) => row.scheduledOn,
      width: "120px",
    },

    {
      name: "STATUS REMARK",
      selector: (row) => row.statusRemark,
      width: "140px",
    },
    {
      name: "TOTAL ITEM",
      selector: (row) => row.totalItem,
      width: "110px",
    },
    {
      name: "TOTAL COST",
      selector: (row) => row.totalMrp,
      width: "110px",
    },
    {
      name: "PAYABLE AMT.",
      selector: (row) => row.paybleAmount,
      width: "120px",
    },
    {
      name: "PAYMENT MODE",
      selector: (row) => row.paymentMode,
      width: "140px",
    },
    {
      name: "PACKAGE NAME",
      selector: (row) => row.packageName,
      width: "180px",
    },
    {
      name: "REMARK",
      selector: (row) => row.remark,
      width: "160px",
    },
    {
      name: "ISCOMPLETED",
      selector: (row) => row.isCompleted,
      width: "120px",
    },
    {
      name: "ACTION",
      selector: (row) => row.invoiceRec,
      width: "120px",
    },
  ];

  const recurringdata = order?.map((d, i) => ({
    serial: i + 1,
    packageName: d?.packageName,
    orderId: <>ID:{d?.id}</>,
    remark: d?.remark,
    status: (
      <div
        className={
          orderType[d?.status] === "PENDING"
            ? "badge bg-warning text-white"
            : orderType[d?.status] === "CANCELED"
            ? "badge bg-danger text-white"
            : orderType[d?.status] === "RESCHEDULE"
            ? "badge bg-info text-white"
            : orderType[d?.status] === "PLACED"
            ? "badge bg-primary text-white"
            : orderType[d?.status] === "DELIVERED"
            ? "badge bg-success text-white"
            : ""
        }
      >
        {orderType[d?.status]}
      </div>
    ),
    customer: (
      <>
        {d?.customerName}
        <small className="d-block">[Number: {d?.customerMobile}]</small>
      </>
    ),
    entryOn: d?.entryOn,
    scheduledOn: d?.scheduledOn,
    isCompleted: d?.isCompleted ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    statusRemark: d?.statusRemark,
    totalItem: d?.totalItem,
    totalMrp: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.totalMrp}
      </>
    ),
    paybleAmount: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.paybleAmount}
      </>
    ),
    paymentMode: d?.paymentMode,
    invoiceRec: (
      <>
        <NavLink
          to={{
            pathname: "/InvoiceOrder/" + d?.id,
          }}
          state={{ refference: "/RecurringOrder" }}
        >
          <button
            type="button"
            className="btn btn-green sm cus-invoice-btn"
            style={{ fontSize: "10px" }}
          >
            INVOICE
          </button>
        </NavLink>
      </>
    ),
  }));

  const handleSubmitRecurring = async (status, e) => {
    setLoading(true);
    e?.preventDefault();
    try {
      const res = await postRequest(`api/Order/GetOrderForAdmin`, {
        ...input,
        orderStatus: status || input?.orderStatus,
        isRecurring: 1,
        toDate: toDate ? moment(toDate).format("DD MMM YYYY") : "",
        fromDate: fromDate ? moment(fromDate).format("DD MMM YYYY") : "",
      });

      if (!res) {
        toast.error(res.result.error);
      } else {
        setOrder(res.result);
      }
      setLoading(false);
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      setLoading(false);
      toast.error("Something went wrong!!!");
    }
  };

  /////******** RECURRING ORDER********///////

  /////******** SCHEDULEON ORDER********///////

  const scheduledOncolumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "CUSTOMER",
      selector: (row) => row.customer,
      width: "180px",
    },
    {
      name: "ORDER ID",
      selector: (row) => row.orderId,
      width: "100px",
    },

    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "120px",
    },
    {
      name: "SCHEDULE ON",
      selector: (row) => row.scheduledOn,
      width: "120px",
    },

    {
      name: "STATUS REMARK",
      selector: (row) => row.statusRemark,
      width: "140px",
    },
    {
      name: "TOTAL ITEM",
      selector: (row) => row.totalItem,
      width: "110px",
    },
    {
      name: "TOTAL COST",
      selector: (row) => row.totalMrp,
      width: "110px",
    },
    {
      name: "PAYABLE AMT.",
      selector: (row) => row.paybleAmount,
      width: "120px",
    },
    {
      name: "PAYMENT MODE",
      selector: (row) => row.paymentMode,
      width: "140px",
    },
    {
      name: "PACKAGE NAME",
      selector: (row) => row.packageName,
      width: "180px",
    },
    {
      name: "REMARK",
      selector: (row) => row.remark,
      width: "100px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "230px",
    },
  ];

  const handleMarkDelivered = async () => {
    let status = 9;
    try {
      const res = await postRequest(
        `api/Order/ChangeStatus/${idForMark}/${status}`
      );
      if (res.statusCode === 1) {
        toast.success(res.responseText);
        setShowModalFOrMark(false);
      } else {
        toast.error(res.responseText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const scheduledOndata = orderScheduleOn?.map((d, i) => ({
    serial: i + 1,
    packageName: d?.packageName,
    orderId: <>ID:{d?.id}</>,
    remark: d?.remark,
    customer: (
      <>
        {d?.customerName}
        <small className="d-block">[Number: {d?.customerMobile}]</small>
      </>
    ),
    entryOn: d?.entryOn,
    scheduledOn: d?.scheduledOn,
    statusRemark: d?.statusRemark,
    totalItem: d?.totalItem,
    totalMrp: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.totalMrp}
      </>
    ),
    paybleAmount: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        &nbsp;
        {d?.paybleAmount}
      </>
    ),
    paymentMode: payModeType[d?.paymentMode],
    action: (
      <>
        <NavLink
          to={{
            pathname: "/InvoiceOrder/" + d?.id,
          }}
          state={{ refference: "/ScheduleOnOrder" }}
        >
          <button
            type="button"
            className="btn btn-green sm"
            style={{ width: "70px", fontSize: "10px", textAlign: "center" }}
          >
            Invoice
          </button>
        </NavLink>
        &nbsp;&nbsp;
        <button
          type="button"
          className="btn btn-green sm"
          style={{
            fontSize: "10px",
            textAlign: "center",
          }}
          onClick={() => handlepopupMarked(d?.id)}
        >
          Mark Delivered
        </button>
      </>
    ),
  }));

  const handlepopupMarked = async (Id) => {
    setShowModalFOrMark(true);
    setIDfoeMark(Id);
  };

  const handleCloseForMark = () => setShowModalFOrMark(false);

  const handleSubmitScheduleOn = async (e) => {
    setLoading(true);
    e?.preventDefault();
    try {
      const res = await postRequest(`api/Order/GetScheduledOrders`, {
        toDate: toDateNew ? moment(toDateNew).format("DD MMM YYYY") : "",
        fromDate: fromDateNew ? moment(fromDateNew).format("DD MMM YYYY") : "",
      });

      if (!res) {
        toast.error(res.result.error);
      } else {
        setOrderScheduleOn(res.result);
      }
      setLoading(false);
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      setLoading(false);
      toast.error("Something went wrong!!!");
    }
  };

  /////******** SCHEDULEON ORDER********///////

  /////******** PG REPORT********///////

  const PGcolumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },

    {
      name: "USER",
      selector: (row) => row.name,
      width: "170px",
    },
    {
      name: "TID",
      selector: (row) => row.tid,
      width: "100px",
    },

    {
      name: "AMOUNT",
      selector: (row) => row.amount,
      width: "120px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      width: "140px",
    },
    {
      name: "UTR",
      selector: (row) => row.utr,
      width: "200px",
    },
    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "120px",
    },
    {
      name: "MODIFY ON",
      selector: (row) => row.modifyOn,
      width: "120px",
    },
    {
      name: "ACTION",
      selector: (row) => row.actions,
      width: "270px",
    },
  ];

  const handleStatusButtonClick = (tid, status) => {
    setSelectedTid(tid);
    setSelectedStatus(status);
    setShowModalRemark(true);
  };

  const handlingCloseModal = () => {
    setShowModalRemark(false);
    setRemark("");
    setSelectedTid(null);
    setSelectedStatus("");
  };

  const handleCloseModalCheckStatus = () => {
    setShowModalCheckStatus(false);
  };

  const checkStatusClick = async (tid) => {
    setIsLoadingCheck(true);
    try {
      const res = await postRequest(`CashFreeStatusCheck?tid=${tid}`);
      if (res?.statusCode === 1) {
        setShowModalCheckStatus(true);
        setstatusCheckRequest(res.result);
        handleSubmitPgReport();
      } else {
        toast.error(res?.responseText || "Failed");
      }
      setIsLoadingCheck(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoadingCheck(false);
    }
  };

  const handleConfirmSuccess = async () => {
    setLoading(true);
    setIsLoadingCheck(true);
    try {
      const res = await postRequest(`api/user/UpdateTXNManual`, {
        tid: selectedTid,
        status: selectedStatus,
        utr: remark,
      });
      if (res?.statusCode === 1) {
        handleSubmitPgReport();
        toast.success(res?.responseText);
      } else {
        toast.error(res?.responseText);
      }
      handlingCloseModal();
      setLoading(false);
      setIsLoadingCheck(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoadingCheck(false);
    }
  };

  const PGdata = pgorder?.map((d, i) => ({
    serial: i + 1,
    userId: d?.userId,
    tid: (
      <>
        {d?.tid}&nbsp;
        <span onClick={() => handlepopup(d?.tid)}>
          <i className="bi bi-info-circle" title="API Log"></i>
        </span>
      </>
    ),

    name: (
      <>
        {d?.name}
        <small className="d-block">[ID: {d?.userId}]</small>
        <small className="d-block">[Number: {d?.phoneNumber}]</small>
      </>
    ),
    phoneNumber: d?.phoneNumber,
    amount: d?.amount,
    status: (
      <div
        className={
          orderStatus[d?.status] === "SUCCESS"
            ? "badge bg-success text-white"
            : orderStatus[d?.status] === "FAILED"
            ? "badge bg-danger text-white"
            : orderStatus[d?.status] === "PENDING"
            ? "badge bg-warning text-white"
            : ""
        }
      >
        {orderStatus[d?.status]}
      </div>
    ),
    utr: d?.utr,
    entryOn: d?.entryOn,
    modifyOn: (
      <>
        {d && d?.modifyOn && (
          <>{format(new Date(d?.modifyOn), "dd MMM yyyy HH:mm:ss")}</>
        )}
      </>
    ),
    actions: (
      <>
        {orderStatus[d?.status] === "PENDING" && (
          <>
            <button
              type="button"
              className="badge badge-success"
              onClick={() => handleStatusButtonClick(d?.tid, "S")}
            >
              <i className="bi bi-check-circle"></i> &nbsp;SUCCESS
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="badge badge-danger"
              onClick={() => handleStatusButtonClick(d?.tid, "F")}
            >
              <i className="bi bi-x-circle"></i> &nbsp;FAILED
            </button>
            &nbsp;&nbsp;
            <button
              type="button"
              className="badge badge-warning text-white"
              onClick={() => checkStatusClick(d?.tid)}
              disabled={isLoadingCheck}
            >
              <i className="bi bi-exclamation-circle"></i> &nbsp;CHECK STATUS
            </button>
          </>
        )}
      </>
    ),
  }));

  const handleChangePgReport = (e) => {
    const { name, value } = e.target;

    setInput((prev) => {
      return {
        ...prev,
        [name]: name === "searchCriteria" ? parseInt(value) : value,
      };
    });
  };

  const handleSubmitPgReport = async () => {
    setLoading(true);
    try {
      const res = await postRequest(`api/Report/PGReport`, {
        searchCriteria: input.searchCriteria || 0,
        searchText: input.searchText || "",
        status: input.status || "",
        toDate: toDate ? moment(toDate).format("DD MMM YYYY") : "",
        fromDate: fromDate ? moment(fromDate).format("DD MMM YYYY") : "",
      });
      if (res) {
        setPGOrder(res);
      } else {
        toast.error("Something Went Wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };
  /////******** PG REPORT********///////

  /////******** LEDGER REPORT********///////

  const Ldcolumn = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "USER",
      selector: (row) => row.name,
      width: "150px",
    },
    {
      name: "TID",
      selector: (row) => row.tid,
      width: "80px",
    },
    {
      name: "ENTRY ON",
      selector: (row) => row.entryOn,
      width: "150px",
    },

    {
      name: "TR TYPE",
      selector: (row) => row.trType,
      width: "100px",
    },
    {
      name: "OPENING",
      selector: (row) => row.opening,
      width: "100px",
    },
    {
      name: "AMOUNT",
      selector: (row) => row.amount,
      width: "100px",
    },

    {
      name: "CLOSING",
      selector: (row) => row.closing,
      width: "100px",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      width: "250px",
    },

    {
      name: "REMARK",
      selector: (row) => row.remark,
      width: "250px",
    },
  ];

  const Lddata = ledgerList?.map((d, i) => ({
    serial: i + 1,
    trType: (
      <div
        className={
          ledgertrType[d?.trType] === "CREDIT"
            ? "badge bg-success text-white"
            : ledgertrType[d?.trType] === "DEBIT"
            ? "badge bg-danger text-white"
            : ""
        }
      >
        {ledgertrType[d?.trType]}
      </div>
    ),
    opening: d?.opening,
    amount: d?.amount,
    closing: d?.closing,
    remark: d?.remark,
    description: d?.description,
    entryOn: d?.entryOn,
    tid: d?.tid,
    name: (
      <>
        {d?.name}
        <small className="d-block">[ID: {d?.userID}]</small>
        <small className="d-block">[Number: {d?.phoneNumber}]</small>
      </>
    ),
  }));

  const handleChangeLedger = (e) => {
    const { name, value } = e.target;

    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmitLedger = async (e, mobile) => {
    if (e) e.preventDefault();
    setLoading(true);
    // setSubmitLoader(true);
    try {
      let obj = {
        ...input,
      };
      if (mobile) {
        obj.mobileNo = mobile;
      }
      const res = await postRequest(`api/Report/Ledger`, {
        ...obj,
        toDate: toDate ? moment(toDate).format("DD MMM YYYY") : "",
        fromDate: fromDate ? moment(fromDate).format("DD MMM YYYY") : "",
      });

      if (!res) {
        toast.error(res.result.error);
      } else {
        setLedgerList(res.result);
      }
      setLoading(false);
      // setSubmitLoader(false);
    } catch (error) {
      setLoading(false);
      setSubmitLoader(false);
      toast.error("Something went wrong!!!");
    }
  };

  /////******** LEDGER REPORT********///////

  /////******** INVOICE ORDER LIST********///////
  const handleSearch = async () => {
    setLoading(true);
    try {
      const res = await postRequest(
        `api/Order/Invoiceorder?orderId=${param.id}`
      );
      if (res) {
        setInvoice(res);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something Went Wrong");
      setLoading(false);
    }
  };

  const goback = () => {
    if (refference) {
      navigate(refference);
    }
  };

  /////******** INVOICE ORDER LIST********///////

  /////******** REQUEST RESPONSE LOG********///////

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlepopup = async (Id) => {
    try {
      const res = await postRequest(`api/Data/PGReqRes/${Id}`);
      if (res) {
        setLogRequest(res);
      }
    } catch (error) {
      toast.error("Something Went Wrong");
    }

    setShowModal(true);
  };

  /////******** REQUEST RESPONSE LOG********///////

  /////******** EXPORT TO EXCEL FOR NEW ORDER,RECURRING ORDER,LEDGER ,PGREPORT ********///////

  const FILETYPE = {
    EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  //// for new order///

  const handleDownloadNewOrderExcel = async () => {
    try {
      const res = await postRequest(`api/Order/ExportOrderForAdmin`, {
        ...input,
        isRecurring: 0,
        toDate: moment(toDateNew).format("DD MMM YYYY"),
        fromDate: moment(fromDateNew).format("DD MMM YYYY"),
      });
      if (res.statusCode === 1) {
        const base64String = res.result;
        const sampleArr = base64ToArrayBuffer(base64String);
        const blob = new Blob([sampleArr], { type: FILETYPE.EXCEL });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "blank";
        link.download = "ExportedFile.xlsx";
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  //// for new order///

  //// for recurring order///
  const handleDownloadRecurringExcel = async () => {
    try {
      const res = await postRequest(`api/Order/ExportOrderForAdmin`, {
        ...input,
        isRecurring: 1,
        toDate: moment(toDate).format("DD MMM YYYY"),
        fromDate: moment(fromDate).format("DD MMM YYYY"),
      });
      if (res.statusCode === 1) {
        const base64String = res.result;
        const sampleArr = base64ToArrayBuffer(base64String);
        const blob = new Blob([sampleArr], { type: FILETYPE.EXCEL });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "blank";
        link.download = "ExportedFile.xlsx";
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  //// for recurring order///

  //// for ledger///

  const handleDownloadLedgerExcel = async () => {
    try {
      const res = await postRequest(`api/Report/ExportLedger`, {
        toDate: moment(toDate).format("DD MMM YYYY"),
        fromDate: moment(fromDate).format("DD MMM YYYY"),
      });
      if (res.statusCode === 1) {
        const base64String = res.result;
        const sampleArr = base64ToArrayBuffer(base64String);
        const blob = new Blob([sampleArr], { type: FILETYPE.EXCEL });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "blank";
        link.download = "ExportedFile.xlsx";
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  //// for ledger///

  //// for scheduleOn  ///
  const handleDownloadExcelforScheduled = async () => {
    try {
      const res = await postRequest(`api/Order/ExportScheduledOrders`, {
        toDate: moment(toDateNew).format("DD MMM YYYY"),
        fromDate: moment(fromDateNew).format("DD MMM YYYY"),
        isAll: true,
      });
      if (res.statusCode === 1) {
        const base64String = res.result;
        const sampleArr = base64ToArrayBuffer(base64String);
        const blob = new Blob([sampleArr], { type: FILETYPE.EXCEL });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "blank";
        link.download = "ExportedFile.xlsx";
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  //// for scheduleOn  ///

  /// for pg Report////

  const handleDownloadPgReportExcel = async () => {
    try {
      const res = await postRequest(`api/Report/ExportPGReport`, {
        status: input.status || "",
        searchCriteria: input.searchCriteria || 0,
        searchText: input.searchText || "",
        toDate: moment(toDate).format("DD MMM YYYY"),
        fromDate: moment(fromDate).format("DD MMM YYYY"),
      });
      if (res.statusCode === 1) {
        const base64String = res.result;
        const sampleArr = base64ToArrayBuffer(base64String);
        const blob = new Blob([sampleArr], { type: FILETYPE.EXCEL });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "blank";
        link.download = "ExportedFile.xlsx";
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  /// for pg Report////

  /////******** EXPORT TO EXCEL FOR NEW ORDER,RECURRING ORDER,LEDGER ,PGREPORT ********///////

  return {
    toDate,
    fromDate,
    handleChangedate,
    handleChangedates,
    handleChange,
    data,
    handleSubmit,
    submitloader,
    toDateNew,
    fromDateNew,
    handleChangedateNew,
    handleChangedatesNew,
    input,
    columns,
    loading,
    handleSearch,
    invoice,
    goback,
    PGcolumns,
    PGdata,
    handleChangeLedger,
    handleSubmitLedger,
    Ldcolumn,
    Lddata,
    handleChangePgReport,
    handleSubmitPgReport,
    recurringcolumns,
    recurringdata,
    handleSubmitRecurring,
    setSubmitLoader,
    showModal,
    handleCloseModal,
    logRequest,
    handlingCloseModal,
    handleConfirmSuccess,
    remark,
    showModalRemark,
    setRemark,
    showModalCheckStatus,
    handleCloseModalCheckStatus,
    statusCheckRequest,
    scheduledOncolumns,
    scheduledOndata,
    handleSubmitScheduleOn,
    handleMarkDelivered,
    handleCloseForMark,
    showModalForMark,
    setInput,
    setToDate,
    setfromDate,
    handleDownloadNewOrderExcel,
    handleDownloadLedgerExcel,
    handleDownloadPgReportExcel,
    handleDownloadRecurringExcel,
    handleDownloadExcelforScheduled,
    setInput,
    // defaultOrderStatus,
    // setDefaultOrderStatus,
  };
};
export default GetOrderService;
