import { useState } from "react";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getRequest } from "../../Services/ApiServices";
import { useParams, useLocation } from "react-router-dom";

const SettingServices = () => {
  const [systemSettingData, setSystemSettingData] = useState([]);
  const [deliveryPersonInfo, setDeliveryPersonInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userAdd, setUserAdd] = useState();
  const [errors, setErrors] = useState({});
  const [area, setarea] = useState();
  const [submitloader, setSubmitLoader] = useState(false);
  const [input, setInput] = useState();
  const navigate = useNavigate();
  const [assignArea, setAssignArea] = useState([]);
  const [pincode, setPincode] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const params = useParams();
  const { state } = useLocation();
  const { assignMobileUseEffect } = state || {};
  const [showModal, setShowModal] = useState(false);
  const [getuserId, setuserGetId] = useState();
  const [getareaId, setareaGetId] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [showModalFundTranfer, setShowModalFundTransfer] = useState(false);
  const [idFundTransfer, setIDfundTransfer] = useState(null);
  const [inputFundtrans, setInputFundTrans] = useState({ trType: "C" });

  //////////********SYSTEM SETTING *******////////////

  const toggleDropdown = async (i) => {
    setIsOpen(isOpen == i ? "" : i);
  };

  const getSystemSettingData = async () => {
    try {
      setLoading(true);
      const res = await postRequest(`api/ApplicationSetting/GetAsync`);
      if (res) {
        setSystemSettingData(res);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  const handleInputChange = (index, fieldName, value) => {
    const numericValue = parseFloat(value);
    if (fieldName === "ref_Comm") {
      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
        setSystemSettingData((prevData) => {
          const newData = [...prevData];
          newData[index][fieldName] = numericValue;
          return newData;
        });
      }
    } else {
      setSystemSettingData((prevData) => {
        const newData = [...prevData];
        newData[index][fieldName] = value;
        return newData;
      });
    }
  };

  const handleUpdateSetting = async (e) => {
    e.preventDefault();
    try {
      setIsUpdating(true);
      const res = await postRequest(`api/ApplicationSetting/UpdateAsync`, {
        minimum_Purchase: +systemSettingData[0].minimum_Purchase,
        orderCancellationBefore: +systemSettingData[0].orderCancellationBefore,
        cashback: +systemSettingData[0].cashback,
        cashback_Type: systemSettingData[0].cashback_Type,
        ref_Comm: +systemSettingData[0].ref_Comm,
        ref_Duration_In_Days: +systemSettingData[0].ref_Duration_In_Days,
      });
      if (res) {
        toast.success("Data updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {
      console.log("Error", error);
      setIsUpdating(false);
    }
  };

  //////////********SYSTEM SETTING *******////////////

  //////////********EDIT DELIVERY PERSON *******////////////

  const handleDelivery = async (e, postalCode) => {
    const { name, value } = e.target;
    if (postalCode) {
      if (value.length === 6) {
        try {
          const res = await getRequest(
            `api/Home/GetAreaListAsync?postalCode=${value}`
          );
          let _a = [{ id: 0, area: "SELECT " }, ...res];
          setarea(_a);
        } catch (error) {
          console.error("Error occurred:", error);
        }
      }
    }
    setUserAdd((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      var res = await postRequest(`api/user/CreateDeliveryPerson`, {
        ...userAdd,
        otp: "0",
      });
      if (res) {
        toast.success(res?.responseText);
        navigate("/PinCodeMapping");
      } else {
        toast.error("Wrong Input");
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong!!!");
    }
  };

  const validateInput = () => {
    const newErrors = {};
    if (!userAdd?.name) {
      newErrors.name = "Please enter the Name.";
    }
    if (!userAdd?.phoneNumber) {
      newErrors.phoneNumber = "Please enter the phoneNumber.";
    }
    if (!userAdd?.email) {
      newErrors.email = "Please enter an email.";
    }

    if (!userAdd?.postalCode) {
      newErrors.postalCode = "Please enter the Pin code.";
    }
    if (!userAdd?.areaId || input?.areaId === "N/A") {
      newErrors.areaId = "Please enter the Area.";
    }
    if (!userAdd?.gender || input?.gender === "N/A") {
      newErrors.gender = "Please enter the Gender.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //////////********EDIT DELIVERY PERSON *******////////////

  //////////********PINCODE MAPPING AND  DELIVERY PERSON LIST *******////////////

  const dpcolumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "90px",
    },
    {
      name: "ACTION",
      selector: (row) => row.actions,
      width: "100px",
    },

    {
      name: "NAME",
      selector: (row) => row.name,
      width: "150px",
    },
    {
      name: "USER NAME",
      selector: (row) => row.userName,
      width: "150px",
    },
    {
      name: "NUMBER",
      selector: (row) => row.phoneNumber,
      width: "150px",
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      width: "250px",
    },
    {
      name: "BALANCE",
      selector: (row) => row.balance,
      width: "100px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      width: "100px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "150px",
    },
  ];

  const dpdata = deliveryPersonInfo?.map((d, i) => ({
    serial: i + 1,
    actions: (
      <>
        <div key={i} className="dropdown">
          <button
            className="btn btn-green btn-sm dropdown-toggle"
            type="button"
            onClick={() => {
              toggleDropdown(i + 1);
            }}
          ></button>
          <ul
            className={` ${
              isOpen === i + 1 ? "d-block cus-drop" : "d-none cus-drop"
            }`}
          >
            <li>
              <NavLink
                to={{ pathname: "/Ledger" }}
                state={{ alreadyfilled: d }}
              >
                <div className="dropdown-item">Ledger</div>
              </NavLink>
            </li>
            <li>
              <span
                className="dropdown-item"
                type="button"
                onClick={() => handlepopupfundtransfer(d?.id)}
              >
                Fund Transfer
              </span>
            </li>
          </ul>
        </div>
      </>
    ),
    name: d?.name,
    userName: d?.userName,
    phoneNumber: d?.phoneNumber,
    email: d?.email,
    balance: (
      <>
        <span className={d.balance < 0 ? "text-danger" : "text-success"}>
          <i className="fas fa-rupee-sign" aria-hidden="true"></i>
          &nbsp;{d.balance}
        </span>
      </>
    ),
    status: (
      <>
        <label className="toggle-label">
          <input
            className="toggle-input"
            type="checkbox"
            defaultChecked={d?.isActive}
            onChange={(e) => handleToggle(d?.id, e.target.checked)}
          />
          <span className="toggle-span"></span>
        </label>
      </>
    ),
    action: (
      <>
        <NavLink
          to={{
            pathname: `/AssigningArea/${d?.id}`,
          }}
          state={{ assignMobileUseEffect: d?.phoneNumber }}
          className="btn btn-green btn-sm"
        >
          ASSIGN
        </NavLink>
      </>
    ),
  }));

  const handlepopupfundtransfer = async (Id) => {
    setShowModalFundTransfer(true);
    setIDfundTransfer(Id);
  };

  const handlingCloseModal = () => setShowModalFundTransfer(false);

  const handleFundTransfer = (e) => {
    const { name, value } = e.target;
    setInputFundTrans({ ...inputFundtrans, [name]: value });
  };

  const handleTrTypeChange = (type) => {
    setInputFundTrans({
      ...inputFundtrans,
      trType: type,
    });
  };

  const handleConfirmFundTransfer = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(`api/user/FundTransfer`, {
        userId: idFundTransfer,
        amount: +inputFundtrans.amount,
        trType: inputFundtrans.trType,
        remark: inputFundtrans.remark,
      });
      if (res.statusCode === 1) {
        toast.success(res.responseText);
        getDeliveryList();
        setInputFundTrans({});
        setShowModalFundTransfer(false);
      } else {
        toast.error(res.responseText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle = async (Id, check) => {
    try {
      const status = check ? true : false;
      const res = await postRequest(`api/user/DeliveryPersonChangeSTS`, {
        usreId: Id,
        isActive: status,
      });
      if (res) {
        getDeliveryList();
        toast.success(res.responseText);
      } else {
        toast.error(res.responseText);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const getDeliveryList = async () => {
    setLoading(true);
    try {
      const res = await postRequest(`api/user/List`, {
        roleID: 3,
        fromDate: "",
        toDate: "",
      });
      if (res) {
        setDeliveryPersonInfo(res);
      } else {
        toast.error("Something Went Wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const getAssignAreaUseEffect = async () => {
    setLoading(true);
    try {
      const res = await postRequest(`api/user/GetAreaByPincode`, {
        isMapped: true,
        start: 0,
        top: 1000,
        pincode: 0,
        mobileNo: assignMobileUseEffect || "",
      });
      if (res) {
        setAssignArea(res?.areas);
      } else {
        toast.error("Something Went Wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  //////////********PINCODE MAPPING AND  DELIVERY PERSON LIST *******////////////

  //////////********ASSIGNING AREA *******////////////
  const handlePincodeChange = (e) => {
    const value = e.target.value;
    setPincode(value);
  };

  const getByPinCodes = async (pincode) => {
    const res = await postRequest(`api/user/GetAreaByPincode`, {
      isMapped: false,
      start: 0,
      top: 100,
      pincode: pincode,
      mobileNo: "",
    });
    if (res) {
      setTableData(res.areas);
    }
  };

  const pincolumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "100px",
    },

    {
      name: " AREA",
      selector: (row) => row.area,
      width: "180px",
    },

    {
      name: " CITY",
      selector: (row) => row.city,
      width: "140px",
    },
  ];

  const pindata = tableData?.map((d, i) => ({
    serial: (
      <span className="d-flex">
        <input
          type="checkbox"
          className="p-0 m-0"
          checked={selectedRows.includes(i)}
          onChange={() => toggleRowSelection(i)}
        />
        &nbsp;&nbsp;&nbsp;
        {i + 1}
      </span>
    ),
    area: d?.area,
    city: d?.city,
  }));

  const toggleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(index)) {
        return prevSelectedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevSelectedRows, index];
      }
    });
  };

  const handleAssign = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    let selectedItems = selectedRows.map((index) => tableData[index]);
    let additionalData = {
      userID: +params.id,
    };
    let requestData = {
      ...additionalData,
      areaId: selectedItems.map((item) => item?.areaId).toString(),
      pincode: 0,
      //   pincode: selectedItems[0]?.pincode,
    };
    setLoading(true);
    try {
      const res = await postRequest(
        `api/user/DeliveryPincodeMapping`,
        requestData
      );
      if (res) {
        toast.success(res.responseText);
        setAssignArea([...assignArea, ...selectedItems]);
      }
      setSubmitLoader(false);
      setLoading(false);
    } catch (error) {
      console.error("Error assigning data:", error);
      setLoading(false);
    }
  };
  //////////********ASSIGNING AREA *******////////////

  //////////********ASSIGNING AREA LIST SHOW*******////////////

  const assignareacolumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "PINCODE",
      selector: (row) => row.pincode,
      width: "120px",
    },

    {
      name: " AREA",
      selector: (row) => row.area,
      width: "140px",
    },

    {
      name: " CITY",
      selector: (row) => row.city,
      width: "140px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "100px",
    },
  ];

  const assignareadata = assignArea?.map((d, i) => ({
    serial: i + 1,
    action: (
      <>
        <span
          onClick={() => handlepopup(d?.userID, d?.areaId)}
          style={{ cursor: "pointer" }}
        >
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    area: d?.area,
    city: d?.city,
    pincode: d?.pincode,
  }));

  //////////********ASSIGNING AREA LIST SHOW*******////////////

  ////////****** ASSIGN AREA DELETE BY ID */////////

  const handlepopup = async (uId, aId) => {
    setShowModal(true);
    setuserGetId(uId);
    setareaGetId(aId);
  };

  const handleCloseAssignArea = () => setShowModal(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await postRequest(`api/user/Unmapdeliveryperson`, {
        userID: getuserId,
        areaId: getareaId,
      });
      if (res.statusCode) {
        toast.success(res.responseText);
        setShowModal(false);
        getAssignAreaUseEffect();
      } else {
        toast.error("Something Went Wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  ////////****** ASSIGN AREA DELETE BY ID */////////

  return {
    dpcolumns,
    dpdata,
    handleSubmit,
    handleDelivery,
    errors,
    loading,
    getDeliveryList,
    area,
    handlePincodeChange,
    getByPinCodes,
    pindata,
    pincolumns,
    handleAssign,
    pincode,
    assignArea,
    loading,
    getAssignAreaUseEffect,
    assignareadata,
    assignareacolumns,
    submitloader,
    handlepopup,
    handleDelete,
    handleCloseAssignArea,
    showModal,
    getSystemSettingData,
    systemSettingData,
    handleInputChange,
    handleUpdateSetting,
    isUpdating,
    handlingCloseModal,
    handleTrTypeChange,
    handleFundTransfer,
    handleConfirmFundTransfer,
    inputFundtrans,
    showModalFundTranfer,
  };
};

export default SettingServices;
