import { useState } from "react";
import { postRequest } from "../../Services/ApiServices";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AreaListService = () => {
  const [areaInfo, setAreaInfo] = useState([]);
  const [isMap, setIsMap] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [totalAreas, setTotalAreas] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [pincode, setPincode] = useState("");

  const handleMobileSearch = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
  };

  const handlePinCodeSearch = (e) => {
    const value = e.target.value;
    setPincode(value);
  };

  const handleSearchMobileNo = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(`api/user/GetArea`, {
        isMapped: true,
        start: 0,
        top: 100,
        mobileNo: mobileNumber || "",
        pincode: pincode || 0,
      });
      if (res) {
        setAreaInfo(res.areas);
      } else {
        toast.error("Wrong Input");
      }
    } catch (error) {
      toast.error("Something went wrong!!!");
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },

    {
      name: "NAME",
      selector: (row) => row.name,
      width: "220px",
    },
    {
      name: "AREA",
      selector: (row) => row.area,
      width: "180px",
    },
    {
      name: "PHONE NUMBER",
      selector: (row) => row.phoneNumber,
      width: "200px",
    },
    {
      name: "PIN CODE",
      selector: (row) => row.pincode,
      width: "150px",
    },
    {
      name: "MAP",
      selector: (row) => row.map,
    },
  ];

  const data = areaInfo?.map((d, i) => ({
    serial: i + 1,
    name: d?.name,
    area: d?.area,
    phoneNumber: d?.phoneNumber,
    pincode: d?.pincode,
    map: (
      <>
        {!isMap ? (
          <Link
            to={{
              pathname: `/User`,
            }}
            state={{ isArea: true, areaData: d }}
            className="btn btn-green btn-sm "
          >
            Map
          </Link>
        ) : (
          ""
        )}
      </>
    ),
  }));

  const getAreaList = async (unMapValue, isUnMapChange, page, row) => {
    try {
      // setIsLoading(true);
      let start = ((page || currentPage) - 1) * (row || rowPerPage);
      let top = row || rowPerPage;
      const moduleBase = "api/user";
      let obj = {
        isMapped: isUnMapChange ? !unMapValue : isMap, // isMapped will false if unMap true
        start: start,
        top: top,
      };

      const res = await postRequest(`${moduleBase}/GetArea`, obj);
      if (res) {
        setTotalAreas(res?.totalCount);
        setAreaInfo(res?.areas);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred:", error);
    }
  };

  const handleIsMap = async (e) => {
    try {
      setIsMap(!e.target.checked);
      getAreaList(e.target.checked, true);
    } catch (error) {
      console.log(error);
    }
  };

  const onPageChange = async (page) => {
    setCurrentPage(page);
    getAreaList("", "", page, rowPerPage);
  };

  const onRowChange = async (row) => {
    setRowPerPage(row);
    getAreaList("", "", currentPage, row);
  };

  return {
    data,
    isLoading,
    getAreaList,
    columns,
    handleIsMap,
    onPageChange,
    onRowChange,
    totalAreas,
    mobileNumber,
    handleMobileSearch,
    handleSearchMobileNo,
    handlePinCodeSearch,
    pincode,
  };
};

export default AreaListService;
