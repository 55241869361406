import React from "react";
import { useState } from "react";
import { postRequest } from "../../../Services/ApiServices";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EmailSettingServices = () => {
  const [emailfetch, setEmailFetch] = useState([]);
  const [input, setInput] = useState({ isActive: true, isSSL: true });
  const [loading, setLoading] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  const [getId, setGetId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const params = useParams();

  ////////********  GET THE TABLE *******///////////

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "90px",
    },
    {
      name: "EDIT",
      selector: (row) => row.edit,
      width: "100px",
    },

    {
      name: "FROMEMAIL",
      selector: (row) => row.emailFrom,
      width: "250px",
    },
    {
      name: "USRE NAME",
      selector: (row) => row.userId,
      width: "250px",
    },
    {
      name: "PASSWORD",
      selector: (row) => row.password,
      width: "200px",
    },
    {
      name: "HOST NAME",
      selector: (row) => row.hostName,
      width: "170px",
    },
    {
      name: "PORT",
      selector: (row) => row.port,
      width: "100px",
    },

    {
      name: "ACTIVE",
      selector: (row) => row.isActive,
      width: "100px",
    },
    {
      name: "SSL",
      selector: (row) => row.isSSL,
      width: "100px",
    },
  ];

  const data = emailfetch.map((d, i) => ({
    serial: i + 1,
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditEmailSetting/" + d?.id,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span onClick={() => handlepopup(d?.id)} style={{ cursor: "pointer" }}>
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    emailFrom: d?.emailFrom,
    password: d?.password,
    userId: d?.userId,
    port: d?.port,
    hostName: d?.hostName,
    isActive: d?.isActive ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    isSSL: d?.isSSL ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
  }));

  const getEmailSetting = async () => {
    try {
      setLoading(true);
      const res = await postRequest(`api/EmailConfig/GetAll`);
      if (res) {
        setEmailFetch(res);
      }
    } catch (error) {
      console.log("Error", error);
    }
    setLoading(false);
  };

  ////////********  GET THE TABLE *******///////////

  ////////********  ADD THE FORM *******///////////

  const handleChange = async (e) => {
    const { name, value, checked } = e.target;
    setInput((prev) => {
      if (name === "isActive") {
        return { ...prev, [name]: checked };
      } else if (name === "isSSL") {
        return { ...prev, [name]: checked };
      } else {
        return { ...prev, [name]: value };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    try {
      setSubmitLoader(true);
      const res = await postRequest(`api/EmailConfig/Add`, {
        id: params.id || 0,
        emailFrom: input.emailFrom,
        password: input.password,
        isSSL: input.isSSL,
        port: input.port,
        hostName: input.hostName,
        userId: input.userId,
        isActive: input.isActive,
      });
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
        return navigate("/EmailSettings", { replace: true });
      } else {
        toast.error(res.responseText);
      }
      setSubmitLoader(false);
    } catch (error) {
      console.log("error", error);
      setSubmitLoader(false);
    }
  };

  ////////********  ADD THE FORM *******///////////

  ////////********  EDIT THE FORM  BY ID *******///////////

  const getEmailById = async () => {
    try {
      const res = await postRequest(`api/EmailConfig/GetById?Id=${params.id}`);
      if (res?.statusCode === 1) {
        setInput(res.result);
      } else {
        toast.error("Somethig went wrong");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  ////////********  EDIT THE FORM  BY ID *******///////////

  ////////********  DELETE THE FORM  BY ID *******///////////

  const handlepopup = async (Id) => {
    setShowModal(true);
    setGetId(Id);
  };
  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/EmailConfig/Delete?Id=${getId}`);
      if (res) {
        getEmailSetting();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  ////////********  DELETE THE FORM  BY ID *******///////////

  ////////********  VALIDATE THE FORM  *******///////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.emailFrom) {
      newErrors.emailFrom = "Enter the email address.";
    } else if (!/\S+@\S+\.\S+/.test(input.emailFrom)) {
      newErrors.emailFrom = "Enter a valid email address.";
    }
    if (!input?.password) {
      newErrors.password = "Enter the password .";
    }
    if (!input?.userId) {
      newErrors.userId = "Enter the user name .";
    }
    if (!input?.hostName) {
      newErrors.hostName = "Enter the host name.";
    }
    if (!input?.port) {
      newErrors.port = "Enter the port number.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  ////////********  VALIDATE THE FORM  *******///////////
  return {
    getEmailSetting,
    columns,
    data,
    loading,
    showModal,
    handleClose,
    handleDelete,
    handleSubmit,
    submitloader,
    errors,
    input,
    handleChange,
    getEmailById,
  };
};

export default EmailSettingServices;
