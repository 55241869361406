import React from "react";
import SeasonTypeService from "./SeasonTypeService";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const GetMonth = () => {
  const {
    getMonthList,
    monthList,
    goback,
    handleCheckboxMap,
    getSeasonMonthMapping,
    checkMonth,
  } = SeasonTypeService();

  useEffect(() => {
    getMonthList();
    getSeasonMonthMapping();
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center">
                      <ul
                        className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Season</li>

                        <li className="breadcrumb-item active">Month List</li>
                      </ul>
                      <span
                        className="float-end btn btn-sm btn-danger"
                        onClick={goback}
                      >
                        BACK
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" pak-height card">
          <div className="card-body p-2">
          <table className="table">
            <thead>
              <tr style={{ backgroundColor: "#568a0e" }}>
                <th className="text-center">SELECT</th>
                <th className="text-center">#</th>
                <th className="text-center"> NAME</th>
                <th className="text-center">SHORTNAME</th>
              </tr>
            </thead>
            <tbody>
              {monthList?.map((m, i) => (
                <>
                  <tr key={i} data-monthid={m?.monthId}>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={checkMonth[0]?.months?.some(
                          (month) =>
                            month?.monthId == m?.monthId && month.isMapped == 1
                        )}
                        onChange={(e) => handleCheckboxMap(e, m?.monthId)}
                      />
                    </td>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{m?.monthName}</td>
                    <td className="text-center">{m?.shortMonthName}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          </div>
        </div>
      </main>
    </>
  );
};

export default GetMonth;
