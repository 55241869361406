import HeaderTop from "./HeaderTop";

import FooterLast from "./FooterLast";
import { useEffect } from "react";

let Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <HeaderTop />
     
      <main>

        <section className="breadcrumb-area breadcrumb-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="breadcrumb-content">
                            <h2 className="title">Our Gallery</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Our Gallery</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="special-products-area gray-bg pt-75 pb-60">
    <div className="container">
        <div className="row align-items-end mb-50">
            <div className="col-md-8 col-sm-9">
                <div className="section-title">
                    <span className="sub-title">Awesome Pics</span>
                    <h2 className="title">Our Gallery</h2>
                </div>
            </div>
           
        </div>
        <div className="special-products-wrap">
            <div className="row">
                
                <div className="col-12">
                    <div className="list-group gallery">
                    <div className="row ">

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-01.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-01.jpg" />
        {/* <!--<div className="text-right">
<small className="text-muted">Image Title</small>
</div>--> */}
    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-02.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-02.jpg" />

    </a>
</div>


<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-03.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-03.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-04.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-04.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-05.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-05.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-06.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-06.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-07.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-07.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-08.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-08.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-09.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-09.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-10.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-10.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-11.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-11.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-12.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-12.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-13.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-13.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-14.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-14.jpg" />

    </a>
</div>

<div className="col-sm-6 col-xs-6 col-md-3 col-lg-3 img-gal">
    <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-15.jpg">
        <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-15.jpg" />

    </a>
</div>

</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>



    </main>
      <FooterLast />
    </>
  );
};

export default Gallery;
