import { React, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import Style from "./Category.module.css";

const CategoryService = () => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState({
    imageData: "",
    isPublish: true,
  });
  const navigate = useNavigate();
  const params = useParams();
  const [submitloader, setSubmitLoader] = useState(false);
  const [image, setImage] = useState();
  const [banner, setBanner] = useState();
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [getId, setGetId] = useState();

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "70px",
    },
    {
      name: "EDIT",
      selector: (row) => row.edit,
      width: "100px",
    },
    {
      name: "ICON",
      selector: (row) => row.icon,
      width: "150px",
    },
    {
      name: "CATEGORY NAME",
      selector: (row) => row.categoryId,
      width: "250px",
    },
    {
      name: "BANNER",
      selector: (row) => row.banners,
      width: "150px",
    },
    {
      name: "TAX RATE",
      selector: (row) => row.taxRate,
      width: "140px",
    },
    {
      name: "ISPUBLISH",
      selector: (row) => row.isPublish,
      width: "150px",
    },
  ];

  const data = category?.map((d, i) => ({
    serial: i + 1,
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditCategory/" + d?.categoryId,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span
          onClick={() => handlepopup(d?.categoryId)}
          style={{ cursor: "pointer" }}
        >
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    icon: (
      <>
        <img
          className={"card-img-top " + Style.icon}
          src={d?.icon?.split(",")[0]}
          onError={(e) =>
            (e.target.onerror = null)(
              (e.target.src = "/assets/img/noimage.jpg")
            )
          }
          style={{ height: "40px", width: "40px" }}
        />
      </>
    ),
    categoryId: (
      <>
        {d?.categoryName}
        <small className="d-block">[ID:{d?.categoryId}]</small>
      </>
    ),
    banners: (
      <img
        className="card-img-top w-10"
        src={d?.banners?.split(",")[0]}
        onError={(e) => {
          e.target.src = "/assets/img/noimage.jpg";
          e.target.onerror = null;
        }}
        style={{ height: "40px", width: "40px" }}
      />
    ),
    taxRate: <> {d?.taxRate}&nbsp;%</>,
    isPublish: d.isPublish ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
  }));

  //////////******** GET THE CATEGORY LIST ********//////////
  const getCategoryList = async () => {
    try {
      const res = await postRequest(`api/Catgory/Get`, {
        parentId: 0,
      });
      if (res.result) {
        setCategory(res.result);
        setLoading(false);
      } else {
        toast.error("Something is wrong...");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //////////******** GET THE CATEGORY LIST ********//////////

  ///////***** EDIT AND ADD CATEGORY *******////////

  const handleImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["icon"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
  };

  const handleBanner = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["banners"] = objectUrl;
    setBanner({
      value: e.target.files[0],
    });
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "taxRate") {
      if (isNaN(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter valid number",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setInput((prev) => {
          return {
            ...prev,
            [name]: name === "isPublish" ? checked : value,
          };
        });
      }
    } else {
      setInput((prev) => {
        return {
          ...prev,
          [name]: name === "isPublish" ? checked : value,
        };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);

    try {
      let res = {};
      let formData = new FormData();
      let __d = {
        ...input,
        isVendorGrouped: "true",
        parentId: 0,
      };
      formData.append("Images", image?.value);
      formData.append("Banners", banner?.value);
      for (let key in __d) {
        formData.append(key, __d[key]);
      }
      res = await postRequest(
        `api/Catgory/Add`,
        formData,
        "multipart/form-data"
      );
      if (!res) {
        toast.error(res.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/Category", { replace: true });
        }
      }
      setSubmitLoader(false);
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || "Something went wrong";
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong");
      }
      setSubmitLoader(false);
    }
  };
  ///////***** EDIT AND ADD CATEGORY *******////////

  ///////***** GET CATEGORY BY ID *******////////

  const getCategoryDetail = async () => {
    try {
      const res = await postRequest(
        `api/Catgory/GetById?id=${params?.categoryId}`
      );
      if (res.result) {
        if (res.result.hasOwnProperty("imageData")) {
          res.result.imageData = res?.result?.imageData;
        }
        setInput(res.result);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  ///////***** GET CATEGORY BY ID *******////////

  ///////***** VALIDATING THE CATEGORY *******////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.categoryName) {
      newErrors.categoryName = "Please enter a category name.";
    }
    if (!input?.taxRate) {
      newErrors.taxRate = "Please enter the tax Rate.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  ///////***** VALIDATING THE CATEGORY *******////////

  ///////***** DELETE THE CATEGORY *******////////

  const handlepopup = async (cId) => {
    setShowModal(true);
    setGetId(cId);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/Catgory/Delete?categoryId=${getId}`);
      if (res) {
        getCategoryList();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  ///////***** DELETE THE CATEGORY *******////////

  return {
    loading,
    data,
    getCategoryList,
    handleChange,
    handleImage,
    handleSubmit,
    handleBanner,
    params,
    input,
    submitloader,
    getCategoryDetail,
    Style,
    columns,
    errors,
    showModal,
    handleClose,
    handleDelete,
  };
};

export default CategoryService;
