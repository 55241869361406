import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import BannerService from "./BannerService";

const EditBanner = () => {
  const {
    handleSubmit,
    getBannerList,
    handleImage,
    handleChange,
    submitloader,
    input,
    errors,
  } = BannerService();

  const params = useParams();

  useEffect(() => {
    if (params.id !== undefined) {
      getBannerList();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Banner </li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container w-100">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-2">
                      Title :
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        name="title"
                        placeholder="Enter the banner title"
                        value={input?.title}
                        onChange={handleChange}
                      />
                      {errors.title && (
                        <div className="help-block text-danger">
                          {errors.title}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-2">
                      Banner Type :
                      <select
                        className="form-control"
                        id="bannerType"
                        name="bannerType"
                        value={input?.bannerType}
                        onChange={handleChange}
                      >
                        <option value="N/A">Select type...</option>
                        <option value="1">Dashboard</option>
                        <option value="2">Category</option>
                        <option value="3">FarmCommunity</option>
                        <option value="4">AgriTourism</option>
                      </select>
                    </div>
                    {errors.bannerType && (
                      <div className="help-block text-danger">
                        {errors.bannerType}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-2">
                      Backlink :
                      <input
                        type="text"
                        className="form-control"
                        id="backlink"
                        name="backlink"
                        placeholder="Enter the link"
                        value={input?.backlink}
                        onChange={handleChange}
                      />
                      {errors.backlink && (
                        <div className="help-block text-danger">
                          {errors.backlink}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mb-2">
                      Image :
                      <input
                        type="file"
                        className="form-control"
                        id="imagePath"
                        name="imagePath"
                        onChange={handleImage}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 ">
                    <img
                      className="d-block"
                      style={{ minHeight: "80px", maxWidth: "80px" }}
                      src={input?.imagePath || "/assets/img/noimage.jpg"}
                      onError={(e) =>
                        (e.target.onerror = null)(
                          (e.target.src = "/assets/img/noimage.jpg")
                        )
                      }
                      class="card-img-top"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            {!submitloader ? (
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-green">Submitting...</button>
              </>
            )}
            <NavLink to="/Banner" className="btn btn-danger">
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};
export default EditBanner;
