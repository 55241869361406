import React from "react";
import SettingServices from "./SettingServices";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const AssigningArea = () => {
  const {
    handlePincodeChange,
    getByPinCodes,
    pindata,
    pincolumns,
    handleAssign,
    submitloader,
    pincode,
    loading,
    getAssignAreaUseEffect,
    assignareadata,
    assignareacolumns,
    handleDelete,
    handleCloseAssignArea,
    showModal,
  } = SettingServices();

  useEffect(() => {
    if (pincode !== "") {
      getByPinCodes(pincode);
    }
  }, [pincode]);

  useEffect(() => {
    getAssignAreaUseEffect();
  }, []);

  return (
    <div>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0 ">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Assign Area</li>

                        <li className="breadcrumb-item active">Form</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-popup" id="myForm">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="card p-2">
                <form className="form-container w-100">
                  <div className="form-group">
                    <label className="signup_label" htmlFor="pincode">
                      POSTAL CODE:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pincode"
                      name="pincode"
                      value={pincode}
                      onChange={handlePincodeChange}
                    />
                  </div>
                  <div className="card form-group">
                    <div className="col-lg-12 cus-table-records">
                      <div className="table-responsive">
                        {loading ? (
                          <div className="spinner-container">
                            <Spinner type="TailSpin" color="#00BFFF" />
                          </div>
                        ) : (
                          <DataTable
                            columns={pincolumns}
                            data={pindata}
                            pagination
                            className="rdt_TableHeadRow"
                            fixedHeader
                            fixedHeaderScrollHeight="calc(100vh - 205px)"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group  button-group d-flex justify-content-end">
                    {!submitloader ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={handleAssign}
                        >
                          ASSIGN
                        </button>
                      </>
                    ) : (
                      <>
                        <button className="btn btn-green">Processing...</button>
                      </>
                    )}
                    <NavLink to="/PinCodeMapping" className="btn btn-danger">
                      CANCEL
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="card p-2" style={{ minHeight: "172px" }}>
                <h5>ASSIGNED AREA:</h5>
                <hr />
                <div className=" card">
                  <div className="col-lg-12 cus-table-records">
                    <div className="table-responsive">
                      {loading ? (
                        <div className="spinner-container">
                          <Spinner type="TailSpin" color="#00BFFF" />
                        </div>
                      ) : (
                        <DataTable
                          columns={assignareacolumns}
                          data={assignareadata}
                          pagination
                          className="rdt_TableHeadRow"
                          fixedHeader
                          fixedHeaderScrollHeight="calc(100vh - 205px)"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={showModal} size="sm" onHide={handleCloseAssignArea}>
          <Modal.Header>
            <Modal.Title className="mt-3">
              <i
                className="bi bi-exclamation-circle-fill"
                style={{ color: "#f1c40f" }}
              ></i>
              &nbsp;Confirmation
            </Modal.Title>
            <span
              aria-hidden="true"
              onClick={handleCloseAssignArea}
              className="modal-cross"
            >
              &times;
            </span>
          </Modal.Header>
          <Modal.Body className="pt-0">Aye you sure to delete area?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseAssignArea}>
              No
            </Button>
            <Button variant="success" onClick={handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
};

export default AssigningArea;
