import { React, useEffect } from "react";
import { NavLink } from "react-router-dom";
import UserService from "./UserService";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";

const UserProfile = () => {
  const {
    goback,
    getUserProfileAddress,
    addressForProfile,
    orderData,
    orderColumn,
    ordersForUserProfile,
    isLoading,
    userProfileById,
    getUserProfileById,
  } = UserService();

  useEffect(() => {
    getUserProfileById();
    getUserProfileAddress();
    ordersForUserProfile();
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Users</li>

                        <li className="breadcrumb-item active">User Profile</li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-sm-12 justify-content-end d-flex align-items-center">
                      <p className="dropdown d-inline-block mb-0 mr-2">
                        <button
                          type="button "
                          className="btn btn-danger"
                          onClick={goback}
                        >
                          BACK
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ margin: "2px" }}>
          <div className="card p-2 col-md-12">
            <div className="card-header">
              <h5 style={{ color: "green" }}>Personal Detail :</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-2">
                          <b style={{ color: "gray" }}>ID:</b>
                        </div>
                        <div className="col-md-10">{userProfileById?.id}</div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-2">
                          <b style={{ color: "gray" }}>Name:</b>
                        </div>
                        <div className="col-md-10">{userProfileById?.name}</div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-2">
                          <b style={{ color: "gray" }}>Phone No:</b>
                        </div>
                        <div className="col-md-10">
                          {userProfileById?.phoneNumber}
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-2">
                          <b style={{ color: "gray" }}>Email:</b>
                        </div>
                        <div className="col-md-10">
                          {userProfileById?.email}
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-2">
                          <b style={{ color: "gray" }}>Balance:</b>
                        </div>
                        <div className="col-md-10">
                          <i
                            className="text-success fas fa-rupee-sign"
                            aria-hidden="true"
                          ></i>
                          &nbsp;
                          {userProfileById?.balance}&nbsp;&nbsp;&nbsp;
                          <NavLink
                            to={{ pathname: "/Ledger" }}
                            state={{
                              alreadyfilledMobile: userProfileById?.phoneNumber,
                            }}
                          >
                            <i
                              class="bi bi-exclamation-circle-fill"
                              title="Go to ledger"
                              style={{ color: "#f1c40f", cursor: "pointer" }}
                            ></i>
                          </NavLink>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="col-md-3">
                  <div className="text-right">
                    <img
                      src="/assets/img/profile2.png"
                      alt="Image Not Avail"
                      style={{ maxWidth: "100px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card p-2 col-md-12">
          <div className="card-header">
            <h5 style={{ color: "green" }}>Address :</h5>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item cus-profileUser">
              <div className="row">
                <div className="col-md-12">
                  <ul>
                    {addressForProfile?.map((d, i) => (
                      <li className="bullet-list mb-3" key={i}>
                        {d?.houseNo},&nbsp; {d?.areaName},&nbsp; {d?.landmark},
                        &nbsp;{d?.townCity},&nbsp; {d?.stateName},&nbsp;
                        {d?.pincode}&nbsp;&nbsp;
                        {d?.isDefault && (
                          <span
                            className="badge badge-success"
                            style={{ padding: "5px" }}
                          >
                            Default
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="card p-2 col-md-12">
          <div className="card-header">
            <h5 style={{ color: "green" }}>Order History :</h5>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive ">
              {isLoading ? (
                <div className="spinner-container">
                  <Spinner type="TailSpin" color="#00BFFF" />
                </div>
              ) : (
                <DataTable
                  columns={orderColumn}
                  data={orderData}
                  pagination
                  className="rdt_TableHeadRow"
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 260px)"
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserProfile;
