import React, { useState } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { postRequest } from "../../../Services/ApiServices";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const SeasonTypeService = () => {
  const [seasonType, setSeasonType] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState([]);
  const [submitloader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  const param = useParams();
  const [checkMonth, setCheckMonth] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [getId, setGetId] = useState();
  const [errors, setErrors] = useState({});

  /////****GET SEASON TYPE */////

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,

      width: "80px",
    },
    {
      name: "EDIT",
      selector: (row) => row.edit,
      width: "120px",
    },
    {
      name: "NAME",
      selector: (row) => row.seasonName,
      width: "200px",
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
      width: "200px",
    },
    {
      name: "MONTH MAPPING",
      selector: (row) => row.map,
      width: "200px",
    },
  ];

  const data = seasonType?.map((d, i) => ({
    serial: i + 1,
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditSeasonType/" + d?.seasonId,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span
          onClick={() => handlepopup(d?.seasonId)}
          style={{ cursor: "pointer" }}
        >
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    seasonName: (
      <>
        {d?.seasonName} <small className="d-block">[ID:{d?.seasonId}]</small>
      </>
    ),
    status: (
      <>
        <label className="toggle-label">
          <input
            className="toggle-input"
            type="checkbox"
            defaultChecked={d?.status === "In-Active" ? false : true}
            onChange={(e) => handleToggle(d?.seasonId, e.target.checked)}
          />
          <span className="toggle-span"></span>
        </label>
      </>
    ),
    map: (
      <>
        <NavLink
          to={{
            pathname: `/GetMonth/` + d?.seasonId,
          }}
          className="btn btn-green btn-sm"
        >
          Map
        </NavLink>
      </>
    ),
  }));

  const seasonTypeList = async () => {
    try {
      const res = await postRequest(`api/Season/GetAll`);
      if (res) {
        setSeasonType(res);
        setLoading(false);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleToggle = async (Id, check) => {
    try {
      const status = check ? 1 : 0;
      const res = await postRequest(`api/Season/ActiveDeactive`, {
        seasonId: Id,
        status: status,
      });
      if (res) {
        seasonTypeList();
        toast.success(res.responseText);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  /////****GET SEASON TYPE */////

  /////****EDIT SEASON TYPE */////

  const seasonTypeById = async (id) => {
    try {
      const res = await postRequest(`api/Season/GetById`, {
        seasonId: id,
      });
      if (res) {
        setInput(res);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      const res = await postRequest(`api/Season/AddUpdate`, { ...input });
      if (res) {
        toast.success(res.responseText);
        navigate("/GetSeasonType");
      } else {
        toast.error("Something Went Wrong");
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.error("Error occurred:", error);
    }
  };

  /////****EDIT SEASON TYPE */////

  /////****ADD MONTHS TO SEASON */////

  const getMonthList = async () => {
    try {
      const res = await postRequest(`api/Season/GetMonths`);

      if (res) {
        setMonthList(res);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const goback = () => {
    navigate("/GetSeasonType");
  };

  const handleCheckboxMap = async (e, monthId) => {
    const isChecked = e.target.checked;
    const isMapped = isChecked ? 1 : 0;
    const smId = checkMonth[0]?.months?.find(
      (month) => month.monthId == monthId
    )?.smId;
    try {
      const res = await postRequest(`api/Season/SeasonMonthMapping`, {
        smId: smId,
        seasonId: param.seasonId,
        monthId: monthId,
        isMapped,
      });
      if (res) {
        toast.success(res.responseText);
        getSeasonMonthMapping();
      } else {
        toast.error(" request failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("API request failed");
    }
  };

  /////****ADD MONTHS TO SEASON */////

  /////****LIST OF MONTHS THAT ADDED TO SEASON */////

  const getSeasonMonthMapping = async () => {
    try {
      const res = await postRequest(`api/Season/GetSeasonMonthMapping`, {
        seasonId: param.seasonId,
      });
      if (res) {
        setCheckMonth(res);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  /////****LIST OF MONTHS THAT ADDED TO SEASON */////

  /////******* DELETE THE SEASON *///////

  const handlepopup = async (sId) => {
    setShowModal(true);
    setGetId(sId);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/Season/Delete?id=${getId}`);
      if (res) {
        seasonTypeList();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /////******* DELETE THE SEASON *///////

  /////******* VALIDATE THE INPUT *///////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.seasonName) {
      newErrors.seasonName = "Please enter a season name.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  /////******* VALIDATE THE INPUT *///////
  return {
    data,
    columns,
    seasonTypeList,
    loading,
    submitloader,
    handleChange,
    handleSubmit,
    input,
    seasonTypeById,
    getMonthList,
    monthList,
    goback,
    handleCheckboxMap,
    getSeasonMonthMapping,
    checkMonth,
    showModal,
    handleClose,
    handleDelete,
    errors,
  };
};

export default SeasonTypeService;
