import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";
import FooterLast from "../../Component/Homes/FooterLast";

const Team = () => {
  return (
    <>
    
      
      <HeaderTop />
      <section class="breadcrumb-area breadcrumb-bg"><div class="container"><div class="row"><div class="col-12"><div class="breadcrumb-content"><h2 class="title">Our Team</h2><nav aria-label="breadcrumb"><ol class="breadcrumb"><li class="breadcrumb-item"><a href="/">Home</a></li><li class="breadcrumb-item active" aria-current="page">Our Team</li></ol></nav></div></div></div></div></section>
      <section className="bd-week-area pt-40 pb-40">
    <div className="container">
    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8 col-md-10">
                            <div class="section-title section-title-two text-center mb-50">
                                {/* <span class="sub-title">Our Articles</span> */}
                                <h2 class="title">Our Team</h2>
                                
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Pravin.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Pravin Singh</a></h6>
                                    
                                    <div class="price">(Founder & CEO)</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Ashutosh.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Ashutosh Srivastava</a></h6>
                                    
                                    <div class="price">(Co-Founder, Procurement & Supply Chain)</div>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Anjar.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Md. Anzar Siddiqui</a></h6>
                                    
                                    <div class="price">(Co-Founder & CTO)</div>
                                </div>
                            </div>
                        </div>

                        {/* <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Juhi.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Juhi Jindal</a></h6>
                                    
                                    <div class="price">(Co-founder & PR and Marketing)</div>
                                </div>
                            </div>
                        </div> */}

                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Parikshit.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Parikshit Sampat Sai</a></h6>
                                    
                                    <div class="price">(Co Founder &  CMO)</div>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Saheem.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Saheem Khan</a></h6>
                                    
                                    <div class="price">Co-Founder (Sales & Marketing)</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    
        
    </div>
</section>

{/* <section className="best-deal-area pt-60 pb-60">
<div className="container">
        <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
                <div className="best-deal-top-wrap">
                    <div className="bd-section-title">
                        <h3 className="title">Our <span>Team</span></h3>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="row">

            <div className="col pl-0 pr-0">
                <div className="profile-card">
                    <div className="img">
                        <img src="siteadmin/img/team/Pravin.webp" />
                    </div>
                    <div className="caption">
                        <h3>Pravin Singh</h3>
                        <p>(Founder & CEO)</p>
                        
                    </div>
                </div>
            </div>

            <div className="col pl-0 pr-0">
                <div className="profile-card">
                    <div className="img">
                        <img src="siteadmin/img/team/Ashutosh.webp" />
                    </div>
                    <div className="caption">
                        <h3>Ashutosh Srivastava</h3>
                        <p>(Co-Founder, Procurement & Supply Chain)</p>
                        
                    </div>
                </div>
            </div>

            <div className="col pl-0 pr-0">
                <div className="profile-card">
                    <div className="img">
                        <img src="siteadmin/img/team/Anjar.webp" />
                    </div>
                    <div className="caption">
                        <h3>Md. Anzar Siddiqui</h3>
                        <p>(Co-Founder & CTO)</p>
                        
                    </div>
                </div>
            </div>

            <div className="col pl-0 pr-0">
                <div className="profile-card">
                    <div className="img">
                        <img src="siteadmin/img/team/Parikshit.webp" />
                    </div>
                    <div className="caption">
                        <h3>Parikshit Sampat Sai</h3>
                        <p>(Co-founder - Strategy & Innovation)</p>
                        
                    </div>
                </div>
            </div>

            

            <div className="col pl-0 pr-0">
                <div className="profile-card">
                    <div className="img">
                        <img src="siteadmin/img/team/Saheem.webp" />
                    </div>
                    <div className="caption">
                        <h3>Saheem Khan</h3>
                        <p>Co-Founder (Sales & Marketing)</p>
                        
                        
                    </div>
                </div>
            </div>

            <div className="col pl-0 pr-0">
                <div className="profile-card">
                    <div className="img">
                        <img src="siteadmin/img/team/Anil.webp" />
                    </div>
                    <div className="caption">
                        <h3>Anil Sharma</h3>
                        <p>
                            (Chief Advisor & Mentor)
                            Ex GM, Central Bank of India
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> */}


      <FooterLast />
    </>
  );
};
export default Team;
