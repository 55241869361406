import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";

import FooterLast from "../../Component/Homes/FooterLast";

const Solution = () => {
  return (
    <>
      
      <HeaderTop />
      {/* <HeaderBottom /> */}
     
      <main>


<section className="breadcrumb-area breadcrumb-bg">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumb-content">
                    <h2 className="title">Solutions</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Solutions</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>


<section className="header services-area  pt-50 pb-50">
    <div className="container">
        <div className="container-inner-wrap">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-10">
                    
                    <div className="section-title section-title-two text-center mb-30">
                        <h2 className="title">Solutions</h2>
                        <p>Hello Farmers is a cutting-edge digital platform that offers unique and sustainable community farming solutions. With a variety of solutions such as family subscriptions, community farming, agri-tourism, skill training and employment opportunities, and customized farming solutions, we are committed to transforming traditional farming practices into a modern and sustainable model that benefits both farmers and consumers.</p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                
                    <div className="col-md-3">
                        
                        <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link mb-3 p-4 shadow active" id="Family-Subscriptions-tab" data-toggle="pill" href="#Family-Subscriptions" role="tab" aria-controls="Family-Subscriptions" aria-selected="true">
                                <i className="fa fa-check mr-2"></i>
                                <span className="font-weight-bold text-uppercase">Family Subscriptions</span>
                            </a>

                            <a className="nav-link mb-3 p-4 shadow" id="Community-Farming-tab" data-toggle="pill" href="#Community-Farming" role="tab" aria-controls="Community-Farming" aria-selected="false">
                                <i className="fa fa-check mr-2"></i>
                                <span className="font-weight-bold text-uppercase">Community Farming</span>
                            </a>

                            <a className="nav-link mb-3 p-4 shadow" id="Agri-Tourism-tab" data-toggle="pill" href="#Agri-Tourism" role="tab" aria-controls="Agri-Tourism" aria-selected="false">
                                <i className="fa fa-check mr-2"></i>
                                <span className="font-weight-bold text-uppercase">Agri Tourism</span>
                            </a>

                            <a className="nav-link mb-3 p-4 shadow" id="Skill-Training-tab" data-toggle="pill" href="#Skill-Training" role="tab" aria-controls="Skill-Training" aria-selected="false">
                                <i className="fa fa-check mr-2"></i>
                                <span className="font-weight-bold text-uppercase">Skill Training</span>
                            </a>

                            <a className="nav-link mb-3 p-4 shadow" id="Export-Hub-tab" data-toggle="pill" href="#Export-Hub" role="tab" aria-controls="Export-Hub" aria-selected="false">
                                <i className="fa fa-check mr-2"></i>
                                <span className="font-weight-bold text-uppercase">Export Hub</span>
                            </a>
                        </div>
                    </div>


                    <div className="col-md-9">
                        
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade shadow rounded bg-white show active p-4" id="Family-Subscriptions" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <h4 className="mb-4">Family Subscriptions</h4>
                                <p>Family subscription allows consumers to pay once a month and get fresh vegetables delivered to their doorstep for the whole month at a fixed price.</p>
                                <p>Subscribers can choose from a variety of farm products without worrying about price fluctuations. In addition to vegetables, subscribers can also add other farm products to their basket at Farmer's prices, including rice, flour, spices, pulses, oils, herbs, dry fruits, fruits, and more.</p>
                                <p>Subscribers can visit and experience Community Farming and explore all India-verified AgriTourism destinations.</p>

                                <h4 className="mb-4">How Family Subscription Works:</h4>
                                <p>To get started with our platform, download the app and register as per your profile. Check the service availability in your area by entering your pin code. Then, check the subscription menu and choose the subscription that best fits your family's needs. You can create your first free basket from over 50 fresh vegetables and select your delivery schedule.</p>
                                <p>Users can also add any health concerns or allergy situations for self or any family members so that our AI can find the best suitable alternatives and notify you if any related product is ordered by mistake.</p>

                                <a className="btn btn-theme mt-15 btn-md radius animation mt-4" href="#">SUBSCRIBE NOW</a>
                            </div>

                            <div className="tab-pane fade shadow rounded bg-white p-4" id="Community-Farming" role="tabpanel" aria-labelledby="Community-Farming-tab">
                                <h4 className="mb-4">Community Farming</h4>
                                <p className="text-muted mb-2">We aim to develop community farms in pan-India to grow the best quality products for consumers. Farmers serve niche products directly to consumers with complete transparency. The regular supply of food grown on the farm provides nutritional security and a sense of community for customers.</p>

                                <p><b>One of the key principles of community farming is sustainability.</b> Community farming practices are often designed to promote sustainable agriculture, which means that the farm is managed in a way that is environmentally friendly, socially just, and economically viable. This includes practices such as crop rotation, composting, and conservation of natural resources. Additionally, community farming promotes the use of natural and non-toxic methods of farming, which is beneficial to both the environment and human health.</p>

                                <p>Community farming also provides opportunities for social and cultural exchange. When individuals come together to farm, they have the opportunity to learn from each other and build relationships. This is helpful to strengthen the community and promote a sense of belonging. For example, community members come together to share meals, participate in festivals and celebrations, and even create art and crafts.</p>

                                <a className="btn btn-theme mt-15 btn-md radius animation mt-4" href="#">START NOW</a>
                            </div>

                            <div className="tab-pane fade shadow rounded bg-white p-4" id="Agri-Tourism" role="tabpanel" aria-labelledby="Agri-Tourism-tab">
                                <h4 className="mb-4">Agri Tourism</h4>
                                <p className="text-muted mb-2">We offer verified agri-tourism destinations for our subscribers. Agri-tourism appeals to customers who have the desire to visit a farm and experience its activities. Farm stays are done with comforts like beds and breakfasts to relax in a bucolic atmosphere. Agriculture tourism is one of the fastest-growing industries in the world, and community farming will create new opportunities for farming communities as well as urban residents.</p>
                                <a className="btn btn-theme mt-15 btn-md radius animation mt-4" href="#">START NOW</a>
                            </div>

                            <div className="tab-pane fade shadow rounded bg-white p-4" id="Skill-Training" role="tabpanel" aria-labelledby="Skill-Training-tab">
                                <h4 className="mb-4">Skill Training</h4>
                                <p className="text-muted mb-2">The agriculture sector has always played a crucial role in the growth and development of rural communities, providing employment and livelihood opportunities to millions of people worldwide. However, traditional farming practices face numerous challenges, such as low productivity, limited access to technology, and inadequate training, which hinder the growth and profitability of farmers. In recent years, various initiatives have been undertaken to address these challenges and transform traditional farming into a more inclusive and sustainable sector.</p>

                                <p className="text-muted mb-2">
                                    One such initiative is the Hello Farmers platform, which aims to empower farmers and rural communities through the Hi-Tech Skill Identification and Training, and Employment Generation Program. This platform provides farmers with access to the latest technology, training, and employment opportunities, enabling them to enhance their productivity, income, and livelihoods.
                                </p>

                                <p className="text-muted mb-2">By leveraging the power of technology and innovation, the Hello Farmers platform is transforming traditional farming into an inclusive Agri growth powerhouse, driving economic growth and development in rural communities.</p>
                            </div>

                            <div className="tab-pane fade shadow rounded bg-white p-4" id="Export-Hub" role="tabpanel" aria-labelledby="Export-Hub-tab">
                                <h4 className="mb-4">Export Hub</h4>
                                <p className="text-muted mb-2">In today's global economy, international trade plays a vital role in the growth and development of various industries. The agriculture sector is no exception, as there is a growing demand for high-quality produce from different parts of the world. However, finding the right market for export-quality products can be a daunting task for farmers. On the other hand, buyers may struggle to find the right suppliers for their requirements. To bridge this gap, Export Hub is a platform that connects verified farmers producing export-quality produce with buyers looking for such products. This platform provides a direct and efficient way for both parties to connect and conduct business.</p>
                            </div>
                        </div>
                    </div>
                
            </div>
        </div>
        
    </div>
</section>

</main>
      <FooterLast />
    </>
  );
};

export default Solution;
