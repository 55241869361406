import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import PowerSourceService from "./PowerSourceService";

const AddPowerSource = () => {
  const { handleSubmit, handleChange, submitloader, input, setInput } =
    PowerSourceService();

  const location = useLocation();

  useEffect(() => {
    const { editData } = location.state || {};
    if (editData) {
      setInput(editData);
    }
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Power Source </li>

                        <li className="breadcrumb-item active">Form</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container w-100">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <form className="form-container w-100">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Name :
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Enter the power source name"
                          onChange={handleChange}
                          value={input?.name}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              {!submitloader ? (
                <>
                  <button
                    type="submit"
                    className="btn btn-green"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button className="btn btn-green">Submitting...</button>
                </>
              )}
              <NavLink to="/PowerSource" className="btn btn-danger">
                Close
              </NavLink>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default AddPowerSource;
