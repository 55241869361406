import { React } from "react";
import { NavLink } from "react-router-dom";

import UserService from "./UserService";

const Useraddon = () => {
  const { handleDelivery, handleSubmit, area, errors } = UserService();

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul
                        className="breadcrumb mb-0" >
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">User</li>
                        <li className="breadcrumb-item">Delivery Person</li>
                        <li className="breadcrumb-item active">Form</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container w-100">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <form className="form-container w-100">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="signup_label" htmlFor="name">
                          Name:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={handleDelivery}
                        />
                        {errors.name && (
                          <div className="help-block text-danger">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="signup_label" htmlFor="phoneNumber">
                          Phone Number:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phoneNumber"
                          name="phoneNumber"
                          onChange={handleDelivery}
                        />
                        {errors.phoneNumber && (
                          <div className="help-block text-danger">
                            {errors.phoneNumber}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="signup_label" htmlFor="email">
                          Email:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          onChange={handleDelivery}
                        />
                        {errors.email && (
                          <div className="help-block text-danger">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="signup_label" htmlFor="postalCode">
                          PinCode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="postalCode"
                          name="postalCode"
                          onChange={(e) => handleDelivery(e, true)}
                        />
                        {errors.postalCode && (
                          <div className="help-block text-danger">
                            {errors.postalCode}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="signup_label" htmlFor="areaId">
                          Area:
                        </label>
                        <select
                          className="form-control"
                          name="areaId"
                          onChange={handleDelivery}
                        >
                          {area?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.area}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.areaId && (
                        <div className="help-block text-danger">
                          {errors.areaId}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-4">
                        <label className="signup_label" htmlFor="gender">
                          Gender:
                        </label>
                        <select
                          className="form-control"
                          id="gender"
                          name="gender"
                          onChange={handleDelivery}
                        >
                          <option value="N/A">Select</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Others</option>
                        </select>
                      </div>
                      {errors.gender && (
                        <div className="help-block text-danger">
                          {errors.gender}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-green"
                onClick={handleSubmit}
              >
                Submit
              </button>

              <NavLink to="/User" className="btn btn-danger">
                Cancel
              </NavLink>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};
export default Useraddon;
