export const DATACONSTANT = {
  COOKIE_NAME: ".front.authToken",
  SETCOOKIE: ".plan_info",

  DOMAIN_NAME: "mitraservice.in",

  LOGIN_URL: "/ApiUserAfterLogin/APIUserLogin",

  //BASE_URL: "http://mitraservice.in",
  // BASE_URL: "http://uat.allupi.com/",
  BASE_URL: " https://api.hellofarmers.co.in/",

  BUY_URL: "/ApiUserAfterLogin/VASBuyOrRenewPlanForApp",

  GET_PLAN_URL: "/ApiUserAfterLogin/GetVASPlanMasterForApp",

  Version: "1.0",

  APPID: "ROUNDPAYAPPID13APR20191351",

  BALANCE_URL: "/ApiUserAfterLogin/GetUserBalanceForApp",

  ADD_MONEY_URL: "/ApiUserAfterLogin/AddMoneyForAPI",

  LOGOUT_URL: "/ApiUserAfterLogin/LogoutForAPI",

  CREATEACCOUNT: "/ApiUserAfterLogin/SignupForAPI",

  BANNER_TYPE: {
    1: "Dashboard",
    2: "Category",
    3: "FarmCommunity",
    4: "AgriTourism",
  },
  ORDER_TYPE: {
    0: "no",
    2: "PENDING",
    6: "RESCHEDULE",
    7: "PLACED",
    8: "CANCELED",
    9: "DELIVERED",
  },
  ORDER_STATUS: {
    S: "SUCCESS",
    s: "SUCCESS",
    F: "FAILED",
    f: "FAILED",
    P: "PENDING",
    p: "PENDING",
  },
  LEDGER_TRTYPE: {
    C: "CREDIT",
    D: "DEBIT",
    c: "CREDIT",
    d: "DEBIT",
  },
  GENDER_TYPE: {
    M: "Male",
    F: "Female",
    O: "Other",
  },
  PAYMENTMODE_TYPE: {
    0: "Prepaid",
    1: "COD",
    
  },
};
