import React from "react";
import { NavLink } from "react-router-dom";
import SettingServices from "./SettingServices";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import numberToWords from "number-to-words";

const PinCodeMapping = () => {
  const {
    dpcolumns,
    dpdata,
    loading,
    getDeliveryList,
    handlingCloseModal,
    handleTrTypeChange,
    handleFundTransfer,
    handleConfirmFundTransfer,
    showModalFundTranfer,
    inputFundtrans,
  } = SettingServices();

  const [formattedAmount, setFormattedAmount] = useState("");

  useEffect(() => {
    if (inputFundtrans.amount) {
      const numericAmount = inputFundtrans?.amount;
      const amountWords = numberToWords.toWords(numericAmount);
      setFormattedAmount(amountWords);
    } else {
      setFormattedAmount("");
    }
  }, [inputFundtrans.amount]);

  useEffect(() => {
    getDeliveryList();
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Setting</li>

                        <li className="breadcrumb-item active">
                          Delivery Person - PinCode Mapping
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-6 col-sm-12 justify-content-end d-flex align-items-center">
                      <NavLink
                        to="/EditDeliveryPerson"
                        className="btn btn-sm mr-3"
                      >
                        <i className="fa fa-plus-square"></i>&nbsp;DELIVERY
                        PERSON
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-2">
            <div className="table-responsive ">
              {loading ? (
                <div className="spinner-container">
                  <Spinner type="TailSpin" color="#00BFFF" />
                </div>
              ) : (
                <DataTable
                  columns={dpcolumns}
                  data={dpdata}
                  pagination
                  className="rdt_TableHeadRow"
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 210px)"
                />
              )}
            </div>
          </div>
        </div>
        <Modal
          show={showModalFundTranfer}
          onHide={handlingCloseModal}
          className="cus-top-modal"
        >
          <Modal.Header>
            <Modal.Title className="mt-3 change-status">
              Fund Transfer
            </Modal.Title>
            <span
              aria-hidden="true"
              onClick={handlingCloseModal}
              className="modal-cross"
              style={{ fontSize: "24px", color: "red", cursor: "pointer" }}
            >
              &times;
            </span>
          </Modal.Header>
          <form onSubmit={handleConfirmFundTransfer}>
            <Modal.Body className="pt-0 modal-body-textarea pb-2">
              <div className="row">
                <div className="col-md-12 d-flex">
                  <div className="btn-group btn-toggle my-2" id="btnGroup">
                    <button
                      className={`btn btn-sm ${
                        inputFundtrans.trType === "C"
                          ? "btn-outline-success active"
                          : "btn-outline-success"
                      }`}
                      onClick={() => handleTrTypeChange("C")}
                    >
                      Credit
                    </button>
                    <button
                      className={`btn btn-sm  ${
                        inputFundtrans.trType === "D"
                          ? "btn-outline-danger active"
                          : "btn-outline-danger"
                      }`}
                      onClick={() => handleTrTypeChange("D")}
                    >
                      Debit
                    </button>
                  </div>
                </div>
                <div className="col-md-3 py-2">
                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      marginTop: "5px",
                    }}
                  >
                    Amount :
                  </h6>
                </div>
                <div className="col-md-9 py-2">
                  <input
                    type="number"
                    className="form-control cus-input"
                    placeholder="Enter Amount"
                    name="amount"
                    value={inputFundtrans?.amount || ""}
                    onChange={handleFundTransfer}
                    required={true}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      marginTop: "1rem",
                    }}
                  >
                    Remark :
                  </h6>
                </div>
                <div className="col-md-9">
                  <textarea
                    className="cus-textarea mt-1 w-100"
                    rows="3"
                    cols="55"
                    required={true}
                    placeholder="Enter your remark"
                    name="remark"
                    value={inputFundtrans?.remark || ""}
                    onChange={handleFundTransfer}
                  />
                </div>
              </div>
              <div className="row px-3">
                <h6
                  style={{
                    textAlign: "left",
                    fontWeight: "normal",
                    marginTop: "1rem",
                  }}
                >
                  <span style={{ color: "red", fontSize: "15px" }}>
                    {formattedAmount}
                  </span>
                </h6>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handlingCloseModal}>
                Cancel
              </Button>
              <Button variant="success" type="submit">
                Transfer
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </main>
    </div>
  );
};

export default PinCodeMapping;
