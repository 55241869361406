import React from "react";
import { NavLink } from "react-router-dom";
import ProfileService from "./ProfileService";
import { useEffect } from "react";
import { DATACONSTANT } from "../../Services/DataConstant";

const Profile = () => {
  const { profile, getAdminProfile } = ProfileService();
  const genderType = DATACONSTANT.GENDER_TYPE;

  useEffect(() => {
    getAdminProfile();
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0 ">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Profile</li>

                      <li className="breadcrumb-item active">Admin</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ margin: "2px" }}>
        <div className="card p-2 col-md-12">
          <div className="card-header">
            <h5 style={{ color: "green" }}>Detail :</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-9">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item cus-profileUser">
                    <div className="row">
                      <div className="col-md-2">
                        <b style={{ color: "gray" }}>ID:</b>
                      </div>
                      <div className="col-md-10">{profile?.id}</div>
                    </div>
                  </li>
                  <li className="list-group-item cus-profileUser">
                    <div className="row">
                      <div className="col-md-2">
                        <b style={{ color: "gray" }}>Name:</b>
                      </div>
                      <div className="col-md-10">{profile?.name}</div>
                    </div>
                  </li>
                  <li className="list-group-item cus-profileUser">
                    <div className="row">
                      <div className="col-md-2">
                        <b style={{ color: "gray" }}>Phone No:</b>
                      </div>
                      <div className="col-md-10">{profile?.phoneNumber}</div>
                    </div>
                  </li>
                  <li className="list-group-item cus-profileUser">
                    <div className="row">
                      <div className="col-md-2">
                        <b style={{ color: "gray" }}>Email:</b>
                      </div>
                      <div className="col-md-10">{profile?.email}</div>
                    </div>
                  </li>
                  <li className="list-group-item cus-profileUser">
                    <div className="row">
                      <div className="col-md-2">
                        <b style={{ color: "gray" }}>Gender:</b>
                      </div>
                      <div className="col-md-10">{genderType[profile?.gender]}</div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-md-3">
                <div
                  className="text-right"
                  style={{
                    display: "grid",
                    justifyItems: "center",
                    float: "right",
                  }}
                >
                  <img
                    src="/assets/img/profile2.png"
                    alt="Image Not Avail"
                    style={{
                      maxWidth: "100px",
                    }}
                  />

                  <NavLink to="/Password">
                    <button className="btn btn-success mt-2 btn-sm">
                      Change Password
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
