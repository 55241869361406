import React from "react";
import AgriService from "./AgriService";
import JoditEditor from "jodit-react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";

const EditAgriDetail = () => {
  const {
    handleChange,
    submitloader,
    editor,
    area,
    input,
    handleEditor,
    handleImage,
    handlethumbNail,
    handleSubmit,
    agritourismList,
    handleMapShow,
    handleMapClick,
    isMapShow,
  } = AgriService();
  const params = useParams();
  useEffect(() => {
    if (params.id !== undefined) {
      agritourismList(params.id);
    }
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Agriculture Tourism</li>

                        <li className="breadcrumb-item active">Form</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container w-100">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <form className="form-container w-100">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Title :
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="Enter the title"
                          name="title"
                          onChange={handleChange}
                          value={input?.title}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Address :
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          placeholder="Enter te address"
                          onChange={handleChange}
                          value={input?.address}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Landmark :
                        <input
                          type="text"
                          className="form-control"
                          id="landmark"
                          placeholder="Enter the landmark"
                          name="landmark"
                          onChange={handleChange}
                          value={input?.landmark}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Pincode :
                        <input
                          type="text"
                          className="form-control"
                          id="pincode"
                          name="pincode"
                          placeholder="Enter the pincode"
                          onChange={(e) => handleChange(e, true)}
                          value={input?.pincode}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Area :
                        <select
                          className="form-control"
                          name="areaId"
                          onChange={handleChange}
                          value={input?.areaId}
                        >
                          {area?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.area}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Contact No :
                        <input
                          type="number"
                          className="form-control"
                          id="contactNo"
                          name="contactNo"
                          placeholder="Enter the contact number"
                          onChange={handleChange}
                          value={input?.contactNo}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        No Of Cottage :
                        <input
                          type="number"
                          className="form-control"
                          id="noOfCottage"
                          placeholder="Enter the cottage number"
                          name="noOfCottage"
                          onChange={handleChange}
                          value={input?.noOfCottage}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group mb-2">
                        ThumbNail :
                        <input
                          type="file"
                          className="form-control"
                          id="thumbnail"
                          name="thumbnail"
                          onChange={handlethumbNail}
                        />
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <img
                        className="d-block"
                        style={{ minHeight: "80px", maxWidth: "80px" }}
                        src={input?.thumbnail || "/assets/img/noimage.jpg"}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "/assets/img/noimage.jpg")
                          )
                        }
                        class="card-img-top"
                      />
                    </div>
                    <div className="col-lg-4 ">
                      <div className="form-group mb-2">
                        Images:
                        <input
                          type="file"
                          className="form-control"
                          id="images"
                          name="images"
                          onChange={handleImage}
                        />
                      </div>
                    </div>
                    <div className=" col-md-2">
                      <img
                        className="d-block"
                        style={{ minHeight: "80px", maxWidth: "80px" }}
                        src={input?.images || "/assets/img/noimage.jpg"}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "/assets/img/noimage.jpg")
                          )
                        }
                        class="card-img-top"
                      />
                    </div>

                    <div className=" col-md-12">
                      <div className="form-group">
                        <label
                          className="signup_label"
                          htmlFor="irrigationSource"
                        >
                          <span> Location: </span>
                        </label>
                        {!isMapShow ? (
                          <button
                            type="button"
                            onClick={handleMapShow}
                            className="btn btn-green mx-3"
                          >
                            Map
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={handleMapShow}
                              className="btn btn-green mx-3"
                            >
                              Hide
                            </button>
                            <div
                              className="mt-2"
                              style={{ height: "200px", width: "100%" }}
                            >
                              <GoogleMapReact
                                bootstrapURLKeys={{ key: "" }}
                                defaultCenter={{
                                  lat: +input?.latitude || 26.85,
                                  lng: +input?.longitude || 80.949997,
                                }}
                                defaultZoom={11}
                                onClick={handleMapClick}
                              ></GoogleMapReact>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {input?.latitude && input?.latitude != 0 ? (
                      <>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label className="signup_label" htmlFor="latitude">
                              Latitude:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="latitude"
                              name="latitude"
                              disabled
                              onChange={handleChange}
                              value={input?.latitude}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label className="signup_label" htmlFor="longitude">
                              Longitude:
                            </label>
                            <input
                              type="text"
                              disabled
                              className="form-control"
                              id="longitude"
                              name="longitude"
                              onChange={handleChange}
                              value={input?.longitude}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        Description:
                        <JoditEditor
                          ref={editor}
                          tabIndex={1}
                          id="description"
                          name="description"
                          value={input?.description}
                          onChange={(e) => handleEditor(e)}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              {!submitloader ? (
                <>
                  <button
                    type="submit"
                    className="btn btn-green"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button className="btn btn-green">Submitting...</button>
                </>
              )}
              <NavLink to="/AgriTourism" className="btn btn-danger">
                Close
              </NavLink>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default EditAgriDetail;
