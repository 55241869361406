import React from "react";
import { delete_cookie } from "../Services/Cookies";
import { DATACONSTANT } from "../Services/DataConstant";

const LogOut = () => {
  localStorage.clear();
  delete_cookie(DATACONSTANT.COOKIE_NAME);
  window.location.href = "/home";

  return <></>;
};
export default LogOut;
