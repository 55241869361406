import React from "react";
import { NavLink, useParams } from "react-router-dom";
import GetOrderService from "./GetOrderService";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

const InvoiceOrder = () => {
  const { handleSearch, invoice, goback, loading } = GetOrderService();
  const [totalQuantity, setTotalQuantity] = useState(0);
  const param = useParams();

  useEffect(() => {
    if (invoice && invoice.orderDetail) {
      const quantitySum = invoice.orderDetail.reduce(
        (total, d) => total + d.quantity,
        0
      );
      setTotalQuantity(quantitySum);
    }
  }, [invoice]);

  useEffect(() => {
    if (param.id !== undefined) {
      handleSearch();
    }
  }, [param.id]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Order</li>

                      <li className="breadcrumb-item active"> Invoice</li>
                    </ul>
                  </div>

                  <div className="col-md-6 col-sm-12 justify-content-end d-flex align-items-center">
                    <p className="dropdown d-inline-block mb-0 mr-2">
                      <button
                        type="button "
                        className="btn btn-danger"
                        onClick={goback}
                      >
                        BACK
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="receipt-main col-xs-10 col-sm-10 col-md-12 col-xs-offset-1 col-sm-offset-1 col-md-offset-3">
            {loading ? (
              <div className="spinner-container">
                <Spinner type="TailSpin" color="#00BFFF" />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="cus-hello-farmer">
                      <h3>Invoice</h3>
                    </div>
                  </div>
                  <div
                    className="col-xs-6 col-sm-6 col-md-6"
                    style={{ width: "70%" }}
                  >
                    <div className="receipt-header" style={{ display: "flex" }}>
                      <div className="receipt-left">
                        <img
                          className="img-responsive"
                          alt="hellofarmers"
                          src={
                            "../assets/img/favicon.png" ||
                            "/assets/img/noimage.jpg"
                          }
                          style={{
                            width: "71px",
                            borderradius: "43px",
                            position: "relative",
                            top: "13px",
                          }}
                        />
                      </div>
                      <div className="receipt-right cus-receipt">
                        <p
                          style={{
                            fontSize: "18px",
                            position: "relative",
                            left: "41px",
                            color: "green",
                            fontSize: "23px",
                          }}
                        >
                          Hello Farmers
                        </p>
                        <p>
                          <i className="fa fa-phone cus-i"></i>+91 9889 009622
                        </p>

                        <p>
                          <i className="fa fa-envelope cus-i"></i>
                          support@hellofarmers.co.in
                        </p>

                        <p
                          style={{
                            position: "relative",
                            right: "82px",
                            top: "1px",
                          }}
                        >
                          <i className="fa fa-location-arrow cus-i"></i>3/553,
                          Vivek Khand, Gomti Nagar(Lucknow)-226010
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xs-8 col-sm-8 col-md-6 text-left"
                    style={{ width: "30%" }}
                  >
                    <div className="receipt-header receipt-header-mid">
                      <div className="receipt-right">
                        <p>
                          <b>OrderId:</b> {invoice?.orderSummary?.id}
                        </p>
                        <p>
                          <b>Name:</b> {invoice?.orderSummary?.customerName}
                        </p>
                        <p>
                          <b>Address:</b> {invoice?.orderSummary?.address}
                        </p>
                        <p>
                          <b>Mobile No:</b>{" "}
                          {invoice?.orderSummary?.customerMobile}
                        </p>
                        <p>
                          <b>Date:</b> {invoice?.orderSummary?.scheduledOn}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ backgroundColor: "green" }}>
                        <th>#</th>
                        <th>Product</th>
                        <th>Packging</th>
                        <th style={{ textAlign: "right" }}>Qty</th>
                        <th style={{ textAlign: "right" }}>Mrp.</th>
                        <th style={{ textAlign: "right" }}>Dis[%]</th>
                        <th style={{ textAlign: "right" }}> UnitCost</th>
                        <th style={{ textAlign: "right" }}> Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice?.orderDetail?.map((d, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{d?.productName}</td>
                          <td>{d?.packaging}</td>
                          <td style={{ textAlign: "right" }}>{d?.quantity}</td>
                          <td style={{ textAlign: "right" }}>{d?.mrp}</td>
                          <td style={{ textAlign: "right" }}>{d?.discount}%</td>
                          <td style={{ textAlign: "right" }}>
                            {d?.sellingPrice}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {d?.quantity * d?.sellingPrice}
                          </td>
                        </tr>
                      ))}
                      <tr className="cus-table-color-2">
                        <th></th>
                        <th>Total</th>
                        <th></th>
                        <th style={{ textAlign: "right" }}>{totalQuantity}</th>
                        <th></th>

                        <th colSpan={2} className="text-end">
                          <div>
                            <div>&nbsp;</div>
                            <div>Subscription Discount</div>
                            <div>Payable Amount</div>
                          </div>
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <div>
                            <div style={{ whiteSpace: "nowrap" }}>
                              Rs. {invoice?.orderSummary?.totalSellingPrice}
                            </div>
                            <div style={{ whiteSpace: "nowrap" }}>
                              {invoice?.orderSummary?.subscriptionDiscount}%
                            </div>
                            <div style={{ whiteSpace: "nowrap" }}>
                              Rs. {invoice?.orderSummary?.paybleAmount}
                            </div>
                          </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-xs-8 col-sm-8 col-md-8 text-left">
                    <div className="receipt-header receipt-header-mid receipt-footer">
                      <div className="receipt-right">
                        <h5 style={{ color: "rgb(140, 140, 140)" }}>
                          Thanks for shopping.!
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 text-right">
                    <div className="toolbar hidden-print">
                      <div className="text-end">
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={handlePrint}
                        >
                          <i className="fa fa-print"></i> Print
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default InvoiceOrder;
