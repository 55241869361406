import React, { useState, useRef } from "react";
import { postRequest, getRequest } from "../../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Moment from "react-moment";

const FarmService = () => {
  const [farmcommunity, setFarmCommunity] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState("");
  const [submitloader, setSubmitLoader] = useState(false);
  const editor = useRef(null);
  const [input, setInput] = useState({ imagePath: "" });
  const [irrigationOption, setIrrigationOptions] = useState([]);
  const [powerOption, setPowerOption] = useState([]);
  const [image, setImage] = useState();
  const [area, setarea] = useState();
  const [isMapShow, setIsMapShow] = useState(false);
  const [irrigationSource, setIrrigationSource] = useState([]);
  const [powerSource, setPowerSource] = useState([]);

  const toggleDropdown = async (i) => {
    setIsOpen(isOpen == i ? "" : i);
  };

  const handleMapShow = () => {
    setIsMapShow(!isMapShow);
  };

  const handleMapClick = (event) => {
    setInput((prev) => {
      return {
        ...prev,
        ["latitude"]: event.lat,
        ["longitude"]: event.lng,
      };
    });
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "90px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "100px",
    },
    { name: "EDIT", selector: (row) => row.edit, width: "100px" },
    {
      name: "TITLE",
      selector: (row) => row.title,
      width: "270px",
    },

    {
      name: "ADDRESS",
      selector: (row) => row.address,
      width: "270px",
    },

    {
      name: "ENTRYON",
      selector: (row) => row.entryOn,
      width: "200px",
    },
  ];

  const data = farmcommunity?.map((d, i) => ({
    serial: i + 1,
    action: (
      <>
        <div key={i} className="dropdown ">
          <button
            className="btn btn-green btn-sm dropdown-toggle"
            type="button"
            onClick={() => {
              toggleDropdown(i + 1);
            }}
          ></button>
          <ul
            className={` ${
              isOpen === i + 1 ? "d-block  cus-drop" : "d-none  cus-drop"
            }`}
          >
            <li>
              <NavLink to="/FarmDetail" state={{ farmdata: d }}>
                <div className="dropdown-item">View Detail</div>
              </NavLink>
            </li>
            {/* <li>
              <a className="dropdown-item">Approve</a>
            </li>
            <li>
              <a className="dropdown-item">Disapprove</a>
            </li> */}
          </ul>
        </div>
      </>
    ),
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditFarmDetail/" + d.id,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
      </>
    ),
    title: (
      <>
        {d.title}
        <br />
        [ID:{d.id}]
      </>
    ),
    address: (
      <>
        <span
          title={`${d.address}, ${d.landmark}, ${d.area}, ${d.city}, ${d.state}, ${d.pincode}, ${d.country}`}
        >
          {d.address},&nbsp;
          {d.landmark},&nbsp;
          {d.area},&nbsp;
          {d.city},&nbsp;
          {d.state},&nbsp;
          {d.pincode},&nbsp;{d.country}
        </span>
      </>
    ),
    entryOn: (
      <>
        <Moment format="DD MMMM YYYY ">{d.entryOn}</Moment>
      </>
    ),
  }));

  const farmCommunityList = async (id, isAddfarm) => {
    const res = await postRequest(`api/Bussiness/GetFarmCommunity`, {
      requestType: 1,
      id: id,
    });
    if (res.result) {
      setFarmCommunity(res.result);
      setPowerOption(res.result.powerSources);
      setIrrigationOptions(res.result.irrigationSources);
      if (id) {
        setInput(res.result[0]);
      }
      if (isAddfarm) {
        const areaData = await getRequest(
          `api/Home/GetAreaListAsync?postalCode=${res.result[0]?.pincode}`
        );
        let _a = [{ id: 0, area: "Select... " }, ...areaData];
        setarea(_a);

        const irrigationData = await getRequest(
          `api/Bussiness/GetIrrigationSource`
        );
        const irrigationSourceOptions = irrigationData.result?.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setIrrigationSource(irrigationSourceOptions);

        const powerData = await getRequest(`api/Bussiness/GetPowerSource`);
        const powerSourceOptions = powerData.result.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setPowerSource(powerSourceOptions);
      }
      setLoading(false);
    } else {
      toast.error("Credentials invalid");
    }
  };

  const back = () => {
    navigate("/FarmCommunity");
  };

  /////// ***** EDIT AND ADD OPTION IN FARM DETAILS *****//////

  const handleChange = async (e, pinCode) => {
    const { name, value } = e.target;
    if (pinCode) {
      if (value.length == 6) {
        const res = await getRequest(
          `api/Home/GetAreaListAsync?postalCode=${value}`
        );
        let _a = [{ id: 0, area: "SELECT " }, ...res];
        setarea(_a);
      }
    }
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleOption = (e) => {
    setIrrigationOptions(e);
  };
  const handleOptions = (e) => {
    setPowerOption(e);
  };

  const handleImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["imagePath"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
  };
  const handleEditor = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        ["description"]: e,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      let res = {};
      let formData = new FormData();
      let __d = {
        ...input,
      };
      for (var i = 0; i < powerOption.length; i++) {
        formData.append("powerSource", powerOption[i].value);
      }
      for (var j = 0; j < irrigationOption.length; j++) {
        formData.append("irrigationSource", irrigationOption[j].value);
      }
      formData.append("Images", image?.value);
      for (let key in __d) {
        if (key === "powerSource" && !__d[key]) continue;
        if (key === "irrigationSource" && !__d[key]) continue;
        if (key === "areaId") {
          formData.append(key, parseInt(__d[key]));
        } else {
          formData.append(key, __d[key]);
        }
      }
      res = await postRequest(
        `api/Bussiness/AddFarmCommunity`,
        formData,
        "multipart/form-data"
      );
      if (!res) {
        toast.error(res?.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/FarmCommunity", { replace: true });
        }
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || "Something went wrong";
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
    setSubmitLoader(false);
  };

  return {
    farmCommunityList,
    loading,
    columns,
    data,
    back,
    submitloader,
    handleChange,
    handleEditor,
    handleImage,
    editor,
    input,
    area,
    handleSubmit,
    isMapShow,
    handleMapShow,
    handleMapClick,
    irrigationSource,
    handleOption,
    handleOptions,
    powerSource,
    powerOption,
    irrigationOption,
  };
};

export default FarmService;
