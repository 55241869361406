import { useEffect } from "react";
import React from "react";
import DealsofDayServices from "./DealsofDayServices";
import { NavLink, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button, Table } from "react-bootstrap";
import Select from "react-select";

const EditDealsofDay = () => {
  const {
    handleSubmit,
    handleChange,
    input,
    submitloader,
    getbyIDDealofDay,
    handleEditor,
    editor,
    handleStartDate,
    startDate,
    handleEndDate,
    endDate,
    handleQuantityChange,
    showModal,
    handleCloseModal,
    handleOpenModal,
    product,
    getproductList,
    getCategoryList,
    list,
    handleCateogryList,
    handleProductSelect,
    handleConfirmSelection,
    selectedItems,
    handleRemove,
    baseUrl,
    handleImage,
    errors,
    couponData,
    handleOptions,
    selectedCoupons,
    getCouponList,
    setStartDate,
    setEndDate,
  } = DealsofDayServices();
  const params = useParams();

  useEffect(() => {
    if (params.dealsId) {
      getbyIDDealofDay(params.dealsId);
    } else {
      setStartDate(new Date());
      setEndDate(new Date(Date.now() + 86400000));
    }
    getproductList();
    getCategoryList();
  }, []);

  useEffect(() => {
    getCouponList();
  }, [input?.couponIds]);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Deals Of Days </li>

                        <li className="breadcrumb-item active">Form</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container w-100">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <form className="form-container w-100">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        Title :
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          name="title"
                          placeholder="Enter the deal title"
                          onChange={handleChange}
                          value={input?.title}
                        />
                        {errors.title && (
                          <div className="help-block text-danger">
                            {errors.title}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        Actual Cost :
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="text-success fas fa-rupee-sign"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="actualCost"
                            name="actualCost"
                            onChange={handleChange}
                            value={selectedItems.reduce(
                              (d, i) => d + +i?.mrp * +i?.qty,
                              0
                            )}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        Selling Price :
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i
                                className="text-success fas fa-rupee-sign"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="sellingPrice"
                            name="sellingPrice"
                            placeholder="Enter the selling price"
                            onChange={handleChange}
                            value={selectedItems.reduce(
                              (d, i) => d + +i?.sellingPrice * +i?.qty,
                              0
                            )}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        Offered Cost :
                        <input
                          type="text"
                          className="form-control"
                          id="discountedCost"
                          name="discountedCost"
                          placeholder="Enter the offered cost"
                          onChange={handleChange}
                          value={input?.discountedCost}
                        />
                        {errors.discountedCost && (
                          <div className="help-block text-danger">
                            {errors.discountedCost}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        Start Date:
                        <DatePicker
                          id="startDate"
                          name="startDate"
                          selected={startDate}
                          value={startDate}
                          onChange={handleStartDate}
                          className="form-control"
                          dateFormat="dd MMM yyyy h:mm aa"
                          showTimeSelect
                          timeFormat="h:mm aa"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        End Date :
                        <DatePicker
                          id="endDate"
                          name="endDate"
                          selected={endDate}
                          value={endDate}
                          onChange={handleEndDate}
                          className="form-control"
                          dateFormat="dd MMM yyyy h:mm aa"
                          showTimeSelect
                          timeFormat="h:mm aa"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-2">
                        Select Coupons :
                        <Select
                          name="applicableCoupons"
                          options={couponData}
                          onChange={handleOptions}
                          value={selectedCoupons}
                          isMulti
                        />
                        {errors.applicableCoupons && (
                          <div className="help-block text-danger">
                            {errors.applicableCoupons}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        Allow Quantity :
                        <input
                          type="text"
                          className="form-control"
                          id="allowQuantity"
                          name="allowQuantity"
                          placeholder="Enter the quantity"
                          onChange={handleChange}
                          value={input?.allowQuantity}
                        />
                        {errors.allowQuantity && (
                          <div className="help-block text-danger">
                            {errors.allowQuantity}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div className="form-group mb-2">
                        Image :
                        <input
                          type="file"
                          className="form-control"
                          id="Images"
                          name="Images"
                          onChange={handleImage}
                        />
                      </div>
                    </div>

                    <div className="col-md-2 ">
                      <img
                        className="d-block"
                        width={80}
                        height={80}
                        src={input?.Images || baseUrl + input?.imagePath}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "/assets/img/noimage.jpg")(
                              (e.target.style.width = "80px")
                            ),
                            (e.target.style.height = "80px")
                          )
                        }
                      />
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        Description :
                        <JoditEditor
                          ref={editor}
                          tabIndex={1}
                          id="description"
                          name="description"
                          value={input?.description}
                          onChange={(e) => handleEditor(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <Button
                    className="mt-2 mb-2"
                    variant="green"
                    onClick={handleOpenModal}
                  >
                    <i className="fa fa-plus-square"></i>&nbsp; ADD PRODUCT
                  </Button>

                  <Modal
                    show={showModal}
                    className="cus-moda-view"
                    onHide={handleCloseModal}
                  >
                    <Modal.Header
                      closeButton
                      className="justify-content-between d-flex align-items-center"
                    >
                      <Modal.Title className="cus-title-status">
                        PRODUCT LIST
                      </Modal.Title>
                      <div className="d-flex justify-content-end">
                        <select
                          className="form-control w-75"
                          onChange={handleCateogryList}
                        >
                          {list?.map((item, i) => (
                            <option key={i} value={item?.categoryId}>
                              {item?.categoryName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <Table striped bordered>
                        <thead>
                          <tr style={{ backgroundColor: "#4aad48" }}>
                            <th>#</th>
                            <th>SELECT</th>
                            <th>PRODUCT NAME</th>
                            <th>PACKAGING</th>
                            <th>MRP</th>
                            <th>SELLING PRICE</th>
                            <th>IMAGE</th>
                          </tr>
                        </thead>

                        <tbody>
                          {product?.map((p, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedItems?.some(
                                    (item) => item.productId === p.productId
                                  )}
                                  onChange={() => handleProductSelect(p)}
                                />
                              </td>
                              <td className="text-left">{p.productName}</td>
                              <td className="text-center">{p?.packaging}</td>
                              <td>
                                <i
                                  className="text-success fas fa-rupee-sign"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;{p?.mrp}
                              </td>
                              <td>
                                <i
                                  className="text-success fas fa-rupee-sign"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;{p?.sellingPrice}
                              </td>
                              <td>
                                <img
                                  className="card-img-top w-10"
                                  height={"50px"}
                                  width={"50px"}
                                  src={baseUrl + p?.imageData}
                                  onError={(e) => {
                                    e.target.src = "/assets/img/noimage.jpg";
                                    e.target.onerror = null;
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Modal.Body>

                    {/* <Modal.Footer>
                      <Button
                        variant="green"
                        onClick={handleConfirmSelection}
                        type="button"
                      >
                        Confirm
                      </Button>
                    </Modal.Footer> */}
                  </Modal>

                  <div className="card">
                    <table className="table">
                      <thead>
                        <tr style={{ backgroundColor: "#4aad48" }}>
                          <th>#</th>
                          <th>PRODUCT NAME</th>
                          <th>IMAGE</th>
                          <th>PACKAGING</th>
                          <th>MRP</th>
                          <th>SELLING PRICE</th>
                          <th>QUANTITY</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selectedItems?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item?.productName}</td>
                              <td>
                                <img
                                  className="card-img-top w-10"
                                  height={"40px"}
                                  width={"40px"}
                                  src={
                                    baseUrl +
                                    (item?.productImage || item?.imageData)
                                  }
                                  onError={(e) => {
                                    e.target.src = "/assets/img/noimage.jpg";
                                    e.target.onerror = null;
                                  }}
                                />
                              </td>
                              <td>{item?.packaging}</td>
                              <td>
                                <i
                                  className="text-success fas fa-rupee-sign"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                                {item?.mrp}
                              </td>
                              <td className="text-center">
                                <i
                                  className="text-success fas fa-rupee-sign"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;
                                {item?.sellingPrice}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={item?.qty || 1}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    handleQuantityChange(value, index);
                                  }}
                                />
                                &nbsp;&nbsp;
                                {!item?.dealsId ? (
                                  <span onClick={() => handleRemove(index)}>
                                    <i className="fa fa-trash text-danger"></i>
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              {!submitloader ? (
                <>
                  <button
                    type="submit"
                    className="btn btn-green"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button className="btn btn-green">Submitting...</button>
                </>
              )}
              <NavLink to="/GetDealsOfDay" className="btn btn-danger">
                Close
              </NavLink>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default EditDealsofDay;
