import React from "react";
import Slider from "react-slick";
import Testimonials from "./Testimonials";
import CategoryList from "./CategoryList";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Body = ({ sectionRef, sectionsRef }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // Adjust the autoplay speed as needed
  };

  return (
    <>
      <main>
        {/* <!-- slider-area --> */}
        <section className="slider-area">
          <div className="container custom-container">
            <div className="row">
              <div className="col-7">
                <div className="slider-active">
                  <Slider {...settings}>
                    <div className="single-slider slider-bg slider-bg-1">
                      <div className="slider-content">
                        <h5
                          className="sub-title"
                          data-animation="fadeInUp"
                          data-delay=".2s"
                        >
                          Teach Enabled and
                        </h5>
                        <h2
                          className="title"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                        >
                          Access Controlled Farms
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                          Nourishing You Naturally: Farm Fresh Vegetables!
                        </p>
                        {/* <a href="#" className="btn rounded-btn" data-animation="fadeInUp" data-delay=".8s">
                                                    Shop Now
                                                </a> */}
                      </div>
                    </div>

                    <div className="single-slider slider-bg slider-bg-2"></div>
                    <div className="single-slider slider-bg slider-bg-3">
                      <div className="slider-content">
                        <h5
                          className="sub-title"
                          data-animation="fadeInUp"
                          data-delay=".2s"
                        >
                          Community Farming
                        </h5>
                        <h2
                          className="title"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                        >
                          Agri Tourism
                        </h2>
                        <p data-animation="fadeInUp" data-delay=".6s">
                          Where Agriculture Meets Adventure: Unveil the Bounty
                          of Nature!
                        </p>
                      </div>
                    </div>
                    <div className="single-slider slider-bg slider-bg-4"></div>

                    <div className="single-slider slider-bg slider-bg-5"></div>
                  </Slider>
                </div>
                {/* <div className="slider-active">
                    <div className="single-slider slider-bg slider-bg-1">
                        <div className="slider-content">
                            <h5 className="sub-title" data-animation="fadeInUp" data-delay=".2s">Farm Fresh</h5>
                            <h2 className="title" data-animation="fadeInUp" data-delay=".4s">Farm Fresh</h2>
                            <p data-animation="fadeInUp" data-delay=".6s">Nourishing You Naturally: Farm Fresh Delights!</p>
                            <a href="#" className="btn rounded-btn" data-animation="fadeInUp" data-delay=".8s">Shop Now</a>
                        </div>
                    </div>
                    <div className="single-slider slider-bg slider-bg-2">
                        {/* <!--<div className="slider-content">
                        <h5 className="sub-title" data-animation="fadeInUp" data-delay=".2s">top deal !</h5>
                        <h2 className="title" data-animation="fadeInUp" data-delay=".4s">Farm Fresh</h2>
                        <p data-animation="fadeInUp" data-delay=".6s">Get up to 50% OFF Today Only</p>
                        <a href="#" className="btn rounded-btn" data-animation="fadeInUp" data-delay=".8s">Shop Now</a>
                    </div>--> */}
                {/* </div>

                    <div className="single-slider slider-bg slider-bg-3" >
                        <div className="slider-content">
                            <h5 className="sub-title" data-animation="fadeInUp" data-delay=".2s">Community Farming</h5>
                            <h2 className="title" data-animation="fadeInUp" data-delay=".4s">Agri Tourism</h2>
                            <p data-animation="fadeInUp" data-delay=".6s">Where Agriculture Meets Adventure: Unveil the Bounty of Nature!</p>
                            <a href="#" className="btn rounded-btn" data-animation="fadeInUp" data-delay=".8s">View More</a>
                        </div>
                    </div>
                    <div className="single-slider slider-bg slider-bg-4" >
                        <div className="slider-content">
                            <h5 className="sub-title" data-animation="fadeInUp" data-delay=".2s">Farm Fresh</h5>
                            <h2 className="title" data-animation="fadeInUp" data-delay=".4s">Farm Fresh</h2>
                            <p data-animation="fadeInUp" data-delay=".6s">Nourishing You Naturally: Farm Fresh Delights!</p>
                            <a href="#" className="btn rounded-btn" data-animation="fadeInUp" data-delay=".8s">Shop Now</a>
                        </div>
                    </div>


                </div> */}
              </div>
              <div className="col-3">
                <div className="slider-banner-img mb-20">
                  <a href="#">
                    <img
                      src="siteadmin/img/slider/slider_banner01.webp"
                      alt="Hello Farmers"
                    />
                  </a>
                </div>
                <div className="slider-banner-img">
                  <a href="#">
                    <img
                      src="siteadmin/img/slider/slider_banner02.webp"
                      alt="Hello Farmers"
                    />
                  </a>
                </div>
              </div>
              <div className="col-3">
                <div className="slider-banner-img">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                    target="_blank"
                  >
                    <img
                      src="siteadmin/img/slider/slider_banner03.webp"
                      alt="Hello Farmers"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container custom-container">
            <div className="slider-category-wrap">
              <CategoryList />
            </div>
          </div>
        </section>
        {/* <!-- slider-area-end --> */}

        <section className="contact-area pt-60 pb-60">
          <div className="container">
            <div className="container-inner-wrap">
              <div className="row justify-content-center justify-content-lg-between">
                <div className="col-lg-6 col-md-6 order-2 order-lg-0">
                  <div className="contact-info-wrap">
                    <div className="contact-title mb-25">
                      <h5 className="sub-title">Get to Know Us</h5>
                      <h2 className="title">
                        Introduction<span>.</span>
                      </h2>
                    </div>
                    <div className="contact-wrap-content">
                      <p>
                        Hello Farmers is an Agri-Tech platform based in India
                        that offers a Community Farming as a Service (CFaaS)
                        model utilizing Blockchain and Artificial Intelligence
                        (AI) technology. The platform provides a unique solution
                        to the agricultural supply chain by bridging the gap
                        between farmers and consumers through a transparent and
                        fully automated supply chain.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="">
                    <div className="contact-img mb-0">
                      <img
                        src="siteadmin/img/images/vision.webp"
                        alt="Hello Farmers"
                        className="w-75"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third_bner gray-bg">
          <div className="container">
            <div className="subheadingprt text-center">
              <h3>Select your </h3>
              <h2>Membership</h2>
            </div>
            {/* <!---desktop start---> */}
            <div className="infographic-wrapper desktop_view">
              <div className="infographic-items">
                <div className="top_prt">
                  <h2 className="benefit">BENEFIT</h2>
                </div>
                <ul className="iner_cont first_fixed">
                  <li className="first_col">
                    <span className="first_cls">Amount Paid</span>
                  </li>
                  <li className="sec_col">
                    <span className="first_cls">Additional Discount</span>
                  </li>
                  <li className="thir_col">
                    <span className="first_cls">Validity</span>
                  </li>
                </ul>
              </div>

              <div className="infographic-items">
                <div className="whole_info">
                  <div className="top_prt premium_top">
                    <h2 className="premium_offer">Economy Wallet</h2>
                    <h3 className="freecls">
                      <span className="whole_we">
                        <span className="fre_we">10 % Offer</span>
                        <span className="savecls">
                          Save <br />₹ 249
                        </span>
                      </span>
                    </h3>
                    <h4 className="valcls">
                      <del> </del>{" "}
                    </h4>{" "}
                    <img
                      className="frucls ls-is-cached lazyloaded"
                      src="siteadmin/img/pack-01.webp"
                    />
                  </div>
                  <ul className="iner_cont second_fixed">
                    <li className="first_col">
                      <span className="first_cls">
                        <span>₹ 2499</span> ₹ 2249 / Month
                      </span>
                    </li>
                    <li className="sec_col">
                      <span className="first_cls">
                        10% on your monthly expenses
                      </span>
                    </li>
                    <li className="thir_col">
                      <span className="first_cls">
                        Next Day Fresh Basket Delivery
                      </span>
                    </li>
                    <li className="thir_col">
                      <div className="enroll_now">
                        {" "}
                        <a
                          href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                          target="_blank"
                          className="bt-en1"
                        >
                          Subscribe Now
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="infographic-items">
                <div className="whole_info">
                  <div className="top_prt best_premium">
                    <h2 className="premium_offer">Delight Wallet</h2>
                    <h3 className="freecls">
                      <span className="whole_we">
                        <span className="fre_we">15 % Offer</span>
                        <span className="savecls">
                          Save <br />₹ 525
                        </span>
                      </span>
                    </h3>
                    <h4 className="valcls">
                      <del></del>{" "}
                    </h4>{" "}
                    <img
                      className="frucls ls-is-cached lazyloaded"
                      src="siteadmin/img/pack-02.webp"
                    />
                  </div>
                  <ul className="iner_cont third_fixed">
                    <li className="first_col">
                      <span className="first_cls">
                        <span>₹ 3499</span> ₹ 2975 / Month
                      </span>
                    </li>
                    <li className="sec_col">
                      <span className="first_cls">
                        15% on your monthly expenses
                      </span>
                    </li>
                    <li className="thir_col">
                      <span className="first_cls">
                        Next Day Fresh Basket Delivery
                      </span>
                    </li>
                    <li className="thir_col">
                      <div className="enroll_now">
                        {" "}
                        <a
                          href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                          target="_blank"
                          className="bt-en1"
                        >
                          Subscribe Now
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="infographic-items">
                <div className="whole_info">
                  <div className="top_prt better_premium">
                    <h2 className="premium_offer">Exclusive Wallet</h2>
                    <h3 className="freecls">
                      <span className="whole_we">
                        <span className="fre_we">20 % Offer </span>
                        <span className="savecls">
                          Save <br />₹ 1000
                        </span>
                      </span>
                    </h3>
                    <h4 className="valcls">
                      <del></del>{" "}
                    </h4>{" "}
                    <img
                      className="frucls ls-is-cached lazyloaded"
                      src="siteadmin/img/pack-03.webp"
                    />
                  </div>
                  <ul className="iner_cont fourth_fixed">
                    <li className="first_col">
                      <span className="first_cls">
                        <span>₹ 4999</span> ₹ 3999 / Month
                      </span>
                    </li>
                    <li className="sec_col">
                      <span className="first_cls">
                        20% on your monthly expenses
                      </span>
                    </li>
                    <li className="thir_col">
                      <span className="first_cls">
                        Next Day Fresh Basket Delivery
                      </span>
                    </li>
                    <li className="thir_col">
                      <div className="enroll_now">
                        {" "}
                        <a
                          href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                          target="_blank"
                          className="bt-en1"
                        >
                          Subscribe Now
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="mobile_view">
              <div className="infographic-wrapper ">
                <div className="infographic-items">
                  <div className="top_prt">
                    <h2 className="benefit">BENEFIT</h2>
                  </div>
                  <ul className="iner_cont first_fixed">
                    <li className="first_col">
                      <span className="first_cls">
                        Amount
                        <br />
                        Paid
                      </span>
                    </li>
                    <li className="sec_col">
                      <span className="first_cls">
                        Additional
                        <br /> Discount
                      </span>
                    </li>
                    <li className="thir_col">
                      <span className="first_cls">Validity</span>
                    </li>
                  </ul>
                </div>
                <div className="infographic-items">
                  <div className="whole_info">
                    <div className="top_prt premium_top">
                      <h2 className="premium_offer">Economy Wallet</h2>
                      <h3 className="freecls">
                        <span className="whole_we">
                          <span className="fre_we">10 % Offer</span>
                          <span className="savecls">
                            Save <br />₹ 249
                          </span>
                        </span>
                      </h3>
                      <h4 className="valcls">
                        <del> </del>{" "}
                      </h4>{" "}
                      <img
                        className="frucls ls-is-cached lazyloaded"
                        src="siteadmin/img/pack-01.webp"
                      />
                    </div>
                    <ul className="iner_cont second_fixed">
                      <li className="first_col">
                        <span className="first_cls">
                          <span>₹ 2499</span> ₹ 2249 / Month
                        </span>
                      </li>
                      <li className="sec_col">
                        <span className="first_cls">
                          10% on your monthly expenses
                        </span>
                      </li>
                      <li className="thir_col">
                        <span className="first_cls">
                          Next Day Fresh Basket Delivery
                        </span>
                      </li>
                      <li className="thir_col">
                        <div className="enroll_now">
                          {" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                            target="_blank"
                            className="bt-en1"
                          >
                            Subscribe Now
                          </a>{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="infographic-items">
                  <div className="top_prt">
                    <h2 className="benefit">BENEFIT</h2>
                  </div>
                  <ul className="iner_cont first_fixed">
                    <li className="first_col">
                      <span className="first_cls">
                        Amount
                        <br />
                        Paid
                      </span>
                    </li>
                    <li className="sec_col">
                      <span className="first_cls">
                        Additional
                        <br /> Discount
                      </span>
                    </li>
                    <li className="thir_col">
                      <span className="first_cls">Validity</span>
                    </li>
                  </ul>
                </div>
                <div className="infographic-items">
                  <div className="whole_info">
                    <div className="top_prt best_premium">
                      <h2 className="premium_offer">Delight Wallet</h2>
                      <h3 className="freecls">
                        <span className="whole_we">
                          <span className="fre_we">15 % Offer</span>
                          <span className="savecls">
                            Save <br />₹ 525
                          </span>
                        </span>
                      </h3>
                      <h4 className="valcls">
                        <del></del>{" "}
                      </h4>{" "}
                      <img
                        className="frucls ls-is-cached lazyloaded"
                        src="siteadmin/img/pack-02.webp"
                      />
                    </div>
                    <ul className="iner_cont third_fixed">
                      <li className="first_col">
                        <span className="first_cls">
                          <span>₹ 3499</span> ₹ 2975 / Month
                        </span>
                      </li>
                      <li className="sec_col">
                        <span className="first_cls">
                          15% on your monthly expenses
                        </span>
                      </li>
                      <li className="thir_col">
                        <span className="first_cls">
                          Next Day Fresh Basket Delivery
                        </span>
                      </li>
                      <li className="thir_col">
                        <div className="enroll_now">
                          {" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                            target="_blank"
                            className="bt-en1"
                          >
                            Subscribe Now
                          </a>{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="infographic-items">
                  <div className="top_prt">
                    <h2 className="benefit">BENEFIT</h2>
                  </div>
                  <ul className="iner_cont first_fixed">
                    <li className="first_col">
                      <span className="first_cls">
                        Amount
                        <br />
                        Paid
                      </span>
                    </li>
                    <li className="sec_col">
                      <span className="first_cls">
                        Additional
                        <br /> Discount
                      </span>
                    </li>
                    <li className="thir_col">
                      <span className="first_cls">Validity</span>
                    </li>
                  </ul>
                </div>
                <div className="infographic-items">
                  <div className="whole_info">
                    <div className="top_prt better_premium">
                      <h2 className="premium_offer">Exclusive Wallet</h2>
                      <h3 className="freecls">
                        <span className="whole_we">
                          <span className="fre_we">20 % Offer </span>
                          <span className="savecls">
                            Save <br />₹ 1000
                          </span>
                        </span>
                      </h3>
                      <h4 className="valcls">
                        <del></del>{" "}
                      </h4>{" "}
                      <img
                        className="frucls ls-is-cached lazyloaded"
                        src="siteadmin/img/pack-03.webp"
                      />
                    </div>
                    <ul className="iner_cont fourth_fixed">
                      <li className="first_col">
                        <span className="first_cls">
                          <span>₹ 4999</span> ₹ 3999 / Month
                        </span>
                      </li>
                      <li className="sec_col">
                        <span className="first_cls">
                          20% on your monthly expenses
                        </span>
                      </li>
                      <li className="thir_col">
                        <span className="first_cls">
                          Next Day Fresh Basket Delivery
                        </span>
                      </li>
                      <li className="thir_col">
                        <div className="enroll_now">
                          {" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                            target="_blank"
                            className="bt-en1"
                          >
                            Subscribe Now
                          </a>{" "}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!----mobile end----> */}
          </div>
        </section>

        {/* <!-- discount-area --> */}
        <section className="discount-area pt-80 d-none">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9">
                <div className="best-deal-top-wrap">
                  <div className="bd-section-title">
                    <p className="text-center">SUBSCRIBE BASKET</p>
                    <h3 className="title">
                      Farm to Kitchen <span>Subscription</span>
                    </h3>
                  </div>
                  {/* <!--<div className="coming-time" data-countdown="2023/10/20"></div>--> */}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-6 col-md-8">
                <div className="discount-item mb-20">
                  <div className="discount-thumb">
                    <img
                      src="siteadmin/img/product/discount_img01.jpg"
                      alt="Hello Farmers"
                    />
                  </div>
                  <div className="discount-content">
                    {/* <!--<span>SMALL BASKET</span>--> */}
                    <h4 className="title">
                      <a href="#">SMALL BASKET</a>
                    </h4>
                    <a href="#" className="btn">
                      SUBSCRIBE
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-8">
                <div className="discount-item mb-20">
                  <div className="discount-thumb">
                    <img
                      src="siteadmin/img/product/discount_img02.jpg"
                      alt="Hello Farmers"
                    />
                  </div>
                  <div className="discount-content">
                    {/* <!--<span>MEDIUM BASKET</span>--> */}
                    <h4 className="title">
                      <a href="#">MEDIUM BASKET</a>
                    </h4>
                    <a href="#" className="btn">
                      SUBSCRIBE
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-8">
                <div className="discount-item mb-20">
                  <div className="discount-thumb">
                    <img
                      src="siteadmin/img/product/discount_img03.jpg"
                      alt="Hello Farmers"
                    />
                  </div>
                  <div className="discount-content">
                    {/* <!--<span>LARGE BASKET</span>--> */}
                    <h4 className="title">
                      <a href="#">LARGE BASKET</a>
                    </h4>
                    <a href="#" className="btn">
                      SUBSCRIBE
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="best-deal-area pt-60 pb-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9">
                <div className="best-deal-top-wrap">
                  <div className="bd-section-title">
                    <h3 className="title">
                      Customer <span>Testimonials</span>
                    </h3>
                    {/* <!--<p>A virtual assistant collects the products from your list</p>--> */}
                  </div>
                  {/* <!--<div className="coming-time" data-countdown="2023/10/20"></div>--> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Testimonials />
              </div>
            </div>
          </div>
        </section>

        <section className="services-area services-bg">
          <div className="container-fluid">
            <div className="container-inner-wrap">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-9">
                  <div className="services-section-title text-center">
                    <h2 className="title">Healthy Life With Fresh Products</h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl col-lg col-md-6 col-sm-6">
                  <div className="services-item">
                    <a href="#" className="services-link"></a>
                    <div className="icon">
                      <i className="flaticon-user"></i>
                    </div>
                    <div className="content">
                      <h5>
                        Active <br />
                        Members
                      </h5>
                      <p>
                        <span className="new">1200+</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl col-lg col-md-6 col-sm-6">
                  <div className="services-item">
                    <a href="contact.html" className="services-link"></a>
                    <div className="icon">
                      <i className="flaticon-delivery"></i>
                    </div>
                    <div className="content">
                      <h5>
                        Active <br />
                        Community Farms
                      </h5>
                      <p>
                        <span className="new">450+</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl col-lg col-md-6 col-sm-6">
                  <div className="services-item">
                    <a href="contact.html" className="services-link"></a>
                    <div className="icon">
                      <i className="flaticon-place"></i>
                    </div>
                    <div className="content">
                      <h5>
                        Operational <br />
                        States
                      </h5>
                      <p>
                        <span className="new">5</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl col-lg col-md-6 col-sm-6">
                  <div className="services-item">
                    <a href="contact.html" className="services-link"></a>
                    <div className="icon">
                      <i className="flaticon-return"></i>
                    </div>
                    <div className="content">
                      <h5>
                        Active <br />
                        Subscription Cities
                      </h5>
                      <p>
                        <span className="new">2</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="special-products-area gray-bg pt-75 pb-60">
          <div className="container">
            <div className="row align-items-end mb-50">
              <div className="col-md-8 col-sm-9">
                <div className="section-title">
                  <span className="sub-title">Awesome Pics</span>
                  <h2 className="title">Our Gallery</h2>
                </div>
              </div>
              <div className="col-md-4 col-sm-3">
                <div className="section-btn text-left text-md-right">
                  <a href="/gallery" className="btn">
                    View All
                  </a>
                </div>
              </div>
            </div>
            <div className="special-products-wrap">
              <div className="row">
                <div className="col-3 d-none d-lg-block">
                  <div className="special-products-add">
                    <div className="sp-add-thumb">
                      <img
                        src="siteadmin/img/product/special_products_add.webp"
                        alt="Hello Farmers"
                        className="img-responsive img-thumbnail"
                      />
                    </div>
                    <div className="sp-add-content">
                      <span className="sub-title">Hello Farmers</span>
                      <h4 className="title">
                        Farm Fresh <b>Vegetables</b>
                      </h4>
                      {/* <p>Super Offer TO 20% OFF</p> */}
                      {/* <a href="#" className="btn rounded-btn">shop now</a> */}
                    </div>
                  </div>
                </div>
                <div className="col-9">
                  <div className="list-group gallery">
                    <div className="row ">
                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-01.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-01.jpg"
                          />
                          {/* <!--<div className="text-right">
                                    <small className="text-muted">Image Title</small>
                                </div>--> */}
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-02.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-02.jpg"
                          />
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-03.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-03.jpg"
                          />
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-04.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-04.jpg"
                          />
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-05.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-05.jpg"
                          />
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-06.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-06.jpg"
                          />
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-07.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-07.jpg"
                          />
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-08.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-08.jpg"
                          />
                        </a>
                      </div>

                      <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                        <a
                          className="thumbnail"
                          rel="ligthbox"
                          href="siteadmin/img/gallery/img-09.jpg"
                        >
                          <img
                            className="img-responsive img-thumbnail"
                            alt="Hello Farmers"
                            src="siteadmin/img/gallery/thumb-img-09.jpg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ganic-app-area pt-50 pb-50">
          <div className="container">
            <div className="ganic-app-wrap">
              <div className="ganic-app-img">
                <img
                  src="siteadmin/img/images/download_img.webp"
                  alt="Hello Farmers"
                />
              </div>
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="ganic-app-content">
                    <div className="shape">
                      <img
                        src="siteadmin/img/images/app_download_shape.png"
                        alt="Hello Farmers"
                      />
                    </div>
                    <span className="sub-title">Online Platform</span>
                    <h2 className="title">
                      <span>download</span> Hello Farmer's APP
                    </h2>
                    <div className="ganic-app-btn">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                        target="_blank"
                      >
                        <img
                          src="siteadmin/img/icon/app_btn01.png"
                          alt="Hello Farmers"
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/in/app/hello-farmers/id6465133530"
                        target="_blank"
                      >
                        <img
                          src="siteadmin/img/icon/app_btn02.png"
                          alt="Hello Farmers"
                        />
                      </a>
                    </div>
                    <p>
                      Load your wallet and get up to 20% additional discount
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-area gray-bg pt-70 pb-50">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8 col-md-10">
                <div className="section-title section-title-two text-center mb-50">
                  <span className="sub-title">Our Articles</span>
                  <h2 className="title">Our Latest Blogs</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-9">
                <div className="blog-post-item mb-30">
                  <div className="blog-post-thumb">
                    <a href="/blog-details">
                      <img
                        src="siteadmin/img/blog/blog_post_thumb01.webp"
                        alt="Hello Farmers"
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <div className="blog-post-date">
                      03 <span>AUG</span>
                    </div>
                    <div className="blog-post-meta">
                      <ul>
                        <li>
                          <a href="/blog-details">Admin</a>
                        </li>
                        <li>
                          <a href="/blog-details">03 Comments</a>
                        </li>
                      </ul>
                    </div>
                    <h4 className="title">
                      <a href="/blog-details">
                        Best ways to support healthy immune system
                      </a>
                    </h4>
                    <div className="blog-post-bottom">
                      <ul>
                        <li className="read-more">
                          <a href="/blog-details">
                            Read More{" "}
                            <i className="fas fa-angle-double-right"></i>
                          </a>
                        </li>
                        <li className="share-btn">
                          <a href="/blog-details">
                            <i className="fas fa-share-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-9">
                <div className="blog-post-item mb-30">
                  <div className="blog-post-thumb">
                    <a href="/blog-details">
                      <img
                        src="siteadmin/img/blog/blog_post_thumb02.webp"
                        alt="Hello Farmers"
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <div className="blog-post-date">
                      04 <span>AUG</span>
                    </div>
                    <div className="blog-post-meta">
                      <ul>
                        <li>
                          <a href="/blog-details">Admin</a>
                        </li>
                        <li>
                          <a href="/blog-details">04 Comments</a>
                        </li>
                      </ul>
                    </div>
                    <h4 className="title">
                      <a href="/blog-details">
                        Farm Fresh Vegetables Produced Method
                      </a>
                    </h4>
                    <div className="blog-post-bottom">
                      <ul>
                        <li className="read-more">
                          <a href="/blog-details">
                            Read More{" "}
                            <i className="fas fa-angle-double-right"></i>
                          </a>
                        </li>
                        <li className="share-btn">
                          <a href="/blog-details">
                            <i className="fas fa-share-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-9">
                <div className="blog-post-item mb-30">
                  <div className="blog-post-thumb">
                    <a href="/blog-details">
                      <img
                        src="siteadmin/img/blog/blog_post_thumb03.webp"
                        alt="Hello Farmers"
                      />
                    </a>
                  </div>
                  <div className="blog-post-content">
                    <div className="blog-post-date">
                      05 <span>AUG</span>
                    </div>
                    <div className="blog-post-meta">
                      <ul>
                        <li>
                          <a href="/blog-details">Admin</a>
                        </li>
                        <li>
                          <a href="/blog-details">03 Comments</a>
                        </li>
                      </ul>
                    </div>
                    <h4 className="title">
                      <a href="/blog-details">
                        Best ways to support healthy immune system
                      </a>
                    </h4>
                    <div className="blog-post-bottom">
                      <ul>
                        <li className="read-more">
                          <a href="/blog-details">
                            Read More{" "}
                            <i className="fas fa-angle-double-right"></i>
                          </a>
                        </li>
                        <li className="share-btn">
                          <a href="/blog-details">
                            <i className="fas fa-share-alt"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="discount-time-area pt-70 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="discount-time-bg">
                  <div className="discount-time-img left">
                    <img
                      src="siteadmin/img/images/discount_count_shape01.webp"
                      className="wow rollIn"
                      data-wow-delay=".3s"
                      alt="Hello Farmers"
                    />
                  </div>
                  <div className="discount-time-img right">
                    <img
                      src="siteadmin/img/images/discount_count_shape02.webp"
                      alt="Hello Farmers"
                    />
                  </div>
                  <div className="discount-time-content">
                    <div className="section-title section-title-two text-center mb-25">
                      <span className="sub-title">Get a Discount</span>
                      <h2 className="title">
                        Download Hello Farmers App from Play Store to
                        grab the deal
                      </h2>
                    </div>
                    <div
                      className="coming-time"
                      data-countdown="2023/08/19"
                    ></div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                      target="_blank"
                      className="mr-3"
                    >
                      <img
                        src="siteadmin/img/icon/app_btn01.png"
                        alt="Hello Farmers"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/hello-farmers/id6465133530"
                      target="_blank"
                    >
                      <img
                        src="siteadmin/img/icon/app_btn02.png"
                        alt="Hello Farmers"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* <Contactus sectionRef={sectionRef} /> */}
    </>
  );
};
export default Body;
