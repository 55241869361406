import { useState, useRef } from "react";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";

const NotificationService = () => {
  const [notification, setNotification] = useState({
    email: false,
    app: true,
  });
  const [input, setInput] = useState({ apiCode: "" });
  const [active, setActive] = useState(4);
  const [submitloader, setSubmitLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const editor = useRef(null);
  const [fieldsHeader, setFieldsHeaders] = useState([
    { type: "text", value: "" },
  ]);
  const [fieldsBody, setFieldsBody] = useState([{ type: "text", value: "" }]);
  const [imageMediaAlert, setImageMediaAlert] = useState("");
  const [appImageBase64, setAppImageBase64] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const handleNext = () => {
    switch (active) {
      case 2:
        if (notification?.whatsapp) {
          setActive(3);
        } else {
          setActive(4);
        }
        break;
      case 3:
        if (notification?.app) {
          setActive(4);
        }
        break;
      default:
        break;
    }
  };

  const handlePrevious = () => {
    switch (active) {
      case 4:
        if (notification?.whatsapp) {
          setActive(3);
        } else {
          setActive(2);
        }
        break;
      case 3:
        if (notification?.email) {
          setActive(2);
        }
        break;
      default:
        break;
    }
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      switch (name) {
        case "email":
          setActive(2);
          break;

        case "whatsapp":
          setActive(3);
          break;

        case "app":
          setActive(4);
          break;

        default:
          break;
      }
    } else {
      if (notification?.email && name !== "email") {
        setActive(2);
      } else if (notification?.whatsapp && name !== "whatsapp") {
        setActive(3);
      } else if (notification?.app && name !== "app") {
        setActive(4);
      }
    }
    setNotification((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: name === "bcc" || name === "sendTo" ? value.trim() : value,
      };
    });
  };
  const handleEditor = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        ["emailmessage"]: e,
      };
    });
  };

  /////////////////////******* FOR WHATSAPP**********///////////////////////////////
  const handleTypeChangeHeader = (index, newType) => {
    const updatedFields = [...fieldsHeader];
    updatedFields[index].type = newType;
    setFieldsHeaders(updatedFields);
  };
  const handleTypeChangeBody = (index, newType) => {
    const updatedFields = [...fieldsBody];
    updatedFields[index].type = newType;
    setFieldsBody(updatedFields);
  };

  const handleValueChangeHeader = (index, newValue, type) => {
    const updatedFields = [...fieldsHeader];
    if (type === "file") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        updatedFields[index].value = base64String;
        setFieldsHeaders(updatedFields);
      };
      reader.readAsDataURL(newValue);
    } else {
      updatedFields[index].value = newValue;
      setFieldsHeaders(updatedFields);
    }
  };
  const handleValueChangeBody = (index, newValue, type) => {
    const updatedFields = [...fieldsBody];
    if (type === "file") {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        updatedFields[index].value = base64String;
        setFieldsBody(updatedFields);
      };
      reader.readAsDataURL(newValue);
    } else {
      updatedFields[index].value = newValue;
      setFieldsBody(updatedFields);
    }
  };

  const addFieldHeader = () => {
    setFieldsHeaders([...fieldsHeader, { type: "text", value: "" }]);
  };
  const addFieldBody = () => {
    setFieldsBody([...fieldsBody, { type: "text", value: "" }]);
  };

  const removeFieldHeader = (index) => {
    const updatedFields = [...fieldsHeader];
    updatedFields.splice(index, 1);
    setFieldsHeaders(updatedFields);
  };
  const removeFieldBody = (index) => {
    const updatedFields = [...fieldsBody];
    updatedFields.splice(index, 1);
    setFieldsBody(updatedFields);
  };

  const handleValueChangeMedia = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        setImageMediaAlert(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  /////////////////////******* FOR WHATSAPP**********///////////////////////////////

  /////////////////////******* FOR APP**********///////////////////////////////

  const handleAppImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result;
        setAppImageBase64(base64String);
        setImagePreview(base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  /////////////////////******* FOR APP**********///////////////////////////////

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    try {
      const obj = [];
      if (notification?.email) {
        obj.push({
          communicationMode: 2,
          sendTo: "",
          bcc: input?.bcc,
          subject: input?.subject,
          message: input?.emailmessage,
          topic: "",
          appTitle: "",
          language: "",
          template: "",
          apiCode: "",
          appImageBase64: "",
          components: [
            {
              type: "",
              parameters: [
                {
                  type: "",
                  text: "",
                  image: {
                    link: "",
                  },
                },
              ],
            },
          ],
        });
      }

      if (notification?.app) {
        obj.push({
          communicationMode: 4,
          sendTo: "",
          bcc: "",
          subject: "",
          message: input?.appmessage,
          topic: "ALL_USER",
          appTitle: input?.appTitle,
          language: "",
          template: "",
          apiCode: "",
          appImageBase64: appImageBase64.slice(
            appImageBase64.indexOf(";base64,") + 8
          ),
          components: [
            {
              type: "",
              parameters: [
                {
                  type: "",
                  text: "",
                  image: {
                    link: "",
                  },
                },
              ],
            },
          ],
        });
      }

      if (notification?.whatsapp) {
        if (input.apiCode == "FBCLOUD") {
          const dynamicComponents = [];
          dynamicComponents.push({
            type: "header",
            parameters: fieldsHeader.map((field) => ({
              type: field.type.toUpperCase(),
              image:
                field.type === "text"
                  ? {}
                  : {
                      link: field.value.slice(
                        field.value.indexOf(";base64,") + 8
                      ),
                    },

              text: field.type === "text" ? field.value : "",
            })),
          });

          dynamicComponents.push({
            type: "body",
            parameters: fieldsBody.map((field) => ({
              type: field.type.toUpperCase(),
              image: field.type === "text" ? {} : { link: field.value },
              text: field.type === "text" ? field.value : "",
            })),
          });

          obj.push({
            communicationMode: 3,
            sendTo: input?.sendTo,
            bcc: "",
            subject: "",
            message: input?.whatsappmessage,
            topic: "",
            appTitle: "",
            language: "en",
            template: input?.template,
            apiCode: input?.apiCode,
            appImageBase64: "",
            components: dynamicComponents,
          });
        } else {
          obj.push({
            communicationMode: 3,
            sendTo: input.sendTo,
            bcc: "",
            subject: "",
            message: input.whatsappmessage,
            topic: "",
            appTitle: "",
            language: "",
            template: "",
            apiCode: input.apiCode,
            appImageBase64: "",
            components: [
              {
                type: "IMAGE",
                parameters: [
                  {
                    type: "IMAGE",
                    text: "",
                    image: {
                      link: imageMediaAlert.slice(
                        imageMediaAlert.indexOf(";base64,") + 8
                      ),
                    },
                  },
                ],
              },
            ],
          });
        }
      }
      setSubmitLoader(true);
      const res = await postRequest(`api/Notify/BulkNotify`, obj);
      if (res?.statusCode === 1) {
        toast.success(res?.responseText);
      } else {
        toast.error(res?.responseText);
      }
      setSubmitLoader(false);
    } catch (error) {
      console.log("Error", error);
      setSubmitLoader(false);
    }
  };

  ///////////******** VALIDATE THE INPUT****** *///////////

  const validateInput = () => {
    const newErrors = {};

    if (notification?.email) {
      if (!input?.bcc) {
        newErrors.bcc = "Enter the email address.";
      }
      if (!input?.subject) {
        newErrors.subject = "Enter the subject.";
      }
    }
    if (notification?.app) {
      if (!input?.appTitle) {
        newErrors.appTitle = "Enter the app title.";
      }
      if (!input?.appmessage) {
        newErrors.appmessage = "Enter the message body .";
      }
    }
    if (notification?.whatsapp) {
      if (!input?.sendTo) {
        newErrors.sendTo = "Enter the Number.";
      }
      if (!input?.whatsappmessage) {
        newErrors.whatsappmessage = "Enter the message body .";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return {
    handleChange,
    handleCheckBox,
    handleSubmit,
    setActive,
    input,
    notification,
    submitloader,
    handleNext,
    active,
    errors,
    editor,
    handleEditor,
    handlePrevious,
    handleTypeChangeHeader,
    handleValueChangeHeader,
    removeFieldHeader,
    addFieldHeader,
    addFieldBody,
    fieldsHeader,
    fieldsBody,
    handleTypeChangeBody,
    removeFieldBody,
    handleValueChangeBody,
    handleAppImage,
    imagePreview,
    handleValueChangeMedia,
  };
};

export default NotificationService;
