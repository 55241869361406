import { useState } from "react";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PasswordService = () => {
  const [input, setInput] = useState();
  const [submitloader, setSubmitLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      const res = await postRequest(`api/user/ChangePassword`, {
        ...input,
        otp: "string",
      });
      if (res.statusCode === -1) {
        toast.error(res.responseText);
      } else {
        toast.success("Password Changed Successfully");
        navigate("/Profile", { replace: true });
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something Went Wrong");
    }
  };

  ///////********* VALIDATING THE PASSWORD *******/////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.mobileNo) {
      newErrors.mobileNo = "Please enter the Mobile No.";
    }
    if (!input?.oldPassword) {
      newErrors.oldPassword = "Please enter the Old Password.";
    }
    if (!input?.newPassword) {
      newErrors.newPassword = "Please enter the New Password.";
    }
    if (input?.newPassword !== input?.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  ///////********* VALIDATING THE PASSWORD *******/////////

  return {
    input,
    submitloader,
    errors,
    handleChange,
    handleSubmitPassword,
  };
};

export default PasswordService;
