import { React, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import ProdInPackService from "./ProdInPackService";

const ProductInPackage = () => {
  const {
    prodInPackage,
    handleUnmapProd,
    handleClose,
    showModal,
    columns,
    data,
    loading,
  } = ProdInPackService();

  useEffect(() => {
    prodInPackage();
  }, []);

  return (
    <main id="main" className="main">
      <div className="page-header">
        <span className="page-title"> PRODUCT IN PACKAGE </span>
      </div>

      <div className="pak-height card">
        <div className="col-lg-12">
          <div className="table-responsive ">
            {loading ? (
              <div className="spinner-container">
                <Spinner type="TailSpin" color="#00BFFF" />
              </div>
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  className="rdt_TableHeadRow"
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 190px)"
                />
              </>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to unmap the item?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleUnmapProd}>
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              NO
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </main>
  );
};
export default ProductInPackage;
