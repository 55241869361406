export const MeasurementUnits = [
    { 
        "id": 0,
        "name": "SELECT "
    },
    {
        "id": 7,
        "name": "Hectare"
    },
    {
        "id": 8,
        "name": "Acres"
    },
    {
        "id": 9,
        "name": "Bigha"
    }
]