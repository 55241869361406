import { useState } from "react";
import { postRequest } from "../../../Services/ApiServices";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SmsSettingService = () => {
  const [smsFetch, setSmsFetch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [getId, setgetId] = useState();
  const [input, setInput] = useState({
    isActive: true,
    isMultipleAllowed: true,
  });
  const [submitloader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const param = useParams();

  ////////// ********GET THE SMS TABLE ********//////////

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "90px",
    },
    {
      name: "EDIT",
      selector: (row) => row.edit,
      width: "120px",
    },
    {
      name: "API CODE",
      selector: (row) => row.apiCode,
      width: "120px",
    },
    {
      name: "URL",
      selector: (row) => row.url,
      width: "250px",
    },
    {
      name: "API METHOD",
      selector: (row) => row.apiMethod,
      width: "120px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      width: "120px",
    },
    {
      name: "MULTIPLE ALLOWED",
      selector: (row) => row.isMultipleAllowed,
      width: "150px",
    },
    {
      name: "ACTIVE",
      selector: (row) => row.isActive,
      width: "120px",
    },
    {
      name: "WHATSAPP",
      selector: (row) => row.isWhatsapp,
      width: "120px",
    },
  ];

  const data = smsFetch.map((d, i) => ({
    serial: i + 1,
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditSmsSetting/" + d?.id,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span onClick={() => handlepopup(d?.id)} style={{ cursor: "pointer" }}>
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    apiCode: d?.apiCode,
    url: <div className="word-wrap"> {d?.url}</div>,
    apiMethod: d?.apiMethod,
    name: d?.name,
    isMultipleAllowed: d?.isMultipleAllowed ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    isActive: d?.isActive ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    isWhatsapp: d?.isWhatsapp ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
  }));

  const getSmsDetail = async () => {
    setLoading(true);
    try {
      const res = await postRequest(`api/SMSAPI/GetAll`);
      if (res) {
        setSmsFetch(res);
      }
    } catch (error) {
      console.log("Error", error);
    }
    setLoading(false);
  };

  ////////// ********GET THE SMS TABLE ********//////////

  ////////// ********ADD AND EDIT THE SMS FORM ********//////////

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setInput((prev) => {
      if (type === "checkbox") {
        return { ...prev, [name]: checked };
      } else {
        return {
          ...prev,
          [name]: name === "apiType" ? parseInt(value) : value,
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      const res = await postRequest(`api/SMSAPI/Add`, {
        id: param.id || 0,
        apiCode: input.apiCode,
        apiType: input.apiType,
        apiMethod: input.apiMethod,
        name: input.name,
        url: input.url,
        isMultipleAllowed: input.isMultipleAllowed,
        isActive: input.isActive,
        isWhatsapp: input.isWhatsapp,
      });
      if (res) {
        toast.success(res.responseText);
        navigate("/SmsSettings");
      } else {
        toast.error(res.responseText);
      }
    } catch (error) {
      console.log("Error", error);
    }
    setSubmitLoader(false);
  };

  const getDetailById = async () => {
    try {
      const res = await postRequest(`api/SMSAPI/GetById?ID=${param.id}`);
      if (res.statusCode === 1) {
        setInput(res.result);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  ////////// ********ADD AND EDIT THE SMS FORM ********//////////

  ////////// ********DELETE SMS INDEX BY ID ********//////////

  const handlepopup = (id) => {
    setShowModal(true);
    setgetId(id);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/SMSAPI/Delete?ID=${getId}`);
      if (res) {
        toast.success(res.responseText);
        setShowModal(false);
        getSmsDetail();
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  ////////// ********DELETE SMS INDEX BY ID ********//////////

  ////////********  VALIDATE THE FORM  *******///////////

  const validateInput = () => {
    const newErrors = {};

    if (!input?.apiCode) {
      newErrors.apiCode = "Enter the api code .";
    }
    if (input?.apiType === undefined) {
      newErrors.apiType = "Enter the api type.";
    }
    if (!input?.apiMethod) {
      newErrors.apiMethod = "Enter the api method.";
    }
    if (!input?.url) {
      newErrors.url = "Enter the URL.";
    } else {
      const urlPattern =
        /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([/\w.-]*)*\/?$/;

      if (!urlPattern.test(input?.url)) {
        newErrors.url = "Enter a valid URL.";
      }
    }

    if (!input?.name) {
      newErrors.name = "Enter the name.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  ////////********  VALIDATE THE FORM  *******///////////
  return {
    columns,
    data,
    loading,
    getSmsDetail,
    showModal,
    handleClose,
    handleDelete,
    input,
    handleChange,
    handleSubmit,
    submitloader,
    errors,
    getDetailById,
  };
};

export default SmsSettingService;
