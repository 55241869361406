import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import GetOrderService from "./GetOrderService";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const GetOrder = () => {
  const location = useLocation();
  const {
    toDateNew,
    fromDateNew,
    handleChangedateNew,
    handleChangedatesNew,
    handleChange,
    data,
    handleSubmit,
    handleDownloadNewOrderExcel,
    input,
    columns,
    loading,
    setInput,
  } = GetOrderService();

  useEffect(() => {
    const isComingFromDashboard = location.state?.fromDashboard;
    if (isComingFromDashboard && input.orderStatus !== 7) {
      setInput((prevInput) => ({
        ...prevInput,
        orderStatus: 7,
      }));
      handleSubmit(7);
    } else {
      handleSubmit();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Order</li>

                      <li className="breadcrumb-item active">New Order</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <div className="form-container">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    From Date:
                    <DatePicker
                      id="fromDate"
                      name="fromDate"
                      selected={fromDateNew}
                      maxDate={new Date()}
                      onChange={handleChangedatesNew}
                      className="form-control"
                      dateFormat="dd MMM yyyy"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6">
                    To Date:
                    <DatePicker
                      id="toDate"
                      name="toDate"
                      selected={toDateNew}
                      minDate={new Date(fromDateNew)}
                      maxDate={new Date()}
                      onChange={handleChangedateNew}
                      className="form-control"
                      dateFormat="dd MMM yyyy"
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-lg-3 col-md-6">
                    Order Status:
                    <select
                      className="form-control green-dropdown"
                      id="orderStatus"
                      name="orderStatus"
                      value={input?.orderStatus}
                      onChange={handleChange}
                    >
                      <option value="">Select</option>
                      <option value="2">Pending</option>
                      <option value="6">Reschedule</option>
                      <option value="7">Order Placed</option>
                      <option value="8">Order Canceled</option>
                      <option value="9">Order Delivered</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-6 d-flex align-items-end">
                    <>
                      <button
                        type="submit"
                        className="btn btn-green mr-2"
                        id="btnSubmit"
                        onClick={(e) => handleSubmit(false, e)}
                      >
                        Search
                      </button>
                    </>
                    <div>
                      <>
                        <button
                          type="button"
                          className="btn btn-green btn-md"
                          onClick={handleDownloadNewOrderExcel}
                          style={{ padding: "11px" }}
                        >
                          <i className="bi bi-file-earmark-excel"></i>
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body p-2">
            <div className="table-responsive">
              {loading ? (
                <div className="spinner-container">
                  <Spinner type="TailSpin" color="#00BFFF" />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  className="rdt_TableHeadRow"
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 260px)"
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </main>
  );
};

export default GetOrder;
