import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";
import FooterLast from "../../Component/Homes/FooterLast";
import { useEffect } from "react";
const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderTop />
            <main>
                <section className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content">
                                    <h2 className="title">Privacy Policy</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <a href="/home">Home</a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">
                                                Privacy Policy
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="terms-and-conditions-area pt-85 pb-85">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="terms-and-conditions-wrap">
                                    <p>
                                        <b>SKVIPM DIGISOLUTIONS PRIVATE LIMITED, commonly known as “HELLO FARMERS”</b>
                                        (“SDPL” or “we”) takes the privacy of your information seriously.
                                    </p>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <p>
                                        This privacy notice (“Privacy Notice”) describes the types of personal information we collect from you, through our stores, other premises, website (including sub-domains and microsites), mobile
                                        applications, or other means. It describes the purposes for which we collect that personal information, the other parties with whom we may share it, and the measures we take to try and protect it. It
                                        also tells you about your rights and choices with respect to your personal information, and how you can contact us about our privacy practices.
                                    </p>

                                    <p>
                                        You are advised to carefully read this Privacy Notice before using or availing any of our products and/or services. By doing so, you are consenting to the collection and processing of your Data
                                        (including SPDI) in accordance with the practices and policies outlined in this Privacy Notice.
                                    </p>
                                    <p>
                                        Our products and services are targeted at users in India, and this Privacy Notice is intended to comply with the laws of India. If you are accessing or using the website or mobile application from an
                                        overseas location, you are requested to independently validate the impact of any applicable local laws. If you do not agree with this Privacy Notice at any time, in part or as a whole, write to our
                                        Grievance Office at the address specified below.
                                    </p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>1. DEFINITIONS</h5>
                                    <p>
                                        <b>In this Privacy Notice, the following definitions are used</b>
                                    </p>

                                    <p>
                                        <b>Cookies : </b> Cookies refer to small file placed on your device by our website or mobile application when you either visit or use certain features of our website or mobile application. A cookie
                                        generally allows a website to remember your actions or preference for a certain period of time.
                                    </p>

                                    <p>
                                        <b>Data : </b> Data means personal information and SPDI about you, which either directly or indirectly in combination with other information, could allow you to be identified when you visit our
                                        stores, website and/or mobile application.
                                    </p>

                                    <p>
                                        <b>Data Protection Laws : </b> Data Protection laws are applicable law for the time being in force relating to the processing of Data, including the Digital Personal Data Protection Bill, 2023;
                                        Information Technology Act, 2000; and Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, as amended or replaced from time to time.
                                    </p>

                                    <p>
                                        <b>Partners : </b> Partners are the third parties with whom we have contracts or arrangements through which they may offer products or services to You or our other users, and to whom we may disclose
                                        your Data for a relevant purpose.
                                    </p>
                                    <p>
                                        <b>Partners : </b>Service Providers: Service Providers include entities to whom we, or our Partners will disclose your Data in order for them to process it for the purpose of providing services to us
                                        pursuant to written contract.
                                    </p>

                                    <p>
                                        <b>SPDI : </b> SPDI means the sensitive personal data or information of a person as personal information about that person relating to passwords; financial information such as bank account credit and
                                        debit card details or other payment instrument details; physical, confidential physiological and mental health condition; sexual orientation; medical records and history; or biometric information.
                                    </p>
                                    <p>
                                        <b>SDPL : </b> SKVIPM DIGISOLUTIONS PRIVATE LIMITED, a company incorporated in India whose registered office is at 3/553, Vivek Khand, Station Road, Gomti Nagar, Lucknow, Uttar Pradesh – 226010.
                                    </p>
                                    <p>
                                        <b>User or You : </b> User or you means the natural person who accesses our stores, website or mobile application.
                                    </p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>2. WHAT DATA DO WE COLLECT ABOUT YOU</h5>
                                    <p>
                                        <b>SDPL</b> collects Data for various purposes set out in this Privacy Notice. This Data includes, without limitation, the following categories:
                                    </p>
                                    <ul>
                                        <li>
                                            <b>Contact Information:</b> First and last name, email address, postal address, country, employer, phone number and other similar contact data.
                                        </li>
                                        <li>
                                            <b>Financial Information:</b> Payment instrument information, transactions, transaction history, preferences, method, mode and manner of payment, spending pattern or trends, and other similar
                                            data.
                                        </li>

                                        <li>
                                            <b>Technical Information:</b> Website, device and mobile app usage, Internet Protocol (IP) address and similar information collected via automated means, such as cookies and similar technologies.
                                        </li>

                                        <li>
                                            <b>Transaction Information:</b> The date of the transaction, total amount, transaction history and preferences and related details.
                                        </li>

                                        <li>
                                            <b>Product and Service Information:</b> Your account membership number, registration and payment information, and program – specific information, when you request products and/or services directly
                                            from us, or participate in marketing programs. Your reviews, feedback and opinions about our products, programmes and services.
                                        </li>

                                        <li>
                                            <b>Personal Information:</b> Age, sex, date of birth, marital status, nationality, details of government identification documents provided, occupation, ethnicity, religion, travel history, or any
                                            other personal information provided in responses to surveys or questionnaires.
                                        </li>

                                        <li>
                                            <b>Payment Instrument Information:</b> Through our website and mobile applications, you may choose a payment service provider of your choice for making payment and may agree to store your payment
                                            instrument details with the chosen service provider. The payment instrument data is managed by the respective service provider.
                                        </li>
                                    </ul>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <h5>3. HOW WE COLLECT DATA</h5>
                                    <p>We collect Data in the following ways:</p>

                                    <ul>
                                        <li>
                                            <b>Information You Give Us:</b> We receive and store any information you enter on our website or mobile application or give us in any other way (e.g., at outlets, stores, hotels, kiosks). Please
                                            see the section titled “Data Shared by You” for more information.
                                        </li>

                                        <li>
                                            <b>Automatic Information We Collect:</b> We use cookies and similar technologies to receive and store certain types of information whenever you interact with us. Please see the section below,
                                            titled “Data that is Collected Automatically” for more information.
                                        </li>

                                        <li>
                                            <b>E-mail Communications:</b> To help us make e – mails more relevant and interesting, we often receive a confirmation (if your device supports such capabilities) when you open e – mail from us or
                                            click on a link in the e-mail. You can choose not to receive marketing emails from us by clicking on the unsubscribe link in any marketing email.
                                        </li>

                                        <li>
                                            <b>Automatic Information We Collect from Other Websites:</b> We receive and store certain types of information when you interact with third – party websites that use our technology or with whom we
                                            have a specific agreement. Because we process this information on behalf of the applicable website operators, collection, processing, and use of such information is subject to the applicable
                                            website operators’ privacy policies and is not covered by our Privacy Notice.
                                        </li>

                                        <li>
                                            <b>Information from Other Sources:</b> We may obtain information from other sources. An example of this is when you authorize a third – party website to interact directly with our website or
                                            mobile application to provide or receive Data about you. In that case, we might receive such Data used by that third – party website to identify your account with that website.
                                        </li>

                                        <li>
                                            <b>Information Previously Provided to our Partners:</b> Where you have shared any information previously with any of our Partners and have consented to the further sharing of such information,
                                            such information will be shared with us by our Partners.
                                        </li>
                                    </ul>

                                    <p>
                                        You can make choices about our collection and use of your Data. For example, you may want to access, edit or remove your Data on our website or mobile application. When you are asked to provide Data,
                                        you may decline, however, in such a case, you may not be able to avail benefit of some of our products, programmes or services or access our stores or physical premises.
                                    </p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>4. DATA SHARED BY YOU</h5>
                                    <p>
                                        <b>SDPL</b> may collect your Data in several ways from your use of our stores, website or mobile application. For instance:
                                    </p>

                                    <ul>
                                        <li>When you register with us to receive our products and/or services;</li>
                                        <li>When you conduct a transaction with us or attempt a transaction at our stores, on our website or mobile application;</li>
                                        <li>When you complete surveys conducted by or for us;</li>
                                        <li>When you elect to receive any communications (including promotional offers) from us;</li>
                                        <li>From the information gathered by your visit to our stores, website or mobile application; or</li>
                                        <li>By way of correlation or inferences from information sourced from sources, which have collected or processed this data based on your consent.</li>
                                    </ul>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>5. DATA THAT IS COLLECTED AUTOMATICALLY</h5>
                                    <p>We automatically collect some information when you visit our website or use our mobile application. This information helps us to make improvements to our content and navigation.</p>

                                    <p>
                                        The information collected automatically includes your IP address. Our web servers or affiliates who provide analytics and performance enhancement services collect IP addresses, operating system
                                        details, browsing details, device details and language settings. This information is aggregated to measure the number of visits, average time spent on the site, pages viewed and similar information.
                                        We may collect your Data automatically via Cookies and similar technologies in line with settings on your browser.
                                    </p>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <h5>6. OUR USE OF DATA</h5>
                                    <p>Any or all the above Data may be required by us from time to time to provide information relating to SDPL and to work on the experience when using our website or mobile application.</p>

                                    <p>Specifically, Data may be used by us for the following reasons:</p>
                                    <ul>
                                        <li>To carry out our obligations arising from any contract entered into between you and us;</li>
                                        <li>To provide products and/or services and communicate with you about products and/or services offered by us;</li>
                                        <li>To enable payment, billing and invoicing related purposes;</li>
                                        <li>To enable Service Providers and Partners to offer their products and/or services and communicate with you about such products and/or services;</li>
                                        <li>
                                            For processing, disclosing, transmitting, and/or sharing the Data with other third parties which have business or contractual dealings with us for any legitimate purpose/use and in terms of this
                                            Privacy Notice;
                                        </li>
                                        <li>To provide you with offers, personalized services and recommendations and improve your experience on our website and mobile application;</li>
                                        <li>To operate, evaluate and improve our business website and mobile application;</li>
                                        <li>
                                            To generate aggregated data to prepare insights to enable us to understand customer behaviour, patterns and trends with a view to learning more about your preferences or other characteristics;
                                        </li>
                                        <li>To provide privileges and benefits to you, marketing and promotional campaigns based on your profile;</li>
                                        <li>To enforce the terms of use of our website and mobile application;</li>
                                        <li>To protect against and prevent fraud, illegal activity, harm, financial loss and other legal or information security risks; and</li>
                                        <li>To serve other purposes for which we provide specific notice at the time of collection, and as otherwise authorized or required by applicable law.</li>
                                    </ul>

                                    <p>
                                        Where required under applicable law, we will only use your personal information with your consent; as necessary to provide you with products and/or services; or for such other purpose as is
                                        permissible under applicable law
                                    </p>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <h5>7. MINORS</h5>
                                    <p>
                                        Our website and mobile application do not offer products or services for use by minors. If you are under 18, you may use our website or mobile application only with the consent of a parent or your
                                        guardian. In the event that we learn that we have collected personal information from a minor without verification of parental consent, we will delete that information at the earliest. If you believe
                                        that we might have any such information, please contact the Grievance Officer.
                                    </p>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <h5>8. SHARING OF DATA</h5>

                                    <p>We may share your Data with/ for:</p>
                                    <ul>
                                        <li>
                                            <b>Partners :</b> We may make available to you services, products, or applications provided by Partners for use on or through our website or mobile application. If you choose to use such service,
                                            customer information related to those transactions may be shared with such Partner. Such Partners will be required to respect the security of your Data and to treat it in accordance with this
                                            Privacy Notice and applicable law.
                                        </li>
                                        <li>
                                            <b>Service Providers :</b> We may share your Data with Service Providers. Examples include storing and analysing Data, protecting and securing our systems, providing search results and links,
                                            providing customer service, credit analysis, processing your information for profiling, user analysis and payment processing. These Service Providers will be required to only process Data in
                                            accordance with express instructions and as necessary to perform services for purposes set forth in this Privacy Notice. The Service Providers will also be required to safeguard the security and
                                            confidentiality of the Data Confidential they process by implementing appropriate technical and organizational security measures and confidentiality obligations binding employees accessing Data.
                                        </li>
                                        <li>
                                            <b>Protecting SDPL : </b>We may release Data when we believe release is appropriate to comply with applicable law or legal process, enforce or apply the Terms of Use of our website or mobile
                                            application and other agreements, protect SDPL against harm or financial loss, when we believe disclosure is necessary to protect individuals’ vital interests, or in connection with an
                                            investigation of suspected or actual fraudulent or illegal activity. This may include exchanging information with other companies and organizations for fraud protection, risk management and
                                            dispute resolution. This does not include selling or otherwise disclosing personally identifiable information from users for commercial purposes in violation of this Privacy Notice.
                                        </li>
                                        <li>
                                            <b>Business Transfers : </b> As we continue to develop our business, we might sell or buy subsidiaries or business units. Your Data may be transferred as part of such transaction or in the course
                                            of exploratory activities related thereto, including due diligence and audit exercises. The relevant transferee may thereafter make use of Data in accordance with then applicable Law.
                                        </li>
                                        <li>
                                            <b>Third Parties : </b> We may also share your Data with other third parties where:
                                            <ul>
                                                <li>You request or authorize us to do so;</li>
                                                <li>We need to comply with applicable law or respond to valid legal process; or</li>
                                                <li>We need to operate and maintain the security of our website or mobile application, including to prevent or stop an attack on our computer systems or networks.</li>
                                                <li>
                                                    We require these third parties by contract to only process sensitive personal data in accordance with our instructions and as necessary to perform services on our behalf or in compliance
                                                    with applicable law. We also require them to safeguard the security and confidentiality of the sensitive personal data they process on our behalf by implementing appropriate
                                                    confidentiality, technical and organizational security measures.
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>9. KEEPING DATA SECURE</h5>
                                    <p>
                                        We will use technical and organisational measures to safeguard your Data and we store your Data on secure servers. Technical and organisational measures include measures to deal with any suspected
                                        data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us by e – mail.
                                    </p>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <h5>10. RETENTION OF DATA</h5>
                                    <p>
                                        SDPL retains Data for as long as necessary for the use of our products and/or services or to provide access to and use of our website or mobile application, or for reasonable purposes such as
                                        complying with our legal obligations, resolving disputes, enforcing our agreements or as otherwise stated in this Privacy Notice, and for such periods or purposes as are permitted under applicable
                                        Confidential law,. Because these needs can vary for different data types and purposes, actual retention periods can vary significantly. Even if we delete your Data, including on account of exercise of
                                        your right under Clause 11 below, it may, subject to applicable law, persist on backup or archival media for audit, legal, tax or regulatory purposes.
                                    </p>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <h5>11. YOUR RIGHTS AND CHOICES</h5>
                                    <p>
                                        When we process Data about you, we do so with your consent and/or as necessary to operate our business, meet our contractual and legal obligations, protect the security of our systems and our
                                        customers, or fulfil other legitimate interests of SDPL as described in this Privacy Notice.
                                    </p>

                                    <p>
                                        We may transfer Data we collect about you to recipients in India, where we are headquartered. India may not have the same Data Protection Laws as the country in which you initially provided the
                                        information. When we transfer your Data to India, we will protect that information as described in this Privacy Notice, as disclosed to you at the time of data collection.
                                    </p>
                                    <p>Depending on which Data Protection Laws are applicable to you, you may have the right or choice to:</p>

                                    <ul>
                                        <li>Opt out of some collection or uses of your Data, including the use of cookies and similar technologies and the use of your Data for marketing purposes.</li>
                                        <li>Change or edit information submitted to us.</li>
                                        <li>Receive the Data you provided to us to transmit it to another company.</li>
                                        <li>Withdraw any consent provided or alter your preferences.</li>
                                        <li>Where applicable, lodge a complaint with your supervisory authority. You may submit a request as described in the “GRIEVANCE REDRESSAL” section below. We will not charge you for any request.</li>
                                    </ul>
                                    <p>Data Protection Laws, depending on your country, may include the following rights in relation to your Data:</p>

                                    <ul>
                                        <li>
                                            {" "}
                                            <b>Right to Confirmation and Access:</b> The right to confirm our methods of processing and request copies of the information we hold about you at any time, or that we modify, update or delete
                                            such information.
                                        </li>
                                        <li>
                                            <b> Right to Correction:</b> The right to have your Data rectified if it is inaccurate or incomplete.
                                        </li>
                                        <li>
                                            <b>Right to be Forgotten:</b> The right to request that we delete or remove your Data from our systems.
                                        </li>
                                        <li>
                                            <b>Right to Restrict / Object to Our Use of your Data:</b> The right to limit the way in which we can use it.
                                        </li>
                                        <li>
                                            <b>Right to Data Portability:</b> The right to request that we move, copy or transfer your Data.
                                        </li>
                                        <li>
                                            <b>Right to Withdraw Consent:</b> The right to withdraw your consent provided earlier.
                                        </li>
                                        <li>
                                            <b>Right to File Complaints:</b> The right to raise complaints to a regulatory authority.
                                        </li>
                                    </ul>
                                    <p>It is important that the Data we hold about you is accurate and current. Please keep us informed if your personal information changes during the period for which we hold it.</p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>12. WHERE WE STORE DATA</h5>
                                    <p>
                                        Data collected under this Privacy Notice is hosted on servers located in India. Subject to applicable law, we may at our sole discretion, transfer Data, including SPDI to any other third party that
                                        agrees to ensure the same level of data protection as is provided by us under the terms hereof, located outside India. You hereby consent to the storage and processing of Data, including SPDI in
                                        locations outside India.
                                    </p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>13. PROCESSING YOUR DATA</h5>
                                    <p>We take steps to ensure that the Data we collect under this Privacy Notice is processed according to the provisions of this Privacy Notice and the requirements of applicable law.</p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>14. SECURITY</h5>
                                    <p>
                                        SDPL implements standard measures to protect against unauthorized access to and unlawful interception of Data. However, no internet site can fully eliminate security risks. SDPL endeavours to take all
                                        measures to protect the security, integrity and confidentiality of the Data against unauthorized breach and hacking. For the purpose of checking possible vulnerabilities and attacks, SDPL may conduct
                                        periodical internal review of data and security measures. However, the internet is not absolutely a secure environment, and we cannot ensure or warrant 100% security.
                                    </p>

                                    <p>
                                        If you are provided with any identification code, password or any other piece of information as part of the security procedure adopted by us, you should treat such information as confidential and not
                                        disclose it to any third party including to other users. You are solely responsible for the activities that occur under your account including the confidentiality of your password and SDPL is not
                                        responsible for the same.
                                    </p>

                                    <p>
                                        Not with standing anything contained in this Privacy Notice or elsewhere, SDPL shall not be held responsible for any loss, damage or misuse of the Data, if such loss, damage or misuse is attributable
                                        to a Force Majeure Event. A “Force Majeure Event” means any event that is beyond the reasonable control of SDPL and includes, without limitation, fire, flood, explosion, acts of God, civil commotion,
                                        strikes, lock outs or industrial action of any kind, riots, insurrection, war, acts of government, power failure, sabotage, computer hacking, unauthorised access to computer data and storage device,
                                        system failure, virus, attacks, bugs, computer crashes, breach of security and encryption.
                                    </p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>15. SEVERABILITY</h5>
                                    <p>
                                        If any court or competent authority finds that any provision of this Privacy Notice or part of any provision is invalid, illegal or unenforceable, that provision or part – provision will, to the
                                        extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Privacy Notice will not be affected.
                                    </p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>16. CHANGES TO THIS PRIVACY NOTICE</h5>
                                    <p>
                                        SDPL reserves the right to change this Privacy Notice from time to time. Any changes shall be effective immediately upon the posting of the revised Privacy Notice on our Website and Mobile Application
                                        and you are deemed to have accepted the terms of the updated Privacy Notice on your first use of our website or mobile application or first purchase of the products and/or services following the
                                        changes to this privacy notice. We encourage you to periodically review this page for the latest information on our privacy practices
                                    </p>
                                </div>
                                <div className="terms-and-conditions-wrap">
                                    <h5>17. GRIEVANCE REDRESSAL</h5>
                                    <p>
                                        If you wish to review, update, or delete your personal information you have provided, you may do so online, on the website itself. Alternatively, you may contact the Company to correct or update such
                                        information by submitting a request to us at <a href="mailto:support@hellofarmers.co.in">support@hellofarmers.co.in</a>
                                    </p>

                                    <p>
                                        You may contact us for information on Service Providers and Partners with whom we may share your Data in compliance with this Privacy Notice and applicable law. We will respond to your request within
                                        30 days. In the event of loss of access to the website, you may contact the Company by writing to us at <a href="mailto:support@hellofarmers.co.in">support@hellofarmers.co.in</a>
                                    </p>

                                    <p>In the event that you have a grievance regarding our Privacy Notice or data usage practices, you may reach out to our Grievance Redressal Officer at the details below:</p>
                                </div>

                                <div className="terms-and-conditions-wrap">
                                    <h5>Grievance Redressal Officer: Praveen Singh</h5>
                                    <p>For information about management of your Data please reach out to us at:</p>
                                    <p>
                                        If you have any questions about this Policy or our privacy practices, please contact us.
                                        <ul>
                                            <li class="mb-2">
                                                <div class="content">
                                                    <strong>Address :</strong> 3/553, Vivek Khand, Gomti Nagar(Lucknow)-226010
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="content">
                                                    <strong>Email :</strong> <a href="mailto:support@hellofarmers.co.in">support@hellofarmers.co.in</a>
                                                </div>
                                            </li>
                                            <li class="mb-2">
                                                <div class="content">
                                                    <strong>Phone :</strong> <a href="tel:9889009622">+91 9889009622</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <FooterLast />
        </>
    );
};

export default Privacy;
