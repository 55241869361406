import { React, useEffect, useState } from "react";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { getCookie } from "../../Services/Cookies";
import { DATACONSTANT } from "../../Services/DataConstant";

const Dashboard = () => {
  const [dashB, setDashB] = useState([]);
  const [storedRole, setRole] = useState();

  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role);
    dashboardSummary();
  }, []);

  const dashboardSummary = async () => {
    const auth = getCookie(DATACONSTANT.COOKIE_NAME);
    if (!auth) {
      return;
    }
    const res = await postRequest(`api/Data/DashboardSummary`);
    if (res.result) {
      setDashB(res.result);
    }
  };

  return (
    <>
      <div id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <i className="fa fa-home"></i>
                        </li>

                        <li className="breadcrumb-item active">Dashboard</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section dashboard">
          <div className="row">
            {storedRole === "Admin" ? (
              <>
                {" "}
                <div className="col-md-4 mt-3">
                  <NavLink
                    to="/Package"
                    className="card info-card sales-card nav-link-no-underline"
                  >
                    <div className="card-body">
                      <h5 className="card-title">Package</h5>

                      <div className="d-flex align-items-center">
                        <div
                          className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                          style={{ backgroundColor: "#aa3042" }}
                        >
                          <i className=" bi-basket-fill"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{dashB?.totalPackage}</h6>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="col-md-4 mt-3">
                  <NavLink
                    to="/Category"
                    className="card info-card sales-card nav-link-no-underline"
                  >
                    <div className="card-body ">
                      <h5 className="card-title">Category</h5>

                      <div className="d-flex align-items-center">
                        <div
                          className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                          style={{ backgroundColor: "#dd2fbc" }}
                        >
                          <i className="bi bi-list"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{dashB?.totalCategory}</h6>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="col-md-4 mt-3">
                  <NavLink
                    to="/Product"
                    className="card info-card sales-card nav-link-no-underline"
                  >
                    <div className="card-body">
                      <h5 className="card-title">Product</h5>

                      <div className="d-flex align-items-center">
                        <div
                          className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                          style={{ backgroundColor: "rgb(53 239 46)" }}
                        >
                          <i className="bi-basket3-fill"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{dashB?.totalProduct}</h6>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </>
            ) : null}
            <div className="col-md-4 mt-3">
              <NavLink
                to={{
                  pathname: "/GetOrder",
                }}
                state={{ fromDashboard: true }}
                className="card info-card sales-card nav-link-no-underline"
              >
                <div className="card-body">
                  <h5 className="card-title">
                    New Order<span> | Today</span>
                  </h5>

                  <div className="d-flex align-items-center">
                    <div
                      className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#e5bb33" }}
                    >
                      <i className="fa fa-cart-plus"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{dashB?.newOrder}</h6>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="col-md-4 mt-3">
              <NavLink
                to={{ pathname: "/RecurringOrder" }}
                state={{ fromDashboardRecurring: true }}
                className="card info-card sales-card nav-link-no-underline"
              >
                <div className="card-body">
                  <h5 className="card-title">
                    Recurring Order <span>| Today</span>
                  </h5>

                  <div className="d-flex align-items-center">
                    <div
                      className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#1abee5" }}
                    >
                      <i className="bi bi-cart"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{dashB?.recurringOrder}</h6>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="col-md-4 mt-3">
              <NavLink
                to="/ScheduleOnOrder"
                className="card info-card sales-card nav-link-no-underline"
              >
                <div className="card-body">
                  <h5 className="card-title">
                    Schedule Order <span>| Today</span>
                  </h5>

                  <div className="d-flex align-items-center">
                    <div
                      className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#1abee5" }}
                    >
                      <i className="bi bi-cart"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{dashB?.scheduledOrder}</h6>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="col-md-4 mt-3">
              <NavLink
                to="/User"
                className="card info-card sales-card nav-link-no-underline"
              >
                <div className="card-body">
                  <h5 className="card-title">
                    New Users
                    <span> | Today</span>
                  </h5>

                  <div className="d-flex align-items-center">
                    <div
                      className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                      style={{ backgroundColor: "#e04f5f" }}
                    >
                      <i className="bi bi-people"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{dashB?.newUser}</h6>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            {storedRole === "Admin" ? (
              <div className="col-md-4 mt-3">
                <NavLink
                  to="/PGReport"
                  className="card info-card sales-card nav-link-no-underline"
                >
                  <div className="card-body">
                    <h5 className="card-title">
                      PG Report |<span>Count</span>
                      <span> | Amount</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div
                        className="card-icon rounded-circle d-flex align-items-center justify-content-center"
                        style={{ backgroundColor: "#e04f5f" }}
                      >
                        <i className="fa fa-file"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {dashB?.pgSuccessTxnCount}
                          <span> |{dashB?.pgSuccessTxnAmount} </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
