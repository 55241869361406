import { React, useState } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { postRequest } from "../../Services/ApiServices";
import { DATACONSTANT } from "../../Services/DataConstant";

const BannerService = () => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState({
    imagePath: "",
  });
  const navigate = useNavigate();
  const params = useParams();
  const [submitloader, setSubmitLoader] = useState(false);
  const [image, setImage] = useState();
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [getId, setGetId] = useState();

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,

      width: "60px",
    },
    {
      name: "EDIT",
      selector: (row) => row.edit,
      width: "80px",
    },

    {
      name: "TITLE",
      selector: (row) => row.title,
      width: "250px",
    },
    {
      name: "BANNER IMAGE",
      selector: (row) => row.imagePath,
      width: "150px",
    },
    {
      name: "BANNER TYPE",
      selector: (row) => row.bannerType,
      width: "150px",
    },

    {
      name: "BACKLINK",
      selector: (row) => row.backlink,
      width: "280px",
    },
    {
      name: "ENTRYON",
      selector: (row) => row.entryOn,
      width: "120px",
    },
  ];

  const data = banner?.map((d, i) => ({
    serial: i + 1,
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditBanner/" + d.id,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span onClick={() => handlepopup(d?.id)} style={{ cursor: "pointer" }}>
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    title: (
      <>
        {d.title} <small className="d-block">[ID:{d?.id}]</small>
      </>
    ),
    bannerType: <> {DATACONSTANT.BANNER_TYPE[d?.bannerType]}</>,
    imagePath: (
      <img
        className="card-img-top w-10"
        src={d?.imagePath?.split(",")[0]}
        onError={(e) => {
          e.target.src = "/assets/img/noimage.jpg";
          e.target.onerror = null;
        }}
        style={{ height: "40px", width: "40px" }}
      />
    ),
    backlink: (
      <>
        <a href={d?.backlink} target="_blank" rel="noopener noreferrer">
          {d?.backlink}
        </a>
      </>
    ),
    entryOn: d?.entryOn,
  }));

  ///////****** GET BANNER LIST ******////////

  const bannerProfile = async (id) => {
    try {
      const res = await postRequest(`api/Banner/Get`, {
        bannerType: id || "",
      });
      if (res.result) {
        setBanner(res.result);
        setLoading(false);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleBannerList = (event) => {
    bannerProfile(event.target.value);
  };

  ///////****** GET BANNER LIST ******////////

  ///////****** EDIT AND ADD BANNER LIST ******////////

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["imagePath"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    try {
      setSubmitLoader(true);
      let res = {};
      let formData = new FormData();
      let __d = {
        ...input,
      };
      formData.append("Banner", image?.value);
      for (let key in __d) {
        formData.append(key, __d[key]);
      }
      res = await postRequest(
        `api/Banner/Add`,
        formData,
        "multipart/form-data"
      );
      if (!res.statusCode == 1) {
        toast.error(res.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/Banner", { replace: true });
        }
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.log("Error", error);
      toast.error("Something went wrong!!!");
    }
  };

  ///////****** EDIT AND ADD BANNER LIST ******////////

  ///////****** VALIDATING THE BANNER ******////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.title) {
      newErrors.title = "Please enter the banner title.";
    }
    if (!input?.backlink) {
      newErrors.backlink = "Please enter the backlink.";
    }
    if (!input?.bannerType || input?.skuGroup === "N/A") {
      newErrors.bannerType = "Banner Type is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  ///////****** VALIDATING THE BANNER ******////////

  ///////******* BANNER LIST GET BY ID *****//////////

  const getBannerList = async () => {
    try {
      const res = await postRequest(`api/Banner/GetById?id=${params?.id}`);
      if (res.result) {
        if (res.result.hasOwnProperty("imagePath")) {
          res.result.imagePath = res?.result?.imagePath;
        }
        setInput(res.result);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error fetching banner list:", error);
    }
  };
  //////******* BANNER LIST GET BY ID *****//////////

  //////******* DELETE BANNER LIST *****//////////

  const handlepopup = async (bId) => {
    setShowModal(true);
    setGetId(bId);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/Banner/Delete?id=${getId}`);
      if (res) {
        bannerProfile();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //////******* DELETE BANNER LIST *****//////////
  return {
    bannerProfile,
    loading,
    data,
    handleSubmit,
    getBannerList,
    handleImage,
    handleChange,
    submitloader,
    input,
    columns,
    errors,
    showModal,
    handleClose,
    handleDelete,
    banner,
    handleBannerList,
  };
};

export default BannerService;
