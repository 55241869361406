import DealsofDayServices from "./DealsofDayServices";
import { React, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";

const GetDealsOfDay = () => {
  const {
    loading,
    columns,
    data,
    listDealsOfDays,
    showsModal,
    handleClose,
    handleDelete,
  } = DealsofDayServices();

  useEffect(() => {
    listDealsOfDays();
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row ">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Deals Of Days</li>

                      <li className="breadcrumb-item active">
                        Deals Of Days List
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-6 col-sm-12 justify-content-end d-flex align-items-center">
                    <NavLink to="/EditDealsofDay" className="btn btn-sm mr-3">
                      <i className="fa fa-plus-square"></i>&nbsp;ADD
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pak-height card">
        <div className="card-body p-2">
          <div className="table-responsive ">
            {loading ? (
              <div className="spinner-container">
                <Spinner type="TailSpin" color="#00BFFF" />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                pagination
                className="rdt_TableHeadRow"
                fixedHeader
                fixedHeaderScrollHeight="calc(100vh - 188px)"
              />
            )}
          </div>
        </div>
      </div>
      <Modal show={showsModal} size="sm" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="mt-3">
            <i
              className="bi bi-exclamation-circle-fill"
              style={{ color: "#f1c40f" }}
            ></i>
            &nbsp;Confirmation
          </Modal.Title>
          <span
            aria-hidden="true"
            onClick={handleClose}
            className="modal-cross"
          >
            &times;
          </span>
        </Modal.Header>
        <Modal.Body className="pt-0">
          Aye you sure to delete the deal?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            No
          </Button>
          <Button variant="success" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default GetDealsOfDay;
