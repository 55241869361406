import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import TopMsg from "../../Component/Homes/TopMsg";
import Navigation from "./Navigation";

const HeaderTop = () => {
  const [dateTime, setDateTime] = useState("");
  const handleSideBar = (isRemove) => {
    if (isRemove) {
      const body = document.body;
      body.classList.remove("mobile-menu-visible");
    } else {
      const body = document.body;
      body.classList.add("mobile-menu-visible");
    }
  };

  const updateTime = () => {
    const now = new Date();

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    const today = now.toLocaleDateString("en-US", options);

    setDateTime(today);
  };

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <header>
      <TopMsg />

      <div className="header-top-wrap">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="header-top-left">
                <ul>
                  <li className="header-work-time">
                    <i className="fa fa-clock"></i>{" "}
                    <span id="dateDiv">{dateTime}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-5">
              <div className="header-top-right">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61550659974825&mibextid=LQQJ4d"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/hello_farmers_?igshid=MzRlODBiNWFlZA=="
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/skvipm-digisolutions-pvt-ltd/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- header-top-end --> */}
      {/* <!-- header-search-area --> */}
      <div className="header-search-area">
        <div className="container custom-container">
          <div className="row align-items-center">
            <div className="col-xl-2 col-lg-3 d-none d-lg-block">
              <div className="logo">
                <a href="/">
                  <img
                    src="siteadmin/img/logo/hello-farmers-logo.svg"
                    alt="Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-10 col-lg-9">
              <div className="d-block d-sm-flex align-items-center justify-content-end">
                <div className="header-search-wrap mob-off">
                  <form action="#">
                    <input type="text" placeholder="Search Product..." />
                    <button>
                      <i className="flaticon-loupe-1"></i>
                    </button>
                  </form>
                </div>
                <div className="header-action">
                  <ul>
                    <li className="header-phone">
                      <div className="icon">
                        <i className="flaticon-telephone"></i>
                      </div>
                      <a href="tel:+919889009622">
                        <span>Call Us Now</span>+91 988 9009 622
                      </a>
                    </li>
                    <li className="header-user mob-off">
                      <button
                        class="dropdown-toggle bg-gr"
                        type="button"
                        id="dropdownMenuButton4"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="flaticon-shop"></i> App Store
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton4"
                      >
                        <a
                          class="dropdown-item"
                          href="https://play.google.com/store/apps/details?id=com.solution.hello.farmers"
                          target="_blank"
                        >
                          <i className="fab fa-android"></i>&nbsp; Android App
                        </a>
                        <a
                          class="dropdown-item"
                          href="https://apps.apple.com/in/app/hello-farmers/id6465133530"
                          target="_blank"
                        >
                          <i className="fab fa-apple"></i>&nbsp; IOS App
                        </a>
                      </div>
                    </li>
                    <li className="header-user mob-on">
                      <a href="/login" target="_blank">
                        <i className="fa fa-sign-in"></i>&nbsp; Login
                      </a>
                    </li>
                    {/* <!--<li className="header-cart-action">
                                      <div className="header-cart-wrap">
                                          <a href="cart.html"><i className="flaticon-shopping-basket"></i></a>
                                          <span className="item-count">2</span>
                                          <ul className="minicart">
                                              <li className="d-flex align-items-start">
                                                  <div className="cart-img">
                                                      <a href="#"><img src="siteadmin/img/product/cart_p01.jpg" alt="Hello Farmers"></a>
                                                  </div>
                                                  <div className="cart-content">
                                                      <h4><a href="#">Organic Farm Fresh Nuts</a></h4>
                                                      <div className="cart-price">
                                                          <span className="new">₹ 229.9</span>
                                                          <span><del>₹ 229.9</del></span>
                                                      </div>
                                                  </div>
                                                  <div className="del-icon">
                                                      <a href="#"><i className="far fa-trash-alt"></i></a>
                                                  </div>
                                              </li>
                                              <li className="d-flex align-items-start">
                                                  <div className="cart-img">
                                                      <a href="#"><img src="siteadmin/img/product/cart_p02.jpg" alt="Hello Farmers"></a>
                                                  </div>
                                                  <div className="cart-content">
                                                      <h4><a href="#">Organic Fresh Nuts Vanla Butter</a></h4>
                                                      <div className="cart-price">
                                                          <span className="new">₹ 229.9</span>
                                                          <span><del>₹ 229.9</del></span>
                                                      </div>
                                                  </div>
                                                  <div className="del-icon">
                                                      <a href="#"><i className="far fa-trash-alt"></i></a>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div className="total-price">
                                                      <span className="f-left">Total:</span>
                                                      <span className="f-right">₹ 239.9</span>
                                                  </div>
                                              </li>
                                              <li>
                                                  <div className="checkout-link">
                                                      <a href="cart.html">Shopping Cart</a>
                                                      <a className="black-color" href="#">Checkout</a>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                      <div className="cart-amount">₹ 0.00</div>
                                  </li>--> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- header-search-area-end --> */}

      <div id="sticky-header" className="menu-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div
                className="mobile-nav-toggler"
                onClick={() => handleSideBar(false)}
              >
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo d-block d-lg-none">
                    <a href="/">
                      <img
                        src="siteadmin/img/logo/hello-farmers-logo.svg"
                        alt="Hello Farmers"
                      />
                    </a>
                  </div>
                  {/* <!--<div className="header-category d-none d-lg-block">
                                  <a href="#" className="cat-toggle"><i className="fas fa-bars"></i>ALL CATEGORIES<i className="fas fa-angle-down"></i></a>
                                  <ul className="category-menu">

                                      <li><a href="#"><i className="flaticon-broccoli"></i> Vegetable</a></li>
                                      <li><a href="#"><i className="flaticon-cherry"></i> Fruits</a></li>
                                      <li className="menu-item-has-children">
                                          <a href="#"><i className="flaticon-groceries"></i> Groceries</a>
                                          <ul className="megamenu">
                                              <li className="sub-column-item">
                                                  <a href="#">Grocery </a>
                                                  <ul>
                                                      <li><a href="#">Ghee & Oils</a></li>
                                                      <li><a href="#">Rice & Flours</a></li>
                                                      <li><a href="#">Pulses</a></li>
                                                      <li><a href="#">Salt & Sugar</a></li>
                                                      <li><a href="#">Pickles</a></li>
                                                      <li><a href="#">Spices</a></li>
                                                  </ul>
                                              </li>


                                          </ul>
                                      </li>
                                      <li><a href="#"><i className="flaticon-cupcake"></i> Dairy & Bakery</a></li>
                                      <li><a href="#"><i className="flaticon-nut"></i> Dry Fruits</a></li>
                                      <li><a href="#"><i className="flaticon-groceries-1"></i> Snacks</a></li>
                                      <li><a href="#"><i className="flaticon-pop-corn"></i> Sweets</a></li>
                                      <li><a href="#"><i className="flaticon-pop-corn-1"></i> Tea Coffee</a></li>



                                  </ul>
                              </div>--> */}
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <Navigation />
                  </div>

                  <div className="header-super-store d-none d-xl-block d-lg-none d-md-block">
                    <div>
                      <a className="dropdown-toggle" href="/login">
                        <i className="fa fa-sign-in"></i> Login{" "}
                      </a>
                    </div>
                  </div>
                </nav>
              </div>

              <div className="mobile-menu">
                <nav className="menu-box">
                  <div
                    className="close-btn"
                    onClick={() => handleSideBar(true)}
                  >
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <a href="/">
                      <img
                        src="siteadmin/img/logo/hello-farmers-logo.svg"
                        alt="Hello Farmers"
                        title=""
                      />
                    </a>
                  </div>
                  <div className="menu-outer">
                    <Navigation />
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=61550659974825&mibextid=LQQJ4d"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/hello_farmers_?igshid=MzRlODBiNWFlZA=="
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/skvipm-digisolutions-pvt-ltd/"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div
                className="menu-backdrop"
                onClick={() => handleSideBar(true)}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderTop;
