import React from 'react';
import Slider from 'react-slick';



const CategoryList = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className=" category-active">
      <Slider {...settings}>
        {/* Your slide content goes here */}
        
               
                    <div className="category-item active">
                        
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Fruits.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Fruits</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Vegetables.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Vegetables</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Dry_Fruits.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Dry Fruits</h6>
                        </div>
                    </div>
                
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Rice.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Rice & Flours</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Pulses.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Pulses</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Ghee.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Ghee & Oils</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Salt.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Salt & Sugar</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Pickles.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Pickles</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Spices.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Spices</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Snacks.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Snacks</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Sweets.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Sweets</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Bakery.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Dairy & Bakery</h6>
                        </div>
                    </div>
                
                    <div className="category-item">
                    <div className='div-over'>
                        <p>
                        Coming Soon
                        </p>
                        </div>
                        <a href="#" className="category-link"></a>
                        <div className="category-thumb">
                            <img src="siteadmin/img/product/category_Tea.webp" alt="Hello Farmers" />
                        </div>
                        <div className="category-content">
                            <h6 className="title">Tea & Coffee</h6>
                        </div>
                    </div>
                
            
        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
};

export default CategoryList;
