import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import GetOrderService from "./GetOrderService";
import { NavLink } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const ScheduleOnOrder = () => {
  const {
    submitloader,
    fromDateNew,
    toDateNew,
    handleChangedatesNew,
    handleChangedateNew,
    loading,
    setSubmitLoader,
    scheduledOncolumns,
    scheduledOndata,
    handleSubmitScheduleOn,
    handleMarkDelivered,
    handleCloseForMark,
    showModalForMark,
    handleDownloadExcelforScheduled,
  } = GetOrderService();

  useEffect(() => {
    handleSubmitScheduleOn();
  }, []);
  return (
    <div>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Order</li>

                        <li className="breadcrumb-item active">
                          ScheduleOn Order
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <div className="form-container">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      From Date:
                      <DatePicker
                        id="fromDate"
                        name="fromDate"
                        selected={fromDateNew}
                        onChange={handleChangedatesNew}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      To Date:
                      <DatePicker
                        id="toDate"
                        name="toDate"
                        selected={toDateNew}
                        onChange={handleChangedateNew}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>

                    <div className="col-lg-3 col-md-6 d-flex align-items-end">
                      {!submitloader ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-green mr-2"
                            id="btnSubmit"
                            onClick={(e) => {
                              handleSubmitScheduleOn(e);
                              setSubmitLoader(true);
                            }}
                          >
                            Search
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="btn btn-green">
                            Searching...
                          </button>
                        </>
                      )}
                      <div>
                        <>
                          <button
                            type="button"
                            className="btn btn-green btn-md"
                            onClick={handleDownloadExcelforScheduled}
                            style={{ padding: "11px" }}
                          >
                            <i className="bi bi-file-earmark-excel"></i>
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body p-2">
              <div className="table-responsive">
                {loading ? (
                  <div className="spinner-container">
                    <Spinner type="TailSpin" color="#00BFFF" />
                  </div>
                ) : (
                  <DataTable
                    columns={scheduledOncolumns}
                    data={scheduledOndata}
                    pagination
                    className="rdt_TableHeadRow"
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 205px)"
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        <Modal show={showModalForMark} size="sm" onHide={handleCloseForMark}>
          <Modal.Header>
            <Modal.Title className="mt-3">Confirmation</Modal.Title>
            <span
              aria-hidden="true"
              onClick={handleCloseForMark}
              className="modal-cross"
            >
              &times;
            </span>
          </Modal.Header>
          <Modal.Body className="pt-0">
            Aye you sure to mark delivered?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleMarkDelivered}>
              Confirm
            </Button>
            <Button variant="danger" onClick={handleCloseForMark}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
};

export default ScheduleOnOrder;
