import { React, useRef, useState } from "react";
import { postRequest, getRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { DATACONSTANT } from "../../Services/DataConstant";
import { useParams, useNavigate } from "react-router-dom";

const ProductService = () => {
  const [product, setProduct] = useState([]);
  const baseUrl = DATACONSTANT.BASE_URL;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [seasonList, setSeasonList] = useState([]);
  const editor = useRef(null);
  const moduleBase = "api/Product";
  const [input, setInput] = useState({
    unit: 0,
    imageData: "",
    isVeg: true,
    isPublished: true,
    isSeasonalFood: true,
    isCultivatedDateShow: true,
    isShowOnHome: true,
  });
  const [image, setImage] = useState();
  const [unit, setUnit] = useState([{ id: 0, name: ":: Select ::" }]);
  const [submitloader, setSubmitLoader] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [checkCultivated, setCheckCultivated] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [getId, setGetId] = useState();
  const [couponData, setCouponData] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [categoryIdSave, setCategoryTdSave] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const titleRef = useRef(null);
  const nameRef = useRef(null);
  const mrpRef = useRef(null);
  const smrpRef = useRef(null);

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "SELECT",
      selector: (row) => row.select,
      width: "80px",
    },
    {
      name: "EDIT",
      selector: (row) => row.edit,
      width: "80px",
    },
    {
      name: "IMAGE",
      selector: (row) => row.image,
      width: "120px",
    },
    {
      name: "TITLE",
      selector: (row) => row.productTitle,
      width: "250px",
    },
    {
      name: "NAME",
      selector: (row) => row.productName,
      width: "300px",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.productDescription,
      width: "200px",
    },
    {
      name: "MRP",
      selector: (row) => row.mrp,
      width: "150px",
    },
    {
      name: "SELL PRICE",
      selector: (row) => row.sellingPrice,
      width: "150px",
    },

    {
      name: "PACKAGING",
      selector: (row) => row.packaging,
      width: "120px",
    },

    {
      name: "PUBLISH",
      selector: (row) => row.isPublished,
      width: "100px",
    },
    {
      name: "VEG",
      selector: (row) => row.isVeg,
      width: "100px",
    },
    {
      name: "SHOW ON HOME",
      selector: (row) => row.isShowOnHome,
      width: "170px",
    },
    {
      name: "CULTIVATED",
      selector: (row) => row.isCultivatedDateShow,
      width: "130px",
    },
    {
      name: "SEASONAL FOOD",
      selector: (row) => row.isSeasonalFood,
      width: "150px",
    },
  ];

  const onPriceChange = (e, index, field) => {
    const newValue = e.target.value;
    const newData = product.map((d, i) =>
      i === index ? { ...d, [field]: newValue } : d
    );
    setProduct(newData);
  };

  const handleFormChange = async (e, index, field, Id) => {
    const requestBody = {
      productId: Id,
      mrp: product[index].mrp,
      sellingPrice: product[index].sellingPrice,
    };

    try {
      const res = await postRequest(
        `api/Product/UpdateMRPSellingPrice`,
        requestBody
      );
      if (res.statusCode === 1) {
        toast.success(res.responseText);
        setLoading(false);
      } else {
        toast.error(res.responseText);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const data = product?.map((d, i) => ({
    serial: i + 1,
    select: (
      <>
        <input
          type="checkbox"
          checked={checkCultivated?.includes(d?.productId)}
          onChange={(e) => handleCultivation(e, d?.productId)}
        />
      </>
    ),
    edit: (
      <>
        <NavLink
          onClick={() => setCategoryTdSave(d?.categoryId)}
          to={{
            // pathname: "/EditProduct/" + d?.productId,
            pathname: `/EditProduct/${d?.productId}/${d?.categoryId}`,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span
          onClick={() => handlepopup(d?.productId)}
          style={{ cursor: "pointer" }}
        >
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    image: (
      <div className="product-coupon-div">
        {d?.couponCodes ? (
          <span title={d?.couponCodes}>
            <i type="button" className="fa fa-gift"></i>
          </span>
        ) : (
          ""
        )}
        <img
          className="card-img-top w-10"
          src={baseUrl + d?.imageData.split(",")[0]}
          onError={(e) => {
            e.target.src = "/assets/img/noimage.jpg";
            e.target.onerror = null;
          }}
          style={{ height: "40px", width: "40px" }}
        />
      </div>
    ),

    productTitle: (
      <>
        {d?.productTitle},<small className="d-block">[ID:{d?.productId}]</small>
      </>
    ),
    productName: d?.productName,
    productDescription: (
      <>
        <div
          className="product-description-8div"
          dangerouslySetInnerHTML={{
            __html: d?.productDescription,
          }}
        />
      </>
    ),
    mrp: (
      <>
        <input
          type="text"
          className="form-control"
          value={d?.mrp}
          onChange={(e) => onPriceChange(e, i, "mrp")}
          onBlur={(e) => handleFormChange(e, i, "mrp", d?.productId)}
        />
      </>
    ),
    sellingPrice: (
      <>
        <input
          type="text"
          className="form-control"
          value={d?.sellingPrice}
          onChange={(e) => onPriceChange(e, i, "sellingPrice")}
          onBlur={(e) => handleFormChange(e, i, "sellingPrice", d?.productId)}
        />
      </>
    ),
    packaging: d?.packaging,
    isPublished: (
      <>
        <label className="toggle-label">
          <input
            className="toggle-input"
            type="checkbox"
            defaultChecked={d?.isPublished}
            onChange={(e) => handleToggleisPublish(d?.productId, e)}
          />
          <span className="toggle-span"></span>
        </label>
      </>
    ),
    isSeasonalFood: (
      <>
        <label className="toggle-label">
          <input
            className="toggle-input"
            type="checkbox"
            defaultChecked={d?.isSeasonalFood}
            onChange={(e) => handleToggleisSeasonalFood(d?.productId, e)}
          />
          <span className="toggle-span"></span>
        </label>
      </>
    ),
    isCultivatedDateShow: (
      <>
        <label className="toggle-label">
          <input
            className="toggle-input"
            type="checkbox"
            defaultChecked={d?.isCultivatedDateShow}
            onChange={(e) => handleToggleisCultivatedDateShow(d?.productId, e)}
          />
          <span className="toggle-span"></span>
        </label>
      </>
    ),
    isVeg: (
      <>
        <label className="toggle-label">
          <input
            className="toggle-input"
            type="checkbox"
            defaultChecked={d?.isVeg}
            onChange={(e) => handleToggleisVeg(d?.productId, e)}
          />
          <span className="toggle-span"></span>
        </label>
      </>
    ),
    isShowOnHome: (
      <>
        <label className="toggle-label">
          <input
            className="toggle-input"
            type="checkbox"
            defaultChecked={d?.isShowOnHome}
            onChange={(e) => handleToggleisShowOnHome(d?.productId, e)}
          />
          <span className="toggle-span"></span>
        </label>
      </>
    ),
  }));
  const handleToggleisPublish = async (Id, e) => {
    const status = !!e.target.checked;
    try {
      const res = await postRequest(`api/Product/UpDateIsPublished`, {
        productId: Id,
        isPublished: status,
      });
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText);
        e.target.checked = !status;
      }
    } catch (error) {
      //toast.error(error.message);
      console.error("Error occurred:", error);
      e.target.checked = !status;
    }
  };
  const handleToggleisSeasonalFood = async (Id, e) => {
    const status = !!e.target.checked;
    try {
      const res = await postRequest(`api/Product/UpdateIsSessional`, {
        productId: Id,
        isSessionalFood: status,
      });
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText);
        e.target.checked = !status;
      }
    } catch (error) {
      // toast.error(error.message);
      console.error("Error occurred:", error);
      e.target.checked = !status;
    }
  };

  const handleToggleisCultivatedDateShow = async (Id, e) => {
    const status = !!e.target.checked;
    try {
      const res = await postRequest(`api/Product/UpDateIsCultivatedDataShow`, {
        productId: Id,
        isCultivatedDateShow: status,
      });
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText);
        e.target.checked = !status;
      }
    } catch (error) {
      // toast.error(error.message);
      console.error("Error occurred:", error);
      e.target.checked = !status;
    }
  };

  const handleToggleisVeg = async (Id, e) => {
    const status = !!e.target.checked;
    try {
      const res = await postRequest(`api/Product/UPDateIsVeg`, {
        productId: Id,
        isVeg: status,
      });
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText);
        e.target.checked = !status;
      }
    } catch (error) {
      // toast.error(error.message);
      console.error("Error occurred:", error);
      e.target.checked = !status;
    }
  };
  const handleToggleisShowOnHome = async (Id, e) => {
    const status = !!e.target.checked;
    try {
      const res = await postRequest(`api/Product/UpDateIsSHowOnHome`, {
        productId: Id,
        isShowOnHome: status,
      });
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText);
        e.target.checked = !status;
      }
    } catch (error) {
      // toast.error(error.message);
      console.error("Error occurred:", error);
      e.target.checked = !status;
    }
  };

  ////////****** GET PRODUCT LIST */////////

  const productProfile = async (id) => {
    setLoading(true);
    try {
      const res = await postRequest(`api/Product/Get`, {
        categoryId: id || 31,
      });
      if (res.result.products) {
        setProduct(res.result.products);
        setLoading(false);
      } else {
        toast.error("Credentials invalid");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };

  ////////****** GET PRODUCT LIST */////////

  ////////****** GET PRODUCT LIST BY ID */////////

  const getproduct = async () => {
    const moduleBase = "api/Product";
    try {
      const res = await postRequest(`${moduleBase}/GetById?id=${params?.id}`);
      if (res.result) {
        if (res.result.hasOwnProperty("imageData")) {
          res.result.imageData = baseUrl + res?.result?.imageData;
        }
        setInput(res.result);
        getunit(res.result.skuGroup);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  ////////****** GET PRODUCT LIST BY ID */////////

  ////////****** EDIT AND ADD PRODUCT */////////

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "mrp" || name === "sellingPrice" || name === "quantity") {
      if (isNaN(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter valid number",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setInput((prev) => {
          return {
            ...prev,
            [name]:
              name === "isVeg" ||
              name === "isPublished" ||
              name === "isSeasonalFood" ||
              name === "isCultivatedDateShow" ||
              name === "isShowOnHome"
                ? checked
                : value,
          };
        });
      }
    } else {
      setInput((prev) => {
        return {
          ...prev,
          [name]:
            name === "isVeg" ||
            name === "isPublished" ||
            name === "isSeasonalFood" ||
            name === "isCultivatedDateShow" ||
            name === "isShowOnHome"
              ? checked
              : value,
        };
      });
    }
  };

  const handleImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["imageData"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
  };

  const handleChangeGroup = async (e) => {
    const { name, value } = e.target;

    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    getunit(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      let res = {};
      let formData = new FormData();
      let coupons = selectedCoupons?.map((item) => item.value);
      let __d = { ...input };
      if (coupons?.length > 0) {
        __d.applicableCoupons = coupons;
      }
      if (!__d.applicableCoupons) {
        delete __d.applicableCoupons;
      }
      formData.append("Images", image?.value);
      for (let key in __d) {
        formData.append(key, __d[key]);
      }
      res = await postRequest(
        `${moduleBase}/Add`,
        formData,
        "multipart/form-data"
      );
      if (!res) {
        toast.error(res?.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/Product", {
            replace: true,
            state: { CID: params.cId },
          });
        }
      }
      setSubmitLoader(false);
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || "Something went wrong";
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
    setSubmitLoader(false);
  };

  ////////****** EDIT AND ADD PRODUCT */////////

  ////////****** GETTING UNIT,SEASON AND CATEGORY DROPDOWN LIST */////////

  const getCategoryList = async () => {
    try {
      const res = await postRequest(`api/Catgory/Get`, {
        parentId: 0,
      });
      if (res?.result) {
        let _l = [
          // { categoryId: 0, categoryName: "Select Category" },
          ...res.result,
        ];
        setList(_l);
        // setInput((old) => {
        //   return {
        //     ...old,
        //     ['categoryId']: res.result[0].categoryId
        //   }
        // })
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleCateogryList = (event) => {
    productProfile(event.target.value);
    setCategoryTdSave(event.target.value);
  };

  const getSeasonList = async () => {
    try {
      const res = await postRequest(`api/Season/GetAll`);
      if (res) {
        let _s = [{ seasonId: 0, seasonName: "Select Season" }, ...res];
        setSeasonList(_s);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSeasonList = (event) => {
    productProfile(event.target.value);
  };

  const getunit = async (skuId) => {
    try {
      const res = await getRequest(
        `api/SKU/MeasurementUnitsByGroup${skuId ? "?UnitGroup=" + skuId : ""}`
      );
      let _u = [{ id: 0, name: ":: Select ::" }, ...res?.result];
      setUnit(_u);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  ////////****** GETTING UNIT, SEASON AND CATEGORY DROPDOWN LIST */////////

  ////////****** VALIDATING THE PRODUCT */////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.categoryId || input?.categoryId === "N/A") {
      newErrors.categoryId = "Please enter a CategoryName.";
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }
    if (!input?.productTitle) {
      if (titleRef?.current) {
        titleRef.current.focus();
      }
      newErrors.productTitle = "Please enter a product title.";
    }
    if (!input?.productName) {
      if (nameRef?.current) {
        nameRef.current.focus();
      }
      newErrors.productName = "Please enter the product name.";
    }

    if (!input?.mrp) {
      if (mrpRef?.current) {
        mrpRef.current.focus();
      }
      newErrors.mrp = "Please enter the MRP.";
    }
    if (!input?.sellingPrice) {
      if (smrpRef?.current) {
        smrpRef.current.focus();
      }
      newErrors.sellingPrice = "Please enter Selling Price.";
    }
    if (!input?.quantity) {
      newErrors.quantity = "Please enter the Quantity.";
    }
    if (!input?.skuGroup || input?.skuGroup === "N/A") {
      newErrors.skuGroup = "Sku group is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  ////////****** VALIDATING THE PRODUCT */////////

  ////////****** PRODUCT DELETE BY ID */////////

  const handlepopup = async (pId) => {
    setShowModal(true);
    setGetId(pId);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/Product/Delete?id=${getId}`);
      if (res) {
        productProfile(categoryIdSave);
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  ////////****** PRODUCT DELETE BY ID */////////

  ////////****** CULTIVATION SHOW DATE */////////

  const handleCultivation = (e, productId) => {
    if (checkCultivated?.includes(productId)) {
      setCheckCultivated(checkCultivated.filter((id) => id !== productId));
    } else {
      setCheckCultivated([...checkCultivated, productId]);
    }
  };

  const handleCultivationSubmit = async (e) => {
    e.preventDefault();
    const value = checkCultivated.join(",");
    try {
      const res = await postRequest(
        `api/Product/UpdateCultivatedDate?productIds=${value}`
      );
      if (res) {
        toast.success(res.responseText);
        setCheckCultivated([]);
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  };
  ////////****** CULTIVATION SHOW DATE */////////

  const getCouponList = async () => {
    try {
      const moduleBase = "api/Coupon";
      const res = await postRequest(`${moduleBase}/GetAllCouponAsync`);
      if (res) {
        let option = res?.map((item) => ({
          value: item.id,
          label: item.code,
        }));
        if (input?.couponIds) {
          let selected = res?.map((item) => {
            if (input?.couponIds?.includes(item.id)) {
              return {
                value: item.id,
                label: item.code,
              };
            }
          });
          selected = selected?.filter((item) => !!item?.label);
          setSelectedCoupons(selected);
        }
        setCouponData(option);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptions = (e) => {
    setSelectedCoupons(e);
  };

  const handleEditor = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        ["productDescription"]: e,
      };
    });
  };

  const handleSearch = async (e) => {
    try {
      if (!searchTerm) {
        return;
      }
      setLoading(true);
      const res = await postRequest(
        `${moduleBase}/SearchProduct?Product_Name=${searchTerm}`
      );
      if (res?.statusCode === 1) {
        setProduct(res.products);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error("Failed to search product");
    }
  };

  return {
    getCategoryList,
    data,
    handleCateogryList,
    handleSeasonList,
    productProfile,
    list,
    loading,
    handleChangeGroup,
    handleChange,
    handleImage,
    handleSubmit,
    getproduct,
    getunit,
    input,
    unit,
    submitloader,
    columns,
    errors,
    getSeasonList,
    seasonList,
    handleCultivationSubmit,
    showModal,
    handleClose,
    handleDelete,
    checkCultivated,
    getCouponList,
    couponData,
    handleOptions,
    selectedCoupons,
    editor,
    handleEditor,
    categoryIdSave,
    setCategoryTdSave,
    searchTerm,
    setSearchTerm,
    handleSearch,
    inputRef,
    mrpRef,
    smrpRef,
    nameRef,
    titleRef,
  };
};
export default ProductService;
