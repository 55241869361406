import React from "react";
import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";
import FooterLast from "../../Component/Homes/FooterLast";

const AboutUs = () => {
  return (
    <>
  
      <HeaderTop />
      {/* <HeaderBottom /> */}
      <>
      <main>


<section className="breadcrumb-area breadcrumb-bg">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumb-content">
                    <h2 className="title">About Us</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">About us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>


<section className="ingredients-area pt-90 pb-90">
    <div className="container">
        <div className="ingredients-inner-wrap">
            <div className="row align-items-center">
                {/* <!--<div className="col-6">
                <div className="ingredients-img">
                    <img src="siteadmin/img/images/ingredients_img.webp" alt="Hello Farmers" className="img-fluid">
                    <div className="active-years">
                        <h2 className="title">49+ <span>Years</span></h2>
                    </div>
                </div>
            </div>--> */}
                <div className="col-12">
                    <div className="ingredients-content-wrap">
                        <div className="ingredients-section-title">
                            <span className="sub-title">About Us</span>
                            <h2 className="title">Executive Summery</h2>
                        </div>
                        <p>
                            Hello Farmers is renowned for its in-house state of the art sustainable agriculture development techniques coupled with innovative thinking with respect to natural resources utilization for higher profitability.
                        </p>
                        <p>
                            We seek to maximize natural agricultural production whilst minimizing the dependency on middle man. The released revenue for farmers can then be utilized in a range of diverse and profitable downstream activities bringing enormous benefits to the Country.
                        </p>
                        <p>
                            As a leading provider of cloud-based procurement and strategic sourcing solutions, we thought it would be useful to show how Hello Farmers could rapidly address key challenges of farmers and help to meet the objectives.
                        </p>


                        <div className="ingredients-section-title">
                            <h2 className="title">Introduction</h2>
                        </div>
                        <p>
                            Hello Farmers is an Agri-Tech platform based in India that offers a Community Farming as a Service (CFaaS) model utilizing Blockchain and Artificial Intelligence (AI) technology. The platform provides a unique solution to the agricultural supply chain by bridging the gap between farmers and consumers through a transparent and fully automated supply chain.
                        </p>
                        <p>
                            Farmer will find a one stop solution with the help of advanced technology. Every individual farmer will be trained and made aware about advanced agricultural practices. Integration of all departments and services on a single platform. All needs of farmers and consumers will be monitored by a centralized system. Rural Agri Support Centers, Rural Call Centers, Big Data Analysis, and small-scale Agri industries will create new employments at village level, individual revenue increases, every farm produce yield go high. New employment opportunities for farming community.
                        </p>

                        <p>
                            One of the key services offered by Hello Farmers is the Family Kitchen Subscription, which provides a farm-to-kitchen supply chain that offers 100% traceability and transparency between farmers and consumers. This service ensures that consumers have access to high-quality and chemical-free farm fresh products at reasonable prices. In addition, the platform has a "Fair Trade Policy" that ensures farmers receive fair prices for their products.
                        </p>
                        <p>
                            Timely supply of quality information is a key enabler to reduce overall cost of cultivation and improving yields.
                        </p>
                        <p>
                            Hello Farmers is linking every individual process of Agri business on a single platform to minimize Demand & Supply GAP to build a sustainable agriculture ecosystem.

                        </p>

                        <div className="ingredients-section-title">
                            <h2 className="title">Overview</h2>
                        </div>


                        <p>Our Holistic Agribusiness Strategy that is developed by thinking about the Farming as a whole, Its place in the broader economy and society, and in the lives of its customers. It attempts to develop and maintain multiple perspectives on the farmer's commercial activities.</p>

                        {/* <!--<div className="ingredients-fact">
<ul>
    <li>
        <div className="icon"><img src="siteadmin/img/icon/ing_icon01.png" alt="Hello Farmers"></div>
        <div className="content">
            <h4>128+</h4>
            <span>Awards Winner</span>
        </div>
    </li>
    <li>
        <div className="icon"><img src="siteadmin/img/icon/ing_icon02.png" alt="Hello Farmers"></div>
        <div className="content">
            <h4>35k+</h4>
            <span>Active Volunteers</span>
        </div>
    </li>
</ul>
</div>--> */}
                        {/* <!--<div className="ingredients-btn-wrap">
                            <a href="#" className="btn">Shop now</a>
                            <a href="/" className="store-link">our Super Store <i className="fas fa-arrow-right"></i></a>
                        </div>--> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="ingredients-area gray-bg pt-90 pb-90" id="mission">
    <div className="container">
        <div className="ingredients-inner-wrap">
            <div className="row align-items-center">
                <div className="col-6">
                    <div className="ingredients-img">
                        <img src="siteadmin/img/images/ingredients_img.webp" alt="Hello Farmers" className="img-fluid" />
                        <div className="active-years">
                            <h2 className="title">ACHIVEMENTS</h2>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="ingredients-content-wrap">
                        <div className="ingredients-section-title">

                            <h2 className="title">Mission</h2>
                        </div>
                        <p>
                            " Our mission is to provide unique and sustainable community farming solutions that promote healthy eating habits, reduce the carbon footprint of food wastage and transportation, and empower farming communities to become self-sufficient and economically independent. We also aim to create employment opportunities for farming communities through skill development and training programs. "
                        </p>
                        <div className="ingredients-fact">
                            <ul>
                                <li>
                                    <div className="icon"><img src="siteadmin/img/icon/ing_icon01.webp" alt="Hello Farmers" /></div>
                                    <div className="content">
                                        <h4>20+</h4>
                                        <span>Community Farms</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon"><img src="siteadmin/img/icon/ing_icon02.webp" alt="Hello Farmers" /></div>
                                    <div className="content">
                                        <h4>1200+</h4>
                                        <span>Active Members</span>
                                    </div>
                                </li>

                                <li>
                                    <div className="icon"><img src="siteadmin/img/icon/ing_icon03.webp" alt="Hello Farmers" /></div>
                                    <div className="content">
                                        <h4>450+</h4>
                                        <span>Operational States</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="contact-area pt-90 pb-90" id="vision">
    <div className="container">
        <div className="container-inner-wrap">
            <div className="row justify-content-center justify-content-lg-between">
                <div className="col-lg-6 col-md-6 order-2 order-lg-0">
                    <div className="contact-info-wrap">
                        <div className="contact-title mb-25">
                            <h5 className="sub-title">Get to Know Us</h5>
                            <h2 className="title">Our Vision<span>.</span></h2>
                        </div>
                        <div className="contact-wrap-content">
                            <p>Our vision is to transform traditional farming practices into a modern and sustainable model that benefits both farmers and consumers. We aim to create a community-based platform that promotes transparency, accountability, and sustainability in the agriculture sector.</p>

                        </div>
                    </div>
                   
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                    <div className="">
                        <div className="contact-img mb-0">
                            <img src="siteadmin/img/images/vision.webp" alt="Hello Farmers" className="w-75" />
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section className="bd-week-area gray-bg pt-40 pb-40">
    <div className="container">
    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8 col-md-10">
                            <div class="section-title section-title-two text-center mb-50">
                                {/* <span class="sub-title">Our Articles</span> */}
                                <h2 class="title">Our Team</h2>
                                
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Pravin.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Pravin Singh</a></h6>
                                    
                                    <div class="price">(Founder & CEO)</div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Ashutosh.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Ashutosh Srivastava</a></h6>
                                    
                                    <div class="price">(Co-Founder, Procurement & Supply Chain)</div>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Anjar.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Md. Anzar Siddiqui</a></h6>
                                    
                                    <div class="price">(Co-Founder & CTO)</div>
                                </div>
                            </div>
                        </div>

                        {/* <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Juhi.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Juhi Jindal</a></h6>
                                    
                                    <div class="price">(Co-founder & PR and Marketing)</div>
                                </div>
                            </div>
                        </div> */}

                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Parikshit.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Parikshit Sampat Sai</a></h6>
                                    
                                    <div class="price">(Co Founder &  CMO)</div>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="sp--product--item mb-50">
                                <div class="sp--product--thumb">
                                    <a href="javascript:void(0)"><img src="siteadmin/img/team/Saheem.webp" /></a>
                                </div>
                                <div class="sp--product--content">
                                    <h6 class="title"><a href="javascript:void(0)">Saheem Khan</a></h6>
                                    
                                    <div class="price">Co-Founder (Sales & Marketing)</div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    
        
    </div>
</section>


<section className="newsletter-area pt-90 pb-90">
    <div className="container">
        <div className="container-inner-wrap">
            <div className="row">
                <div className="col-12">
                    <div className="newsletter-wrap">
                        <h2 className="title">Are you ready to get your <span>business grossing</span></h2>
                        <div className="newsletter-form">
                            <form action="#">
                                <input type="email" placeholder="Email address" />
                                <button className="btn">subscribe</button>
                            </form>
                        </div>
                        <img src="siteadmin/img/images/newsletter_shape01.png" alt="Hello Farmers" className="newsletter-shape top-shape wow fadeInDownBig" data-wow-delay=".3s" />
                        <img src="siteadmin/img/images/newsletter_shape02.png" alt="Hello Farmers" className="newsletter-shape bottom-shape wow fadeInUpBig" data-wow-delay=".3s" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section className="online-support-area" id="usp">
    <div className="container">
        <div className="container-inner-wrap">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-5">
                    <div className="online-support-img">
                        <img src="siteadmin/img/images/support-img.webp" alt="Hello Farmers" />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-7">
                    <div className="online-support-content">
                        <h2 className="title">Unique Selling Proposition (USP)</h2>
                        <ul className="product-desc-list">
                            <li>100% <b>Transparency</b> and <b>Traceability</b> through <b>Blockchain</b></li>
                            <li>Advanced Pre-Harvest Trading</li>
                            <li>Fair Trade method</li>
                            <li>Driven by Verified and Skilled Community Members</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


</main>
      </>
      <FooterLast />
    </>
  );
};
export default AboutUs;
