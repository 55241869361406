import { React, useEffect } from "react";
import { useParams } from "react-router-dom";
import Styles from "./ProdInPack.module.css";
import ProdInPackService from "./ProdInPackService";

const ProductCard = () => {
  const {
    goback,
    handleSubmit,
    productList,
    product,
    canChoose,
    totalMap,
    packageName,
    setPackageName,
    pageloader,
    baseUrl,
  } = ProdInPackService();
  const params = useParams();

  useEffect(() => {
    productList();
    setPackageName(params.packageName);
  }, []);

  return (
    <div>
      <main id="main" className="main">
        <h5>
          {packageName} &nbsp;&nbsp;&nbsp;
          <small>
            [Total Mapped:{totalMap} | Max Default : {canChoose}]
          </small>
          <span className="float-end btn btn-sm btn-danger" onClick={goback}>
            BACK
          </span>
        </h5>
        {!pageloader ? (
          <>
            <div className={Styles.scrollHeight + " row"}>
              {product.map((d, i) => (
                <>
                  <div className="col-md-3 p-1">
                    <form id={"form" + i}>
                      <div className="card">
                        <div className="card-body p-0">
                          <img
                            className="d-block"
                            style={{ minHeight: "175px", maxHeight: "175px" }}
                            src={baseUrl + d.imageData.split(",")[0]}
                            onError={(e) =>
                              (e.target.onerror = null)(
                                (e.target.src = "/assets/img/noimage.jpg")
                              )
                            }
                            class="card-img-top"
                          />
                        </div>
                        <div className="card-footer text-center p-1">
                          <span className="d-block mt-2">{d.productTitle}</span>
                          <span>[Package size : {d.packaging}]</span>

                          <div className="row mt-3">
                            <div className="col-6 pe-0">
                              <div className="mb-2">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Min Limit"
                                  name="minLimit"
                                  defaultValue={d.minLimit}
                                  onBlur={(e) =>
                                    handleSubmit(e, d.id, "form" + i)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="mb-2">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Max Limit"
                                  name="maxLimit"
                                  defaultValue={d.maxLimit}
                                  onBlur={(e) =>
                                    handleSubmit(e, d.id, "form" + i)
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-6 pe-0">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="isDefault"
                                        id={"isDefault" + i}
                                        defaultChecked={d.isDefault}
                                        onChange={(e) =>
                                          handleSubmit(e, d.id, "form" + i)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <label
                                  for={"isDefault" + i}
                                  className="form-check-label form-control p-0 pt-2"
                                >
                                  Default
                                </label>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="isMapped"
                                        id={"isMapped" + i}
                                        defaultChecked={d.isMapped}
                                        onClick={(e) =>
                                          handleSubmit(e, d.id, "form" + i)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <label
                                  for={"isMapped" + i}
                                  className="form-check-label form-control p-0 pt-2"
                                >
                                  Map
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              ))}
            </div>
          </>
        ) : (
          <>
            <div
              className="spinner-border"
              role="status"
              style={{
                textAlign: "center",
                marginLeft: "30rem",
                marginTop: "15rem",
              }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </>
        )}
      </main>
    </div>
  );
};
export default ProductCard;
