import React, { useState } from "react";
import { toast } from "react-toastify";
import { postRequest } from "../../Services/ApiServices";
import { validEmail, validName, validNumber } from "../../Services/Validation";


import HeaderTop from "../../Component/Homes/HeaderTop";
import FooterLast from "../../Component/Homes/FooterLast";

const Contactus = ({ sectionRef }) => {
  const [data, setData] = useState();
  const [error, setError] = useState([]);
  const [OnProgress, setOnProgress] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    let err = [];
    if (name === "name" && !validName.test(value)) {
      err.push("name");
    }
    if (name === "email" && !validEmail.test(value)) {
      err.push("email");
    }
    if (name === "phone" && !validNumber.test(value)) {
      err.push("phone");
    }
    setError(err);
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const sendMail = async (e) => {
    setOnProgress(true);
    e.preventDefault();
    try {
      let req = {
        subject: "Query From Hello Farmers",
        body: `<table style="width:100%"><tr><td style="width:120px">Name</td><td>${data.name}</td></tr>
          <tr><td>E-Mail</td><td>${data.email}</td></tr>
          <tr><td>Phone</td><td>${data.phone}</td></tr>
          <tr><td>Query</td><td>${data.comments}</td></tr></table>`,
      };
      const res = await postRequest("sendMail", req);
      if (res.statusCode === 1) {
        toast.success(
          "Your query has been submitted successfully!! We will get back soon 😀"
        );
        setData({ name: "", email: "", phone: "", comments: "" });
      } else {
        toast.error(res.result.error);
      }
      setOnProgress(false);
    } catch (error) {
      toast.error("Something went wrong!!!");
      setOnProgress(false);
    }
  };

  return (

    <>
<HeaderTop />
<>
<main>

     

<section className="contact-area pt-90 pb-90">
    <div className="container">
        <div className="container-inner-wrap">
            <div className="row justify-content-center justify-content-lg-between">
                <div className="col-lg-6 col-md-8 order-2 order-lg-0">
                    <div className="contact-title mb-25">
                        <h5 className="sub-title">Contact Us</h5>
                        <h2 className="title">Let's Talk Question<span>.</span></h2>
                    </div>
                    <div className="contact-wrap-content">
                        
                        <form action="#" className="contact-form" method="post">
                            <div className="form-grp">
                                <label for="name">Your Name <span>*</span></label>
                                <input
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Name*"
                        type="text"
                        onChange={handleChange}
                        required
                      />
                      {error.includes("name") ? (
                        <span className="text-danger">
                          *Your Name is invalid
                        </span>
                      ) : (
                        ""
                      )}
                            </div>
                            <div className="form-grp">
                                <label for="email">Your Email <span>*</span></label>
                                <input
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email*"
                        type="email"
                        onChange={handleChange}
                        required
                      />
                      {error.includes("email") ? (
                        <span className="text-danger">
                          *Your email is invalid
                        </span>
                      ) : (
                        ""
                      )}
                            </div>
                            <div className="form-grp">
                                <label for="mobile">Mobile Number <span>*</span></label>
                                <input
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        type="number"
                        onChange={handleChange}
                      />
                      {error.includes("phone") && (
                        <span className="text-danger">
                          *Your Phone Number is invalid
                        </span>
                      )}
                            </div>
                            <div className="form-grp">
                                <label for="message">Your Message <span>*</span></label>
                                <textarea
                        className="form-control"
                        id="comments"
                        name="comments"
                        placeholder="Tell Us About Project *"
                        required
                        onChange={handleChange}
                      ></textarea>
                            </div>
                            {OnProgress ? (
                      <button disabled="disabled">
                        <i className="fa fa-paper-plane me-2"></i>{" "}
                        Requesting....
                      </button>
                    ) : (
                      <button
                        type="submit"
                        name="submit"
                        id="submit"
                        className="btn rounded-btn"
                        onClick={sendMail}
                      >
                        <i className="fa fa-paper-plane me-2"></i>Get in Touch
                      </button>
                    )}
                            {/* <button type="button" className="btn rounded-btn">Send Now</button> */}
                           
                        </form>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 col-md-8">
                    <div className="contact-info-wrap">
                        <div className="contact-img">
                            <img src="siteadmin/img/images/contact-img.webp" alt="Hello Farmers" />
                        </div>
                        <div className="contact-info-list">
                            <ul>
                                <li>
                                    <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                    <div className="content">
                                        <p>3/553, Vivek Khand, Gomti Nagar(Lucknow)-226010</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                    <div className="content">
                                        <p>+91 9889 009622</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon"><i className="fas fa-envelope-open"></i></div>
                                    <div className="content">
                                        <p>support@hellofarmers.co.in</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="contact-social">
                            <ul>
                                <li><a href="https://www.facebook.com/profile.php?id=61550659974825&mibextid=LQQJ4d" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                <li><a href="https://instagram.com/hello_farmers_?igshid=MzRlODBiNWFlZA==" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/skvipm-digisolutions-pvt-ltd/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


</main>
</>
<FooterLast />
</>
  );
};

export default Contactus;
