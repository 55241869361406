import { React, useState } from "react";
import { toast } from "react-toastify";
import { postRequest } from "../../Services/ApiServices";
import { useParams, useNavigate } from "react-router-dom";
import { DATACONSTANT } from "../../Services/DataConstant";
import serialize from "form-serialize";
import { NavLink, Link } from "react-router-dom";
import Style from "./ProdInPack.module.css";

const ProdInPackService = () => {
  const [prodInPack, setprodInPack] = useState([]);
  const [itemsPerPage] = useState(5);
  const [pageloader, setPageLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [getid, setGetId] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [packageName, setPackageName] = useState("");
  const [totalMap, setTotalMap] = useState(0);
  const [canChoose, setCanChoose] = useState(0);
  const baseUrl = DATACONSTANT.BASE_URL;
  const [loading, setLoading] = useState(true);

  ///** PRODUCT IN PACKAGE *///

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },

    {
      name: "MAP",
      selector: (row) => row.map,
      width: "100px",
      className: "tab-top",
    },
    {
      name: "PACKAGE",
      selector: (row) => row.packageName,
      width: "250px",
    },
    {
      name: "PRODUCT",
      selector: (row) => row.product,
    },
  ];

  const data = prodInPack?.map((d, i) => ({
    serial: i + 1,
    map: (
      <>
        <NavLink
          to={{
            pathname: `/ProductCard/${d.packageName}/${d.packageId}`,
          }}
          className="btn btn-primary"
        >
          Map
        </NavLink>
      </>
    ),
    packageName: (
      <>
        {d.packageName}
        <small className="d-block">[ID:{d.packageId}]</small>
      </>
    ),
    product: (
      <>
        <div className="pro-table">
          {d.products.length > 0 ? (
            d?.products?.slice(1, 6)?.map((item, i) => (
              <>
                <div className="card mb-2 me-1" key={i}>
                  <div
                    className={
                      Style.delete + " d-flex justify-content-end text-danger"
                    }
                    type="button"
                    onClick={(e) => handlepopup(e, d?.packageId, item?.id)}
                  >
                    <i className="fa fa-times"></i>
                  </div>

                  <img
                    style={{
                      width: "100%",
                      height: "75px",
                      objectFit: "cover",
                    }}
                    className="d-block"
                    src={baseUrl + item.imageData.split(",")[0]}
                    onError={(e) => {
                      e.target.src = "/assets/img/noimage.jpg";
                      e.target.onerror = null;
                    }}
                  />

                  <p
                    className="text-center m-2"
                    style={{
                      fontFamily: "math",
                    }}
                  >
                    {item.productTitle}
                    <br />
                    [pkg capacity : {item.packaging}]
                    <br />
                    [(Min){item.minLimit}-{item.maxLimit}
                    (Max)]
                  </p>
                </div>
              </>
            ))
          ) : (
            <span className="card p-2 m-2 text-center">
              <img
                className="d-block"
                style={{ height: "50px" }}
                src="/assets/img/noimage.jpg"
              />
              No Product
            </span>
          )}
        </div>

        <div className=" d-flex justify-content-end">
          <Link
            to="/MappedProduct"
            state={{ productdata: d }}
            className="btn btn-primary btn-xs"
          >
            Show More
          </Link>
        </div>
      </>
    ),
  }));

  const prodInPackage = async () => {
    const moduleBase = "api/ProductPackage";

    const res = await postRequest(`${moduleBase}/ProductInPackage`);
    if (res.result) {
      setprodInPack(res.result);
      setLoading(false);
    } else {
      toast.error("Credentials invalid");
    }
  };

  const handleClose = () => setShowModal(false);

  const handlepopup = async (e, packageId, productId) => {
    setShowModal(true);
    setGetId({ productId, packageId });
  };
  const handleUnmapProd = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(
        `api/ProductPackage/UnMap?ProductId=${getid.productId}&PackageId=${getid.packageId}`
      );
      if (res) {
        prodInPackage();
        toast.success(res.responseText);
        setShowModal(false);
      } else {
        toast.error(res.result.error);
      }
    } catch (error) {
      toast.error("Something went wrong!!!");
      console.error(error);
    }
  };

  ////***** PRODUCT CARD *****////

  const goback = () => {
    navigate("/ProductInPackage");
  };

  const handleSubmit = async (e, id, _form) => {
    try {
      const { name, checked, type } = e.target;
      if (name === "isDefault") {
        let isDisabled =
          product.filter((x) => x.isDefault).length >= canChoose && checked;
        if (isDisabled) {
          toast.error(`You cannot set default more than ${canChoose}.`);
          e.target.checked = false;
          return;
        }
      }
      let form = document.querySelector("#" + _form);
      let formData = serialize(form, { hash: true });
      if (formData.hasOwnProperty("isDefault")) {
        formData["isDefault"] = formData["isDefault"] === "on";
      }

      if (formData.hasOwnProperty("isMapped")) {
        formData["isMapped"] = formData["isMapped"] === "on";
      }
      if (formData["isMapped"] == undefined) {
        formData["isMapped"] = false;
      }

      if (Object.keys(formData).length < 5 && formData["isMapped"] == false) {
        return false;
      }
      if (
        form["minLimit"] == "" ||
        form["minLimit"] == 0 ||
        form["maxLimit"] == "" ||
        form["maxLimit"] == 0
      ) {
        return false;
      }
      let d = {
        productId: id,
        packageId: params.id,
        ...formData,
      };
      const moduleBase = "api/ProductPackage";
      const res = await postRequest(`${moduleBase}/Map`, {
        ...d,
        entryOn: "2023-02-03",
        modifyOn: "2023-02-03",
        isActive: true,
      });

      if (!res) {
        toast.error(res.responseText);
      } else {
        if (res.statusCode == 1) {
          toast.success(res.responseText);
          if (
            product.filter((x) => x.id === d.productId && x.isMapped).length ===
            0
          ) {
            if (formData["isMapped"]) {
              setTotalMap(totalMap + 1);
            }
          }
          let newPro = product.map((x) => {
            if (x.id === d.productId) {
              return {
                ...x,
                isMapped: formData["isMapped"],
                isDefault: formData["isDefault"],
              };
            }
            return x;
          });
          setProduct(newPro);
        } else {
          if (type === "checkbox") {
            e.target.checked = false;
          }
          toast.error(res.responseText);
        }
      }
    } catch (error) {
      toast.error("Something went wrong!!!");
    }
  };

  const productList = async () => {
    setPageLoader(true);
    const res = await postRequest(
      "api/ProductPackage/productlistformapping?packageId=" + params.id
    );
    if (res?.result?.data) {
      const countMapped = res?.result?.data?.filter(
        (item) => item.isMapped === true
      ).length;
      setProduct(res?.result?.data);
      setTotalMap(countMapped);
      setCanChoose(res?.result?.canChooseOnly);
      setPageLoader(false);
    } else {
      toast.error("Credentials invalid");
    }
  };

  return {
    prodInPackage,
    handleUnmapProd,
    handleClose,
    showModal,
    pageloader,
    prodInPack,
    itemsPerPage,
    goback,
    handleSubmit,
    productList,
    product,
    canChoose,
    totalMap,
    packageName,
    setPackageName,
    baseUrl,
    data,
    columns,
    loading,
    handlepopup,
    Style,
  };
};

export default ProdInPackService;
