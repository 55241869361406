import React from "react";
import PasswordService from "./PasswordService";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Password = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { input, submitloader, errors, handleChange, handleSubmitPassword } =
    PasswordService();

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Password</li>

                      <li className="breadcrumb-item active">
                        Password Change Form
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container w-100">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label className="signup_label" htmlFor="mobileNo">
                        Mobile No:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobileNo"
                        name="mobileNo"
                        value={input?.mobileNo}
                        onChange={handleChange}
                      />
                      {errors.mobileNo && (
                        <div className="help-block text-danger">
                          {errors.mobileNo}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label className="signup_label" htmlFor="oldPassword">
                        Old Password:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="oldPassword"
                        name="oldPassword"
                        value={input?.oldPassword}
                        onChange={handleChange}
                      />
                      {errors.oldPassword && (
                        <div className="help-block text-danger">
                          {errors.oldPassword}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label className="signup_label" htmlFor="newPassword">
                        New Password:
                      </label>
                      <div className="input-group">
                        <input
                          type={showNewPassword ? "text" : "password"}
                          className="form-control "
                          id="newPassword"
                          name="newPassword"
                          value={input?.newPassword}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-success cus-linecorner cus-pa"
                            type="button"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showNewPassword ? faEyeSlash : faEye}
                            />
                          </button>
                        </div>
                      </div>
                      {errors.newPassword && (
                        <div className="help-block text-danger">
                          {errors.newPassword}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label className="signup_label" htmlFor="confirmPassword">
                        Confirm Password:
                      </label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control "
                          id="confirmPassword"
                          name="confirmPassword"
                          value={input?.confirmPassword}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-success cus-linecorner cus-pa"
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </button>
                        </div>
                      </div>
                      {errors.confirmPassword && (
                        <div className="help-block text-danger">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            {!submitloader ? (
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmitPassword}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-green">Submitting...</button>
              </>
            )}
            <NavLink to="/Profile" className="btn btn-danger">
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};

export default Password;
