import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import GetOrderService from "./GetOrderService";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RecurringOrder = () => {
  const {
    toDate,
    fromDate,
    handleChangedate,
    handleChangedates,
    handleChange,
    recurringcolumns,
    recurringdata,
    handleSubmitRecurring,
    submitloader,
    setSubmitLoader,
    handleDownloadRecurringExcel,
    input,
    loading,
    setInput,
  } = GetOrderService();
  const location = useLocation();

  useEffect(() => {
    const fromDashboard = location?.state?.fromDashboardRecurring;
    if (fromDashboard && input?.orderStatus !== 7) {
      setInput((prev) => ({ ...prev, orderStatus: 7 }));
      handleSubmitRecurring(7);
    } else {
      handleSubmitRecurring();
    }
  }, []);

  return (
    <div>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Order</li>

                        <li className="breadcrumb-item active">
                          Recurring Order
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <div className="form-container">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      From Date:
                      <DatePicker
                        id="fromDate"
                        name="fromDate"
                        selected={fromDate}
                        maxDate={new Date()}
                        onChange={handleChangedates}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      To Date:
                      <DatePicker
                        id="toDate"
                        name="toDate"
                        selected={toDate}
                        minDate={new Date(fromDate)}
                        maxDate={new Date()}
                        onChange={handleChangedate}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      Order Status:
                      <select
                        className="form-control green-dropdown"
                        id="orderStatus"
                        name="orderStatus"
                        value={input?.orderStatus}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="2">Pending</option>
                        <option value="6">Reschedule</option>
                        <option value="7">Order Placed</option>
                        <option value="8">Order Canceled</option>
                        <option value="9">Order Delivered</option>
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-6 d-flex align-items-end">
                      {!submitloader ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-green mr-2"
                            id="btnSubmit"
                            onClick={(e) => {
                              handleSubmitRecurring(false, e);
                              setSubmitLoader(true);
                            }}
                          >
                            Search
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="btn btn-green">
                            Searching...
                          </button>
                        </>
                      )}
                      <div>
                        <>
                          <button
                            type="button"
                            className="btn btn-green btn-md"
                            onClick={handleDownloadRecurringExcel}
                            style={{ padding: "11px" }}
                          >
                            <i className="bi bi-file-earmark-excel"></i>
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body p-2">
              <div className="table-responsive">
                {loading ? (
                  <div className="spinner-container">
                    <Spinner type="TailSpin" color="#00BFFF" />
                  </div>
                ) : (
                  <DataTable
                    columns={recurringcolumns}
                    data={recurringdata}
                    pagination
                    className="rdt_TableHeadRow"
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 205px)"
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default RecurringOrder;
