import { useState } from "react";
import { postRequest } from "../../Services/ApiServices";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DATACONSTANT } from "../../Services/DataConstant";

const CouponListService = () => {
  const [couponData, setCouponData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnMapLoading, setIsUnMapLoading] = useState(false);
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [submitloader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const baseUrl = DATACONSTANT.BASE_URL;
  const [isUnMapModal, setIsUnMapModal] = useState(false);
  const [couponByProduct, setCouponByProduct] = useState({});
  let unMapCouponStruct = [];

  const handleUnMapClose = () => setIsUnMapModal(false);
  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      setShowModal(false);
      const res = await postRequest(`api/Coupon/DeleteCoupon?id=${deleteId}`);
      if (res?.statusCode === 1) {
        getCouponList();
        toast.success(res.responseText);
      } else {
        toast.error(res?.responseText || "Failed to delete");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnMap = async () => {
    try {
      setIsUnMapLoading(true);
      const moduleBase = "api/Coupon";
      const res = await postRequest(
        `${moduleBase}/UNMAPCoupon?id=${couponByProduct?.id}`
      );
      if (res?.statusCode === 1) {
        toast.success(res?.responseText);
      } else {
        toast.error(res?.responseText);
      }
      await getProductList();
      setIsUnMapLoading(false);
    } catch (error) {
      setIsUnMapLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "ACTION",
      selector: (row) => row.edit,
    },

    {
      name: "CODE",
      selector: (row) => row.code,
    },
    {
      name: "PRICE",
      selector: (row) => row.price,
    },
    {
      name: "MAXBENEFIT",
      selector: (row) => row.maxbenefit,
      width: "110px",
    },
    {
      name: "MINPURCHASE",
      selector: (row) => row.minPurchase,
      width: "120px",
    },
    {
      name: "REDEEMCOUNT",
      selector: (row) => row.redeemCount,
      width: "120px",
    },
    {
      name: "ASSOCIATED",
      selector: (row) => row.isAssociated,
      width: "110px",
    },
    {
      name: "FIXED",
      selector: (row) => row.isFixed,
    },
    {
      name: "AUTO APPLICABLE",
      selector: (row) => row.isAutoApplicable,
      width: "160px",
    },
    {
      name: "FREQUENCY",
      selector: (row) => row.frequency,
    },
    {
      name: "SHORT DESCRIPTION",
      selector: (row) => (
        <div className="wrap-word-line">{row.shortdescription}</div>
      ),
      width: "170px",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => (
        <div className="wrap-word-line">{row.description}</div>
      ),
      width: "200px",
    },
    {
      name: "EXPIRY ON",
      selector: (row) => <div className="wrap-word-line">{row.expiryOn}</div>,
      width: "140px",
    },
  ];

  const productColumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "120px",
    },
    {
      name: "IMAGE",
      selector: (row) => row.image,
      width: "120px",
    },
    {
      name: "PRODUCTNAME",
      selector: (row) => row.productName,
      // width: "80px",
    },
  ];

  const UnMapColumns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "120px",
    },
    {
      name: "COUPONCODE",
      selector: (row) => row.couponCode,
      width: "120px",
    },
  ];

  const couponArrayByProduct = couponByProduct.couponCodes?.split(",");
  if (couponArrayByProduct?.length > 0) {
    unMapCouponStruct = couponArrayByProduct?.map((d, i) => ({
      serial: i + 1,
      couponCode: d,
      action: (
        <>
          <button
            type="button"
            onClick={handleUnMap}
            className="btn btn-sm btn-primary"
          >
            UnMap
          </button>
        </>
      ),
    }));
  }

  const productDataStruct = productData?.map((d, i) => ({
    serial: i + 1,
    productName: d?.productName,
    action: (
      <>
        {d?.couponCodes ? (
          <button
            onClick={() => {
              setIsUnMapModal(true);
              setCouponByProduct(d);
            }}
            type="button"
            className="btn btn-sm btn-primary"
          >
            UnMap
          </button>
        ) : (
          ""
        )}
      </>
    ),
    image: (
      <div>
        <img
          className="card-img-top w-10"
          src={baseUrl + d?.imageData.split(",")[0]}
          onError={(e) => {
            e.target.src = "/assets/img/noimage.jpg";
            e.target.onerror = null;
          }}
          style={{ height: "40px", width: "40px" }}
        />
      </div>
    ),
  }));

  const data = couponData?.map((d, i) => ({
    serial: i + 1,
    code: d?.code,
    price: d?.price,
    description: d?.description,
    shortdescription: d?.shortdescription,
    redeemCount: d?.redeemCount,
    minPurchase: d?.minPurchase,
    maxbenefit: d?.maxbenefit,
    isFixed: d?.isFixed ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    isAssociated: d?.isAssociated ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    isAutoApplicable: d?.isAutoApplicable ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    expiryOn: d?.expiryOn,
    frequency: d?.frequency,
    edit: (
      <>
        <Link
          to={{
            pathname: "/AddCoupon",
          }}
          state={{ id: d.id }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </Link>
        <span onClick={() => handlepopup(d?.id)} style={{ cursor: "pointer" }}>
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
  }));

  const getCouponList = async () => {
    try {
      setIsLoading(true);
      const moduleBase = "api/Coupon";
      const res = await postRequest(`${moduleBase}/GetAllCouponAsync`);
      if (res) {
        setCouponData(res);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChange = async (e) => {
    const { name, value, checked } = e.target;
    const checkBoxFields = ["isFixed", "isAssociated"];
    if (value) {
      setErrors((prev) => {
        return {
          ...prev,
          [name]: "",
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          [name]: "Please enter " + name,
        };
      });
    }
    setInput((prev) => {
      return {
        ...prev,
        [name]: checkBoxFields.includes(name) ? checked : value,
      };
    });
  };

  function formatDate(date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  const validateInput = () => {
    const newErrors = {};
    if (!input?.price) {
      newErrors.price = "Please enter price";
    }
    if (input?.price && isNaN(input?.price)) {
      newErrors.price = "Please enter valid price";
    }
    if (!input?.code) {
      newErrors.code = "Please enter code";
    }
    if (!input?.description) {
      newErrors.description = "Please enter description";
    }
    if (!input?.shortdescription) {
      newErrors.shortdescription = "Please enter short description";
    }
    if (!input?.redeemCount) {
      newErrors.redeemCount = "Please enter redeem count";
    }
    if (!input?.minPurchase) {
      newErrors.minPurchase = "Please enter min purchase";
    }
    if (!input?.maxbenefit) {
      newErrors.maxbenefit = "Please enter max benefit";
    }
    if (!input?.frequency) {
      newErrors.frequency = "Please enter frequency";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const isValid = validateInput();
      if (!isValid) {
        return;
      }

      const currentDate = new Date();
      const futureDate = new Date();
      futureDate.setDate(currentDate.getDate() + 10);

      let obj = {
        id: id || 0,
        code: input.code,
        price: +input.price,
        entryOn: id ? "" : "string",
        expiryOn: formatDate(futureDate),
        description: input.description,
        shortdescription: input.shortdescription,
        isFixed: input.isFixed,
        isAutoApplicable: false,
        redeemCount: +input.redeemCount,
        isAssociated: input.isAssociated,
        minPurchase: +input.minPurchase,
        maxbenefit: +input.maxbenefit,
        frequency: +input.frequency,
      };

      setSubmitLoader(true);
      let res = await postRequest(`api/Coupon/AddUpdateAsync`, obj);
      if (!res.statusCode == 1) {
        toast.error(res?.result?.error || "Failed to add coupon.");
      } else {
        toast.success(res?.responseText || "Success");
        navigate("/CouponList", { replace: true });
      }
      setSubmitLoader(false);
    } catch (error) {
      console.log("error", error);
      setSubmitLoader(false);
    }
  };

  const getCouponById = async () => {
    try {
      const moduleBase = "api/Coupon";
      const res = await postRequest(`${moduleBase}/GetCouponById?id=${id}`);
      if (res) {
        setInput(res[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlepopup = async (bId) => {
    setShowModal(true);
    setDeleteId(bId);
  };

  const getProductList = async () => {
    const moduleBase = "api/Product";
    try {
      setIsLoading(true);
      const res = await postRequest(`${moduleBase}/Get`, {
        categoryId: 0,
      });
      if (res?.result?.products) {
        setProductData(res.result.products);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred:", error);
    }
  };

  return {
    data,
    columns,
    getCouponList,
    isLoading,
    handleChange,
    handleSubmit,
    errors,
    input,
    submitloader,
    getCouponById,
    id,
    showModal,
    handleClose,
    handleDelete,
    getProductList,
    productDataStruct,
    productColumns,
    isUnMapModal,
    handleUnMapClose,
    unMapCouponStruct,
    UnMapColumns,
  };
};

export default CouponListService;
