import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import AreaListService from "./AreaListService";

const AreaList = () => {
  const {
    data,
    isLoading,
    getAreaList,
    columns,
    handleIsMap,
    onPageChange,
    onRowChange,
    totalAreas,
    mobileNumber,
    handleMobileSearch,
    handleSearchMobileNo,
    handlePinCodeSearch,
    pincode,
  } = AreaListService();

  useEffect(() => {
    getAreaList();
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-9 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Area</li>

                        <li className="breadcrumb-item active"> List</li>
                      </ul>
                    </div>

                    <div className="col-md-3 col-sm-12 justify-content-end d-flex align-items-center">
                      <span className="fw-bold">UnMap : </span>
                      <div className="mx-2 mt-1">
                        <label className="toggle-label">
                          <input
                            className="toggle-input"
                            type="checkbox"
                            // checked={isMap ? true : false}
                            onChange={(e) => handleIsMap(e)}
                          />
                          <span className="toggle-span"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form className="form-container">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <div className="form-container">
                  <div className="row">
                    <div className="col-lg-2 col-md-6">
                      Mobile No:
                      <input
                        type="text"
                        name="mobileNo"
                        value={mobileNumber}
                        className="form-control"
                        onChange={handleMobileSearch}
                      ></input>
                    </div>

                    <div className="col-lg-2 col-md-6">
                      Pin Code:
                      <input
                        type="text"
                        name="pincode"
                        value={pincode}
                        className="form-control"
                        onChange={handlePinCodeSearch}
                      ></input>
                    </div>

                    <div className="col-lg-2 col-md-6 d-flex align-items-end">
                      {/* {!submitloader ? ( */}
                      <>
                        <button
                          type="button"
                          className="btn btn-green mr-2"
                          id="btnSearch"
                          onClick={handleSearchMobileNo}
                        >
                          Search
                        </button>
                      </>
                      {/* ) : (
                           <>
                             <button className="btn btn-green">
                               Submitting...
                             </button>
                           </>
                         )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pak-height card">
            <div className="card-body p-2">
              <div className="table-responsive">
                {isLoading ? (
                  <div className="spinner-container">
                    <Spinner type="TailSpin" color="#00BFFF" />
                  </div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer={true}
                    // paginationPerPage={10}
                    onChangeRowsPerPage={(row) => onRowChange(row)}
                    onChangePage={(page) => onPageChange(page)}
                    className="rdt_TableHeadRow"
                    fixedHeader
                    paginationTotalRows={totalAreas}
                    fixedHeaderScrollHeight="calc(100vh - 210px)"
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};
export default AreaList;
