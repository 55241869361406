import React from "react";
import { NavLink } from "react-router-dom";
import FarmService from "./FarmService";
import JoditEditor from "jodit-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { MeasurementUnits } from "../../../Services/interface";
import Select from "react-select";

const EditFarmDetail = () => {
  const {
    submitloader,
    handleChange,
    handleEditor,
    handleImage,
    handleSubmit,
    handleMapShow,
    handleMapClick,
    editor,
    input,
    area,
    farmCommunityList,
    isMapShow,
    irrigationSource,
    powerSource,
    handleOption,
    handleOptions,
    powerOption,
    irrigationOption,
  } = FarmService();
  const params = useParams();

  useEffect(() => {
    // if (params.id !== undefined) {
    farmCommunityList(params.id, true);
    // }
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Farm Community</li>
                        <li className="breadcrumb-item active">Form</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="form-container w-100">
          <div className="card">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <form className="form-container w-100">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Title :
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the farm title"
                          id="title"
                          name="title"
                          onChange={handleChange}
                          value={input?.title}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Address :
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          placeholder="Enter the address"
                          name="address"
                          onChange={handleChange}
                          value={input?.address}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Landmark :
                        <input
                          type="text"
                          className="form-control"
                          id="landmark"
                          name="landmark"
                          placeholder="Enter the landmark"
                          onChange={handleChange}
                          value={input?.landmark}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Pincode :
                        <input
                          type="text"
                          className="form-control"
                          id="pincode"
                          placeholder="Enter the pincode"
                          name="pincode"
                          onChange={(e) => handleChange(e, true)}
                          value={input?.pincode}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Area :
                        <select
                          className="form-control"
                          name="areaId"
                          onChange={handleChange}
                          value={input?.areaId}
                        >
                          {area?.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.area}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        No. Of Authorised Person :
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter the number of authorizsed person"
                          id="numberofAuthorisedperson"
                          name="numberofAuthorisedperson"
                          onChange={handleChange}
                          value={input?.numberofAuthorisedperson}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Irrigation Source :
                        <Select
                          name="irrigationSource"
                          options={irrigationSource}
                          onChange={handleOption}
                          value={irrigationOption}
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Power Source :
                        <Select
                          name="powerSource"
                          options={powerSource}
                          onChange={handleOptions}
                          value={powerOption}
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Field Measurement :
                        <input
                          type="text"
                          className="form-control"
                          id="fieldMeasurement"
                          name="fieldMeasurement"
                          placeholder="Enter the measurement length"
                          onChange={handleChange}
                          value={input?.fieldMeasurement}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Field Measurement Id :
                        <select
                          className="form-control"
                          name="fieldMeasurementId"
                          onChange={handleChange}
                          value={input?.fieldMeasurementId}
                        >
                          {MeasurementUnits?.map((item) => (
                            <option
                              key={item.id}
                              field={item.name}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Contact Person :
                        <input
                          type="text"
                          className="form-control"
                          id="contactPerson"
                          name="contactPerson"
                          placeholder="Enter the contact person"
                          onChange={handleChange}
                          value={input?.contactPerson}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Contact Number :
                        <input
                          type="text"
                          className="form-control"
                          id="contactNumber"
                          placeholder="Enter the contact number"
                          name="contactNumber"
                          onChange={handleChange}
                          value={input?.contactNumber}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        Current Crops :
                        <input
                          type="text"
                          className="form-control"
                          id="currentCrops"
                          name="currentCrops"
                          placeholder="Enter the crop name"
                          onChange={handleChange}
                          value={input?.currentCrops}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group mb-2">
                        Image :
                        <input
                          type="file"
                          className="form-control"
                          id="imagePath"
                          name="imagePath"
                          onChange={handleImage}
                        />
                      </div>
                    </div>

                    <div className="col-md-2 ">
                      <img
                        className="d-block"
                        style={{ minHeight: "80px", maxWidth: "80px" }}
                        src={
                          input?.imagePath ||
                          input?.imageData ||
                          "/assets/img/noimage.jpg"
                        }
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src = "/assets/img/noimage.jpg")
                          )
                        }
                        class="card-img-top"
                      />
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label
                          className="signup_label"
                          htmlFor="irrigationSource"
                        >
                          <span> Location: </span>
                        </label>
                        {!isMapShow ? (
                          <button
                            type="button"
                            onClick={handleMapShow}
                            className="btn btn-green mx-3"
                          >
                            Map
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={handleMapShow}
                              className="btn btn-green mx-3"
                            >
                              Hide
                            </button>
                            <div
                              className="mt-2"
                              style={{ height: "200px", width: "100%" }}
                            >
                              <GoogleMapReact
                                bootstrapURLKeys={{ key: "" }}
                                defaultCenter={{
                                  lat: +input?.latitude || 26.85,
                                  lng: +input?.longitude || 80.949997,
                                }}
                                defaultZoom={11}
                                onClick={handleMapClick}
                              ></GoogleMapReact>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {input?.latitude && input?.latitude != 0 ? (
                      <>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label className="signup_label" htmlFor="latitude">
                              Latitude:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="latitude"
                              name="latitude"
                              disabled
                              onChange={handleChange}
                              value={input?.latitude}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label className="signup_label" htmlFor="longitude">
                              Longitude:
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="longitude"
                              disabled
                              name="longitude"
                              onChange={handleChange}
                              value={input?.longitude}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        Description :
                        <JoditEditor
                          ref={editor}
                          tabIndex={1}
                          id="description"
                          name="description"
                          value={input?.description}
                          onChange={(e) => handleEditor(e)}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              {!submitloader ? (
                <>
                  <button
                    type="submit"
                    className="btn btn-green"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <button className="btn btn-green">Submitting...</button>
                </>
              )}
              <NavLink to="/FarmCommunity" className="btn btn-danger">
                Close
              </NavLink>
            </div>
          </div>
        </form>
      </main>
    </>
  );
};

export default EditFarmDetail;
