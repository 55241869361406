import React, { useState, useRef } from "react";
import { postRequest, getRequest } from "../../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import Moment from "react-moment";

const AgriService = () => {
  const [agritourism, setAgriTourism] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState("");
  const editor = useRef(null);
  const [submitloader, setSubmitLoader] = useState(false);
  const [input, setInput] = useState({ imagePath: "" });
  const [area, setarea] = useState();
  const [image, setImage] = useState();
  const [thumbnails, setThumbnails] = useState();
  const [isMapShow, setIsMapShow] = useState(false);

  const toggleDropdown = async (i) => {
    setIsOpen(isOpen == i ? "" : i);
  };

  const handleMapShow = () => {
    setIsMapShow(!isMapShow);
  };

  const handleMapClick = (event) => {
    setInput((prev) => {
      return {
        ...prev,
        ["latitude"]: event.lat,
        ["longitude"]: event.lng,
      };
    });
  };

  const columnsAgri = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "80px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "100px",
    },
    { name: "EDIT", selector: (row) => row.edit, width: "100px" },
    {
      name: "TITLE",
      selector: (row) => row.title,
      width: "220px",
    },

    {
      name: "ADDRESS",
      selector: (row) => row.address,
      width: "270px",
    },
    {
      name: "NUMBER",
      selector: (row) => row.contactNo,
      width: "150px",
    },

    {
      name: "ENTRYON",
      selector: (row) => row.entryOn,
      width: "180px",
    },
  ];

  const dataAgri = agritourism?.map((d, i) => ({
    serial: i + 1,
    action: (
      <>
        <div key={i} className="dropdown">
          <button
            className="btn btn-green btn-sm dropdown-toggle"
            type="button"
            onClick={() => {
              toggleDropdown(i + 1);
            }}
          ></button>
          <ul
            className={` ${
              isOpen === i + 1 ? "d-block cus-drop" : "d-none cus-drop"
            }`}
          >
            <li>
              <NavLink to="/AgriDetail" state={{ agridata: d }}>
                <div className="dropdown-item">View Detail</div>
              </NavLink>
            </li>
            {/* <li>
              <a className="dropdown-item">Approve</a>
            </li>
            <li>
              <a className="dropdown-item">Disapprove</a>
            </li> */}
          </ul>
        </div>
      </>
    ),
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditAgriDetail/" + d.id,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
      </>
    ),
    title: (
      <>
        {d.title}
        <small className="d-block">[ID:{d?.id}]</small>
      </>
    ),

    address: (
      <>
        <span
          title={`${d.address}, ${d.landmark},  ${d.city}, ${d.state}, ${d.pincode}, ${d.country}`}
        >
          {d.address},&nbsp;
          {d.landmark},&nbsp;
          {d.city},&nbsp;
          {d.state},&nbsp;
          {d.pincode},&nbsp;{d.country}
        </span>
      </>
    ),
    contactNo: d.contactNo,
    entryOn: (
      <>
        <Moment format="DD MMMM YYYY ">{d.entryOn}</Moment>
      </>
    ),
  }));

  const agritourismList = async (id) => {
    const res = await postRequest(`api/Bussiness/GetAgriTourism`, {
      requestType: 1,
      id: id,
    });
    if (res.result) {
      setAgriTourism(res.result);
      if (id) {
        setInput(res.result[0]);
        const areaData = await getRequest(
          `api/Home/GetAreaListAsync?postalCode=${res.result[0]?.pincode}`
        );
        let _a = [{ id: 0, area: "Select... " }, ...areaData];
        setarea(_a);
      }

      setLoading(false);
    } else {
      toast.error("Credentials invalid");
    }
  };
  const goback = () => {
    navigate("/AgriTourism");
  };

  /////******ADD AND EDIT AGRI DETAIL */

  const handleChange = async (e, pinCode) => {
    const { name, value } = e.target;
    if (pinCode) {
      if (value.length == 6) {
        const res = await getRequest(
          `api/Home/GetAreaListAsync?postalCode=${value}`
        );
        let _a = [{ id: 0, area: "SELECT " }, ...res];
        setarea(_a);
      }
    }
    setInput((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleEditor = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        ["description"]: e,
      };
    });
  };
  const handleImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["images"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
  };
  const handlethumbNail = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["thumbnail"] = objectUrl;
    setThumbnails({
      value: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      let res = {};
      let formData = new FormData();
      let __d = { ...input };
      formData.append("ImagesFile", image?.value);
      formData.append("ThumbnailFile", thumbnails?.value);
      for (let key in __d) {
        if (key === "areaId" || key === "noOfCottage") {
          formData.append(key, parseInt(__d[key]));
        } else {
          formData.append(key, __d[key]);
        }
      }
      res = await postRequest(
        `api/Bussiness/AddAgriTourism`,
        formData,
        "multipart/form-data"
      );
      if (!res) {
        toast.error(res?.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/AgriTourism", { replace: true });
        }
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || "Something went wrong";
        toast.error(errorMessage);
      } else {
        toast.error("Something went wrong");
      }
    }
    setSubmitLoader(false);
  };

  return {
    agritourismList,
    columnsAgri,
    dataAgri,
    goback,
    loading,
    submitloader,
    editor,
    handleChange,
    area,
    input,
    handleEditor,
    handleImage,
    handlethumbNail,
    handleSubmit,
    isMapShow,
    handleMapShow,
    handleMapClick,
  };
};

export default AgriService;
