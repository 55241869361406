import HeaderTop from "./HeaderTop";

import FooterLast from "./FooterLast";
import { useEffect } from "react";

let Blogdetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <HeaderTop />
     
      <main>

        <section className="breadcrumb-area breadcrumb-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="breadcrumb-content">
                            <h2 className="title">Blog Details</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Blog Single</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="blog-details-area blog-gray-bg">
            <div className="container">
                <div className="container-inner-wrap">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-10">
                            <div className="standard-blog-item mb-50">
                                <div className="blog-thumb">
                                    <img src="siteadmin/img/blog/blog_thumb01.webp" className="w-100" alt="Hello Farmers" />
                                </div>
                                <div className="standard-blog-content blog-details-content">
                                    <h4 className="title">Community Supported Agriculture:</h4>
                                    <ul className="standard-blog-meta">
                                        <li><a href="#"><i className="far fa-user"></i>By Admin</a></li>
                                        <li><a href="#"><i className="far fa-calendar"></i>April 15, 2023</a></li>
                                        <li><a href="#"><i className="far fa-comment-alt"></i>35 Comments</a></li>
                                    </ul>
                                    <p>Our specially designed HelloFarmers Community Farming as a Service (CFaaS) business model is developed by thinking about the consumer as a community and providing direct farm access to every individual consumer where they can choose what to grow for their food requirements and farmer can receive a fair price in return. HelloFarmers is a brilliant next level example of Community Supported Agriculture.</p>
                                    <p><b> Community Farming</b> is a relatively recent and innovative concept that is intended to create a relationship between farmers and consumers. Explore the new ways of growing farms. Let’s grow your own food with a new advanced style. With this small footprint, you choose rapid growth of locally grown, fresh, pesticide-free food</p>
                                    <blockquote>
                                        "Community farming also provides opportunities for social and cultural exchange. When individuals come together to farm, they have the opportunity to learn from each other and build relationships. This is helpful to strengthen the community and promote a sense of belonging. For example, community members come together to share meals, participate in festivals and celebrations, and even create art and crafts."
                                    </blockquote>
                                    <p><b>One of the key principles of community farming is sustainability : </b>Community farming practices are often designed to promote sustainable agriculture, which means that the farm is managed in a way that is environmentally friendly, socially just, and economically viable. This includes practices such as crop rotation, composting, and conservation of natural resources. Additionally, community farming promotes the use of organic and non-toxic methods of farming, which is beneficial to both the environment and human health</p>
                                    
                                </div>
                            </div>
                           
                            <div className="comment-reply-box">
                                <h5 className="title">LEAVE A REPLY</h5>
                                <form action="#" className="comment-reply-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="text" placeholder="Author *" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="email" placeholder="Your Email *" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-grp">
                                        <textarea name="message" placeholder="Type Comment Here..."></textarea>
                                    </div>
                                    <div className="form-grp checkbox-grp">
                                        <input type="checkbox" id="checkbox" />
                                        <label for="checkbox">Don’t show your email address</label>
                                    </div>
                                    <button type="submit" className="btn rounded-btn">Submit now</button>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>


    </main>
      <FooterLast />
    </>
  );
};

export default Blogdetails;
