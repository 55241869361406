import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import numberToWords from "number-to-words";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { useEffect } from "react";

const NaviBar = ({ toggleSidebar }) => {
  const [showModal, setShowModal] = useState(false);
  const [input, setInput] = useState();
  const [storedRole, setRole] = useState();
  const [formattedAmount, setFormattedAmount] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role);
    if (input?.amount) {
      const numericAmount = input?.amount;
      const amountWords = numberToWords.toWords(numericAmount);
      setFormattedAmount(amountWords);
    } else {
      setFormattedAmount("");
    }
  }, [input?.amount]);

  const handlepopupAddMoney = () => {
    setShowModal(true);
  };

  const handlingCloseModal = () => {
    setShowModal(false);
  };

  const handleAddMoney = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleConfirmAddMoney = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(
        `api/user/AddMoney?amount=${input?.amount}&paymentGatewayType=${0}`
      );
      if (res?.statusCode === 1) {
        toast.success(res.responseText);
        setInput({});
      } else {
        toast.error(res.responseText);
      }
      setShowModal(false);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <link
        href="/assets/vendor/bootstrap-icons/bootstrap-icons.css"
        rel="stylesheet"
      ></link>
      <link href="/assets/admin-style.css" rel="stylesheet" />
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <a href="" className="logo d-flex align-items-center">
            <img src="/assets/img/logoadmin.png" alt="Hello Farmers" />
          </a>
          {/* <i
            className="bi bi-list toggle-sidebar-btn pc-off"
            onClick={toggleSidebar}
          ></i> */}
          <i
            onClick={toggleSidebar}
            className="bi bi-list toggle-sidebar-btn"
          ></i>
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {storedRole === "CustomerCare" ? (
              <li className="nav-item dropdown cus-header">
                <a className="nav-link nav-icon" data-bs-toggle="dropdown">
                  <i className="bi bi-bell cus-bell"></i>
                  <span className="badge bg-primary badge-number"></span>
                </a>
              </li>
            ) : null}
            <li
              className="nav-item dropdown pe-3"
              style={{ cursor: "pointer" }}
            >
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0 "
                data-bs-toggle="dropdown"
              >
                <img
                  src="/assets/img/profile2.png"
                  alt="Profile"
                  className="rounded-circle"
                />
              </a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile cus-accounts">
                {storedRole === "Admin" ? (
                  <>
                    <li className="dropdown-header d-flex align-items-center">
                      <img
                        src="/assets/img/profile.png"
                        alt="Profile"
                        style={{ width: "40%", marginRight: "10px" }}
                        className="img-fluid"
                      />
                      <h6>
                        Hello Farmers
                        <br /> <span>Admin</span>
                      </h6>
                    </li>
                  </>
                ) : null}
                {storedRole === "CustomerCare" ? (
                  <li className="dropdown-header d-flex align-items-center">
                    <img
                      src="/assets/img/profile.png"
                      alt="Profile"
                      style={{ width: "40%", marginRight: "10px" }}
                      className="img-fluid"
                    />
                    <h6>
                      Hello Farmers
                      <br /> <span>CustomerCare</span>
                    </h6>
                  </li>
                ) : null}

                {storedRole === "Admin" ? (
                  <>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <NavLink
                        to="/Profile"
                        className="dropdown-item d-flex align-items-center"
                      >
                        <i className="bi bi-box-arrow-right"></i>
                        <span>My Profile</span>
                      </NavLink>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <span
                        className="dropdown-item "
                        type="button"
                        onClick={handlepopupAddMoney}
                      >
                        <i className="bi bi-plus-square"></i>
                        Add Money
                      </span>
                    </li>
                  </>
                ) : null}
                {storedRole === "CustomerCare" ? (
                  <li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <NavLink
                      to="/Password"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <i className="bi bi-gear"></i>
                      <span>Password Settings</span>
                    </NavLink>
                  </li>
                ) : null}
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <NavLink
                    to="/LogOut"
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </NavLink>
                </li>

                {/* <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="pages-faq.html"
                  >
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </a>
                </li> */}
              </ul>
            </li>
          </ul>
        </nav>
      </header>
      <Modal
        show={showModal}
        onHide={handlingCloseModal}
        className="cus-top-modal"
      >
        <Modal.Header>
          <Modal.Title className="mt-3 change-status">Add Money</Modal.Title>
          <span
            aria-hidden="true"
            onClick={handlingCloseModal}
            className="modal-cross"
            style={{ fontSize: "24px", color: "red", cursor: "pointer" }}
          >
            &times;
          </span>
        </Modal.Header>
        <form onSubmit={handleConfirmAddMoney}>
          <Modal.Body className="pt-0 modal-body-textarea pb-2">
            <div className="row">
              <div className="col-md-12 d-flex"></div>
              <div className="col-md-3 py-2">
                <h6
                  style={{
                    textAlign: "left",
                    fontWeight: "normal",
                    marginTop: "5px",
                  }}
                >
                  Amount :
                </h6>
              </div>
              <div className="col-md-9 py-2">
                <input
                  type="number"
                  className="form-control cus-input"
                  placeholder="Enter Amount"
                  name="amount"
                  value={input?.amount || ""}
                  onChange={handleAddMoney}
                  required={true}
                />
              </div>
            </div>

            <div className="row px-3">
              <h6
                style={{
                  textAlign: "left",
                  fontWeight: "normal",
                  marginTop: "1rem",
                }}
              >
                <span style={{ color: "red", fontSize: "15px" }}>
                  {formattedAmount}
                </span>
              </h6>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handlingCloseModal}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              Transfer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default NaviBar;
