import React from "react";
import { useEffect } from "react";
import FarmService from "./FarmService";
import Style from "./Farm.module.css";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const FarmCommunity = () => {
  const { farmCommunityList, loading, data, columns } = FarmService();

  useEffect(() => {
    farmCommunityList();
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Farm Community</li>

                      <li className="breadcrumb-item active">Community List</li>
                    </ul>
                  </div>

                  <div className="col-md-6 col-sm-12 d-flex align-items-center justify-content-end">
                    <NavLink to="/EditFarmDetail" className="btn btn-sm mr-3">
                      <i className="fa fa-plus-square"></i>&nbsp;ADD
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" card">
        <div className="card-body p-2">
          <div className="table-responsive ">
            {loading ? (
              <div className="spinner-container">
                <Spinner type="TailSpin" color="#00BFFF" />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                pagination
                className="rdt_TableHeadRow"
                fixedHeader
                fixedHeaderScrollHeight="calc(100vh - 245px)"
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default FarmCommunity;
