import "./App.css";
import "./Component/Homes/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
import { DATACONSTANT } from "./Services/DataConstant";
import { getCookie } from "./Services/Cookies";
import Login from "./Component/Homes/Login";
import LoginOtp from "./Component/Homes/LoginOtp";
import Dashboard from "./Component/Administrative/Dashboard";
import Footer from "./Component/Administrative/Footer";
import Package from "./Pages/Package/Package";
import EditPackage from "./Pages/Package/EditPackage";
import Product from "./Pages/Product/Product";
import EditProduct from "./Pages/Product/EditProduct";
import ProductInPackage from "./Pages/Product In Package/ProductInPackage";
import ProductCard from "./Pages/Product In Package/ProductCard";
import Home from "./Component/Homes/Home";
import LogOut from "./Component/LogOut";
import Registration from "./Component/Homes/Registration";
import User from "./Pages/User/User";
import Useraddon from "./Pages/User/Useraddon";
import ProtectedRoute from "./Component/ProtectedRoute ";
import Category from "./Pages/Category/Category";
import EditCategory from "./Pages/Category/EditCategory";
import Banner from "./Pages/Banner/Banner";
import EditBanner from "./Pages/Banner/EditBanner";
import CropList from "./Pages/Crop/CropList";
import EditCrop from "./Pages/Crop/EditCrop";
import GetOrder from "./Pages/Order/GetOrder";
import AssignArea from "./Pages/User/AssignArea";
import AboutUs from "./Component/Homes/AboutUs";
import Oppurtunity from "./Component/Homes/Oppurtunity";
import Solution from "./Component/Homes/Solution";
import Team from "./Component/Homes/Team";
import Blog from "./Component/Homes/Blog";
import Refund from "./Component/Homes/Refund";
import Terms from "./Component/Homes/Terms";
import Privacy from "./Component/Homes/Privacy";
import Faq from "./Component/Homes/FAQ";
import MappedProduct from "./Pages/Product In Package/MappedProduct";
import FarmCommunity from "./Pages/Business/FarmCommunity/FarmCommunity";
import AgriTourism from "./Pages/Business/AgriTourism/AgriTourism";
import AgriDetail from "./Pages/Business/AgriTourism/AgriDetail";
import FarmDetail from "./Pages/Business/FarmCommunity/FarmDetail";
import EditFarmDetail from "./Pages/Business/FarmCommunity/EditFarmDetail";
import EditAgriDetail from "./Pages/Business/AgriTourism/EditAgriDetail";
import IrrigationCommunity from "./Pages/Business/IrrigationSource/IrrigationSource";
import AddIrrigationSource from "./Pages/Business/IrrigationSource/AddIrrigationSource";
import PowerSource from "./Pages/Business/PowerSource/PowerSource";
import AddPowerSource from "./Pages/Business/PowerSource/AddPowerSource";
import GetDealsOfDay from "./Pages/DealsOfDay/GetDealsOfDay";
import EditDealsofDay from "./Pages/DealsOfDay/EditDealsofDay";
import ListOfDealProduct from "./Pages/DealsOfDay/ListOfDealProduct";
import GetSeasonType from "./Pages/Season/Season Type/GetSeasonType";
import EditSeasonType from "./Pages/Season/Season Type/EditSeasonType";
import GetMonth from "./Pages/Season/Season Type/GetMonth";
import AreaList from "./Pages/Area/AreaList";
import Password from "./Pages/Password/Password";
import InvoiceOrder from "./Pages/Order/InvoiceOrder";
import CouponList from "./Pages/Coupon/CouponList";
import AddCoupon from "./Pages/Coupon/AddCoupon";
import SystemSetting from "./Pages/Settings/SystemSetting";
import PGReport from "./Pages/Order/PGReport";
import Ledger from "./Pages/Order/Ledger";
import RecurringOrder from "./Pages/Order/RecurringOrder";
import PinCodeMapping from "./Pages/Settings/PinCodeMapping";
import EditDeliveryPerson from "./Pages/Settings/EditDeliveryPerson";
import AssigningArea from "./Pages/Settings/AssigningArea";
import ScheduleOnOrder from "./Pages/Order/ScheduleOnOrder";
import Contactus from "./Component/Homes/Contactus";
import Agripreneur from "./Component/Homes/Agripreneur";
import Blogdetails from "./Component/Homes/Blogdetails";
import Events from "./Component/Homes/Events";
import Eventdetails from "./Component/Homes/Eventdetails";
import Gallery from "./Component/Homes/Gallery";
import UserProfile from "./Pages/User/UserProfile";
import CutomerCare from "./Pages/CustomerCare/CutomerCare";
import AddCustomerCare from "./Pages/CustomerCare/AddCustomerCare";
import Profile from "./Pages/AdminProfile/Profile";
import Notification from "./Pages/Notification/Notification";
import EmailSettings from "./Pages/Settings/EmailSetting/EmailSettings";
import EditEmailSetting from "./Pages/Settings/EmailSetting/EditEmailSetting";
import SmsSettings from "./Pages/Settings/SMSSetting/SmsSettings";
import EditSmsSetting from "./Pages/Settings/SMSSetting/EditSmsSetting";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    if (showSidebar) document.body.classList.add("toggle-sidebar");
    else document.body.classList.remove("toggle-sidebar");
  };
  // window.cookieStore.addEventListener("change", ({ changed }) => {
  //   for (const { name, value } of changed) {
  //     if (name === ".front.authToken") {
  //       console.log(`${name} was set to ${value}`);
  //       navigate("/Login");
  //     }
  //   }
  // });

  window.addEventListener("unload", () => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === ".front.authToken") {
        console.log(`${name} was set to ${value}`);
        navigate("/Login");
      }
    }
  });

  document.addEventListener("DOMContentLoaded", function () {
    // Find all elements with the class 'dropdown-btn'
    const dropdownButtons = document.querySelectorAll(
      ".mobile-menu li.menu-item-has-children .dropdown-btn"
    );

    // Add click event listeners to each 'dropdown-btn'
    dropdownButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        // Toggle the 'open' class on the clicked button
        button.classList.toggle("open");

        // Find the previous sibling 'ul' element
        const previousUL = button.previousElementSibling;

        // Toggle the 'display' style of the 'ul' element
        if (
          previousUL.style.display === "none" ||
          previousUL.style.display === ""
        ) {
          previousUL.style.display = "block";
        } else {
          previousUL.style.display = "none";
        }
      });
    });
  });

  // Check if elements with the class 'mobile-menu' exist
  var mobileMenuElements = document.querySelectorAll(".mobile-menu");

  if (mobileMenuElements.length) {
    // Get the content of the main menu
    var mainMenuContent = document.querySelector(
      ".menu-area .main-menu"
    ).innerHTML;

    // Append the main menu content to the mobile menu
    mobileMenuElements.forEach(function (mobileMenu) {
      var menuOuter = mobileMenu.querySelector(".menu-box .menu-outer");
      if (menuOuter) {
        menuOuter.innerHTML += mainMenuContent;
      }
    });

    // Dropdown Button
    var dropdownButtons = document.querySelectorAll(
      ".mobile-menu li.menu-item-has-children .dropdown-btn"
    );
    dropdownButtons.forEach(function (button) {
      button.addEventListener("click", function () {
        this.classList.toggle("open");
        var submenu = this.previousElementSibling;
        if (submenu) {
          if (submenu.style.display === "block") {
            submenu.style.display = "none";
          } else {
            submenu.style.display = "block";
          }
        }
      });
    });

    // Menu Toggle Btn
    var mobileNavToggler = document.querySelectorAll(".mobile-nav-toggler");
    mobileNavToggler.forEach(function (toggler) {
      toggler.addEventListener("click", function () {
        document.body.classList.add("mobile-menu-visible");
      });
    });

    // Menu Toggle Btn
    var closeButtons = document.querySelectorAll(
      ".menu-backdrop, .mobile-menu .close-btn"
    );
    closeButtons.forEach(function (closeButton) {
      closeButton.addEventListener("click", function () {
        document.body.classList.remove("mobile-menu-visible");
      });
    });
  }

  let x = getCookie(DATACONSTANT.COOKIE_NAME);

  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      e.preventDefault();
    });
    setIsAuthenticated(!!getCookie(DATACONSTANT.COOKIE_NAME));
  }, [isAuthenticated, x]);

  const mynavElement = document.getElementById("sidebar");
  const sidebarLinks = mynavElement?.querySelectorAll(".nav-item");
  sidebarLinks?.forEach((link) => {
    link.addEventListener("click", () => {
      if (window.innerWidth < 768) {
        toggleSidebar();
      }
    });
  });

  if (location?.pathname?.toLowerCase() === "/login" && isAuthenticated) {
    window.location.href = "/";
  }

  return (
    <>
      <ToastContainer />

      {isAuthenticated && location?.pathname !== "/home" ? (
        <>
          {/* <NaviBar toggleSidebar={toggleSidebar} /> */}
          {/* <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} /> */}
        </>
      ) : (
        ""
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            isAuthenticated ? (
              <ProtectedRoute
                showSidebar={showSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Dashboard />
              </ProtectedRoute>
            ) : (
              <Home />
            )
          }
        />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/AboutUs" element={<AboutUs />} />
        <Route exact path="/Oppurtunity" element={<Oppurtunity />} />
        <Route exact path="/Solution" element={<Solution />} />
        <Route exact path="/Agripreneur" element={<Agripreneur />} />
        <Route exact path="/Team" element={<Team />} />
        <Route exact path="/Blog" element={<Blog />} />
        <Route exact path="/Blog-details" element={<Blogdetails />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/event-details" element={<Eventdetails />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/Refund" element={<Refund />} />
        <Route exact path="/Terms" element={<Terms />} />
        <Route exact path="/Privacy" element={<Privacy />} />
        <Route exact path="/Faq" element={<Faq />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/LoginOtp" element={<LoginOtp />} />
        <Route path="/Registration" element={<Registration />} />
        <Route path="/contactus" element={<Contactus />} />
        {/* <Route
          path="/"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Dashboard />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/Dashboard"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Profile"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Package"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Package />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditPackage/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditPackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditPackage"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditPackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditProduct/:id/:cId"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditProduct/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditProduct"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Product"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Product />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Category"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Category />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditCategory"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditCategory/:categoryId"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/LogOut"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <LogOut />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Password"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Password />
            </ProtectedRoute>
          }
        />
        <Route
          path="/User"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AssignArea"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AssignArea />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AssignArea/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AssignArea />
            </ProtectedRoute>
          }
        />
        <Route
          path="/User/:RoleID"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Banner"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Banner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditBanner"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditBanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditBanner/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditBanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CropList"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <CropList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditCrop"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditCrop />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditCrop/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditCrop />
            </ProtectedRoute>
          }
        />
        <Route
          path="/GetOrder"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <GetOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/InvoiceOrder"
          element={
            <ProtectedRoute
            // showSidebar={showSidebar}
            // toggleSidebar={toggleSidebar}
            >
              <InvoiceOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/InvoiceOrder/:id"
          element={
            <ProtectedRoute
            // showSidebar={showSidebar}
            // toggleSidebar={toggleSidebar}
            >
              <InvoiceOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Useraddon"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Useraddon />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ProductInPackage"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <ProductInPackage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ProductCard/:packageName/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <ProductCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/MappedProduct"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <MappedProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/FarmCommunity"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <FarmCommunity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AgriTourism"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AgriTourism />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AgriDetail"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AgriDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/FarmDetail"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <FarmDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditFarmDetail"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditFarmDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditFarmDetail/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditFarmDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditAgriDetail"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditAgriDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditAgriDetail/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditAgriDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/IrrigationSource"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <IrrigationCommunity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddIrrigationSource"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AddIrrigationSource />
            </ProtectedRoute>
          }
        />
        <Route
          path="/PowerSource"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <PowerSource />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddPowerSource"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AddPowerSource />
            </ProtectedRoute>
          }
        />
        <Route
          path="/GetDealsOfDay"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <GetDealsOfDay />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditDealsofDay"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditDealsofDay />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ListOfDealProduct"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <ListOfDealProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditDealsofDay/:dealsId"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditDealsofDay />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditSeasonType"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditSeasonType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/GetSeasonType"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <GetSeasonType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditSeasonType/:seasonId"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditSeasonType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/GetMonth"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <GetMonth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/GetMonth/:seasonId"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <GetMonth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AreaList"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AreaList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CouponList"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <CouponList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddCoupon"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AddCoupon />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/UnMapCoupon"
          element={
            <ProtectedRoute showSidebar={showSidebar} toggleSidebar={toggleSidebar}>
              <CouponUnMap />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/SystemSetting"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <SystemSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/PGReport"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <PGReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Ledger"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Ledger />
            </ProtectedRoute>
          }
        />
        <Route
          path="/RecurringOrder"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <RecurringOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/PinCodeMapping"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <PinCodeMapping />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditDeliveryPerson"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditDeliveryPerson />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AssigningArea"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AssigningArea />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AssigningArea/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AssigningArea />
            </ProtectedRoute>
          }
        />
        <Route
          path="/UserProfile"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/UserProfile/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ScheduleOnOrder"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <ScheduleOnOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CustomerCare"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <CutomerCare />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AddCustomerCare"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <AddCustomerCare />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Notification"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <Notification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EmailSettings"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EmailSettings />
            </ProtectedRoute>
          }
        />

        <Route
          path="/EditEmailSetting"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditEmailSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditEmailSetting/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditEmailSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SmsSettings"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <SmsSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditSmsSetting"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditSmsSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditSmsSetting/:id"
          element={
            <ProtectedRoute
              showSidebar={showSidebar}
              toggleSidebar={toggleSidebar}
            >
              <EditSmsSetting />
            </ProtectedRoute>
          }
        />

        <Route
          path="/*"
          element={
            <main
              id="main"
              className="p-5"
              style={{ height: "calc(100vh - 100px)" }}
            >
              <h1 className="">404 Not found</h1>
            </main>
          }
        />
      </Routes>

      {/* {isAuthenticated ? <Footer /> : ""}  */}
    </>
  );
}

export default App;
