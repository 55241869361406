import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import ProductService from "./ProductService";
import { useParams } from "react-router-dom";
import Select from "react-select";
import JoditEditor from "jodit-react";

const EditProduct = () => {
  const {
    handleChangeGroup,
    handleChange,
    handleImage,
    handleSubmit,
    getproduct,
    getunit,
    list,
    input,
    getCategoryList,
    unit,
    submitloader,
    errors,
    getSeasonList,
    seasonList,
    getCouponList,
    couponData,
    handleOptions,
    selectedCoupons,
    editor,
    handleEditor,
    inputRef,
    mrpRef,
    smrpRef,
    titleRef,
    nameRef,
  } = ProductService();

  const params = useParams();

  useEffect(() => {
    if (params.id !== undefined) {
      getproduct();
    }
    getCategoryList();
    getSeasonList();
  }, []);

  useEffect(() => {
    getCouponList();
  }, [input?.couponIds]);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Product </li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container ">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Category :
                      <select
                        ref={inputRef}
                        className="form-control"
                        name="categoryId"
                        value={input?.categoryId}
                        onChange={handleChange}
                      >
                        <option value="" selected>
                          Select category...
                        </option>
                        {list?.map((item) => (
                          <option key={item?.id} value={item?.categoryId}>
                            {item?.categoryName}
                          </option>
                        ))}
                      </select>
                      {errors.categoryId && (
                        <div className="help-block text-danger">
                          {errors.categoryId}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Season :
                      <select
                        className="form-control"
                        name="seasonalId"
                        value={input?.seasonalId}
                        onChange={handleChange}
                      >
                        <option value="" selected>
                          Select season...
                        </option>
                        {seasonList?.map((item) => (
                          <option key={item?.id} value={item?.seasonId}>
                            {item?.seasonName}
                          </option>
                        ))}
                      </select>
                      {errors.SeasonalId && (
                        <div className="help-block text-danger">
                          {errors.SeasonalId}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Title :
                      <input
                        ref={titleRef}
                        type="text"
                        className="form-control"
                        id="productTitle"
                        name="productTitle"
                        placeholder="Enter the product title"
                        value={input?.productTitle}
                        onChange={handleChange}
                      />
                      {errors.productTitle && (
                        <div className="help-block text-danger">
                          {errors.productTitle}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Name :
                      <input
                        ref={nameRef}
                        type="text"
                        className="form-control"
                        id="productName"
                        name="productName"
                        placeholder="Enter the product name"
                        value={input?.productName}
                        onChange={handleChange}
                      />
                      {errors.productName && (
                        <div className="help-block text-danger">
                          {errors.productName}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Mrp :
                      <input
                        ref={mrpRef}
                        type="number"
                        className="form-control"
                        id="mrp"
                        name="mrp"
                        placeholder="Enter the mrp in rs."
                        value={input?.mrp || ""}
                        onChange={handleChange}
                      />
                      {errors.mrp && (
                        <div className="help-block text-danger">
                          {errors.mrp}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Selling Price :
                      <input
                        ref={smrpRef}
                        type="number"
                        className="form-control"
                        id="sellingPrice"
                        name="sellingPrice"
                        placeholder="Enter the selling price in rs."
                        value={input?.sellingPrice || ""}
                        onChange={handleChange}
                      />
                      {errors.sellingPrice && (
                        <div className="help-block text-danger">
                          {errors.sellingPrice}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Sku Group :
                      <select
                        className="form-control"
                        id="skuGroup"
                        name="skuGroup"
                        value={input?.skuGroup}
                        onChange={handleChangeGroup}
                      >
                        <option value="N/A">Select group...</option>
                        <option value="W">Weight</option>
                        <option value="L">Liquid</option>
                        <option value="C">Piece</option>
                      </select>
                      {errors.skuGroup && (
                        <div className="help-block text-danger">
                          {errors.skuGroup}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Packaging :
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="quantity"
                          name="quantity"
                          placeholder="Enter the product quantity"
                          value={input?.quantity || ""}
                          onChange={handleChange}
                        />

                        <div className="input-group-append d-flex">
                          <select
                            className="form-control"
                            name="skuId"
                            value={input?.skuId}
                            onChange={handleChange}
                          >
                            {unit?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {errors.quantity && (
                        <div className="help-block text-danger">
                          {errors.quantity}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      Select Coupons :
                      <Select
                        name="applicableCoupons"
                        options={couponData}
                        onChange={handleOptions}
                        value={selectedCoupons}
                        isMulti
                      />
                      {errors.applicableCoupons && (
                        <div className="help-block text-danger">
                          {errors.applicableCoupons}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 ">
                    <div className="form-group mb-2">
                      Image :
                      <input
                        type="file"
                        className="form-control"
                        id="imageData"
                        name="imageData"
                        onChange={handleImage}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 ">
                    <img
                      className="d-block mx-3"
                      style={{
                        minHeight: "80px",
                        maxWidth: "80px",
                      }}
                      src={input?.imageData}
                      onError={(e) =>
                        (e.target.onerror = null)(
                          (e.target.src = "/assets/img/noimage.jpg")
                        )
                      }
                      class="card-img-top"
                    />
                  </div>

                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isPublished"
                            type="checkbox"
                            checked={input?.isPublished}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Published</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isVeg"
                            type="checkbox"
                            checked={input?.isVeg}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Veg</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isSeasonalFood"
                            type="checkbox"
                            checked={input?.isSeasonalFood}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right ">
                          <span>SeasonalFood</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isShowOnHome"
                            type="checkbox"
                            checked={input?.isShowOnHome}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>ShowOnHome</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isCultivatedDateShow"
                            type="checkbox"
                            checked={input?.isCultivatedDateShow}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>CultivatedDateShow</span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group mb-2">
                      Description:
                      <JoditEditor
                        ref={editor}
                        tabIndex={1}
                        id="productDescription"
                        name="productDescription"
                        value={input?.productDescription}
                        onChange={(e) => handleEditor(e)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            {!submitloader ? (
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-green">Submitting...</button>
              </>
            )}
            <NavLink
              to="/Product"
              className="btn btn-danger"
              state={{ CID: params.cId }}
            >
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};

export default EditProduct;
