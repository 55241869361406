import React, { useState } from "react";
import { postRequest } from "../../Services/ApiServices";

const ProfileService = () => {
  const [profile, setProfile] = useState();

  const getAdminProfile = async () => {
    try {
      const res = await postRequest(`api/user/GetUserDetail`);
      if (res) {
        setProfile(res);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  return { profile, getAdminProfile };
};

export default ProfileService;
