import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import CategoryService from "./CategoryService";
const EditCategory = () => {
  const {
    handleChange,
    handleImage,
    handleSubmit,
    handleBanner,
    params,
    input,
    getCategoryDetail,
    submitloader,
    errors,
  } = CategoryService();

  useEffect(() => {
    if (params.categoryId !== undefined) {
      getCategoryDetail();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0 ">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Category </li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container w-100">
        <div className="card">
          <div className="card-body  p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-2">
                      Name :
                      <input
                        type="text"
                        className="form-control"
                        id="categoryName"
                        name="categoryName"
                        placeholder="Enter the category name"
                        value={input?.categoryName}
                        onChange={handleChange}
                      />
                      {errors.categoryName && (
                        <div className="help-block text-danger">
                          {errors.categoryName}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="form-group mb-2">
                      Tax Rate :
                      <input
                        type="text"
                        className="form-control"
                        id="taxRate"
                        name="taxRate"
                        placeholder="Enter the tax rate in percentage"
                        value={input?.taxRate || ""}
                        onChange={handleChange}
                      />
                      {errors.taxRate && (
                        <div className="help-block text-danger">
                          {errors.taxRate}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="form-group mb-2">
                      Category Image :
                      <input
                        type="file"
                        className="form-control"
                        id="icon"
                        name="icon"
                        onChange={handleImage}
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <img
                      className="d-block mx-4"
                      style={{
                        minHeight: "80px",
                        maxWidth: "80px",
                      }}
                      src={input?.icon || "/assets/img/noimage.jpg"}
                      onError={(e) =>
                        (e.target.onerror = null)(
                          (e.target.src = "/assets/img/noimage.jpg")
                        )
                      }
                      class="card-img-top"
                    />
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      Banner Image :
                      <input
                        type="file"
                        className="form-control"
                        id="banners"
                        name="banners"
                        onChange={handleBanner}
                        multiple
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <img
                      className="d-block mx-4"
                      style={{
                        minHeight: "80px",
                        maxWidth: "80px",
                      }}
                      src={input?.banners || "/assets/img/noimage.jpg"}
                      onError={(e) =>
                        (e.target.onerror = null)(
                          (e.target.src = "/assets/img/noimage.jpg")
                        )
                      }
                      class="card-img-top"
                    />
                  </div>

                  <div className="col-md-2">
                    <div className="form-group ">
                      <label className="mb-0 d-flex align-items-center">
                        <div className="radio-left">
                          <input
                            name="isPublish"
                            type="checkbox"
                            checked={input?.isPublish}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="radio-right">
                          <span>Published</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {!submitloader ? (
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              ) : (
                <button className="btn btn-green">Submitting...</button>
              )}
              <NavLink to="/Category" className="btn btn-danger">
                Close
              </NavLink>
            </div>
          </div>
        </div>
      </form>
    </main>
  );
};
export default EditCategory;
