import React from "react";
import SeasonTypeService from "./SeasonTypeService";
import { NavLink, useParams } from "react-router-dom";
import { useEffect } from "react";

const EditSeasonType = () => {
  const {
    submitloader,
    handleChange,
    handleSubmit,
    input,
    seasonTypeById,
    errors,
  } = SeasonTypeService();
  const params = useParams();

  useEffect(() => {
    if (params.seasonId !== undefined) {
      seasonTypeById(params?.seasonId);
    }
  }, []);
  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Seasons </li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container w-100">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Season Name :
                      <input
                        type="text"
                        className="form-control"
                        id="seasonName"
                        name="seasonName"
                        placeholder="Ener the season name"
                        value={input?.seasonName}
                        onChange={handleChange}
                      />
                      {errors.seasonName && (
                        <div className="help-block text-danger">
                          {errors.seasonName}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            {!submitloader ? (
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-green">Submitting...</button>
              </>
            )}
            <NavLink to="/GetSeasonType" className="btn btn-danger">
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};

export default EditSeasonType;
