import React from "react";
import { NavLink } from "react-router-dom";
import SettingServices from "./SettingServices";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

const SystemSetting = () => {
  const {
    getSystemSettingData,
    systemSettingData,
    handleInputChange,
    handleUpdateSetting,
    isUpdating,
    loading,
  } = SettingServices();

  useEffect(() => {
    getSystemSettingData();
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-9 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Setting</li>
                        <li className="breadcrumb-item"> System Setting</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-2 pl-1">
          {loading ? (
            <div className="spinner-container">
              <Spinner type="TailSpin" color="#00BFFF" />
            </div>
          ) : (
            <>
              <div className="card-body">
                {systemSettingData?.map((d, i) => (
                  <>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label for="minimum_Purchase" className="lbl">
                            <i className="fas fa-rupee-sign text-success"></i>
                            &nbsp;Minimum Purchase
                          </label>
                          <input
                            aria-describedby="iftHelp"
                            className="form-control"
                            id="minimum_Purchase"
                            name="minimum_Purchase"
                            placeholder="Enter the minimum purchase"
                            type="number"
                            value={d?.minimum_Purchase}
                            onChange={(e) =>
                              handleInputChange(
                                i,
                                "minimum_Purchase",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label for="orderCancellationBefore">
                            <i className="fas fa-clock text-info"></i>&nbsp;
                            Order Cancellation Before(Hours)
                          </label>
                          <input
                            aria-describedby="minfHelp"
                            className="form-control"
                            id="orderCancellationBefore"
                            name="orderCancellationBefore"
                            placeholder="Enter the number in hours"
                            type="number"
                            value={d?.orderCancellationBefore}
                            onChange={(e) =>
                              handleInputChange(
                                i,
                                "orderCancellationBefore",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="cashback_Type">
                            <i className="fas fa-exclamation-circle text-warning"></i>
                            &nbsp; Cashback Type
                          </label>
                          <select
                            className="form-control"
                            id="cashback_Type"
                            name="cashback_Type"
                            value={d?.cashback_Type}
                            onChange={(e) =>
                              handleInputChange(
                                i,
                                "cashback_Type",
                                e.target.value
                              )
                            }
                          >
                            <option value="P">Percentage</option>
                            <option value="F">Fixed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="cashback">
                            {d?.cashback_Type === "P" ? (
                              <>
                                <i className="fas fa-percent text-warning"></i>
                                &nbsp;Cashback
                              </>
                            ) : (
                              <>
                                <i className="fas fa-rupee-sign text-success"></i>
                                &nbsp;Cashback
                              </>
                            )}
                          </label>
                          <input
                            aria-describedby="AEPHelp"
                            className="form-control"
                            id="cashback"
                            name="cashback"
                            placeholder="Enter the amount"
                            type="number"
                            value={d?.cashback}
                            onChange={(e) =>
                              handleInputChange(i, "cashback", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label for="ref_Comm">
                            <i className="fas fa-percent text-danger"></i>
                            &nbsp;&nbsp;Referral Commission
                          </label>
                          <input
                            aria-describedby="irHelp"
                            className="form-control"
                            id="ref_Comm"
                            name="ref_Comm"
                            placeholder="Enter the commission"
                            type="number"
                            value={d?.ref_Comm}
                            onChange={(e) =>
                              handleInputChange(i, "ref_Comm", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label for="ref_Duration_In_Days">
                            <i className="fas fa-calendar-day text-primary"></i>
                            &nbsp; Referral Duration In Days
                          </label>
                          <input
                            aria-describedby="maxfHelp"
                            className="form-control"
                            id="ref_Duration_In_Days"
                            name="ref_Duration_In_Days"
                            placeholder="Enter number of days"
                            type="number"
                            value={d?.ref_Duration_In_Days}
                            onChange={(e) =>
                              handleInputChange(
                                i,
                                "ref_Duration_In_Days",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="mb-2 mr-4 text-right">
                <button
                  type="button"
                  className="btn btn-success btn-sm"
                  id="btnUpdateSetting"
                  disabled={isUpdating}
                  onClick={handleUpdateSetting}
                >
                  {isUpdating ? "Updating...." : "Upadate Setting"}
                </button>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default SystemSetting;
