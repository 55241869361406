import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ showSidebar }) => {
  const [storedRole, setRole] = useState();
  // const storedRole = sessionStorage.getItem("role");
  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role);
  }, []);
  return (
    <div>
      <aside
        id="sidebar"
        className={showSidebar ? "sidebar d-block" : "sidebar"}
      >
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item cus-navlink">
            <NavLink to="/Dashboard" className="nav-link">
              <i className="bi-graph-up cus-position"></i>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item cursor-pointer">
            <a
              className="nav-link cus-navlink collapsed"
              data-bs-target="#business-nav7"
              data-bs-toggle="collapse"
            >
              <i className="bi bi-person-lines-fill cus-position"></i>
              <span>User Management</span>
              <i
                className="bi bi-chevron-right ms-auto cus-i"
                style={{ color: "black" }}
              ></i>
            </a>
            <ul
              id="business-nav7"
              className="nav-content collapse cus-child-business"
              data-bs-parent="#sidebar-nav"
            >
              <li className="nav-item cus-navlink">
                <NavLink to="/User" className="nav-link">
                  <i className="bi-people cus-position cus-sidebar cus-agriculture"></i>
                  <span>User</span>
                </NavLink>
              </li>
              {storedRole === "Admin" ? (
                <li className="nav-item cus-navlink">
                  <NavLink to="/CustomerCare" className="nav-link">
                    <i className="bi bi-envelope-plus  cus-position cus-sidebar cus-agriculture"></i>
                    <span>Customer Care</span>
                  </NavLink>
                </li>
              ) : null}
              <li className="nav-item cus-navlink">
                <NavLink to="/PinCodeMapping" className="nav-link">
                  <i className="bi bi-truck cus-position cus-sidebar cus-agriculture"></i>
                  <span>Delivery Manager</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            {storedRole === "Admin" ? (
              <>
                {" "}
                <li className="nav-item cursor-pointer">
                  <a
                    className="nav-link cus-navlink collapsed"
                    data-bs-target="#business-nav1"
                    data-bs-toggle="collapse"
                  >
                    <i className="bi bi-book-half cus-position "></i>
                    <span>Catalogue</span>
                    <i
                      className="bi bi-chevron-right ms-auto cus-i"
                      style={{ color: "black" }}
                    ></i>
                  </a>
                  <ul
                    id="business-nav1"
                    className="nav-content collapse"
                    data-bs-parent="#sidebar-nav"
                  >
                    <li className="nav-item cus-navlink">
                      <NavLink to="/Package" className="nav-link">
                        <i className=" bi-basket-fill cus-position cus-business-2"></i>
                        <span>Package</span>
                      </NavLink>
                    </li>
                    <li className="nav-item cus-navlink">
                      <NavLink to="/Product" className="nav-link">
                        <i className=" bi-basket3-fill cus-position cus-business-2"></i>
                        <span>Product</span>
                      </NavLink>
                    </li>
                    <li className="nav-item cus-navlink">
                      <NavLink to="/Category" className="nav-link">
                        <i className=" bi bi-list cus-position cus-business-2"></i>
                        <span>Category</span>
                      </NavLink>
                    </li>
                    <li className="nav-item cus-navlink">
                      <NavLink to="/IrrigationSource" className="nav-link">
                        <i className=" bi-bucket-fill cus-position cus-sidebar cus-agriculture"></i>
                        <span>Irrigation Source</span>
                      </NavLink>
                    </li>
                    <li className="nav-item cus-navlink">
                      <NavLink to="/PowerSource" className="nav-link">
                        <i className=" bi-battery-full cus-position cus-sidebar cus-agriculture"></i>
                        <span>Power Source</span>
                      </NavLink>
                    </li>
                    <li className="nav-item cus-navlink">
                      <NavLink to="/CropList" className="nav-link">
                        <i className="bi bi-box-arrow-in-right cus-position"></i>
                        <span>Crops</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link cus-navlink collapsed cursor-pointer"
                    data-bs-target="#business-nav2"
                    data-bs-toggle="collapse"
                  >
                    <i className="bi bi-megaphone-fill cus-position"></i>
                    <span>Promotions</span>
                    <i
                      className="bi bi-chevron-right ms-auto cus-i"
                      style={{ color: "black" }}
                    ></i>
                  </a>
                  <ul
                    id="business-nav2"
                    className="nav-content collapse"
                    data-bs-parent="#sidebar-nav"
                  >
                    <li className="nav-item cursor-pointer">
                      <NavLink to="/Banner" className="nav-link">
                        <i className="bi-image cus-position cus-business-2"></i>
                        <span>Banner</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/GetDealsOfDay" className="nav-link">
                        <i className="bi-box cus-position cus-business-2"></i>
                        <span>Deals Of Day</span>
                      </NavLink>
                    </li>
                    <li className="nav-item cus-position cus-navlink">
                      <NavLink to="/CouponList" className="nav-link">
                        <i className="bi bi-textarea-resize cus-position"></i>
                        <span>Coupons</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}
            <li className="nav-item">
              <a
                className="nav-link cus-navlink collapsed  cursor-pointer"
                data-bs-target="#business-nav3"
                data-bs-toggle="collapse"
              >
                <i className="bi bi-card-checklist cus-position"></i>
                <span>Report </span>
                <i
                  className="bi bi-chevron-right ms-auto cus-i"
                  style={{ color: "black" }}
                ></i>
              </a>
              <ul
                id="business-nav3"
                className="nav-content collapse"
                data-bs-parent="#sidebar-nav"
              >
                <li className="nav-item  cursor-pointer">
                  <NavLink to="/GetOrder" className="nav-link">
                    <i className="bi-image cus-position cus-business-2"></i>
                    <span>New Order</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/RecurringOrder" className="nav-link">
                    <i className="bi-box cus-position cus-business-2"></i>
                    <span>Recurring Order</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/ScheduleOnOrder" className="nav-link">
                    <i className="bi bi-archive cus-position cus-business-2"></i>
                    <span>Today Scheduled</span>
                  </NavLink>
                </li>
                <li>
                  {storedRole === "Admin" ? (
                    <li className="nav-item cus-position cus-navlink">
                      <NavLink to="/PGReport" className="nav-link">
                        <i className="bi bi-files cus-position"></i>
                        <span>PG Report</span>
                      </NavLink>
                    </li>
                  ) : null}
                </li>
                <li className="nav-item cus-position cus-navlink">
                  <NavLink to="/Ledger" className="nav-link">
                    <i className="bi-currency-dollar cus-position"></i>
                    <span>Ledger</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item  cursor-pointer">
              <a
                className="nav-link cus-navlink collapsed"
                data-bs-target="#business-nav5"
                data-bs-toggle="collapse"
              >
                <i className="bi bi-gear cus-position"></i>
                <span>Settings</span>
                <i
                  className="bi bi-chevron-right ms-auto cus-i"
                  style={{ color: "black" }}
                ></i>
              </a>
              <ul
                id="business-nav5"
                className="nav-content collapse cus-child-business"
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  {storedRole === "Admin" ? (
                    <>
                      <li className="nav-item cus-navlink">
                        <NavLink to="/SystemSetting" className="nav-link">
                          <i className=" bi bi-gear cus-position cus-sidebar cus-agriculture"></i>
                          <span>System Setting</span>
                        </NavLink>
                      </li>
                      <li className="nav-item cus-navlink">
                        <NavLink to="/EmailSettings" className="nav-link">
                          <i className=" bi bi-envelope-slash cus-position cus-sidebar cus-agriculture"></i>
                          <span>Email Setting</span>
                        </NavLink>
                      </li>
                      <li className="nav-item cus-navlink">
                        <NavLink to="/SmsSettings" className="nav-link">
                          <i className="bi bi-chat-dots-fill cus-position cus-sidebar cus-agriculture"></i>
                          <span>SMS Setting</span>
                        </NavLink>
                      </li>
                    </>
                  ) : null}
                </li>

                <li className="nav-item cus-navlink">
                  <NavLink to="/PinCodeMapping" className="nav-link">
                    <i className=" bi bi-pin-map-fill cus-position cus-sidebar cus-agriculture"></i>
                    <span>PinCode Mapping</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {storedRole === "Admin" ? (
              <ul>
                <li className="nav-item cus-position cus-navlink">
                  <NavLink to="/Notification" className="nav-link">
                    <i className="bi bi-envelope cus-position"></i>
                    <span>Notification</span>
                  </NavLink>
                </li>
                <li className="nav-item cursor-pointer">
                  <a
                    className="nav-link cus-navlink collapsed"
                    data-bs-target="#business-nav"
                    data-bs-toggle="collapse"
                  >
                    <i className="bi bi-layout-text-window-reverse cus-position"></i>
                    <span>Business</span>
                    <i
                      className="bi bi-chevron-right ms-auto cus-i"
                      style={{ color: "black" }}
                    ></i>
                  </a>
                  <ul
                    id="business-nav"
                    className="nav-content collapse cus-child-business"
                    data-bs-parent="#sidebar-nav"
                  >
                    <li className="nav-item cus-navlink">
                      <NavLink to="/FarmCommunity" className="nav-link">
                        <i className="bi-people-fill cus-position cus-sidebar cus-agriculture"></i>
                        <span>Farm Community</span>
                      </NavLink>
                    </li>
                    <li className="nav-item cus-navlink">
                      <NavLink to="/AgriTourism" className="nav-link">
                        <i className="bi-shop cus-position cus-sidebar cus-agriculture"></i>
                        <span>Agri Tourism</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li className="nav-item cus-position cus-navlink">
                  <NavLink to="/GetSeasonType" className="nav-link">
                    <i className="bi bi-cloud-sun cus-position"></i>
                    <span>Season</span>
                  </NavLink>
                </li>
              </ul>
            ) : null}
          </li>

          {/* <li className="nav-item">
            <NavLink to="/ProductInPackage" className="nav-link">
              <i className="bi-minecart-loaded"></i>
              <span>Product In Package</span>
            </NavLink>
          </li> */}

          <li className="nav-item cus-position cus-navlink">
            <NavLink to="/AreaList" className="nav-link">
              <i className="bi bi-textarea-resize cus-position"></i>
              <span>Area</span>
            </NavLink>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
