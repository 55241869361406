import HeaderTop from "./HeaderTop";

import FooterLast from "./FooterLast";
import { useEffect } from "react";

let Eventdetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <HeaderTop />
     
      <main>

        <section className="breadcrumb-area breadcrumb-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-9">
                        <div className="breadcrumb-content">
                            <h2 className="title">Event Details</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Event</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="blog-details-area blog-gray-bg">
            <div className="container">
                <div className="container-inner-wrap">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-10">
                            <aside className="blog-sidebar">
                                
                                <div className="widget">
                                    <h4 className="widget-title">Recent Events</h4>
                                    <div className="rc-post-list">
                                        <ul>
                                            <li>
                                                <div className="rc-post-thumb">
                                                    <a href="event-details"><img src="siteadmin/img/blog/rc_post_thumb01.jpg" alt="Hello Farmers" /></a>
                                                </div>
                                                <div className="rc-post-content">
                                                    <h6 className="title"><a href="event-details">How Manage Business Online Reputation</a></h6>
                                                    <span className="date"><i className="far fa-calendar-alt"></i> August 19, 2021</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="rc-post-thumb">
                                                    <a href="event-details"><img src="siteadmin/img/blog/rc_post_thumb02.jpg" alt="Hello Farmers" /></a>
                                                </div>
                                                <div className="rc-post-content">
                                                    <h6 className="title"><a href="event-details">Consulting is defined as the practise</a></h6>
                                                    <span className="date"><i className="far fa-calendar-alt"></i> August 19, 2021</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="rc-post-thumb">
                                                    <a href="event-details"><img src="siteadmin/img/blog/rc_post_thumb03.jpg" alt="Hello Farmers" /></a>
                                                </div>
                                                <div className="rc-post-content">
                                                    <h6 className="title"><a href="event-details">Marketing consulting Online Business</a></h6>
                                                    <span className="date"><i className="far fa-calendar-alt"></i> August 19, 2021</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                               
                                <div className="widget">
                                    <div className="sidebar-add">
                                        <a href="#"><img src="siteadmin/img/product/special_products_add.webp" alt="Hello Farmers" /></a>
                                    </div>
                                </div>
                                
                            </aside>
                        </div>

                        <div className="col-lg-8 col-md-10">
                            <div className="standard-blog-item mb-50">
                                <div className="standard-blog-content blog-details-content">
                                    <h4 className="title">Community Supported Agriculture:</h4>
                                    <ul className="standard-blog-meta">
                                        <li><a href="#"><i className="far fa-user"></i>By Admin</a></li>
                                        <li><a href="#"><i className="far fa-calendar"></i>April 15, 2023</a></li>
                                        <li><a href="#"><i className="far fa-comment-alt"></i>35 Comments</a></li>
                                    </ul>
                                    <p>Our specially designed HelloFarmers Community Farming as a Service (CFaaS) business model is developed by thinking about the consumer as a community and providing direct farm access to every individual consumer where they can choose what to grow for their food requirements and farmer can receive a fair price in return. HelloFarmers is a brilliant next level example of Community Supported Agriculture.</p>


                                    <div className="blog-details-img">
                                        <div className="list-group gallery">
                                            <div className="row ">

                                            <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                                                <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-01.jpg">
                                                    <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-01.jpg" />
                                                    {/* <!--<div className="text-right">
                                    <small className="text-muted">Image Title</small>
                                </div>--> */}
                                                </a>
                                            </div>

                                            <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                                                <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-02.jpg">
                                                    <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-02.jpg" />

                                                </a>
                                            </div>


                                            <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                                                <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-03.jpg">
                                                    <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-03.jpg" />

                                                </a>
                                            </div>

                                            <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                                                <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-04.jpg">
                                                    <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-04.jpg" />

                                                </a>
                                            </div>

                                            <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                                                <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-05.jpg">
                                                    <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-05.jpg" />

                                                </a>
                                            </div>

                                            <div className="col-sm-4 col-xs-6 col-md-4 col-lg-4 img-gal">
                                                <a className="thumbnail fancybox" rel="ligthbox" href="siteadmin/img/gallery/img-06.jpg">
                                                    <img className="img-responsive img-thumbnail" alt="Hello Farmers" src="siteadmin/img/gallery/thumb-img-06.jpg" />

                                                </a>
                                            </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>



                            <div className="comment-reply-box">
                                <h5 className="title">LEAVE A REPLY</h5>
                                <form action="#" className="comment-reply-form">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="text" placeholder="Author *" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-grp">
                                                <input type="email" placeholder="Your Email *" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-grp">
                                        <textarea name="message" placeholder="Type Comment Here..."></textarea>
                                    </div>
                                    <div className="form-grp checkbox-grp">
                                        <input type="checkbox" id="checkbox" />
                                        <label for="checkbox">Don’t show your email address</label>
                                    </div>
                                    <button type="submit" className="btn rounded-btn">Submit now</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>


    </main>
      <FooterLast />
    </>
  );
};

export default Eventdetails;
