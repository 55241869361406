import axios from "axios";
import { getCookie, setCookie, delete_cookie } from "./Cookies";
import { DATACONSTANT } from "./DataConstant";
import { toast } from "react-toastify";

const baseURL = DATACONSTANT.BASE_URL;

const getStoredAuthToken = () => {
  let c = getCookie(DATACONSTANT.COOKIE_NAME);
  return c ?? "";
};

function getHeaders() {
  return {
    accept: "application/json",
    authorization: `Bearer ${getStoredAuthToken()}`,
  };
}

export const postRequest = async (
  endpoint,
  data = null,
  contentType = "application/json"
) => {
  const headers = {
    authorization: `Bearer ${getStoredAuthToken()}`,
  };

  if (contentType === "multipart/form-data") {
    //const formData = new FormData();
    // for (const key in data) {
    //   formData.append(key, data[key]);
    // }
    // data = formData;
  } else {
    headers["Content-Type"] = contentType;
  }
const config = {
    method: "post",
    url: baseURL + endpoint,
    headers: headers,
    data: data,
  };
try {
    const response = await axios(config);
    return response.data;
  } catch (err) {
    let errorMessage = "";
    if (!err?.response?.data?.errors) {
      return err?.response?.data;
    }
    const errorFields = Object.keys(err.response.data.errors);
    for (let i = 0; i < errorFields.length; i++) {
      errorMessage =
        errorMessage + err.response.data.errors[errorFields[i]].join("<br/>") ||
        "Something went wrong";
    }
    if (err.response.status === 401) {
      setCookie(".front.authToken", "", "");
      toast.error("Your session has been expired. please Login again");
    } else {
      console.error(err.response);
      toast.error(errorMessage);
    }
  }
};

export const getRequest = async (endpoint, data = null) => {
  let _url = `${baseURL + endpoint}`;
  if (data) {
    _url += `?${new URLSearchParams(data).toString()}`;
  }
  return axios
    .get(_url, {
      headers: getHeaders(),
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        setCookie(".front.authToken", "", "");
        toast.error("Your session has been expired. please Login again");
      } else {
        console.log(`Error in get request to endpoint ${endpoint}`, err);
      }
      throw err;
    });
};
