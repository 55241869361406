import { React, useState, useRef } from "react";
import { postRequest } from "../../Services/ApiServices";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { DATACONSTANT } from "../../Services/DataConstant";

const PackageService = () => {
  const [packageProf, setPackageProf] = useState([]);
  const moduleBase = "api/Package";
  const [input, setInput] = useState({ isActive: true });
  const [submitloader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const editor = useRef(null);
  const editor1 = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [getId, setGetId] = useState();
  const baseUrl = DATACONSTANT.BASE_URL;
  const [image, setImage] = useState();

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    { name: "EDIT", selector: (row) => row.edit, width: "60px" },
    {
      name: "NAME",
      selector: (row) => row.packageName,
      width: "180px",
    },
    {
      name: "IMAGE",
      selector: (row) => row.imagePath,
      width: "150px",
    },
    {
      name: "COST",
      selector: (row) => row.packageCost,
      width: "100px",
    },
    {
      name: "DISCOUNT",
      selector: (row) => row.discount,
      width: "100px",
    },
    {
      name: "RECURRING IN",
      selector: (row) => row.recurringIn,
      width: "130px",
    },
    {
      name: "MINIMUM PURCHASE",
      selector: (row) => row.minimumPurchase,
      width: "170px",
    },

    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      width: "240px",
    },
    {
      name: "OFFER DESCRIPTION",
      selector: (row) => row.offerDescription,
      width: "240px",
    },

    {
      name: "ACTIVE",
      selector: (row) => row.isActive,
      width: "120px",
    },
    {
      name: "ENTRYON",
      selector: (row) => row.entryOn,
      width: "120px",
    },

    {
      name: "EXPIREDIN",
      selector: (row) => row.expiredIn,
      width: "120px",
    },
  ];

  const data = packageProf?.map((d, i) => ({
    serial: i + 1,
    edit: (
      <>
        <NavLink
          to={{
            pathname: "/EditPackage/" + d.id,
          }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span onClick={() => handlepopup(d?.id)} style={{ cursor: "pointer" }}>
          <i title="Delete" className="fa fa-trash fa-sm text-danger mx-2"></i>
        </span>
      </>
    ),
    packageName: (
      <>
        {d.packageName} <small className="d-block">[ID:{d.id}]</small>
      </>
    ),
    imagePath: (
      <img
        className="card-img-top w-10"
        src={baseUrl + d?.imagePath?.split(",")[0]}
        onError={(e) => {
          e.target.src = "/assets/img/noimage.jpg";
          e.target.onerror = null;
        }}
        style={{ height: "40px", width: "40px" }}
      />
    ),
    packageCost: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        {d.packageCost}
      </>
    ),
    discount: <>{d.discount}&nbsp;%</>,
    recurringIn: <>{d.recurringIn}&nbsp;Days</>,
    minimumPurchase: (
      <>
        <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
        {d.minimumPurchase}
      </>
    ),
    isActive: d.isActive ? (
      <img src="/assets/img/check.png" style={{ width: "20px" }} />
    ) : (
      <img src="/assets/img/delete.png" style={{ width: "20px" }} />
    ),
    description: (
      <>
        <div
          className="abcd-descriptionforprod "
          title={
            new DOMParser().parseFromString(d?.description, "text/html").body
              .textContent
          }
          dangerouslySetInnerHTML={{
            __html: d.description,
          }}
        />
      </>
    ),
    offerDescription: (
      <>
        <div
          className="abcd-descriptionforprod "
          title={
            new DOMParser().parseFromString(d?.offerDescription, "text/html")
              .body.textContent
          }
          dangerouslySetInnerHTML={{
            __html: d.offerDescription,
          }}
        />
      </>
    ),

    entryOn: d.entryOn,
    expiredIn: <>{d.expiredIn}&nbsp;Days</>,
  }));

  //////******** GET PACKAGE LIST*********///////////

  const packageProfile = async () => {
    setLoading(true);
    const moduleBase = "api/Package";
    try {
      const res = await postRequest(`${moduleBase}/Get`);
      if (res.result) {
        setPackageProf(res.result);
      } else {
        toast.error("Something Went Wrong");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
    }
  };
  //////******** GET PACKAGE LIST*********///////////

  ////////*EDIT AND ADD PACKAGE*////////

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (
      name === "packageCost" ||
      name === "recurringIn" ||
      name === "expiredIn" ||
      name === "minimumPurchase" ||
      name === "discount"
    ) {
      if (isNaN(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter valid number",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
        setInput((prev) => {
          return {
            ...prev,
            [name]: name === "isActive" ? checked : value,
          };
        });
      }
    } else {
      setInput((prev) => {
        return {
          ...prev,
          [name]: name === "isActive" ? checked : value,
        };
      });
    }
  };

  const handleEditor = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        ["description"]: e,
      };
    });
  };

  const handleEditor1 = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        ["offerDescription"]: e,
      };
    });
  };

  const handleImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["imagePath"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
  };

  ////////*EDIT AND ADD PACKAGE*////////

  ////////*EDIT PACKAGE WITH ID*////////

  const getpackage = async () => {
    const moduleBase = "api/Package";
    try {
      const res = await postRequest(`${moduleBase}/GetById?id=${params.id}`);
      if (res.result) {
        if (res.result.hasOwnProperty("imagePath")) {
          res.result.imagePath = res?.result?.imagePath;
        }
        setInput(res.result);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  ////////*EDIT PACKAGE WITH ID*////////

  ////////*VALIDATION ON PACKAGE*////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.packageName) {
      newErrors.packageName = "Please enter a package name.";
    }
    if (!input?.packageCost) {
      newErrors.packageCost = "Please enter the Cost.";
    }

    if (!input?.recurringIn) {
      newErrors.recurringIn = "Please enter the Recurring Days .";
    }
    if (!input?.discount) {
      newErrors.discount = "Please enter discount.";
    }

    if (!input?.expiredIn) {
      newErrors.expiredIn = "Please enter the days for Expired.";
    }
    if (!input?.minimumPurchase) {
      newErrors.minimumPurchase = "Please enter the minimumPurchase.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  ////////*VALIDATION ON PACKAGE*////////

  ////////*DELETE THE PACKAGE*////////
  const handlepopup = async (Id) => {
    setShowModal(true);
    setGetId(Id);
  };

  const handleClose = () => setShowModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/Package/Delete?id=${getId}`);
      if (res) {
        packageProfile();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  ////////*DELETE THE PACKAGE*////////

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      let res = {};
      let formData = new FormData();
      let __d = { ...input };
      formData.append("ImageFiles", image?.value);
      for (let key in __d) {
        formData.append(key, __d[key]);
      }
      res = await postRequest(
        `${moduleBase}/Add`,
        formData,
        "multipart/form-data"
      );
      if (!res) {
        toast.error(res.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/Package", { replace: true });
        }
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.log("Error", error);
      toast.error("Something Went Wrong");
    }
  };

  return {
    packageProfile,
    handleChange,
    handleEditor,
    handleEditor1,
    getpackage,
    handleSubmit,
    input,
    editor,
    submitloader,
    loading,
    columns,
    data,
    errors,
    editor1,
    showModal,
    handleClose,
    handleDelete,
    handleImage,
    baseUrl,
  };
};

export default PackageService;
