import React from "react";
import GetOrderService from "./GetOrderService";
import DatePicker from "react-datepicker";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { DATACONSTANT } from "../../Services/DataConstant";

const PGReport = () => {
  const {
    loading,
    PGcolumns,
    PGdata,
    input,
    submitloader,
    toDate,
    fromDate,
    handleChangedate,
    handleChangedates,
    handleChangePgReport,
    handleSubmitPgReport,
    showModal,
    handleCloseModal,
    handlingCloseModal,
    logRequest,
    handleConfirmSuccess,
    remark,
    showModalRemark,
    setRemark,
    showModalCheckStatus,
    handleCloseModalCheckStatus,
    statusCheckRequest,
    handleDownloadPgReportExcel,
  } = GetOrderService();
  const checkorderstatus = DATACONSTANT.ORDER_STATUS;

  useEffect(() => {
    document.getElementById("btnSearch").click();
  }, []);

  return (
    <div>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row ">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Order</li>

                        <li className="breadcrumb-item active">PG Report</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <form className="form-container">
          <div className="card mb-1">
            <div className="card-body p-2">
              <div className="form-popup" id="myForm">
                <div className="form-container">
                  <div className="row">
                    <div className="col-lg-2 col-md-6">
                      From Date:
                      <DatePicker
                        id="fromDate"
                        name="fromDate"
                        maxDate={new Date()}
                        selected={fromDate}
                        onChange={handleChangedates}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-2 col-md-6">
                      To Date:
                      <DatePicker
                        id="toDate"
                        name="toDate"
                        selected={toDate}
                        minDate={new Date(fromDate)}
                        maxDate={new Date()}
                        onChange={handleChangedate}
                        className="form-control"
                        dateFormat="dd MMM yyyy"
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-2 col-md-6">
                      Search Criteria:
                      <select
                        className="form-control green-dropdown"
                        id="searchCriteria"
                        name="searchCriteria"
                        defaultValue={input?.searchCriteria}
                        onChange={handleChangePgReport}
                      >
                        <option value="0">Select</option>
                        <option value="1">UserId</option>
                        <option value="2">MobileNo</option>
                        <option value="3">OrderId</option>
                      </select>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      SearchText:
                      <input
                        type="text"
                        name="searchText"
                        className="form-control"
                        value={input?.searchText}
                        onChange={handleChangePgReport}
                      ></input>
                    </div>
                    <div className="col-lg-2 col-md-6">
                      Status
                      <select
                        className="form-control green-dropdown"
                        id="status"
                        name="status"
                        defaultValue={input?.status}
                        onChange={handleChangePgReport}
                      >
                        <option value="n/a">Select</option>
                        <option value="S">SUCCESS</option>
                        <option value="F">FAILED</option>
                        <option value="P">PENDING</option>
                      </select>
                    </div>
                    <div className="col-lg-2 col-md-6 d-flex align-items-end">
                      {!submitloader ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-green mr-2"
                            id="btnSearch"
                            onClick={handleSubmitPgReport}
                          >
                            Search
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="btn btn-green">
                            Searching...
                          </button>
                        </>
                      )}
                      <div>
                        <>
                          <button
                            type="button"
                            className="btn btn-green btn-md"
                            onClick={handleDownloadPgReportExcel}
                            style={{ padding: "11px" }}
                          >
                            <i className="bi bi-file-earmark-excel"></i>
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" card">
            <div className="card-body p-2">
              <div className="table-responsive">
                {loading ? (
                  <div className="spinner-container">
                    <Spinner type="TailSpin" color="#00BFFF" />
                  </div>
                ) : (
                  <DataTable
                    columns={PGcolumns}
                    data={PGdata}
                    pagination
                    className="rdt_TableHeadRow"
                    scrollY="60px"
                    fixedHeader
                    fixedHeaderScrollHeight="calc(100vh - 260px)"
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </main>

      <Modal
        show={showModal}
        className="cus-moda-view"
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <h3>Log</h3>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered>
            {logRequest?.map((d, index) => (
              <div className="card mb-3" style={{ width: "965px" }}>
                <>
                  <div className="card-header cus-date">
                    <span className="cus-log">URL</span> Time : [{d?.entryOn}]
                  </div>

                  <div
                    className="card-body"
                    style={{ background: "#f3eeee", textAlign: "left" }}
                  >
                    {d?.url}
                  </div>
                  <div className="card-header text-left">Request</div>
                  <div
                    className="card-body"
                    style={{ background: "#f3eeee", textAlign: "left" }}
                  >
                    {d?.request}
                  </div>
                  <div className="card-header text-left">Response</div>

                  <div className="card-body cus-body" style={{ textAlign: "left" }}>
                    {d?.response}
                  </div>
                </>
              </div>
            ))}
          </Table>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalRemark}
        size="sm"
        onHide={handlingCloseModal}
        className="cus-top-modal"
      >
        <Modal.Header>
          <Modal.Title className="mt-3 change-status">
            Change Status
          </Modal.Title>
          <span
            aria-hidden="true"
            onClick={handlingCloseModal}
            className="modal-cross"
            style={{ fontSize: "24px", color: "red", cursor: "pointer" }}
          >
            &times;
          </span>
        </Modal.Header>
        <Modal.Body className="pt-0 modal-body-textarea">
          <h6 style={{ textAlign: "left", fontWeight: "normal" }}>Remark</h6>
          <textarea
            className="cus-textarea mt-1"
            rows="3"
            cols="55"
            placeholder="Enter your remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handlingCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmSuccess}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalCheckStatus}
        onHide={handleCloseModalCheckStatus}
        className="modal-35 cus-modal-35"
      >
        <Modal.Header
          closeButton
          className="justify-content-between d-flex align-items-center"
        >
          <Modal.Title className="cus-title-status">Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered>
            <div className="card cus-status-card">
              <div className="cus-cut-img">
                {statusCheckRequest?.orderStatus === "P" && (
                  <img
                    src="/assets/img/exclamation.png"
                    style={{ width: "110px" }}
                    alt="Exclamation"
                  />
                )}
                {statusCheckRequest?.orderStatus === "S" && (
                  <img
                    src="/assets/img/check.png"
                    style={{ width: "110px" }}
                    alt="Green Tick"
                  />
                )}
                {statusCheckRequest?.orderStatus === "F" && (
                  <img
                    src="/assets/img/delete.png"
                    style={{ width: "110px" }}
                    alt="Cross"
                  />
                )}
              </div>
              <>
                <div className="d-flex align-items-center">
                  <div className="cus-check-status mt-2">
                    <span
                      className={
                        statusCheckRequest?.orderStatus === "P"
                          ? "text-warning"
                          : statusCheckRequest?.orderStatus === "S"
                          ? "text-success"
                          : statusCheckRequest?.orderStatus === "F"
                          ? "text-danger"
                          : ""
                      }
                    >
                      {checkorderstatus[statusCheckRequest?.orderStatus]}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center cus-utr-status">
                  <div>UTR :</div>
                  <div>{statusCheckRequest?.referenceId}</div>
                </div>
                <div className="d-flex align-items-center cus-utr-status">
                  <div>TransactionId :</div>
                  <div className="">{statusCheckRequest?.orderId}</div>
                </div>
              </>
            </div>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PGReport;
