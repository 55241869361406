import React from "react";
import { useEffect } from "react";
import Style from "./Irrigation.module.css";
import DataTable from "react-data-table-component";
import { Button, Modal, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import IrrigationService from "./IrrigationService";

const IrrigationSource = () => {
  const {
    getIrrigationSource,
    loading,
    data,
    columns,
    isDeleteShow,
    handleClose,
    handleDeleteConfirm,
  } = IrrigationService();

  useEffect(() => {
    getIrrigationSource();
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Irrigation Source</li>

                      <li className="breadcrumb-item active"> List</li>
                    </ul>
                  </div>

                  <div className="col-md-6 col-sm-12 justify-content-end d-flex align-items-center">
                    <NavLink
                      to="/AddIrrigationSource"
                      className="btn btn-sm mr-3"
                    >
                      <i className="fa fa-plus-square"></i>&nbsp;ADD
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={Style.scrollHeight + " card"}>
        <div className="card-body p-2">
          <div className="table-responsive">
            {loading ? (
              <div className="spinner-container">
                <Spinner type="TailSpin" color="#00BFFF" />
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={data}
                pagination
                className="rdt_TableHeadRow"
                fixedHeader
                fixedHeaderScrollHeight="calc(100vh - 205px)"
              />
            )}
          </div>
          <Modal show={isDeleteShow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i
                  className="bi bi-exclamation-circle-fill"
                  style={{ color: "#f1c40f" }}
                ></i>
                &nbsp;Confirmation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure to delete irrigation source?</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                No
              </Button>
              <Button variant="success" onClick={handleDeleteConfirm}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default IrrigationSource;
