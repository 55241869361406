import { React, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import Style from "./Banner.module.css";
import BannerService from "./BannerService";
import { Modal, Button } from "react-bootstrap";
import { DATACONSTANT } from "../../Services/DataConstant";

const Banner = () => {
  const {
    bannerProfile,
    loading,
    data,
    columns,
    showModal,
    handleClose,
    handleDelete,
    banner,
    handleBannerList,
    input,
  } = BannerService();

  useEffect(() => {
    bannerProfile();
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Banners</li>

                        <li className="breadcrumb-item active">Banner List</li>
                      </ul>
                    </div>

                    <div className="col-md-3 col-sm-12 justify-content-end d-flex align-items-center">
                      <p className="dropdown d-inline-block mb-0">
                        <button
                          className="btn-secondarydropdown-toggle"
                          id="dropdownMenu1"
                          data-toggle="dropdown"
                          style={{ border: "none", background: "transparent" }}
                        >
                          <select
                            className="form-control"
                            style={{ fontSize: "15px", width: "289px" }}
                            id="bannerType"
                            name="bannerType"
                            value={input?.bannerType}
                            onChange={handleBannerList}
                          >
                            <option value="N/A">Select</option>
                            <option value="1">Dashboard</option>
                            <option value="2">Category</option>
                            <option value="3">FarmCommunity</option>
                            <option value="4">AgriTourism</option>
                          </select>
                        </button>
                      </p>
                    </div>
                    <div className="col-md-3 col-sm-12 d-flex justify-content-end align-items-center">
                      <NavLink to="/EditBanner" className="btn btn-sm mr-3">
                        <i className="fa fa-plus-square"></i>&nbsp;ADD
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Style.scrollHeight + " card"}>
          <div className="card-body p-2">
            <div className="table-responsive">
              {loading ? (
                <div className="spinner-container">
                  <Spinner type="TailSpin" color="#00BFFF" />
                </div>
              ) : (
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  className="rdt_TableHeadRow"
                  searchable={true}
                  fixedHeader
                  fixedHeaderScrollHeight="calc(100vh - 210px)"
                />
              )}
            </div>
          </div>
        </div>
        <Modal show={showModal} size="sm" onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="mt-3">
              <i
                className="bi bi-exclamation-circle-fill"
                style={{ color: "#f1c40f" }}
              ></i>
              &nbsp;Confirmation
            </Modal.Title>
            <span
              aria-hidden="true"
              onClick={handleClose}
              className="modal-cross"
            >
              &times;
            </span>
          </Modal.Header>
          <Modal.Body className="pt-0">
            Aye you sure to delete banner?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              No
            </Button>
            <Button variant="success" onClick={handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
};
export default Banner;
