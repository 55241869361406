import React from "react";
import { DATACONSTANT } from "../../Services/DataConstant";
import ProdInPackService from "./ProdInPackService";
import { useLocation, NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const MappedProduct = () => {
  const { handlepopup, Style, showModal, handleClose, handleUnmapProd } =
    ProdInPackService();
  const baseUrl = DATACONSTANT.BASE_URL;
  const location = useLocation();
  const { productdata } = location.state || {};

  return (
    <main id="main" className="main">
      <div className="page-header">
        <span className="page-title">
          {productdata.packageName}
          <br /> [ID:{productdata.packageId}]
        </span>
        <NavLink
          to={{
            pathname: `/ProductCard/${productdata.packageName}/${productdata.packageId}`,
          }}
          className="btn btn-primary"
          style={{ marginRight: "25px" }}
        >
          Map
        </NavLink>
      </div>
      <div className="pro-table">
        {productdata.products.length > 0 ? (
          productdata?.products?.map((item, i) => (
            <>
              <div className="card mb-2 me-1" key={i}>
                <div
                  className={
                    Style.delete + " d-flex justify-content-end text-danger"
                  }
                  type="button"
                  onClick={(e) =>
                    handlepopup(e, productdata?.packageId, item?.id)
                  }
                >
                  <i className="fa fa-times"></i>
                </div>

                <img
                  style={{
                    width: "100%",
                    height: "75px",
                    objectFit: "cover",
                  }}
                  className="d-block"
                  src={baseUrl + item.imageData.split(",")[0]}
                  onError={(e) => {
                    e.target.src = "/assets/img/noimage.jpg";
                    e.target.onerror = null;
                  }}
                />

                <p
                  className="text-center m-2"
                  style={{
                    fontFamily: "math",
                  }}
                >
                  {item.productTitle}
                  <br />
                  [pkg capacity : {item.packaging}]
                  <br />
                  [(Min){item.minLimit}-{item.maxLimit}
                  (Max)]
                </p>
              </div>
            </>
          ))
        ) : (
          <span className="card p-2 m-2 text-center">
            <img
              className="d-block"
              style={{ height: "50px" }}
              src="/assets/img/noimage.jpg"
            />
            No Product
          </span>
        )}
      </div>
      {showModal && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to unmap the item?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleUnmapProd}>
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              NO
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </main>
  );
};

export default MappedProduct;
