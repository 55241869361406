import React from "react";
import { useState } from "react";
import { getRequest, postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";

const CustomerCareService = () => {
  const [input, setInput] = useState();
  const [area, setarea] = useState();
  const [custCareList, setCustCareList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitloader, setSubmitLoader] = useState(false);
  const [errors, setErrors] = useState({});

  /////////// GET CUTOMERCARE LIST /////////////

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },

    {
      name: "NAME",
      selector: (row) => row.name,
      width: "180px",
    },

    {
      name: "PHONE NUMBER",
      selector: (row) => row.phoneNumber,
      width: "140px",
    },

    {
      name: "BALANCE",
      selector: (row) => row.balance,
      width: "100px",
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      width: "230px",
    },
    {
      name: "ADDRESS",
      selector: (row) => row.address,
      width: "200px",
    },

    {
      name: "ACTIVE",
      selector: (row) => row.isActive,
      width: "120px",
    },
    {
      name: "MAIL CONFIRMATION",
      selector: (row) => row.emailConfirmed,
      width: "200px",
    },
  ];

  // const data = custCareList?.map((d, i) => ({
  //   serial: i + 1,
  //   name: (
  //     <>
  //       {d.name} <small className="d-block">[{d?.userName}]</small>
  //       <small className="d-block">[ID:{d?.id}]</small>
  //     </>
  //   ),
  //   phoneNumber: d?.phoneNumber,
  //   email: d?.email,
  //   address: (
  //     <>
  //       <span title={d?.address}>
  //         {d?.address?.toString()?.slice(0, 22) || ""}{" "}
  //         {d?.address?.length >= 22 ? "..." : ""}
  //       </span>
  //     </>
  //   ),
  //   balance: (
  //     <>
  //       <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
  //       &nbsp;
  //       {d?.balance}
  //     </>
  //   ),
  //   isActive: d?.isActive ? (
  //     <img src="/assets/img/check.png" style={{ width: "20px" }} />
  //   ) : (
  //     <img src="/assets/img/delete.png" style={{ width: "20px" }} />
  //   ),
  //   emailConfirmed: d?.emailConfirmed ? (
  //     <img src="/assets/img/check.png" style={{ width: "20px" }} />
  //   ) : (
  //     <img src="/assets/img/delete.png" style={{ width: "20px" }} />
  //   ),
  // }));
  const data = Array.isArray(custCareList)
    ? custCareList.map((d, i) => ({
        serial: i + 1,
        name: (
          <>
            {d.name} <small className="d-block">[{d?.userName}]</small>
            <small className="d-block">[ID:{d?.id}]</small>
          </>
        ),
        phoneNumber: d?.phoneNumber,
        email: d?.email,
        address: (
          <>
            <span title={d?.address}>
              {d?.address?.toString()?.slice(0, 22) || ""}{" "}
              {d?.address?.length >= 22 ? "..." : ""}
            </span>
          </>
        ),
        balance: (
          <>
            <i
              className="text-success fas fa-rupee-sign"
              aria-hidden="true"
            ></i>
            &nbsp;
            {d?.balance}
          </>
        ),
        isActive: d?.isActive ? (
          <img src="/assets/img/check.png" style={{ width: "20px" }} />
        ) : (
          <img src="/assets/img/delete.png" style={{ width: "20px" }} />
        ),
        emailConfirmed: d?.emailConfirmed ? (
          <img src="/assets/img/check.png" style={{ width: "20px" }} />
        ) : (
          <img src="/assets/img/delete.png" style={{ width: "20px" }} />
        ),
      }))
    : [];

  const getCustomerCareList = async () => {
    setLoading(true);
    try {
      const res = await postRequest(`api/user/List`, {
        roleID: 6,
        fromDate: "",
        toDate: "",
        mobileNo: "",
      });
      if (res) {
        setCustCareList(res);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    }
  };

  /////////// GET CUTOMERCARE LIST /////////////

  /////////// CREATE CUTOMERCARE  /////////////

  const handleChange = async (e, postalCode) => {
    const { name, value } = e.target;
    if (postalCode) {
      if (value.length === 6) {
        try {
          const res = await getRequest(
            `api/Home/GetAreaListAsync?postalCode=${value}`
          );
          setarea(res);
        } catch (error) {
          console.error("Error occurred:", error);
        }
      }
    }
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    setSubmitLoader(true);
    try {
      const res = await postRequest(`api/user/CreateCustomerCare`, {
        ...input,
      });
      if (res.statusCode === 1) {
        toast.success(res.responseText);
      } else {
        toast.error(res.responseText);
      }
      setSubmitLoader(false);
    } catch (error) {
      console.log("Error", error);
      setSubmitLoader(false);
    }
  };

  const validateInput = () => {
    const newErrors = {};
    if (!input?.name) {
      newErrors.name = "Please enter the Name.";
    }
    if (!input?.phoneNumber) {
      newErrors.phoneNumber = "Please enter the phoneNumber.";
    }
    if (!input?.email) {
      newErrors.email = "Please enter an email.";
    }

    if (!input?.postalCode) {
      newErrors.postalCode = "Please enter the Pin code.";
    }
    if (!input?.areaId || input?.areaId === "N/A") {
      newErrors.areaId = "Please enter the Area.";
    }
    if (!input?.gender || input?.gender === "N/A") {
      newErrors.gender = "Please enter the Gender.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /////////// CREATE CUTOMERCARE  /////////////

  return {
    handleChange,
    handleSubmit,
    area,
    submitloader,
    errors,
    getCustomerCareList,
    loading,
    columns,
    data,
  };
};

export default CustomerCareService;
