import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";
import FooterLast from "../../Component/Homes/FooterLast";
import { useEffect } from "react";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <HeaderTop />
      
      <main>

        
        <section className="breadcrumb-area breadcrumb-bg">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            <h2 className="title">Question & Answer</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Question & Answer</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        
        <section className="terms-and-conditions-area pb-85">
            <div className="container">
                <div className="product-desc-wrap">
                    <ul className="nav nav-tabs" id="myTabTwo" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="Consumers-tab" data-toggle="tab" href="#Consumers" role="tab" aria-controls="details" aria-selected="true">Consumers about Subscription<br /> of Community Farming</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="Farmers-tab" data-toggle="tab" href="#Farmers" role="tab" aria-controls="val" aria-selected="false">Farmers about <br />Community farming</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContentTwo">
                        <div className="tab-pane fade active show" id="Consumers" role="tabpanel" aria-labelledby="Consumers-tab">
                            <div className="product-desc-content">
                                <h4>FAQs for Consumers about Hello Farmers Community Farming as a Service platform:</h4>
                                <div className="accordion accordion-regular" id="faqAccordion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">What is Hello Farmers Platform and how does it work?</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Community Farming as a Service (CFaaS) is a cutting-edge digital platform that provides unique and sustainable community farming solutions. This is a type of farm-to-kitchen supply service through a community farming
                                                    method that aims to connect the farming community with the consumers through a subscription-based model. In this model, Consumers can subscribe to this service and receive a regular supply of fresh, domesticallysourced
                                                    produce. Also, consumers can access the Agri tourism and community farming offered by farmers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What are the Benefits of Subscribing to Hello Farmers Community Farming Platform?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Subscribing to Hello Farmers community farming platform will provide consumers with access to fresh, domestically-sourced produce that is grown using sustainable and efficient farming practices. It also supports Farming
                                                    Community and helps to promote sustainable agriculture economy.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                What types of produce can I expect to receive through a community farming platform?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    The types of produce available through a community farming platform will vary depending on the specific geography and the growing season. However, common types of produce that will be available include fruits,
                                                    vegetables, pulses, grains, spices, dry fruits, oils and herbs etc.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">How do I subscribe to a community farming platform?</button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    To subscribe to a community farming platform, you will typically need to sign up online and provide information such as your delivery address and contact information. You will also need to choose a subscription plan that
                                                    fits your needs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                How much does it cost to subscribe to a community farming platform?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    The cost of subscribing to a community farming platform will vary depending on the specific location and the size of your subscription. However, Hello Farmers platform offer a range of subscription options at different
                                                    price points to accommodate different budgets.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingSix">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                Are there any options for customization or flexibility in my subscription?
                                            </button>
                                        </h2>
                                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Hello Farmers platform offer some level of customization and flexibility in their subscriptions. For example, you may be able to choose the types of produce you want to receive, adjusting the quantity of produce,
                                                    selecting a specific delivery schedule. Also be able to add or remove items from your subscription or to pause or cancel your subscription at any time.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingSeven">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                What is the process for delivery of scheduled products?
                                            </button>
                                        </h2>
                                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>The process for delivery of scheduled products will be very clear all around. Hello Farmers platform offer home delivery. The delivery schedule, like every 4th day, also depends on the subscription you choose.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingEight">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">How can I ensure the quality of products?</button>
                                        </h2>
                                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Hello Farmers Community Farming as a Service is a fully Phygital Platform that have strict ICS (Internal Control System) which means a transparent quality control measures in place to ensure that the produce you receive
                                                    is of the highest quality. Also follows a satisfaction guarantee resolution, so that if any customer are not satisfied with the quality of the produce, can inform the platform and they will make it right.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingNine">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                How does Hello Farmers Community Farming as a Service Platform differ from traditional grocery stores or online grocery ecommerce platforms?
                                            </button>
                                        </h2>
                                        <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Hello Farmers Community Farming Platform connects consumers directly with farming Community who are part of our community farming model, whereas traditional grocery stores or online grocery ecommerce platform typically
                                                    source produce from mandi or commission agents. Community farming platforms offer fresher, domestically-sourced produce grown using sustainable practices, while traditional grocery stores are have produce that have been
                                                    transported over long distances and also not be as fresh or safe.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTen">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                What are the benefits of knowing my farmer and sourcing produce locally?
                                            </button>
                                        </h2>
                                        <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Knowing your farmer and sourcing produce directly provide a number of benefits, including the ability to support the local economy, access to fresher and more nutritious produce, and the ability to make more informed
                                                    choices about the food you eat. Additionally, by sourcing produce directly, you are reducing the environmental impact associated with transportation, wastage and distribution of food.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingEleven">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                How can I be sure that the produce delivered is truly from a farmer?
                                            </button>
                                        </h2>
                                        <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Hello Farmers has strict standards and regulations in place to ensure that the produce they deliver is truly sourced from the farmers. Also provides detailed information about the farmers and their farming practices on
                                                    the platform. Hello Farmers is a technology driven and brilliantly implemented platform.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading12">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">What is Agri-tourism and how can a Consumer participate?</button>
                                        </h2>
                                        <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Agri-tourism is a type of tourism that allows visitors to experience and learn about farming and agriculture. As a customer, you can participate in Agri-tourism by booking experiences such as farm tours, crop picking,
                                                    farm stays and other hands-on experiences offered by farmers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading13">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                                How can I use the Community Farming mobile application to book Agri-tourism experiences?
                                            </button>
                                        </h2>
                                        <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    The Hello Farmers mobile application is a platform that allows customers to connect with farmers and book Agri-tourism experiences. Customers can use the App to browse through different experiences offered by farmers,
                                                    check availability, and book experiences directly through the App.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading14">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                                What types of Agri-tourism experiences are available to book?
                                            </button>
                                        </h2>
                                        <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    The types of Agri-tourism experiences available on the App may vary depending on what farmers are offering. Some examples include: farm tours, crop picking, animal feeding, orchid development, cooking classes, farm
                                                    stays, participation in local events and many more.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading15">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse13">How do I pay for my Agri-tourism experiences?</button>
                                        </h2>
                                        <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>
                                                    Payment for Agri-tourism experiences booked through the App can be made using a valid payment method such as UPI, credit or debit card. The fee for the experience will be clearly displayed on the App, and must be paid at
                                                    the time of booking. through the App.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading16">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">Can I cancel or reschedule my Agri-tourism experience?</button>
                                        </h2>
                                        <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                <p>Cancellation and rescheduling policies may vary depending on the farmer offering the experience. It is important to review the farmer's cancellation and rescheduling policy before booking an experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading17">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                                Are there any age restrictions for Agri-tourism experiences?
                                            </button>
                                        </h2>
                                        <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body"><p>Age restrictions depending on the farmer offering. It is important to review the farmer's age restrictions before booking an experience.</p></div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="heading18">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                                                Do I need to bring anything with me for the Agri-tourism experience?
                                            </button>
                                        </h2>
                                        <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#faqAccordion">
                                            <div className="accordion-body"><p>Specific requirements for Agri-tourism services may vary depending on the farmer offering. It is important to review the farmer's requirements before booking an experience.</p></div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="tab-pane fade" id="Farmers" role="tabpanel" aria-labelledby="Farmers-tab">
                            <div className="product-desc-content">
                                <h4>FAQs for Farmers about join Hello Farmers Community Farming model</h4>
                                <div className="accordion accordion-regular" id="faqFarmer">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h1"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#F1" aria-expanded="true" aria-controls="F1">What is community farming and how does it work?</button></h2>
                                        <div id="F1" className="accordion-collapse collapse show" aria-labelledby="h1" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Community farming is a model where a group of farmers come together to share resources, knowledge, and labor to collectively grow and harvest crops. It allows farmers to pool resources and share risks, while also
                                                    promoting sustainable and efficient farming practices.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h2">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F2" aria-expanded="false" aria-controls="F2">What are the benefits of joining the community farming model?</button>
                                        </h2>
                                        <div id="F2" className="accordion-collapse collapse" aria-labelledby="h2" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Joining Hello Farmers Community Farming model provides farmers with access to resources such as equipment, land, and marketing channels. It also allows farmers to share knowledge and expertise, which is lead to more
                                                    efficient and sustainable farming practices. Additionally, Hello Farmers Platform is helping farmers reduce costs and increase profits by pre harvest trading and direct customer linkage. economy.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h3">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F3" aria-expanded="false" aria-controls="F3">What are the requirements for joining a community farming model?</button>
                                        </h2>
                                        <div id="F3" className="accordion-collapse collapse" aria-labelledby="h3" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Requirements for joining a community farming model can vary depending on the specific locations. However, Hello Farmers Community Farming platform require farmers to be committed to sustainable and efficient farming
                                                    practices, and to be willing to share resources and knowledge with other members of the community.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h4">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F4" aria-expanded="false" aria-controls="F4">How do I find a community farming model to join?</button>
                                        </h2>
                                        <div id="F4" className="accordion-collapse collapse" aria-labelledby="h4" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    There are several ways to find a community farming model to join. One way is to search online and look for best available platforms who promote community farming in your area. Another way is to reach out to local FPO or
                                                    agricultural extension offices for information on community farming platform in your area or just download Hello Farmers application and start your partnership.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h5">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F5" aria-expanded="false" aria-controls="F5">What are the costs associated with joining a community farming model?</button>
                                        </h2>
                                        <div id="F5" className="accordion-collapse collapse" aria-labelledby="h5" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    The costs associated with joining a community farming model can vary depending on the specific locations. Hello Farmers platform requires a subscription fee or an open profit-sharing partnership contract. Additionally,
                                                    farmers are responsible for their own costs related to farming or infrastructure development, but they also have the opportunity to share costs with other farmers in the community.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h6">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F6" aria-expanded="false" aria-controls="F6">
                                                Can I still sell my produce independently if I join a community farming model?
                                            </button>
                                        </h2>
                                        <div id="F6" className="accordion-collapse collapse" aria-labelledby="h6" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Yes, farmers who join Hello Farmers Community Farming model can still sell their produce independently. Hello Farmers Community Farming as a Service model provide a platform for farmers to sell their produce directly to
                                                    consumers, and farmers are also free to sell their produce through their own channels.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h7">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F7" aria-expanded="false" aria-controls="F7">What is the difference between community farming and traditional farming?</button>
                                        </h2>
                                        <div id="F7" className="accordion-collapse collapse" aria-labelledby="h7" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Community farming is a model where Individual farmer or group of farmers join hands to share resources, knowledge, and infrastructure to grow and harvest crops. It is focused on sustainable and efficient farming
                                                    practices, and on the sharing of resources and knowledge among Farmers and consumers. In contrast, traditional farming is typically done by individual farmers working independently without any plan and using their own
                                                    resources and everything is depending on traders.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h8">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F8" aria-expanded="false" aria-controls="F8">How does community farming benefit farmers in terms of income and growth?</button>
                                        </h2>
                                        <div id="F8" className="accordion-collapse collapse" aria-labelledby="h8" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Community farming allows farmers to pool resources and share risks, which is lead to reduced costs and increased profits. Additionally, by sharing knowledge and expertise, farmers learn new techniques and strategies for
                                                    improving yields and reducing costs. This will help farmers grow their businesses and increase their income.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h9">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F9" aria-expanded="false" aria-controls="F9">
                                                What types of resources are shared among members in a community farming model?
                                            </button>
                                        </h2>
                                        <div id="F9" className="accordion-collapse collapse" aria-labelledby="h9" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Resources that are commonly shared among members in a community farming model include land, equipment, and marketing channels. In addition, members may also share knowledge and expertise related to sustainable and
                                                    efficient farming practices.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h10">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F10" aria-expanded="false" aria-controls="F10">What is the process for joining a community farming model?</button>
                                        </h2>
                                        <div id="F10" className="accordion-collapse collapse" aria-labelledby="h10" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    The process for joining a community farming model can vary depending on the specific location. The Hello Farmers Community Farming model requires interested farmers to fill out an application, and provide information
                                                    about their farming experience and practices.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h11">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F11" aria-expanded="false" aria-controls="F11">What is Agri-tourism and how can I participate as a farmer?</button>
                                        </h2>
                                        <div id="F11" className="accordion-collapse collapse" aria-labelledby="h11" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Agri-tourism is a type of tourism that allows visitors to experience and learn about farming and agriculture. As a farmer, you can participate in Agri-tourism by offering activities such as farm tours, crop picking, farm
                                                    stays, and other hands-on experiences for visitors.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h12">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F12" aria-expanded="false" aria-controls="F12">
                                                How can I use the Community Farming mobile application to offer Agri-tourism experiences?
                                            </button>
                                        </h2>
                                        <div id="F12" className="accordion-collapse collapse" aria-labelledby="h12" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    The Hello Farmers Community Farming mobile application is a platform that allows farmers to connect with consumers and offer Agri-tourism experiences. You can use the App to create a profile for your farm, post
                                                    information about your Agri-tourism offerings, and allow consumers to book experiences directly through the App.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h13">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F13" aria-expanded="false" aria-controls="F13">What types of Agri-tourism experiences can I offer?</button>
                                        </h2>
                                        <div id="F13" className="accordion-collapse collapse" aria-labelledby="h13" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    The types of Agri-tourism experiences you can offer are only limited by your imagination. Some examples include farm tours, crop picking, farm stays, animal feeding, village cultural tours, cooking classes, and many
                                                    more.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h14">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F14" aria-expanded="false" aria-controls="F14">How do I charge for my Agri-tourism experiences?</button>
                                        </h2>
                                        <div id="F14" className="accordion-collapse collapse" aria-labelledby="h14" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    As a farmer, you can charge for your Agri-tourism experiences in any way you see fit. Charges should be a flat fee per person, you can charge by the hour or per day. The fee will be clearly displayed on the App, and
                                                    consumers will be required to pay the fee at the time of booking.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h15">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F15" aria-expanded="false" aria-controls="F15">
                                                Do I need any special permits or licenses to offer Agri-tourism experiences?
                                            </button>
                                        </h2>
                                        <div id="F15" className="accordion-collapse collapse" aria-labelledby="h15" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    The requirements for offering Agri-tourism experiences vary depending on your location and the type of experience you are offering. The Hello Farmers platform will provide you with relevant information as per your
                                                    requirement.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h16">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F16" aria-expanded="false" aria-controls="F16">How can I ensure the safety of visitors during Agri-tourism experiences?</button>
                                        </h2>
                                        <div id="F16" className="accordion-collapse collapse" aria-labelledby="h16" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Ensuring the safety of visitors is an important part of offering agri-tourism experiences. It's important to have proper liability insurance and to provide clear guidelines and instructions for visitors. It's also a good
                                                    idea to have emergency plans in place in case of accidents or injuries.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h17">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F17" aria-expanded="false" aria-controls="F17">Can I offer food and beverages as part of my Agri-tourism experiences?</button>
                                        </h2>
                                        <div id="F17" className="accordion-collapse collapse" aria-labelledby="h17" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Yes, you can offer food and beverages as part of your Agri-tourism experiences. However, you will need to comply with all relevant food safety laws and regulations and may need to obtain additional licenses and permits.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h18">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F18" aria-expanded="false" aria-controls="F18">Can I offer farm stay as part of my Agri-tourism offerings?</button>
                                        </h2>
                                        <div id="F18" className="accordion-collapse collapse" aria-labelledby="h18" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Yes, you can offer farm stays as part of your Agri-tourism offerings. Farm stays allow visitors to stay on a farm for a period of time, experiencing the daily life of a farmer. This can include activities such as animal
                                                    care, crop planting, and harvesting. In order to offer farm stays, you will need to have proper lodging facilities such as a farmhouse, guesthouse, or tent. It is also important to comply with all local zoning and safety
                                                    laws and regulations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h19">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F19" aria-expanded="false" aria-controls="F19">How do I promote my farm stay offerings on the application?</button>
                                        </h2>
                                        <div id="F19" className="accordion-collapse collapse" aria-labelledby="h19" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    You can promote your farm stay offerings on the App by creating a detailed listing that includes information such as the type of accommodation, amenities, and activities offered. You can also include photos and reviews
                                                    from past guests. Additionally, you can use the App's search filters to make it easy for visitors to find your farm stay offerings.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="h20">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#F20" aria-expanded="false" aria-controls="F20">What are the benefits of offering farm stays?</button>
                                        </h2>
                                        <div id="F20" className="accordion-collapse collapse" aria-labelledby="h20" data-bs-parent="#faqFarmer">
                                            <div className="accordion-body">
                                                <p>
                                                    Offering farm stays is a great way to supplement your income and connect with visitors who are interested in learning more about farming and rural life. Farm stays will also help increase the visibility and awareness of
                                                    your farm and products, and can create a sense of community among visitors who have shared an immersive experience. Additionally, farm stays provide an opportunity to educate visitors about sustainable and responsible
                                                    farming practices, and to promote the use of locally sourced products
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        

    </main>

      <FooterLast />
    </>
  );
};

export default Faq;
