import React from "react";
import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";
import FooterLast from "../../Component/Homes/FooterLast";

const Oppurtunity = () => {
  return (
    <>
      
      <HeaderTop />
      {/* <HeaderBottom /> */}
      <main>

      
        <section className="breadcrumb-area breadcrumb-bg">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumb-content">
                            <h2 className="title">Oppurtunity</h2>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Oppurtunity</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  

        <section className="header services-area  pt-50 pb-50">
            <div className="container">
                <div className="container-inner-wrap">
                   
                    <div className="row justify-content-center">
                        
                            <div className="col-md-4">
                               
                                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link mb-3 p-4 shadow active" id="Community-Farming-Partner-tab" data-toggle="pill" href="#Community-Farming-Partner" role="tab" aria-controls="Community-Farming-Partner" aria-selected="true">
                                        <i className="fa fa-check mr-2"></i>
                                        <span className="font-weight-bold text-uppercase">Community Farming Partner</span>
                                    </a>

                                    <a className="nav-link mb-3 p-4 shadow" id="Urban-Franchise-Partner-tab" data-toggle="pill" href="#Urban-Franchise-Partner" role="tab" aria-controls="Urban-Franchise-Partner" aria-selected="false">
                                        <i className="fa fa-check mr-2"></i>
                                        <span className="font-weight-bold text-uppercase">Urban Franchise Partner</span>
                                    </a>

                                    <a className="nav-link mb-3 p-4 shadow" id="Farm-School-Program-tab" data-toggle="pill" href="#Farm-School-Program" role="tab" aria-controls="Farm-School-Program" aria-selected="false">
                                        <i className="fa fa-check mr-2"></i>
                                        <span className="font-weight-bold text-uppercase">Farm to School Program</span>
                                    </a>

                                    <a className="nav-link mb-3 p-4 shadow" id="Corporate-Farming-tab" data-toggle="pill" href="#Corporate-Farming" role="tab" aria-controls="Corporate-Farming" aria-selected="false">
                                        <i className="fa fa-check mr-2"></i>
                                        <span className="font-weight-bold text-uppercase">Corporate Farming</span>
                                    </a>

                                    <a className="nav-link mb-3 p-4 shadow" id="Customized-Farming-tab" data-toggle="pill" href="#Customized-Farming" role="tab" aria-controls="Customized-Farming" aria-selected="false">
                                        <i className="fa fa-check mr-2"></i>
                                        <span className="font-weight-bold text-uppercase">Customized Farming</span>
                                    </a>
                                </div>
                            </div>


                            <div className="col-md-8">
                               
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade shadow rounded bg-white show active p-4" id="Community-Farming-Partner" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <h4 className="mb-4">Community Farming Partner</h4>
                                        <p>Farming has been an integral part of human civilization since ancient times, providing food and livelihood opportunities to communities worldwide. In recent years, there has been a growing interest in community farming as a means of promoting sustainable and locally sourced food systems. To support this trend, Hello Farmers is providing an innovative opportunity for individuals to become Community Farming Partner and start their own community farm. By partnering with Hello Farmers, aspiring farmers can receive complete guidance and assistance in setting up their farms, including access to the latest technology, training, and resources. Moreover, by joining the Hello Farmers community, farmers can also access the CFaaS platform to sell their produce to a wider customer base, thereby increasing their income and profitability. This partnership model not only supports individual farmers but also promotes sustainable and locally sourced food systems, benefiting the wider community.</p>

                                        
                                    </div>

                                    <div class="tab-pane fade shadow rounded bg-white p-4" id="Urban-Franchise-Partner" role="tabpanel" aria-labelledby="Urban-Franchise-Partner-tab">
                                        <h4 class="mb-4">Urban Franchise Partner</h4>
                                        <p class="text-muted mb-2">
                                            Entrepreneurship has emerged as a critical driver of economic growth and development, providing opportunities for individuals to pursue their passions, create jobs, and contribute to their communities. However, for aspiring women entrepreneurs, there are often significant challenges and barriers to entry, including limited access to training and resources, cultural barriers, and a lack of support systems. To address these challenges, Hello Farmers is providing an innovative opportunity for aspiring women entrepreneurs to become Urban Franchise Partners. This platform offers a great opportunity for any housewife, businesswoman, or unemployed female to start their own business within their neighbourhood or community. By partnering with Hello Farmers, aspiring women entrepreneurs can receive the required professional training and technology support to become self sufficient and financially independent. This initiative not only supports women's entrepreneurship but also promotes inclusivity and gender equality in the entrepreneurial ecosystem.

                                        </p>

                                        <a className="btn btn-theme mt-15 btn-md radius animation mt-4" href="#">START NOW</a>
                                    </div>

                                    <div className="tab-pane fade shadow rounded bg-white p-4" id="Farm-School-Program" role="tabpanel" aria-labelledby="Farm-School-Program-tab">
                                        <h4 className="mb-4">Farm to School Program</h4>
                                        <p className="text-muted mb-2">There has been a growing concern about the quality and nutritional value of the food consumed by children, with parents and educators seeking to promote healthy eating habits and locally sourced produce. To address this concern, Hello Farmers has introduced a unique Farm-to-School program that enables schools to source fresh and healthy produce directly from our community farms. This program not only provides access to fresh produce but also promotes the importance of community farming and healthy eating habits among children. By partnering with Hello Farmers, schools can also start community farming study programs for students, providing an opportunity for children to learn about sustainable agriculture and the importance of locally sourced produce. This initiative not only supports the health and well-being of children but also promotes sustainability and community development.</p>
                                        <a className="btn btn-theme mt-15 btn-md radius animation mt-4" href="#">START NOW</a>
                                    </div>

                                    <div className="tab-pane fade shadow rounded bg-white p-4" id="Corporate-Farming" role="tabpanel" aria-labelledby="Corporate-Farming-tab">
                                        <h4 className="mb-4">Corporate Farming</h4>
                                        <p className="text-muted mb-2">In recent years, there has been a growing interest in corporate social responsibility and sustainability, with companies increasingly seeking to contribute positively to their local communities and the environment. As a result, corporate farming has emerged as an innovative solution that enables companies to promote sustainability while also supporting local farmers and communities. Hello Farmers is providing an innovative corporate farming solution that helps companies set up community farms and promote sustainable farming practices. By partnering with Hello Farmers, companies can access the latest technology, training, and resources to support their corporate farming initiatives, while also contributing to the development of local communities. This platform not only supports corporate social responsibility and sustainability but also promotes inclusivity and collaboration in the agricultural sector.</p>
                                        <a className="btn btn-theme mt-15 btn-md radius animation mt-4" href="#">START NOW</a>
                                    </div>

                                    <div class="tab-pane fade shadow rounded bg-white p-4" id="Customized-Farming" role="tabpanel" aria-labelledby="Customized-Farming-tab">
                                        <h4 class="mb-4">Customized Farming</h4>
                                        <p class="text-muted mb-2">For many individuals and groups of farmers, the idea of growing their own produce may seem daunting due to a lack of knowledge or resources. However, with the advent of customized farming solutions, it has become easier for farmers to cultivate their own crops while receiving guidance and assistance from experts in the field. Hello Farmers is offering a unique customized farming and agri-tourism solution that is tailored to meet the specific needs of individual farmers and groups. Our team of experts provides guidance and assistance at every step of the farming process, helping to ensure the best utilization of available resources. We believe in a lifelong association with our clients, supporting their consistent growth and success. With our customized farming solutions, individuals and groups of farmers can now realize their dreams of cultivating their own crops and contributing to the growth and development of the agricultural sector.</p>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
                
            </div>
        </section>

    </main>
      <FooterLast />
    </>
  );
};
export default Oppurtunity;
