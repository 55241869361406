import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { NavLink } from "react-router-dom";
import NotificationService from "./NotificationService";
import JoditEditor from "jodit-react";

const Notification = () => {
  const {
    handleChange,
    handleCheckBox,
    input,
    notification,
    handleSubmit,
    submitloader,
    setActive,
    active,
    handleNext,
    errors,
    editor,
    handleEditor,
    handlePrevious,
    handleTypeChangeHeader,
    handleValueChangeHeader,
    removeFieldHeader,
    addFieldHeader,
    addFieldBody,
    fieldsHeader,
    fieldsBody,
    handleTypeChangeBody,
    removeFieldBody,
    handleValueChangeBody,
    handleValueChangeMedia,
    handleAppImage,
    imagePreview,
  } = NotificationService();

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Notification</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="card">
          <div className="card-body p-2">
            <h5 class="card-title">Communication Mode</h5>
            <div className="">
              <div className="checkbox p-2">
                <div className="row">
                  <div className="col-md-2 d-flex align-items-center">
                    <label className="checkbox-label d-flex">
                      <div className="radio-left">
                        <input
                          type="checkbox"
                          onChange={handleCheckBox}
                          style={{ marginRight: "5px" }}
                          name="email"
                        />
                      </div>
                      <div className="radio-right">
                        <span> E-Mail</span>
                      </div>
                    </label>
                  </div>
                  <div className="col-md-2 d-flex align-items-center">
                    <label className="checkbox-label d-flex">
                      <div className="radio-left">
                        <input
                          type="checkbox"
                          onChange={handleCheckBox}
                          style={{ marginRight: "5px" }}
                          name="whatsapp"
                        />
                      </div>
                      <div className="radio-right">
                        <span>Whatsapp</span>
                      </div>
                    </label>
                  </div>
                  <div className="col-md-2 d-flex align-items-center">
                    <label className="checkbox-label d-flex">
                      <div className="radio-left">
                        <input
                          type="checkbox"
                          onChange={handleCheckBox}
                          style={{ marginRight: "5px" }}
                          name="app"
                          defaultChecked
                        />
                      </div>
                      <div className="radio-right">
                        <span>App</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card ">
          <div className="card-body p-2 noti-tabs">
            <Tabs
              defaultActiveKey={4}
              activeKey={active}
              onSelect={(e) => setActive(+e)}
            >
              {notification?.email && (
                <Tab eventKey={2} title="E-Mail">
                  <div className="mb-2">
                    <label htmlFor="bcc">Bcc:</label>
                    <input
                      className="form-control"
                      type="text"
                      value={input?.bcc}
                      onChange={handleChange}
                      name="bcc"
                      id="bcc"
                    />
                    {errors.bcc && (
                      <div className="help-block text-danger">{errors.bcc}</div>
                    )}
                  </div>
                  <div className="mb-2">
                    <label htmlFor="subject">Subject:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={input?.subject}
                      onChange={handleChange}
                      name="subject"
                      id="subject"
                    />
                    {errors.subject && (
                      <div className="help-block text-danger">
                        {errors.subject}
                      </div>
                    )}
                  </div>
                  <div className="mb-2">
                    <label htmlFor="message"> Body:</label>
                    <JoditEditor
                      ref={editor}
                      tabIndex={1}
                      id="emailmessage"
                      name="emailmessage"
                      value={input?.emailmessage}
                      onChange={(e) => handleEditor(e)}
                    />
                  </div>
                </Tab>
              )}
              {notification?.whatsapp && (
                <Tab eventKey={3} title="WhatsApp">
                  <div className=" mb-2">
                    <label htmlFor="appTitle">SendTo:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={input?.sendTo}
                      onChange={handleChange}
                      name="sendTo"
                      id="sendTo"
                    />
                    {errors.sendTo && (
                      <div className="help-block text-danger">
                        {errors.sendTo}
                      </div>
                    )}
                  </div>

                  <div className="mb-2">
                    <label htmlFor="appTitle">ApiCode:</label>
                    <select
                      className="form-control"
                      value={input?.apiCode}
                      onChange={handleChange}
                      name="apiCode"
                      id="apiCode"
                    >
                      <option value="">Select</option>
                      <option value="FBCLOUD">FBCLOUD</option>
                      <option value="ALERTHUB">ALERTHUB</option>
                    </select>
                  </div>
                  {input.apiCode === "FBCLOUD" ? (
                    <section>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-2">
                            <label htmlFor="message"> Language:</label>
                            <input
                              className="form-control"
                              value="en"
                              name="language"
                              id="language"
                              readOnly
                            ></input>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-2">
                            <label htmlFor="message"> Template:</label>
                            <input
                              className="form-control"
                              value={input?.template}
                              name="template"
                              id="template"
                              onChange={handleChange}
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5>Header</h5>
                            <button
                              className="btn btn-sm"
                              onClick={addFieldHeader}
                            >
                              +
                            </button>
                          </div>

                          {fieldsHeader.map((field, index) => (
                            <div key={index} className="mb-2">
                              Parameter&nbsp; {index + 1}&nbsp;&nbsp;
                              <div className="d-flex gap-10">
                                <select
                                  className="form-control"
                                  value={field.type}
                                  onChange={(e) =>
                                    handleTypeChangeHeader(
                                      index,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="doc">Document</option>
                                  <option value="video">Video</option>
                                  <option value="image">Image</option>
                                  <option value="text">Text</option>
                                </select>
                                {field.type === "text" ? (
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={field.value}
                                    onChange={(e) =>
                                      handleValueChangeHeader(
                                        index,
                                        e.target.value,
                                        "text"
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) =>
                                      handleValueChangeHeader(
                                        index,
                                        e.target.files[0],
                                        "file"
                                      )
                                    }
                                  />
                                )}
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => removeFieldHeader(index)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="col-md-6">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5>Body</h5>{" "}
                            <button
                              className="btn btn-sm"
                              onClick={addFieldBody}
                            >
                              +
                            </button>
                          </div>

                          {fieldsBody.map((field, index) => (
                            <div key={index}>
                              Parameter&nbsp; {index + 1}&nbsp;&nbsp;
                              <div className="d-flex gap-10">
                                <select
                                  className="form-control"
                                  value={field.type}
                                  onChange={(e) =>
                                    handleTypeChangeBody(index, e.target.value)
                                  }
                                >
                                  <option value="">Select</option>
                                  {/* <option value="doc">Document</option>
                                  <option value="video">Video</option>
                                  <option value="image">Image</option> */}
                                  <option value="text">Text</option>
                                </select>
                                {field.type === "text" ? (
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={field.value}
                                    onChange={(e) =>
                                      handleValueChangeBody(
                                        index,
                                        e.target.value,
                                        "text"
                                      )
                                    }
                                  />
                                ) : (
                                  <input
                                    className="form-control"
                                    type="file"
                                    onChange={(e) =>
                                      handleValueChangeBody(
                                        index,
                                        e.target.files[0],
                                        "file"
                                      )
                                    }
                                  />
                                )}
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => removeFieldBody(index)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  ) : input.apiCode === "ALERTHUB" ? (
                    <main>
                      <div className="mb-2">
                        <label htmlFor="message"> Message:</label>
                        <textarea
                          className="form-control"
                          value={input?.whatsappmessage}
                          onChange={handleChange}
                          name="whatsappmessage"
                          id="whatsappmessage"
                        ></textarea>
                        {errors.whatsappmessage && (
                          <div className="help-block text-danger">
                            {errors.whatsappmessage}
                          </div>
                        )}
                      </div>
                      <label>Media</label>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            className="form-control "
                            name="Image"
                            value="IMAGE"
                            readOnly
                          ></input>
                        </div>
                        <div className="col-md-6">
                          <input
                            className="form-control "
                            type="file"
                            onChange={(e) => handleValueChangeMedia(e)}
                          />
                        </div>
                      </div>
                    </main>
                  ) : null}
                </Tab>
              )}
              {notification?.app && (
                <Tab eventKey={4} title="App">
                  <div className="mb-2">
                    <label htmlFor="appTitle">Title:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={input?.appTitle}
                      onChange={handleChange}
                      name="appTitle"
                      id="appTitle"
                    />
                    {errors.appTitle && (
                      <div className="help-block text-danger">
                        {errors.appTitle}
                      </div>
                    )}
                  </div>

                  <div className="mb-2">
                    <label htmlFor="subject">Topic:</label>
                    <input
                      type="text"
                      className="form-control"
                      value="ALL_USER"
                      readOnly
                      name="topic"
                      id="topic"
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="message"> Message:</label>
                    <textarea
                      className="form-control"
                      value={input?.appmessage}
                      onChange={handleChange}
                      name="appmessage"
                      id="message"
                    ></textarea>
                    {errors.appmessage && (
                      <div className="help-block text-danger">
                        {errors.appmessage}
                      </div>
                    )}
                  </div>
                  <div className="mb-2 row">
                    <div className="col-md-10">
                      <label htmlFor="message"> App Image:</label>
                      <input
                        className="form-control"
                        type="file"
                        onChange={handleAppImage}
                        name="appImage"
                        id="appImage"
                      ></input>
                    </div>

                    <div className="col-md-2 text-center">
                      <br></br>
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Image Preview"
                          style={{ maxWidth: "120px", maxHeight: "130px" }}
                        />
                      )}
                    </div>
                  </div>
                </Tab>
              )}
            </Tabs>
            {(notification?.whatsapp && active === 3 && notification?.email) ||
            (notification?.app &&
              active === 4 &&
              (notification?.email || notification?.whatsapp)) ? (
              <button type="button" onClick={handlePrevious} className="btn">
                Previous
              </button>
            ) : (
              ""
            )}
            &nbsp;&nbsp;
            {(notification?.whatsapp && active === 3 && notification?.app) ||
            (notification?.email &&
              active === 2 &&
              (notification?.app || notification?.whatsapp)) ? (
              <button type="button" onClick={handleNext} className="btn">
                Next
              </button>
            ) : (
              ""
            )}
            &nbsp;&nbsp;
            {(notification?.app && active === 4) ||
            (notification?.whatsapp && active === 3 && !notification?.app) ||
            (notification?.email &&
              active === 2 &&
              !(notification?.whatsapp || notification?.app)) ? (
              <button
                type="button"
                onClick={handleSubmit}
                className="btn"
                disabled={submitloader}
              >
                {submitloader ? "Submitting..." : "Submit"}
              </button>
            ) : (
              ""
            )}
            &nbsp;&nbsp;
          </div>
        </div>
      </div>
    </main>
  );
};
export default Notification;
