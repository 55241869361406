import React from "react";
import { useLocation } from "react-router-dom";
import AgriService from "./AgriService";
import { NavLink } from "react-router-dom";

const AgriDetail = () => {
  const location = useLocation();
  const { agridata } = location.state || {};
  const { goback } = AgriService();
  const latitude = 37.7749;
  const longitude = -122.4194;

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center">
                      <ul className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          Agriculture Tourism Detail
                        </li>

                        <li className="breadcrumb-item active">
                          Tourism Name -&nbsp;[ {agridata.title} ]
                        </li>
                      </ul>
                      <span
                        className="float-end btn btn-sm btn-danger mr-3"
                        onClick={goback}
                      >
                        BACK
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: "2px" }}>
          <div className="card p-2 col-md-12">
            <div className="card-header">
              <h5 style={{ color: "green" }}>Detail :</h5>
            </div>
            <div className="card-body p-2">
              <div className="row">
                <div className="col-md-9">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-3">
                          <b style={{ color: "gray" }}>ID:</b>
                        </div>
                        <div className="col-md-9">{agridata.id}</div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-3">
                          <b style={{ color: "gray" }}> Tourism Name:</b>
                        </div>
                        <div className="col-md-9">{agridata.title} </div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-3">
                          <b style={{ color: "gray" }}>Contact Number:</b>
                        </div>
                        <div className="col-md-9">{agridata.contactNo}</div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-3">
                          <b style={{ color: "gray" }}>Address:</b>
                        </div>
                        <div className="col-md-9">
                          {agridata.address}, &nbsp;
                          {agridata.landmark},&nbsp;
                          {agridata.city},&nbsp;
                          {agridata.state},&nbsp;
                          {agridata.pincode},&nbsp;{agridata.country}
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item cus-profileUser">
                      <div className="row">
                        <div className="col-md-3">
                          <b style={{ color: "gray" }}>No. of Cottage :</b>
                        </div>
                        <div className="col-md-9">{agridata.noOfCottage}</div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="col-md-3">
                  <div className="text-right">
                    <p>Image</p>
                    <img
                      style={{ maxWidth: "140px", maxHeight: "180px" }}
                      src={agridata?.images?.split(",")[0]}
                      alt="Image Not Avail"
                      onError={(e) => {
                        e.target.src = "/assets/img/farm.jpg";
                        e.target.onerror = null;
                      }}
                    />
                    <p>Thumbnail</p>
                    <img
                      style={{ maxWidth: "140px", maxHeight: "180px" }}
                      src={agridata?.thumbnail?.split(",")[0]}
                      alt="Image Not Avail"
                      onError={(e) => {
                        e.target.src = "/assets/img/USP_bg.jpg";
                        e.target.onerror = null;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card p-2 col-md-12">
          <div className="card-header">
            <h5 style={{ color: "green" }}>Other Information :</h5>
          </div>
          <div className="card-body pt-2 pb-2 pl-0 pr-0">
            <div className="row">
              <div className="col-md-6">
                <div className="card-header">
                  <h5 style={{ color: "green" }}>Entry By :</h5>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item cus-profileUser">
                    <div className="row ">
                      <div className="col-md-3">
                        <b style={{ color: "gray" }}>Name:</b>
                      </div>
                      <div className="col-md-9">{agridata.entryByName}</div>
                    </div>
                  </li>
                  <li className="list-group-item cus-profileUser">
                    <div className="row ">
                      <div className="col-md-3">
                        <b style={{ color: "gray" }}>Mobile No. :</b>
                      </div>
                      <div className="col-md-9">{agridata.entryByMobile}</div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-md-6">
                <div className="card-header">
                  <h5 style={{ color: "green" }}>Co-ordinates :</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-6">
                          <b style={{ color: "gray" }}>Latitude:</b>
                        </div>
                        <div className="col-md-6">{agridata.latitude}</div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <b style={{ color: "gray" }}>Longitude:</b>
                        </div>
                        <div className="col-md-6">{agridata.longitude}</div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <NavLink
                        to={`https://www.google.com/maps/search/?api=1&queryy=${
                          agridata.latitude || latitude
                        },${agridata.longitude || longitude}`}
                        target="_blank"
                      >
                        <button
                          className="btn btn-green"
                          style={{ height: "2px" }}
                        >
                          Map
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card-header">
                  <h5 style={{ color: "green" }}>Description :</h5>
                </div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item cus-profileUser">
                    <div className="row">
                      <div className="col-md-12">
                        {
                          new DOMParser().parseFromString(
                            agridata.description,
                            "text/html"
                          ).body.textContent
                        }
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AgriDetail;
