import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import UserService from "./UserService";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";

const AssignArea = () => {
  const {
    submitloader,
    assignArea,
    handlePincodeChange,
    pincode,
    getByPinCodes,
    handleAssign,
    pindata,
    pincolumns,
    loading,
  } = UserService();

  useEffect(() => {
    if (pincode !== "") {
      getByPinCodes(pincode);
    }
  }, [pincode]);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row mt-2" style={{ padding: "10px" }}>
                  <div className="col-md-6 col-sm-12">
                    <ul
                      className="breadcrumb p-l-0 p-b-0 "
                      style={{
                        position: "relative",
                        top: "4px",
                        left: "3px",
                      }}
                    >
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Assign Area</li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-popup" id="myForm">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="card p-4">
              <form className="form-container w-100">
                <div className="form-group">
                  <label className="signup_label" htmlFor="pincode">
                    POSTAL CODE:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    name="pincode"
                    value={pincode}
                    onChange={handlePincodeChange}
                  />
                </div>
                <div className=" card">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      {loading ? (
                        <div className="spinner-container">
                          <Spinner type="TailSpin" color="#00BFFF" />
                        </div>
                      ) : (
                        <DataTable
                          columns={pincolumns}
                          data={pindata}
                          pagination
                          className="rdt_TableHeadRow"
                          fixedHeader
                          fixedHeaderScrollHeight="calc(100vh - 205px)"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group  button-group d-flex justify-content-end">
                  {!submitloader ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-green"
                        onClick={handleAssign}
                      >
                        ASSIGN
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="btn btn-green">Processing...</button>
                    </>
                  )}
                  <NavLink to="/PinCodeMapping" className="btn btn-danger">
                    CANCEL
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="card p-4" style={{ minHeight: "172px" }}>
              <h5>Assigned Area</h5>
              <hr />
              <div className="row">
                {assignArea.map((item, i) => (
                  <div className="col-sm-2">
                    <span className="badge bg-secondary">{item.area}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AssignArea;
