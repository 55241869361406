import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import CropService from "./CropService";

const EditCrop = () => {
  const {
    handleChange,
    handleSubmit,
    getCrop,
    submitloader,
    params,
    input,
    errors,
  } = CropService();

  useEffect(() => {
    if (params.id !== undefined) {
      getCrop();
    }
  }, []);

  return (
    <main id="main" className="main">
      <div className="row clearfix ">
        <div className="col-lg-12">
          <div className="card">
            <div className="body">
              <div className="block-header">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <ul className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <NavLink to="/">
                          <i className="fa fa-home"></i>
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">Crops </li>

                      <li className="breadcrumb-item active">Form</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="form-container w-100">
        <div className="card">
          <div className="card-body p-2">
            <div className="form-popup" id="myForm">
              <form className="form-container w-100">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      Name :
                      <input
                        type="text"
                        className="form-control"
                        id="cropName"
                        name="cropName"
                        placeholder="Enter the crop name"
                        value={input?.cropName}
                        onChange={handleChange}
                      />
                      {errors.cropName && (
                        <div className="help-block text-danger">
                          {errors.cropName}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer">
            {!submitloader ? (
              <>
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <button className="btn btn-primary">Submitting...</button>
              </>
            )}
            <NavLink to="/CropList" className="btn btn-danger">
              Close
            </NavLink>
          </div>
        </div>
      </form>
    </main>
  );
};

export default EditCrop;
