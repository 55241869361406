import React from "react";
import Slider from "react-slick";


const TestimonialCarousel = () => {
    const testimonialData = [
      {
        id: 1,
        image: "siteadmin/img/testimonial/user-01.jpg",
        author: 'Dr Shreya Singh',
        text: 'I have never tasted vegetables this fresh! Every bite is bursting with flavor, and I am amazed by the quality. Hello Farmers is my go-to for farm-fresh veggies.',
      },
      {
        id: 2,
        image: "siteadmin/img/testimonial/user-02.jpg",
        author: 'Vidhee Tripathy',
        text: 'As a vegetarian, I rely on fresh vegetables, and Hello Farmers has become my paradise. Their produce is top-notch, and I could not be happier!',
      },
      {
        id: 3,
        image: "siteadmin/img/testimonial/user-03.jpg",
        author: 'Dr. Rashi',
        text: 'Hello Farmers truly brings the farm to our table. The veggies are so crisp and delicious; it is like they were picked just for us.',
      },
      {
        id: 4,
        image: "siteadmin/img/testimonial/user-04.jpg",
        author: 'Abdul Mannan',
        text: 'Quality matters, and it shows in Hello Farmers vegetables. I can taste the difference, and I am hooked on their farm-fresh goodness.',
      },
      {
        id: 5,
        image: "siteadmin/img/testimonial/user-05.jpg",
        author: 'Auqaf Ahmad',
        text: 'I love knowing that I am supporting local agriculture by choosing Hello Farmers. Their veggies are not only tasty but also help our community thrive.',
      },

      {
        id: 6,
        image: "siteadmin/img/testimonial/user-06.jpg",
        author: 'Mukesh Pandey',
        text: 'Proud to be a loyal customer of Hello Farmers. The freshness of their vegetables is unmatched, and I look forward to every harvest.',
      },
    ];
  
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      autoplay:true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2,
      margin:30,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          },
        },
      ],
    };
  
    return (
      <div className="testimonial-carousel">
        <Slider {...settings}>
          {testimonialData.map((testimonial) => (
            <figure className="snip1533" key={testimonial.id}>
            <figcaption>
            
                <blockquote>
                    <p>{testimonial.text}</p>
                </blockquote>
                <div className="d-flex">
                <div className="fig-div">
            <img src={testimonial.image} alt="Hello Farmers" />
            </div>
            <div>
            <h3>{testimonial.author}</h3>
                <h4>Lucknow</h4>
            </div>
                </div>
                
            </figcaption>
        </figure>
            
          ))}
        </Slider>
      </div>
    );
  };

  export default TestimonialCarousel;


//   export default function SimpleSlider() {
//     var settings = {
//       dots: false,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 4,
//       slidesToScroll: 1,
//       arrows: false,
//     };
//     return (
//       <Slider {...settings}>
        
//                   <figure className="snip1533">
//                       <figcaption>
//                           <blockquote>
//                               <p>If you do the job badly enough, sometimes you don't get asked to do it again.</p>
//                           </blockquote>
//                           <h3>Radhika</h3>
//                           <h4>Omax, Lucknow</h4>
//                       </figcaption>
//                   </figure>
              
//                   <figure className="snip1533">
//                       <figcaption>
//                           <blockquote>
//                               <p>If you do the job badly enough, sometimes you don't get asked to do it again.</p>
//                           </blockquote>
//                           <h3>Radhika</h3>
//                           <h4>Omax, Lucknow</h4>
//                       </figcaption>
//                   </figure>
              
//       </Slider>
//     );
//   }