import React from "react";
import { useLocation } from "react-router-dom";
import DealsofDayServices from "./DealsofDayServices";
import { NavLink } from "react-router-dom";



const ListOfDealProduct = () => {
  const location = useLocation();
  const { dealsofDaydata } = location.state || {};
  const { goback, baseUrl } = DealsofDayServices();

  return (
    <>
      <main id="main" className="main">
        <div className="row clearfix ">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="block-header">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 d-flex justify-content-between align-items-center">
                      <ul
                        className="breadcrumb mb-0" >
                        <li className="breadcrumb-item">
                          <NavLink to="/">
                            <i className="fa fa-home"></i>
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">Deals Of Days </li>

                        <li className="breadcrumb-item active">
                          Name -&nbsp;[ {dealsofDaydata?.title} ]
                        </li>
                      </ul>

                      <span
                        className="float-end btn btn-sm btn-danger mr-3"
                        onClick={goback}
                      >
                        BACK
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" pak-height card">
          <table className="table">
            <thead>
              <tr style={{ backgroundColor: "#4aad48" }}>
                <th className="text-center">#</th>
                <th className="text-center">PRODUCT NAME</th>
                <th className="text-center">IMAGE</th>
                <th className="text-center">QUANTITY</th>
                <th className="text-center">PACKAGING</th>
              </tr>
            </thead>
            <tbody>
              {dealsofDaydata?.listofDealProduct?.map((item, i) => (
                <>
                  <tr
                    key={i}
                    data-dealsid={item?.dealsId}
                    data-productid={item?.productId}
                  >
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{item?.productName}</td>
                    <td className="text-center">
                      <img
                        className="card-img-top w-10"
                        height={"40px"}
                        width={"40px"}
                        src={baseUrl + item?.productImage.split(",")[0]}
                        onError={(e) => {
                          e.target.src = "/assets/img/noimage.jpg";
                          e.target.onerror = null;
                        }}
                      />
                    </td>
                    <td className="text-center">{item?.qty}</td>
                    <td className="text-center">{item?.packaging}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  );
};

export default ListOfDealProduct;
