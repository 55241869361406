import HeaderTop from "../../Component/Homes/HeaderTop";
import HeaderBottom from "./Navigation";
import Body from "../../Component/Homes/Body";
import FooterLast from "../../Component/Homes/FooterLast";
import { useRef } from "react";

const Home = () => {
  const sectionRef = useRef(null);
  const sectionsRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToSections = () => {
    sectionsRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      
{/* <div id="preloader">
  <div id="loading-center">
      <img src="siteadmin/img/app-icon.png" alt="Logo" />
  </div>
</div> */}


<button className="scroll-top scroll-to-target" data-target="html">
  <i className="fas fa-angle-up"></i>
</button>
      <HeaderTop />
      {/* <HeaderBottom
        scrollToSection={scrollToSection}
        scrollToSections={scrollToSections}
      /> */}
      <Body sectionRef={sectionRef} sectionsRef={sectionsRef} />
      <FooterLast />
    </>
  );
};
export default Home;
