import { React, useState } from "react";
import { DATACONSTANT } from "../../Services/DataConstant";
import { setCookie } from "../../Services/Cookies";
import "./Login.css";
import { MDBContainer } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { postRequest } from "../../Services/ApiServices";
import styles from "./Login.module.css";
function LoginOtp() {
  const moduleBase = "api";

  const [viewOtpForm, setViewOtpForm] = useState(false);
  const [input, setInput] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const sendOTP = async (e) => {
    e.preventDefault();

    try {
      const res = await postRequest(
        `${moduleBase}/SendLoginOTP?mobileNo=${input.mobileNo}`
      );
      if (res) {
        setViewOtpForm(true);
        toast.success("OTP Send Successfully.....");
      } else {
        toast.error("Credentials invalid");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const otpSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postRequest(
        `${moduleBase}/LoginWithOTP?mobileNo=${input.mobileNo}&otp=${input.otp}`
      );
      if (res.result && res.result == "Admin") {
        toast.success("SignIn Successfully");
        setCookie(DATACONSTANT.COOKIE_NAME, res.result.token, 30);
        window.location.href = "/Dashboard";
      } else {
        toast.error("User Not Authorized");
      }
    } catch (error) {
      toast.error("Invalid OTP!!!");
    }
  };

  return (
    <MDBContainer>
      <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: "1px" }}>
        Login into account with OTP
      </h5>

      <div className="mb-3">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fas fa-mobile"></i>
            </span>
          </div>
          <input
            type="number"
            name="mobileNo"
            id="form3Example1c"
            className="form-control h-60"
            onChange={handleChange}
            placeholder="Your PhoneNumber"
          />
        </div>
      </div>

      {!viewOtpForm ? (
        <>
          <button type="submit" className={styles.btn} onClick={sendOTP}>
            SEND OTP
          </button>
        </>
      ) : (
        <>
          <div className="col-sm-12 mb-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text p-3">
                  <i className="fas fa-lock"></i>
                </span>
              </div>
              <input
                type="number"
                name="otp"
                id="form3Example1c"
                className="form-control"
                onChange={handleChange}
                placeholder="OTP"
              />
            </div>
          </div>

          <button className={styles.btn} type="submit" onClick={otpSubmit}>
            Verify OTP
          </button>
          <button className={styles.btn} type="submit" onClick={""}>
            Resend OTP
          </button>
        </>
      )}
    </MDBContainer>
  );
}

export default LoginOtp;
