import { React, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { format } from "date-fns";
import { DATACONSTANT } from "../../Services/DataConstant";

const DealsofDayServices = () => {
  const [dealsOfDays, setDealsOfDays] = useState([]);
  const [submitloader, setSubmitLoader] = useState(false);
  const [input, setInput] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const editor = useRef(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [product, setProduct] = useState([]);
  const [list, setList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const baseUrl = DATACONSTANT.BASE_URL;
  const [image, setImage] = useState();
  const [showsModal, setShowsModal] = useState(false);
  const [getId, setGetId] = useState();
  const [errors, setErrors] = useState({});
  const [quantity, setQuantity] = useState("");
  const [couponData, setCouponData] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);

  ////// ****GET DEALS OF DAY**** //////

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "60px",
    },
    { name: "EDIT", selector: (row) => row.edit, width: "100px" },
    {
      name: "TITLE",
      selector: (row) => row.title,
      width: "200px",
    },
    {
      name: "IMAGE",
      selector: (row) => row.imagePath,
      width: "120px",
    },

    {
      name: "STARTDATE",
      selector: (row) => row.startDate,
      width: "190px",
    },
    {
      name: "ENDDATE",
      selector: (row) => row.endDate,
      width: "190px",
    },
    {
      name: " ACTUALCOST",
      selector: (row) => row.actualCost,
      width: "130px",
    },
    {
      name: "DISCOUNT",
      selector: (row) => row.discount,
      width: "100px",
    },
    {
      name: "DISCOUNTCOST",
      selector: (row) => row.discountedCost,
      width: "140px",
    },
    {
      name: "ALLOWQUANTITY",
      selector: (row) => row.allowQuantity,
      width: "130px",
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
      width: "240px",
    },
  ];

  const data = dealsOfDays?.map((d, i) => {
    const formattedStartDate = d.startDate
      ? format(new Date(d.startDate), "dd MMMM yyyy HH:mm:ss")
      : "";
    const formattedEndDate = d.endDate
      ? format(new Date(d.endDate), "dd MMMM yyyy HH:mm:ss")
      : "";
    return {
      serial: i + 1,
      edit: (
        <>
          <NavLink
            to={{
              pathname: "/EditDealsofDay/" + d.dealsId,
            }}
          >
            <i className="fa fa-edit cus-font-color"></i>
          </NavLink>
          <span
            onClick={() => handlepopup(d?.dealsId)}
            style={{ cursor: "pointer" }}
          >
            <i
              title="Delete"
              className="fa fa-trash fa-sm text-danger mx-2"
            ></i>
          </span>
          <NavLink
            to="/ListOfDealProduct"
            state={{ dealsofDaydata: d }}
            style={{ color: "green" }}
          >
            <i className="fa fa-plus-square"></i>&nbsp;
          </NavLink>
        </>
      ),
      title: (
        <>
          <div>
            <span title={d?.title}>
              {d?.title?.toString()?.slice(0, 22) || ""}{" "}
              {d?.title?.length >= 22 ? "..." : ""}
            </span>
            <small className="d-block">[ID:{d.dealsId}]</small>
          </div>
        </>
      ),
      imagePath: (
        <>
          <div className="product-coupon-div">
            {d?.couponCodes ? (
              <span title={d?.couponCodes}>
                <i type="button" className="fa fa-gift"></i>
              </span>
            ) : (
              ""
            )}
            <img
              className="card-img-top w-10"
              src={baseUrl + d?.imagePath?.split(",")[0]}
              onError={(e) => {
                e.target.src = "/assets/img/noimage.jpg";
                e.target.onerror = null;
              }}
              style={{ height: "40px", width: "40px" }}
            />
          </div>
        </>
      ),
      actualCost: (
        <>
          <div className="text-right">
            <i
              className="text-success fas fa-rupee-sign"
              aria-hidden="true"
            ></i>
            &nbsp;
            {d.actualCost}
          </div>
        </>
      ),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      discount: (
        <>
          <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
          &nbsp;
          {d?.discount}
        </>
      ),
      discountedCost: (
        <>
          <i className="text-success fas fa-rupee-sign" aria-hidden="true"></i>
          &nbsp;
          {d?.discountedCost}
        </>
      ),
      allowQuantity: d?.allowQuantity,
      description: (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: d.description,
            }}
          />
        </>
      ),
    };
  });
  const listDealsOfDays = async () => {
    try {
      const res = await postRequest(`api/DealsofDay/Get`);
      if (res) {
        setDealsOfDays(res);
        setLoading(false);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const goback = () => {
    navigate("/GetDealsOfDay");
  };
  ////// ****GET DEALS OF DAY**** //////

  ///// select product//////

  const handleProductSelect = (product) => {
    if (!selectedItems.some((item) => item.productId === product.productId)) {
      let newObj = { ...product };
      newObj.qty = 1;
      setSelectedItems([...selectedItems, newObj]);
    } else {
      const updatedSelection = selectedItems.filter(
        (item) => item.productId !== product.productId
      );
      setSelectedItems(updatedSelection);
    }
  };
  const handleConfirmSelection = () => {
    setSelectedItems(...selectedItems);
    handleCloseModal();
  };
  ///////////////////////

  /// ****  EDIT DEALS OF DAY ****////

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleStartDate = (e) => {
    setStartDate(e);
  };

  const handleEndDate = (e) => {
    setEndDate(e);
  };

  const handleEditor = (e) => {
    setInput((prev) => {
      return {
        ...prev,
        ["description"]: e,
      };
    });
  };

  /// **** OPEN MODAL ****////

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  /// **** OPEN MODAL ****////

  //// **** PRODUCT LIST CATEGORYWISE ****//////

  const getCategoryList = async () => {
    try {
      const res = await postRequest(`api/Catgory/Get`, {
        parentId: 0,
      });
      if (res?.result) {
        let _l = [
          { categoryId: 0, categoryName: "Select Category" },
          ...res.result,
        ];
        setList(_l);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleCateogryList = (event) => {
    getproductList(event.target.value);
  };

  const getproductList = async (id) => {
    try {
      const res = await postRequest(`api/Product/Get`, {
        categoryId: id || 0,
      });
      if (res.result.products) {
        setProduct(res.result.products);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  //// **** PRODUCT LIST CATEGORYWISE ****//////

  //// **** EDIT LIST BY ID***//////

  const getbyIDDealofDay = async (id) => {
    try {
      const res = await postRequest(`api/DealsofDay/GetById`, {
        dealsId: id,
      });

      if (res) {
        if (id) {
          setInput(res[0]);
          setStartDate(new Date(res[0]?.startDate));
          setEndDate(new Date(res[0]?.endDate));
          setSelectedItems(res[0]?.listofDealProduct);
        }
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleQuantityChange = (value, i) => {
    const updatedItems = [...selectedItems];
    updatedItems[i].qty = value;
    setSelectedItems(updatedItems);
  };

  const handleRemove = (index) => {
    setSelectedItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems.splice(index, 1);
      return updatedItems;
    });
  };

  //// **** EDIT LIST BY ID***//////

  const handleImage = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const objectUrl = window.URL.createObjectURL(e.target.files[0]);
    input["Images"] = objectUrl;
    setImage({
      value: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const convertedArray = selectedItems.map(({ productId, qty }) => ({
      productId,
      qty: qty,
    }));
    const isValid = validateInput();
    if (!isValid) {
      return;
    }

    setSubmitLoader(true);
    try {
      let res = {};
      let formData = new FormData();
      let __d = {
        ...input,

        listofProduct: JSON.stringify(convertedArray),
        startDate: moment(startDate).format("MM/DD/yyyy HH:mm:ss"),
        endDate: moment(endDate).format("MM/DD/yyyy HH:mm:ss"),
      };
      let coupons = selectedCoupons?.map((item) => item.value);
      if (coupons?.length > 0) {
        __d.applicableCoupons = coupons;
      }
      formData.append("Images", image?.value);
      for (let key in __d) {
        formData.append(key, __d[key]);
      }
      res = await postRequest(
        `api/DealsofDay/AddUpdate`,
        formData,
        "multipart/form-data"
      );
      if (res?.statusCode !== 1) {
        toast.error(res.result?.error || res?.responseText || "Failed to add.");
      } else {
        toast.success(res?.responseText);
        navigate("/GetDealsOfDay");
        setLoading(false);
      }
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.log("Error", error);
      // toast.error("Something went wrong");
    }
  };
  //////****  DELETE THE DEAL*****///////
  const handlepopup = async (dealId) => {
    setShowsModal(true);
    setGetId(dealId);
  };

  const handleClose = () => setShowsModal(false);

  const handleDelete = async () => {
    try {
      const res = await postRequest(`api/DealsofDay/Delete?id=${getId}`);
      if (res) {
        listDealsOfDays();
        setShowsModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //////****  DELETE THE DEAL*****///////

  ////////*VALIDATION ON DEAL*////////

  const validateInput = () => {
    const newErrors = {};
    if (!input?.title) {
      newErrors.title = "Please enter the title.";
    }
    if (!input?.allowQuantity) {
      newErrors.allowQuantity = "Please enter the quantity.";
    }
    if (!input?.discountedCost) {
      newErrors.discountedCost = "Please enter the discountedCost.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  ////////*VALIDATION ON DEAL*////////

  const getCouponList = async () => {
    try {
      const res = await postRequest(`api/Coupon/GetCouponsDDL`);
      if (res) {
        let option = res?.map((item) => ({
          value: item.id,
          label: item.code,
        }));
        if (input?.couponIds) {
          let selected = res?.map((item) => {
            if (input?.couponIds?.includes(item.id)) {
              return {
                value: item.id,
                label: item.code,
              };
            }
          });
          selected = selected?.filter((item) => !!item?.label);
          setSelectedCoupons(selected);
        }
        setCouponData(option);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptions = (e) => {
    setSelectedCoupons(e);
  };

  return {
    listDealsOfDays,
    loading,
    columns,
    data,
    goback,
    handleSubmit,
    handleChange,
    input,
    submitloader,
    getbyIDDealofDay,
    handleEditor,
    editor,
    handleStartDate,
    startDate,
    handleEndDate,
    endDate,
    getproductList,
    product,
    list,
    getCategoryList,
    handleCateogryList,
    handleQuantityChange,
    showModal,
    handleCloseModal,
    handleOpenModal,
    handleProductSelect,
    handleConfirmSelection,
    selectedItems,
    handleRemove,
    baseUrl,
    handleImage,
    showsModal,
    handleClose,
    handleDelete,
    errors,
    couponData,
    handleOptions,
    selectedCoupons,
    getCouponList,
    setStartDate,
    setEndDate,
  };
};

export default DealsofDayServices;
