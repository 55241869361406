import { React, useState } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { getRequest, postRequest } from "../../Services/ApiServices";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const moduleBase = "api";
  const navigate = useNavigate();
  const [input, setInput] = useState();
  const [area, setarea] = useState();
  const [viewOtpForm, setViewOtpForm] = useState(false);
  const [phoneloader, setPhoneLoader] = useState(false);
  // const [arealoader, setAreaLoader] = useState(false);

  const handleChange = async (e, postalCode, phoneNumber) => {
    const { name, value } = e.target;
    // if (postalCode) {
    //   if (value.length == 6) {
    //     setAreaLoader(true);
    //     const res = await getRequest(
    //       `api/Home/GetAreaListAsync?postalCode=${value}`
    //     );
    //     let _a = [{ id: 0, area: "SELECT " }, ...res];
    //     setarea(_a);
    //     setAreaLoader(false);
    //   }
    // }
    if (phoneNumber) {
      try {
        if (value.length == 10) {
          setPhoneLoader(true);
          const res = await postRequest(
            `api/SendRegistrationOTP?mobileNo=${value}`
          );
          if (res.statusCode == 3) {
            setPhoneLoader(false);
            toast.error(res.responseText);
            return;
          }
          if (res) {
            setViewOtpForm(true);
            setPhoneLoader(false);
            toast.success("OTP has been sent to your Number");
          }
        }
      } catch (error) {
        toast.error("something went wrong");
      }
    }
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      var res = await postRequest(`${moduleBase}/Register`, {
        ...input,
        postalCode: "",
        referralCode: "",
        businessType: [],
      });

      if (res?.statusCode === 1) {
        toast.success("User created Successfully");
        navigate("/Login");
      } else {
        toast.error("Wrong Input");
      }
    } catch (error) {
      toast.error("Something went wrong!!!");
    }
  };

  return (
    <div>
      <div className="container my-5">
        <div className="card">
          <div className="row g-0">
            <div className="col-md-6">
              <img
                className="rounded-start w-100"
                src="assets/img/kisanimage1.jpg"
                alt="login form"
                style={{ height: "90%" }}
              />
            </div>
            <div className="col-md-6">
              <div className="card-body d-flex flex-column">
                <div className="d-flex justify-content-center mb-5">
                  <a
                    href="index.html"
                    className="logo d-flex align-items-center"
                  >
                    <img
                      src="assets/img/logo.png"
                      className="w-100"
                      style={{ maxHeight: "100%" }}
                    />
                  </a>
                </div>

                <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                  <div className="col-sm-12 mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        name="name"
                        id="form3Example1c"
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Your Name"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        name="email"
                        id="form3Example1c"
                        className="form-control"
                        onChange={handleChange}
                        placeholder="Your Email"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text p-3">
                          <i className="fa fa-venus-mars"></i>
                        </span>
                      </div>
                      <select
                        className="form-control"
                        id="gender"
                        name="gender"
                        onChange={handleChange}
                      >
                        <option value="N/A">Select</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Others</option>
                      </select>
                    </div>
                  </div>
                  {!phoneloader ? (
                    <>
                      <div className="col-sm-12 mb-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text p-3">
                              <i className="fas fa-mobile"></i>
                            </span>
                          </div>
                          <input
                            type="number"
                            name="phoneNumber"
                            id="form3Example1c"
                            className="form-control"
                            value={input?.phoneNumber}
                            onChange={(e) => {
                              handleChange(e, false, true);
                            }}
                            placeholder="Your PhoneNumber"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <button className="ripple ripple-surface btn btn-green btn-lg w-100">
                        Loading..
                      </button>
                    </>
                  )}
                  {!viewOtpForm ? (
                    <></>
                  ) : (
                    <>
                      <div className="col-sm-12 mb-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text p-3">
                              <i className="fa fa-key"></i>
                            </span>
                          </div>
                          <input
                            type="number"
                            name="otp"
                            id="form3Example1c"
                            min="0"
                            className="form-control"
                            onChange={handleChange}
                            placeholder="Enter your OTP"
                          />
                        </div>
                      </div>
                      {/* {!arealoader ? (
                        <>
                          <div className="col-sm-12 mb-3">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text p-3">
                                  <i className="fa fa-map-pin"></i>
                                </span>
                              </div>
                              <input
                                type="number"
                                name="postalCode"
                                id="form3Example1c"
                                min="0"
                                className="form-control"
                                value={input?.postalCode}
                                onChange={(e) => handleChange(e, true, false)}
                                placeholder="Enter your PinCode"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <button className="ripple ripple-surface btn btn-green btn-lg w-100">
                            Loading..
                          </button>
                        </>
                      )} */}

                      {/* {area?.length > 0 ? (
                        <div className="col-sm-12 mb-3">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text p-3">
                                <i className="fa fa-map-marker"></i>
                              </span>
                            </div>

                            <select
                              className="form-control"
                              name="areaId"
                              onChange={handleChange}
                            >
                              {area?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.area}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}

                      <div className="form-check  mb-3">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          value=""
                          id="aggrement"
                        />
                        <label className="form-check-label" htmlFor="aggrement">
                          I agree all statements in
                          <NavLink to="/Terms" target="_blank">
                            <span>Term of Service</span>
                          </NavLink>
                        </label>
                      </div>
                      <div className="">
                        <button
                          type="submit"
                          className="ripple ripple-surface btn btn-green btn-lg w-100"
                        >
                          Register
                        </button>
                      </div>
                    </>
                  )}
                </form>

                <p
                  className="mb-2 pb-lg-2 d-flex justify-content-center mt-3"
                  style={{ color: "rgb(57, 63, 129)" }}
                >
                  <NavLink to="/Login" className="nav-link">
                    <span>Back to Login</span>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Registration;
