import React, { useState } from "react";
import { toast } from "react-toastify";
import { setCookie } from "../../Services/Cookies";
import { postRequest } from "../../Services/ApiServices";
import { DATACONSTANT } from "../../Services/DataConstant";
import { NavLink } from "react-router-dom";
import styles from "./Login.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import LoginOtp from "./LoginOtp";

function Login() {
  const moduleBase = "api";

  const [input, setInput] = useState();
  const [basicActive, setBasicActive] = useState("tab1");
  const [disableButton, setdisableButton] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate=useNavigate()
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    setdisableButton(true);
    e.preventDefault();
    try {
      const res = await postRequest(`${moduleBase}/Login`, {
        ...input,
        rememberMe: true,
        isTwoFactorEnabled: true,
        gAuthPin: "",
      });
      if (res.statusCode === 1) {
        localStorage.setItem("role", res.result.role);
        setCookie(DATACONSTANT.COOKIE_NAME, res.result.token, 30);
        toast.success(res.responseText);
        window.location.href = "/Dashboard";
      } else {
        window.location.href = "/Login";
        setdisableButton(false);
        toast.error(res.responseText);
      }
    } catch (error) {
      window.location.href = "/Login";
      toast.error("Something went wrong!!!");
      console.error(error);
      setdisableButton(false);
    }
  };
  return (
    <div>
      <div className="container my-5">
        <div className="card">
          <div className="row g-0">
            <div className="col-md-6">
              <img
                className="rounded-start img-fluid"
                src="assets/img/kisanimage1.jpg"
                alt="login form"
              />
            </div>

            <div className="col-md-6">
              <div className="card-body d-flex flex-column">
                <div className="d-flex justify-content-center mb-4 mt-4">
                  <a href="/home" className="logo d-flex align-items-center">
                    <img src="assets/img/logo.png" />
                  </a>
                </div>

                <MDBTabs className="mb-3">
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleBasicClick("tab1")}
                      active={basicActive === "tab1"}
                    >
                      LOGIN
                    </MDBTabsLink>
                  </MDBTabsItem>
                  <MDBTabsItem>
                    <MDBTabsLink
                      onClick={() => handleBasicClick("tab2")}
                      active={basicActive === "tab2"}
                    >
                      <span className=" text-nowrap"> LOGIN WITH OTP</span>
                    </MDBTabsLink>
                  </MDBTabsItem>
                </MDBTabs>
                <MDBTabsContent>
                  <MDBTabsPane show={basicActive === "tab1"}>
                    <h5
                      className="fw-normal my-4 pb-3"
                      style={{ letterSpacing: "1px" }}
                    >
                      Login into your account
                    </h5>

                    <form className="" onSubmit={handleSubmit}>
                      {/* <div className="mb-3">
                        <select
                          name="yourDropdownName"
                          className="form-control"
                         // onChange={handleDropdownChange}
                        >
                          <option value="option1">Option 1</option>
                          <option value="option2">Option 2</option>
                          <option value="option3">Option 3</option>
                        </select>
                      </div> */}
                      <div className="mb-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-mobile"></i>
                            </span>
                          </div>
                          <input
                            type="number"
                            name="mobileNo"
                            id="form3Example1c"
                            className="form-control h-60"
                            onChange={handleChange}
                            placeholder="Your PhoneNumber"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </span>
                          </div>
                          <input
                            type={showNewPassword ? "text" : "password"}
                            name="password"
                            id="form3Example1c"
                            className="form-control h-60"
                            onChange={handleChange}
                            placeholder="Your Password"
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-success cus-linecorner cus-corner"
                              type="button"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                            >
                              <FontAwesomeIcon
                                icon={showNewPassword ? faEyeSlash : faEye}
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div>
                        {!disableButton ? (
                          <button type="submit" className={styles.btn}>
                            Login
                          </button>
                        ) : (
                          <button
                            className={styles.btn}
                            type="submit"
                            disabled="disabled"
                          >
                            Requesting....
                          </button>
                        )}
                      </div>
                      <a
                        className="small text-muted d-flex justify-content-end pt-2"
                        href="#!"
                      >
                        Forgot password?
                      </a>
                    </form>
                  </MDBTabsPane>
                  <MDBTabsPane show={basicActive === "tab2"}>
                    <LoginOtp />
                  </MDBTabsPane>
                </MDBTabsContent>

                <p className="mb-2 pb-lg-2 pt-2" style={{ color: "#393f81" }}>
                  Don't have an account?
                  <NavLink to="/Registration">
                    <span> Register here</span>
                  </NavLink>
                </p>

                <div className="d-flex justify-content-between">
                  <div className="pull-left">
                    <a
                      href="termcondition.html"
                      className="small text-muted me-1"
                    >
                      Terms of use.
                    </a>

                    <a href="privacy-policy.html" className="small text-muted">
                      Privacy policy
                    </a>
                  </div>

                  <NavLink to="/home" className="nav-link pull-right">
                    <span>BACK TO HOME</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
