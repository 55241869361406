import React from "react";
import { NavLink } from "react-router-dom";
import CustomerCareService from "./CustomerCareService";

const AddCustomerCare = () => {
  const handleKeyDown = (e) => {
    if (
      !(e.key >= "0" && e.key <= "9") &&
      !["ArrowLeft", "ArrowRight", "Backspace", "Delete"].includes(e.key)
    ) {
      e.preventDefault();
    }
  };
  const { handleChange, submitloader, handleSubmit, area, errors } =
    CustomerCareService();
  return (
    <div>
      <div>
        <main id="main" className="main">
          <div className="row clearfix ">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="block-header">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <ul className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <NavLink to="/">
                              <i className="fa fa-home"></i>
                            </NavLink>
                          </li>
                          <li className="breadcrumb-item">CustomerCare</li>
                          <li className="breadcrumb-item">Create Profile</li>
                          <li className="breadcrumb-item active">Form</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form className="form-container w-100">
            <div className="card">
              <div className="card-bosy p-2">
                <div className="form-popup" id="myForm">
                  <form className="form-container w-100">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          Name :
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter the  name"
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <div className="help-block text-danger">
                              {errors.name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          Phone Number :
                          <input
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            placeholder="Enter the phone number"
                            name="phoneNumber"
                            onKeyDown={handleKeyDown}
                            maxLength={10}
                            onChange={handleChange}
                          />
                          {errors.phoneNumber && (
                            <div className="help-block text-danger">
                              {errors.phoneNumber}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          Email :
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter the email address"
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <div className="help-block text-danger">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          PinCode :
                          <input
                            type="text"
                            className="form-control"
                            id="postalCode"
                            name="postalCode"
                            onKeyDown={handleKeyDown}
                            maxLength={6}
                            placeholder="Enter the pincode"
                            onChange={(e) => handleChange(e, true)}
                          />
                          {errors.postalCode && (
                            <div className="help-block text-danger">
                              {errors.postalCode}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          Area :
                          <select
                            className="form-control"
                            name="areaId"
                            onChange={handleChange}
                          >
                            {area?.map((item) => (
                              <>
                                <option value="">Select...</option>
                                <option key={item.id} value={item.id}>
                                  {item.area}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                        {errors.areaId && (
                          <div className="help-block text-danger">
                            {errors.areaId}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group mb-4">
                          Gender :
                          <select
                            className="form-control"
                            id="gender"
                            name="gender"
                            onChange={handleChange}
                          >
                            <option value="">Select...</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Others</option>
                          </select>
                        </div>
                        {errors.gender && (
                          <div className="help-block text-danger">
                            {errors.gender}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-green"
                  onClick={handleSubmit}
                  disabled={submitloader}
                >
                  {submitloader ? "Submitting..." : "Submit"}
                </button>

                <NavLink to="/CustomerCare" className="btn btn-danger">
                  Cancel
                </NavLink>
              </div>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
};

export default AddCustomerCare;
