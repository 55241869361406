import React, { useState } from "react";
import { postRequest, getRequest } from "../../../Services/ApiServices";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";

const IrrigationService = () => {
  const [irrigationSource, setIrrigationSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [submitloader, setSubmitLoader] = useState(false);
  const [input, setInput] = useState({});
  const [isDeleteShow, setIsDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDelete = (id) => {
    setIsDeleteShow(!isDeleteShow);
    setDeleteId(id);
  };

  const handleClose = () => {
    setIsDeleteShow(false);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleteShow(!isDeleteShow);
    try {
      setLoading(true);
      const res = await postRequest(
        `api/Bussiness/DeleteIrrigationSource/${deleteId}`
      );
      if (res.statusCode == 1) {
        getIrrigationSource();
      } else {
        toast.error("Credentials invalid");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.serial,
      width: "90px",
    },
    {
      name: "ACTION",
      selector: (row) => row.action,
      width: "100px",
    },
    {
      name: "NAME",
      selector: (row) => row.name,
      width: "270px",
    },
  ];

  const data = irrigationSource?.map((d, i) => ({
    serial: i + 1,
    name: (
      <>
        {d.name}
        <br />
        [ID:{d.id}]
      </>
    ),
    action: (
      <>
        <NavLink
          to={{
            pathname: "/AddIrrigationSource",
          }}
          state={{ editData: d }}
        >
          <i className="fa fa-edit cus-font-color"></i>
        </NavLink>
        <span
          type="button"
          className="text-danger mx-3"
          onClick={() => handleDelete(d.id)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </>
    ),
  }));

  const getIrrigationSource = async (id) => {
    try {
      setLoading(true);
      const res = await getRequest(`api/Bussiness/GetIrrigationSource`);
      if (res.result) {
        setIrrigationSource(res.result);
        setLoading(false);
      } else {
        toast.error("Credentials invalid");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input?.name) {
      return toast.error("Please enter name.");
    }
    setSubmitLoader(true);
    try {
      let res = await postRequest(`api/Bussiness/AddIrrigationSource`, input);
      if (!res) {
        toast.error(res?.result.error);
      } else {
        toast.success(res.responseText);
        if (res.statusCode === 1) {
          return navigate("/IrrigationSource", { replace: true });
        }
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message || "Something went wrong");
      } else {
        toast.error("Something went wrong");
      }
    }
    setSubmitLoader(false);
  };

  return {
    getIrrigationSource,
    loading,
    columns,
    data,
    setInput,
    input,
    submitloader,
    handleChange,
    handleSubmit,
    isDeleteShow,
    handleClose,
    handleDeleteConfirm,
    // farmCommunityList,
  };
};

export default IrrigationService;
